// src/app/services/chatbot.service.ts

import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Subscription} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {User} from '../../models/business-objects';
import {isPlatformBrowser} from '@angular/common';
import {AiChatMessage} from '../../models/chat-objects';


@Injectable({
  providedIn: 'root'
})
export class ChatbotService implements OnDestroy {
  private threadIdKey: string = 'chatbot_thread_id'; // Key for localStorage (authenticated users)
  private apiUrl = environment.apiURL + environment.URL_CHAT_BOT; // Ensure this is correctly set
  private user : User;
  // Visibility state managed by BehaviorSubject
  private visibilitySource = new BehaviorSubject<boolean>(false);
  visibility$ = this.visibilitySource.asObservable();
  subscriptions = [];
  onBrowser = isPlatformBrowser(this.platformId);
  constructor(private http: HttpClient, private authService: AuthService,@Inject(PLATFORM_ID) private platformId: any,) {
    // Subscribe to AuthService's currentUser observable
    console.log('ChatbotService instance created:', this);
    this.user = this.authService.currentUserValue;
    this.subscriptions.push ( this.authService.currentUser.subscribe(user => {
        if (user) {
          if (this.user) {
            // User is logged in
            // Optionally, you can initiate chatbot visibility or other actions here
          }
          this.user = user;
        } else {
          // User is logged out
          if (this.user) {
            this.removeThreadId();
            this.hideChatbot();
            this.user = null;
          }
        }
      })
    );
    console.log('ChatbotService constructor');
    this.subscriptions.push ( this.visibility$.subscribe(value => {
      console.log('visibility$ emitted:', value);
    })
    );
  }

  /**
   * Show the chatbot and initialize or reactivate the thread.
   */
  async showChatbot(): Promise<void> {
    console.log('ChatbotService showChatbot() called');
    const existingThreadId = await this.getThreadId();
    console.log('Existing Thread ID:', existingThreadId);
    if (existingThreadId !== null) {
      // Fetch chat history if needed
      console.log('Found chatbot thread id %s', existingThreadId);
    } else {
      // Start a new chat thread
      const newThreadId = await this.startChat();
      console.log('creating new thread id %s', newThreadId);
      if (newThreadId) {
        this.setThreadId(newThreadId);
        console.log('New Thread ID set:', newThreadId);
      }
    }
    this.visibilitySource.next(true);
    console.log('visibilitySource.next(true) called');
  }

  /**
   * Hide the chatbot.
   */
  hideChatbot(): void {
    this.visibilitySource.next(false);
  }

  /**
   * Toggle the chatbot's visibility.
   */
  toggleChatbot(): void {
    const isVisible = this.visibilitySource.value;
    this.visibilitySource.next(!isVisible);
    if (!isVisible) {
      this.showChatbot();
    } else {
      this.hideChatbot();
    }
  }

  /**
   * Initialize a new chat thread.
   */
  public startChat(): Promise<string | null> {
    return this.http.post<any>(`${this.apiUrl}/start`, {})
      .toPromise()
      .then(response => response.threadId || null)
      .catch(error => {
        console.error('Error starting chat:', error);
        return null;
      });
  }

  /**
   * Send a message to the Assistant.
   */
  async sendMessage(message: string): Promise<AiChatMessage[]> {
    const user = this.authService.currentUserValue;
    let threadId = await this.getThreadId();
    console.debug('sendMessage threadId:', threadId);
    console.debug('sendMessage message:', message);
    if (threadId !== null) {
      return this.sendMessageWithThread(threadId, message, user !== null);
    } else {
      console.error('Error sending message: No threadId available');
      return [{ message: 'Unable to send message.', error: 'No threadId available.' , sender :'bot', timestamp : new Date(), type: 'text' ,threadId:threadId}];
    }
  }

  private sendMessageWithThread(threadId: string, message: string, isAuthenticated: boolean): Promise<AiChatMessage[]> {
    const params = new HttpParams().set('threadId', threadId);
    const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
    let  replies :AiChatMessage[] =  [];
    return this.http.post<any>(`${this.apiUrl}/message`, message, { params, headers })
      .toPromise()
      .then(data => {
        // la respuesta es un array de AiMessageReply de java
        const messageQty : number  = data.length;
        let response :AiChatMessage;
        if ( messageQty === 0 ) {
          return [{ message: 'Empty response... sorry, please contact us', error: 'An error occurred.' , sender :'bot', timestamp : new Date(), type: 'text' ,threadId:threadId}];
        }
        if ( messageQty === 1 ) {
          response= data[0];
          console.debug('sendMessageWithThread response:', response);
          if (response.error) {
            return [{
              ...response,
              message: 'Unable to send message.',
              error: 'An error occurred.',
              sender: 'bot',
              timestamp: new Date(),
              type: 'text',
              threadId: threadId
            }];
          } else if (response.message !== null) {
            if (isAuthenticated && response.threadId) {
              this.setThreadId(response.threadId);
            }
            replies.push(new AiChatMessage(response));
            return replies;
          } else  {
            return [{
              ...response,
              message: 'No response from Chat Bot',
              error: 'No response from Chat Bot.',
              sender: 'bot',
              timestamp: new Date(),
              type: 'text',
              threadId: threadId
            }];
          }
        } else {
          return data.map(item => new AiChatMessage(item));
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
        return [{ message: 'Unable to send message.', error: error.message , sender :'bot', timestamp : new Date(), type: 'text',threadId:threadId }];
      });
  }

  /**
   * Retrieve threadId from local storage.
   */
  getThreadId(): Promise<string | null> {
    if(this.onBrowser) {
      const value = localStorage.getItem(this.threadIdKey);

      // Check if value is neither 'undefined' nor 'null' as strings and not falsy
      return Promise.resolve(
        value && value !== 'undefined' && value !== 'null' ? value.trim() : null
      );
    }
  }

  /**
   * Store threadId in local storage.
   */
  setThreadId(threadId: string): void {
    if(this.onBrowser) {
      localStorage.setItem(this.threadIdKey, threadId.trim());
    }

  }

  /**
   * Remove threadId from local storage and in-memory temporaryThreadId.
   */
  private removeThreadId(): void {
    if(this.onBrowser) {
      localStorage.removeItem(this.threadIdKey);
    }

  }

  /**
   * Fetch chat history from the backend using threadId.
   */
  async fetchChatHistory(threadId: string): Promise<AiChatMessage[] | null> {
    const params = new HttpParams().set('threadId', threadId);
    try {
      const data = await lastValueFrom(this.http.get<any[]>(`${this.apiUrl}/history`, { params }));
      return data.map(item => new AiChatMessage(item));
    } catch (error) {
      console.error('Error fetching chat history:', error);
      return null;
    }
  }

  /**
   * Clean up subscriptions
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    if(this.onBrowser) {
      localStorage.removeItem(this.threadIdKey);
    }
  }
}
