<div class="fuec-container">
  <!-- Header Section -->
  <div class="fuec-header">
    <h1>VBVH ONLINE FUEC</h1>
  </div>


  <!-- Main Content Section -->
  <div class="fuec-content">
    <!-- Verification Section -->
    <div class="verification-section">
      <div class="verification-header">
        <strong>VERIFICACION ONLINE "FUEC"</strong><br />
        <span class="verification-date">
          EXPEDIDO el:<br />
          {{ fuec.created | date: 'dd MMM yyyy' }}, Hora  {{ fuec.created | date: 'HH:mm:ss a' }}<br />
          <strong>#{{ fuec.fullNumber }}</strong>
        </span>
      </div>
      <!-- Contract Details -->
      <div class="contract-details">
        <table class="details-table">
          <tr>
            <td><strong>RAZON SOCIAL:</strong><br /><span class="highlight">VBVH S.A.S</span></td>
          </tr>
          <tr>
            <td><strong>NIT:</strong><br /><span class="highlight">900220120</span></td>
          </tr>
          <tr>
            <td>
              <strong>CONTRATO:</strong> {{fuec.contractNumber}}<br />
              <strong>Contratante:</strong><br /> {{fuec.contractorName}}<br />
              <strong>Nit / Cedula :</strong><br /> {{fuec.contractorId}}<br />
              <strong>Objeto Contrato:</strong><br />
              {{ fuec.contractObject }}

            </td>
          </tr>
          <tr>
            <td>
              <strong>RECORRIDO:</strong><br />
              <div class="itinerary-text">
            <span *ngFor="let itinerary of fuec.itineraries; let last = last">
              {{ itinerary }}<span *ngIf="!last"></span>
            </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <strong> CONSORCIO / U.TEMP CON:</strong><br />
            </td>
          </tr>



          <tr>
            <td>
              <strong>VIGENCIA CONTRATO:</strong><br />
              <strong>Inicia:</strong> {{ fuec.contractStartDate | date: 'yyyy/MM/dd' }}<br />
              <strong>Termina:</strong> {{ fuec.contractEndDate | date: 'yyyy/MM/dd' }}
            </td>
          </tr>
        </table>
      </div>

      <!-- Vehicle Characteristics -->
      <div class="vehicle-characteristics">
        <strong>CARACTERISTICA VEHICULO:</strong><br />
        <strong>Placa:</strong> {{ fuec.vehicle.plate }}<br />
        <strong>Modelo:</strong> {{ fuec.vehicle.year }}<br />
        <strong>Marca:</strong> {{ fuec.vehicle.makeModel }}<br />
        <strong>Clase:</strong> {{ fuec.vehicle.publicVehicleClass }}<br />
        <strong>No. Interno:</strong> <span class="highlight">{{fuec.vehicle.id }}</span><br />
        <strong>Tjta Operación:</strong> <span class="highlight">{{fuec.vehicleLicence }}</span>
      </div>

      <!-- Drivers Information -->
      <div class="drivers-section">
        <strong>CONDUCTORES:</strong><br />
        <div *ngFor="let driver of fuec.drivers; let i = index" class="driver-info">
          <strong>Conductor {{ i + 1 }}:</strong><br />
          {{ driver.fullName }}<br />
          <strong>No. Cédula:</strong> {{ driver.idNumber }}<br />
          <strong>No. Licencia:</strong> {{ driver.license }}<br />
          <strong>Vigencia:</strong>{{ driver.licenseValidity }}<br /><br />
        </div>
      </div>

      <!-- Responsable Contrato -->
      <div class="responsable-contrato">
        <strong>RESPONSABLE CONTRATO:</strong><br />
        <strong>Nombre:</strong><br />
        {{ fuec.managerName }}<br />
        <strong>Cedula:</strong> {{ fuec.managerId }}<br />
        <strong>Telefono:</strong> {{ fuec.managerPhone }}<br />
        <strong>Dir:</strong> {{ fuec.managerDirection }}
      </div>

      <!-- Empresa Information -->
      <div class="empresa-information">
        <strong>EMPRESA:</strong><br />
        VBVH S.A.S, KRA 23 #68-22 BOGOTA - Cundinamarca
        <br> Telefono fijo 6012114949  <br>
        <br>Email: info&#64;vehiculosvip.com
      </div>
    </div>
  </div>

  <!-- Footer Section -->
  <div class="fuec-footer">
    <p>Todos los derechos reservados 2008-2025</p>
    <p>VBVH SAS</p>
  </div>
</div>
