import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {Error404Component} from './pages/error404/error404.component';
import {NavComponent} from './navigation/nav/nav.component';
import {FooterComponent} from './navigation/footer/footer.component';
import {HomeWrapperComponent} from './widget/home-wrapper/home-wrapper.component';
import {HomeSliderComponent} from './widget/home-slider/home-slider.component';
import {FleetWrapperComponent} from './widget/fleet-wrapper/fleet-wrapper.component';
import {VehicleOverviewComponent} from './pages/vehicle-overview/vehicle-overview.component';
import {RateComponent} from './pages/rate/rate.component';
import {NewsComponent} from './pages/news/news.component';
import {TestimonialsComponent} from './pages/testimonials/testimonials.component';
import {ContactComponent} from './pages/contact/contact.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {SurveyComponent} from './pages/survey/survey.component';
import {LoginComponent} from './pages/login/login.component';
import {CompanyServicesComponent} from './pages/company-services/company-services.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {LocalizeRouterHttpLoader} from '@gilsdav/ngx-translate-router-http-loader';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {BioSecurityComponent} from './pages/bio-security/bio-security.component';
import {VehicleCategoriesComponent} from './pages/vehicle-categories/vehicle-categories.component';
import {FaqComponent} from './pages/faq/faq.component';
import {OnboardComponent} from './pages/onboard/onboard.component';
import {StaffComponent} from './pages/staff/staff.component';
import {StatusComponent} from './pages/status/status.component';
import {I18nModule} from './i18n/i18n.module';


const routes: Routes = [
  {path: 'home',component: HomeComponent},
  {path: 'vehicle/:selectedVehicle/:selectedCity', component: VehicleOverviewComponent},
  {path: 'vehicle/category/:selectedCategory', component: VehicleOverviewComponent},
  {path: 'vehicle/category/:selectedCategory/:selectedCity', component: VehicleOverviewComponent},
  {path: 'vehicle/:selectedVehicle/:selectedCategory/:selectedCity', component: VehicleOverviewComponent},
  {path: 'staff/:staffId', component: StaffComponent},
  {path: 'vehicle-categories', component: VehicleCategoriesComponent},
  {path: 'rates/:serviceLevel', component: RateComponent},
  {path: 'rates/:serviceLevel/:city', component: RateComponent},
  {path: 'news', component: NewsComponent},
  {path: 'testimonials', component: TestimonialsComponent},
  {path: 'company-services', component: CompanyServicesComponent},
  {path: 'news/:tag', component: NewsComponent},
  {path: 'news/id/:id', component: NewsComponent},
  {path: 'news', component: NewsComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'contact-us', component: ContactComponent},
  {path: 'about-us', component: AboutUsComponent},
  {path: 'bio-security', component: BioSecurityComponent},
  {path: 'onboard', component: OnboardComponent},
  {path: 'survey/:id', component: SurveyComponent},
  {path: 'login', component: LoginComponent},
  {path: 'forgot-password/:id/:token', pathMatch: 'full', component: ForgotPasswordComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'change-password', component: ForgotPasswordComponent},
  {path: 'status/:jwt', component: StatusComponent},
  {path: 'statusbysms/:jwt', component: StatusComponent},
  {path: '',  loadChildren:  () => import('./booking/booking.module').then(x => x.BookingModule)},
  {path: '',  loadChildren:  () => import('./payment/payment.module').then(x => x.PaymentModule)},
  {path: '',  loadChildren:  () => import('./fleet/fleet.module').then(x => x.FleetModule)},
  {path: '',  loadChildren:  () => import('./coverage/coverage.module').then(x => x.CoverageModule)},
  {path: '',  loadChildren:  () => import('./pages/privacy-policy/privacy-policy.module').then(x => x.PrivacyPolicyModule)},
  {path: '',  loadChildren:  () => import('./pages/term-and-conditions/terms-and-conditions.module').then(x => x.TermsAndConditionsModule)},
  {path: '',  loadChildren:  () => import('./pages/register/register.module').then(x => x.RegisterModule)},
  {path: '',  loadChildren:  () => import('./private/private.module').then(x => x.PrivateModule)},
  {path: '',  loadChildren:  () => import('./chat/chat.module').then(x => x.ChatModule)},
  {path: '', component: HomeComponent},
  {path: '404', component: Error404Component },
  {path: '**', redirectTo: '/404'}
];
export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings, alwaysSetPrefix: true }, http);
}

@NgModule({
  imports: [
    I18nModule,
    RouterModule.forRoot(routes,
      {
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'disabled',
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64], // [x, y] - adjust scroll offset
        //       preloadingStrategy: PreloadAllModules
      }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      },
      cacheMechanism: CacheMechanism.Cookie,
      cookieFormat: '{{value}};{{expires:20}};path=/',
      initialNavigation: true
      // alwaysSetPrefix: false
    }),

  ],
  exports: [RouterModule, LocalizeRouterModule,I18nModule]
})
export class AppRoutingModule { }
export const routingComponent = [
  HomeComponent, Error404Component, NavComponent, FooterComponent,
  HomeWrapperComponent, HomeSliderComponent, FleetWrapperComponent, VehicleOverviewComponent
];


