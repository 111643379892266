<section id="registration">
  <div class="row auth-height full-height m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden mt-5 mb-5">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                  <img src="../../../assets/img/gallery/login2.jpg" alt="{{'Login-2' | translate}}" class="img-fluid" width="350" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <h4 class="card-title mb-2">{{'Login-2' | translate}}</h4>
                <p>{{'Login-1' | translate}}</p>
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                          <label for="username">{{'Login-3' | translate}}</label>
                          <input id="username" type="text" formControlName="username" class="form-control"
                                 [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                              <div *ngIf="f.username.errors.required">{{'Login-4' | translate}}</div>
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="password">{{'Login-8' | translate}}</label>
                          <input id="password" type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required">{{'Login-5' | translate}}</div>
                          </div>
                      </div>
                      <div class="form-group">
                          <button [disabled]="loading | async" class="btn btn-primary mt-2">{{'Login-2' | translate}}
                            <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
                          </button>
                      </div>
                      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                  </form>
                <a [routerLink]="['/forgot-password' | localize]" title="{{'Login-6' | translate}}"
                   class="btn bg-light-primary me-3 mt-2 mb-2 mb-sm-0">
                  {{'Login-6' | translate}}</a>
                <a [routerLink]="['/registrate' | localize]" title="{{'Login-7' | translate}}</a>"
                   class="btn bg-light-primary mt-2 mb-2 mb-sm-0">
                  {{'Login-7' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
