import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {firstValueFrom, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {VehiclesService} from '../../services/vehicles.service';
import {RateService} from '../../services/rate.service';
import {DataService} from '../../services/data.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading: Subject<boolean> = new Subject();
  submitted = false;
  @Input()  returnUrl;
  error = '';
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthService,
      private translate: TranslateService,
      private vehiclesService: VehiclesService,
      private rateService: RateService,
      private canonicalAndMetaService: CanonicalAndMetaService,
      private localize: LocalizeRouterService,

      private dataService: DataService,
  ) {
    this.translate.setDefaultLang('en');
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('login').then(async result => {
      await this.canonicalAndMetaService.setMetas('login', true, '');
    });
    console.log('entering login');


    if (this.route.snapshot.queryParams.returnUrl) {
      console.log('se detecto un returnUrl ' + this.route.snapshot.queryParams.returnUrl);
      this.returnUrl = this.route.snapshot.queryParams.returnUrl;
    } else {
      this.returnUrl = this.localize.translateRoute('/private');
    }
    console.log('valor de returnUrl saliendo del nginit ' + this.returnUrl);
    if( this.authenticationService.currentUserValue!= null) {
      this.router.navigate(this.returnUrl);
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;
      console.log('Entrando login function');
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          console.log('se sale porque el formulario no esta valido');
          return;
      }

      this.loading.next(true);
      console.log('se dispara la solicitu de login');
    firstValueFrom(this.authenticationService.login(this.f.username.value, this.f.password.value,null)).then(
        data => {
          this.vehiclesService.initializeLists();
          this.rateService.initializeLists();
          if (this.returnUrl) {
            console.log(this.returnUrl);
            const language = this.localize.parser.getLocationLang(this.returnUrl);
            // en caso que le usuario haya cambiado de idiomas mientras se logea tocar restablecer el idioma original de la
            // url que se recibio sino no se va poder utilizarla y la idioma de la barra quedara mal
            if (language) {
              console.log('1-se reenvia a ' + this.returnUrl);
              this.router.navigate([this.returnUrl]).then(() =>{
                this.localize.changeLanguage(language);
              });
            } else {
              console.log('2-se reenvia a this.localize.translateRoute(\'/private\')');
              this.router.navigate([this.localize.translateRoute('/private')]);
            }
          } else {
            console.log('3-se reenvia a this.localize.translateRoute(\'/private\')');
            this.router.navigate([this.localize.translateRoute('/private')]);
          }
        },
        error => {
          console.log('Login Error');
          console.log(error);
          if (error) {
            firstValueFrom(this.translate.get('Error.' + error.result)).then(text => {
              this.error = text;
              if (error.attemptsLeft) this.error = this.error + ' ' + error.attemptsLeft;
              this.loading.next(false);
            });
          } else {
            this.error = "Error Connecting to our server, please try again later";
            this.loading.next(false);
          }
        });
  }
}

