<div class="widget mt-5">
    <div class="widget-block"></div>

    <h3>{{'News-1' | translate}}</h3>

    <div class="news-widget-wrapper clearfix" *ngIf="this.newsList.length >0">
      <div class="news-widget-content">
        <h4><a [routerLink]="'/news' | localize" href="{{'/news' | localize}}">{{this.newsList[0].title}}</a></h4>
        <p>Category: <a [routerLink]="('/news' | localize ) + '/'
                        +this.newsList[0].tags.slice(0
                        ,this.newsList[0].tags.indexOf(';')  !== -1 ? this.newsList[0].tags.indexOf(';') : undefined)"
                        href="{{('/news' | localize ) + '/'
                        +this.newsList[0].tags.slice(0
                        ,this.newsList[0].tags.indexOf(';')  !== -1 ? this.newsList[0].tags.indexOf(';') : undefined)}}">
          {{this.newsList[0].tags.slice(0,this.newsList[0].tags.indexOf(';')  !== -1 ? this.newsList[0].tags.indexOf(';') : undefined)}}</a></p>
      </div>
    </div>

    <div class="news-widget-wrapper clearfix" *ngIf="this.newsList.length >1">
      <div class="news-widget-content">
        <h4><a [routerLink]="'/news' | localize" href="{{'/news' | localize}}">{{this.newsList[1].title}}</a></h4>
        <p>Category: <a [routerLink]="('/news' | localize ) + '/' + this.newsList[1].tags
                        .slice(this.newsList[1].tags.indexOf(';')+1,
                        this.newsList[1].tags.indexOf(';') !== -1 ? this.newsList[1].tags.indexOf(';') : undefined )"
                        href="{{('/news' | localize ) + '/' + this.newsList[1].tags
                        .slice(this.newsList[1].tags.indexOf(';')+1,
                        this.newsList[1].tags.indexOf(';') !== -1 ? this.newsList[1].tags.indexOf(';') : undefined )}}">
          {{this.newsList[1].tags.slice(this.newsList[1].tags.indexOf(';')+1,
          (this.newsList[1].tags.indexOf(';') !== -1 ? this.newsList[1].tags.indexOf(';') : undefined ))}}</a></p>
      </div>
    </div>

    <div class="news-widget-wrapper clearfix" *ngIf="this.newsList.length >2">
      <div class="news-widget-content">
        <h4><a [routerLink]="'/news' | localize" href="{{'/news' | localize}}">{{this.newsList[2].title}}</a></h4>
        <p>Category: <a [routerLink]="('/news' | localize ) + '/' + this.newsList[2].tags
                        .slice(this.newsList[2].tags.indexOf(';',this.newsList[2].tags.indexOf(';')+1)+1,
                               this.newsList[2].tags.indexOf(';') !== -1 ? this.newsList[2].tags.indexOf(';') : undefined )"
                        href="{{('/news' | localize ) + '/' + this.newsList[2].tags
                        .slice(this.newsList[2].tags.indexOf(';',this.newsList[2].tags.indexOf(';')+1)+1,
                               this.newsList[2].tags.indexOf(';') !== -1 ? this.newsList[2].tags.indexOf(';') : undefined )}}">
          {{this.newsList[2].tags.slice(this.newsList[2].tags.indexOf(';',this.newsList[2].tags.indexOf(';')+1)+1,
          (this.newsList[2].tags.indexOf(';') !== -1 ? this.newsList[2].tags.indexOf(';') : undefined ))}}</a></p>
      </div>
    </div>
</div>
