import {Injectable} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter} from 'rxjs/operators';
import {DataService} from '../data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class PromptUpdateService {

  constructor(swUpdate: SwUpdate, private snackbar: MatSnackBar, private dataService: DataService) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        var askReload = this.dataService.inInterActiveSection.value || this.dataService.inPrivateSection;
        if ( !askReload) {
          // Reload the page to update to the latest version.
          document.location.reload();
        } else {
          const snack = this.snackbar.open('Update Available', 'Reload');
          snack
            .onAction()
            .subscribe(() => {
              window.location.reload();
            });

        }
          console.log('waiting for user to accept reload' );
      });
    console.log('PromptUpdateService configured' );
  }

}
