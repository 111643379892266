// src/app/directives/draggable.directive.ts

import { Directive, ElementRef, HostListener, Input, Renderer2, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements AfterViewInit {
  @Input('appDraggable') handleSelector: string | undefined; // CSS selector for drag handle
  @Input() constrainToViewport: boolean = true; // Whether to constrain within viewport
  @Output() positionChanged = new EventEmitter<{ top: number, left: number }>();

  private isDragging: boolean = false;
  private startX: number = 0;
  private startY: number = 0;
  private initialLeft: number = 0;
  private initialTop: number = 0;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    // Ensure the element has position absolute or fixed
    const position = window.getComputedStyle(this.el.nativeElement).position;
    if (position !== 'absolute' && position !== 'fixed') {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'absolute');
      // Removed setting 'top' and 'left' here to prevent overriding saved positions
    }
  }

  ngAfterViewInit() {
    // Log the initial position
    const rect = this.el.nativeElement.getBoundingClientRect();
    console.log('DraggableDirective Initialized:', { top: rect.top, left: rect.left });
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.handleSelector) {
      const handleElement = this.el.nativeElement.querySelector(this.handleSelector);
      if (!handleElement || !handleElement.contains(event.target)) {
        return;
      }
    }

    console.log('DraggableDirective - Mouse Down:', event);
    event.preventDefault();
    this.isDragging = true;
    this.startX = event.clientX;
    this.startY = event.clientY;

    const rect = this.el.nativeElement.getBoundingClientRect();
    this.initialLeft = rect.left;
    this.initialTop = rect.top;

    // Add event listeners to handle dragging
    this.renderer.listen('document', 'mousemove', this.onMouseMove.bind(this));
    this.renderer.listen('document', 'mouseup', this.onMouseUp.bind(this));
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (this.handleSelector) {
      const handleElement = this.el.nativeElement.querySelector(this.handleSelector);
      if (!handleElement || !handleElement.contains(event.target as Node)) {
        return;
      }
    }

    if (event.touches.length !== 1) return; // Single touch
    const touch = event.touches[0];
    console.log('DraggableDirective - Touch Start:', touch);
    this.isDragging = true;
    this.startX = touch.clientX;
    this.startY = touch.clientY;

    const rect = this.el.nativeElement.getBoundingClientRect();
    this.initialLeft = rect.left;
    this.initialTop = rect.top;

    // Add event listeners to handle dragging
    this.renderer.listen('document', 'touchmove', this.onTouchMove.bind(this));
    this.renderer.listen('document', 'touchend', this.onTouchEnd.bind(this));
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;

    const deltaX = event.clientX - this.startX;
    const deltaY = event.clientY - this.startY;

    const newLeft = this.initialLeft + deltaX;
    const newTop = this.initialTop + deltaY;

    // Remove 'bottom' and 'right' styles
    this.renderer.removeStyle(this.el.nativeElement, 'bottom');
    this.renderer.removeStyle(this.el.nativeElement, 'right');

    // Set 'top' and 'left' styles
    this.renderer.setStyle(this.el.nativeElement, 'top', newTop+'px');
    this.renderer.setStyle(this.el.nativeElement, 'left', newLeft+'px');

    // Emit the new position
    this.positionChanged.emit({ top: newTop, left: newLeft });
  }


  private onMouseUp(event: MouseEvent) {
    if (!this.isDragging) return;
    this.isDragging = false;
  }

  private onTouchMove(event: TouchEvent) {
    if (!this.isDragging || event.touches.length !== 1) return;
    const touch = event.touches[0];
    this.moveElement(touch.clientX, touch.clientY);
    event.preventDefault();
  }

  private onTouchEnd(event: TouchEvent) {
    if (!this.isDragging) return;
    this.isDragging = false;
  }

  private moveElement(clientX: number, clientY: number) {
    let deltaX = clientX - this.startX;
    let deltaY = clientY - this.startY;

    let newLeft = this.initialLeft + deltaX;
    let newTop = this.initialTop + deltaY;

    if (this.constrainToViewport) {
      const elWidth = this.el.nativeElement.offsetWidth;
      const elHeight = this.el.nativeElement.offsetHeight;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // Constrain within left and right boundaries
      newLeft = Math.max(0, Math.min(newLeft, windowWidth - elWidth));

      // Constrain within top and bottom boundaries
      newTop = Math.max(0, Math.min(newTop, windowHeight - elHeight));
    }
    this.renderer.removeStyle(this.el.nativeElement, 'bottom');
    this.renderer.removeStyle(this.el.nativeElement, 'right');
    this.renderer.setStyle(this.el.nativeElement, 'left', newLeft+'px');
    this.renderer.setStyle(this.el.nativeElement, 'top', newTop +'px');

    // Emit the new position
    this.positionChanged.emit({ top: newTop, left: newLeft });
  }
}
