		<div class="row justify-content-center our-fleet-sections mt-5">
			<div class="content-wrapper row justify-content-center">
        <div class="col-lg-6 col-md-9 col-sm-11 col-sm-auto ">
          <h3 class="center-title">{{'OurFleet-0' | translate}}</h3>
          <div class="title-block2"></div>
          <p class="text-justify" [innerHTML]="'OurFleet-Bogota' | translate" ></p>
        </div>
			</div>
      <div class="row justify-content-center">
        <ngb-carousel class="fleet-block-wrapper" >
						<ng-template ngbSlide *ngFor="let slide of slides" style="max-width: 970px;">
							<div class="row w-100" style="center:left" @fadeInOut>
								<div class="col pb-1 pt-1 fleet-block"   *ngFor="let vehicle of slide">
									<mat-card>
										<div class="fleet-block-image">
											<img mat-card-image src="../../assets/img/fleet/{{vehicle.image}}-600x350.jpg" class="img-fluid"
                           style="width:100%;"
                           alt="{{vehicle.name}}">
										</div>
										<mat-card-content>
											<div class="fleet-block-content card-body">
												<div class="fleet-price">{{'rateFrom' | translate}}  {{vehicle.hourDayPlan}}/{{'HourAbbreviation' | translate}}</div>
                        <h3 class="mb-1">{{vehicle.name}}</h3>
                        <h4 class="card-title">{{getCategory(vehicle.categoryId).name}}</h4>
                          <ul>
                            <li  class="vehicle-passenger-limit">{{vehicle.passengers}} {{'pax' | translate}}</li>
                            <li class="vehicle-bag-limit">{{vehicle.bags}} {{'bags' | translate}}</li>
                          </ul>
												<button class="book-button" color="primary" [routerLink]="['/booking' | localize]">{{'Booking' | translate}}</button>
											</div>
										</mat-card-content>
									</mat-card>
								</div>
							</div>
					</ng-template>
        </ngb-carousel>
      </div>
    </div>
