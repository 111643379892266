<div id="faq-header">
  <h1>{{'Faq' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
    <i class="fa fa-angle-right"></i>{{'Faq' | translate}}</p>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
    <h3 class="center-title text-uppercase">{{'FrequentlyAskedQuestions' | translate}}</h3>
    <div class="title-block2"></div>
  </div>
</div>
<div class="row justify-content-center mb-5">
  <div class="content-wrapper col-lg-8 col-sm-10 ms-1 me-1">
    <h4 class="center-title text-uppercase">{{'FrequentlyAskedQuestions-Section-1' | translate}}</h4>
    <div class="title-block3"></div>
  </div>
  <div class="col-lg-9 col-md-12">
    <mat-accordion>
      <ng-container *ngFor="let item of [].constructor(10); let i = index">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header class="faq p-2" collapsedHeight="*" expandedHeight="*">
            <mat-panel-title>
              {{('FaqTitle-1-' + i ) | translate}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div  class="ms-5 faq-text" [innerHTML]="('FaqText-1-' + i ) | translate">
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
<div class="row justify-content-center mb-5">
  <div class="content-wrapper col-lg-8 col-sm-10 ms-1 me-1">
    <h4 class="center-title text-uppercase">{{'FrequentlyAskedQuestions-Section-2' | translate}}</h4>
    <div class="title-block3"></div>
  </div>
  <div class="col-lg-9 col-md-12">
    <mat-accordion>
      <ng-container *ngFor="let item of [].constructor(4); let i = index">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header class="faq p-2">
            <mat-panel-title>
              {{('FaqTitle-2-' + i ) | translate}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div  class="ms-5 faq-text" [innerHTML]="('FaqText-2-' + i ) | translate">
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
<div class="row justify-content-center mb-5">
  <div class="content-wrapper col-lg-8 col-sm-10 ms-1 me-1">
    <h4 class="center-title text-uppercase">{{'FrequentlyAskedQuestions-Section-3' | translate}}</h4>
    <div class="title-block3"></div>
  </div>
  <div class="col-lg-9 col-md-12">
    <mat-accordion>
      <ng-container *ngFor="let item of [].constructor(4); let i = index">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header class="faq p-2">
            <mat-panel-title>
              {{('FaqTitle-3-' + i ) | translate}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div  class="ms-5 faq-text" [innerHTML]="('FaqText-3-' + i ) | translate">
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
<div class="row justify-content-center mb-5">
  <div class="content-wrapper col-lg-8 col-sm-10 ms-1 me-1">
    <h4 class="center-title text-uppercase">{{'FrequentlyAskedQuestions-Section-4' | translate}}</h4>
    <div class="title-block3"></div>
  </div>
  <div class="col-lg-9 col-md-12">
    <mat-accordion>
      <ng-container *ngFor="let item of [].constructor(9); let i = index">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header class="faq p-2">
            <mat-panel-title>
              {{('FaqTitle-4-' + i ) | translate}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div  class="ms-5 faq-text" [innerHTML]="('FaqText-4-' + i ) | translate">
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
