<ng-container
  *ngIf="format==='vertical'; then vertical; else horizontal">
</ng-container>

<div #mapContainer id=”map”></div>
<ng-template #vertical>
<!-- BEGIN .widget-booking-form-wrapper -->
  <div class="col body-booking-form-wrapper mr-lg-3 mr-0">
    <!-- BEGIN #booking-tabs -->
    <div id="booking-tabs" class="ui-tabs ui-widget ui-widget-content ui-corner-all">

      <ul class="nav clearfix ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" style="width: 100%">
          <li class="ui-state-default ui-corner-top"
              style="width: 50%"
              [ngClass]="{'ui-tabs-active ui-state-active': booking.serviceType!=='perHour'}" >
            <a (click)="bookingTypeChange('transfer')" class="ui-tabs-anchor"
               title="{{'BookingSteps-35' | translate}}">{{'BookingSteps-35' | translate}}</a></li>
          <li class="ui-state-default ui-corner-top"
              style="width: 50%"
              [ngClass]="{'ui-tabs-active ui-state-active': booking.serviceType==='perHour'}">
            <a  (click)="bookingTypeChange('perHour')" class="ui-tabs-anchor"
                title="{{'BookingSteps-36' | translate}}">{{'BookingSteps-36' | translate}}</a></li>
      </ul>
      <!-- END #booking-tabs -->
    </div>
    <div  label="One-Way Transfer" class="ui-state-default ui-corner-top p-2">
            <!-- BEGIN .booking-form-1 -->
            <form class="booking-form-1" [formGroup]="bookingForm" autocomplete='off'>
              <div class="row mb-3 align-content-center">
                <div class="col-4 justify-content-center align-content-center">
                 <label class="mt-1" style="color: white!important;">{{"BookingSteps-67" | translate}}</label>
                </div>
                <div  class="col-8" style="display: flex; align-items: center; color: white!important;">
                <mat-slide-toggle formControlName="searchMode" [color]="color" [checked]="checked" style="color: white!important;">
                  {{booking.searchType === 'List' ? ("BookingSteps-68" | translate) : ("BookingSteps-69" | translate)}}
                </mat-slide-toggle>
                </div>
              </div>

              <div  class="row mb-3 align-content-center"
                    *ngIf="booking.searchType === 'List'
                    && countries !=null && countries != undefined && countries.length >0
                    && cities !=null && cities != undefined  && cities.length >0">
                <div class="pb-2 col-auto flex-fill">
                  <div>
                    <span style="color: white">{{"BookingSteps-51" | translate}}</span>
                  </div>
                  <mat-select class="form-control"
                              formControlName="puCountry">
                      <mat-option  *ngFor="let item of countries" value="{{item.name}}">
                        {{ item.localName }}
                      </mat-option>
                  </mat-select>
                </div>
                <div class="ps-2 pb-2 col-auto flex-fill">
                  <div>
                    <span style="color: white">{{"BookingSteps-52" | translate}}</span>
                  </div>
                  <mat-select class="form-control"
                              formControlName="puCity">
                      <mat-option *ngFor="let item of cities" value="{{item.name}}">
                        {{ item.localName }}
                      </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="input-group mb-3">
                <input type="text" #searchPu  [readonly]="puDisabled" (click)="clickSearchPu()"
                       name="pickup-address" class="form-control" (change)="clearGooglePuPlace()"
                       placeholder="{{'BookingSteps-37' | translate}}" autocomplete="wegrqwegqergf541233" formControlName="puAdress"/>
                <div class="input-group-append">
                  <span  class="fas fa-map-marker-alt input-group-text" (click)="openPickupMap()" [ngClass]="{ 'marker-active' : booking.puGoogleLocation != null,'marker-error' : booking.puGoogleLocation == null && bookingForm.valid  }"></span>
                </div>
              </div>
              <div class="input-group mb-3" *ngIf="isAirportPickup">
                <div class="input-group-prepend input-group-text">
                  <span  class="fas fa-plane"></span>
                </div>
                <input type="text" name="pickup-flight" class="form-control"
                       placeholder="{{'BookingSteps-50' | translate}}"
                       formControlName="flightNumber"/>
              </div>
              <div class="input-group mb-3">
                <input type="text" #searchDo name="dropoff-address" class="form-control" (change)="clearGoogleDoPlace()"
                       placeholder="{{'BookingSteps-38' | translate}}"  autocomplete="rtheyjsfgjwgj6876412" formControlName="doAdress"/>
                <div class="input-group-append" >
                  <span  class="fas fa-map-marker-alt input-group-text" (click)="openDropOffMap()" [ngClass]="{ 'marker-active' : booking.doGoogleLocation != null,'marker-error' : booking.doGoogleLocation == null && bookingForm.valid  }"></span>
                </div>
              </div>
              <div class="input-group mb-3" >
                <input #pudate="ngbDatepicker" type="text" ngbDatepicker   class="form-control"
                       [minDate]="mindate"
                       data-date-format="yyyy/mm/dd"
                       (click)="pudate.toggle()"
                       placeholder="{{'BookingSteps-8b' | translate}}"
                       name="pickup-date"  formControlName="puDate"/>
                <div class="input-group-append">
                  <span class="fas fa-calendar-alt input-group-text" (click)="pudate.toggle()"></span>
                </div>
              </div>
              <div class="row align-content-center mb-3">
                <div class="col  col-auto justify-content-center align-content-center">
                  <label  class="input-group mb-0"> {{"BookingSteps-39" | translate}}</label>
                </div>

                    <div class="col col-auto flex-fill pl-0">
                      <mat-select formControlName="puHour"
                                  class="form-control" type="text" name="puHour" >
                        <mat-option *ngFor="let hour of hours" value="{{hour.value}}">{{hour.label}}</mat-option>
                      </mat-select>
                    </div>
                    <div class="col col-auto flex-fill pl-0">
                      <mat-select   formControlName="puMin"
                                    class="form-control" type="text" name="puMin" >
                        <mat-option *ngFor="let time of times" value="{{time.value}}">{{time.label}}</mat-option>
                      </mat-select>
                    </div>

              </div>
              <div style="display: flex; justify-content: center;" *ngIf="booking.serviceType==='perHour'">
                <div class="input-group">
                  <label  class="pt-0 mt-1">{{'BookingSteps-40' | translate}}</label>
                </div>
                <div class="input-group">
                    <mat-select formControlName="duration"  class="form-control">
                      <mat-option *ngFor="let duration of durations" value="{{duration.value}}">{{duration.label}} {{'Hours' | translate}}</mat-option>
                    </mat-select>
                </div>
              </div>
            </form>
            <!-- END .booking-form-1 -->
          </div>
    <div *ngIf="!readyForSubmission && insideStepper===true && submitted" class="alert alert-danger">
      <label>{{'FormError-1' | translate}}</label>
    </div>
    <div *ngIf="resultText !== ''" [ngClass]="resultClass" class="mb-2">
      <label>{{resultText | translate}}</label>
    </div>
    <div *ngIf=" (!booking.puGoogleLocation || !booking.doGoogleLocation) && bookingForm.valid" class="mb-2 text-danger">
      <label>{{'BookingSteps-83' | translate}}</label>
      <label>{{'BookingSteps-88' | translate}}</label>
    </div>
    <div style="display: flex; justify-content: center; padding-bottom: 10px">
      <button class="booking-button" type="submit" (click)="gotoBookingForm()" *ngIf="insideStepper===false">{{'BookingSteps-41' | translate}}</button>
      <button class="booking-button" type="button" (click)="beforeNextStep()" *ngIf="insideStepper===true && booking.id == null">{{'BookingSteps-19' | translate}}</button>
      <button class="booking-button" type="button" (click)="newBooking()" *ngIf="insideStepper===true && booking.id != null">{{"BookingSteps-30" | translate}}</button>
      <button matStepperNext #nextButton hidden="true"  *ngIf="insideStepper===true" ></button>
    </div>
  <!-- END .widget-booking-form-wrapper -->
  </div>
</ng-template>
<ng-template #horizontal>
  <div class="container">
      <div class="row w-100 align-content-center mt-5 mb-5 ms-1 me-1">
        <div id="booking-tabs-2"
             class="body-booking-form-wrapper align-content-center ui-tabs ui-widget ui-widget-content ui-corner-all col-3" style="padding-left: 0px; padding-right: 0px">
          <ul class="nav clearfix ui-tabs-nav  ui-corner-all" style="display: inline; height: 100%">
            <li class="ui-state-default ui-corner-top h-50" [ngClass]="{'ui-tabs-active ui-state-active': booking.serviceType==='transfer'}" >
              <a (click)="bookingTypeChange('transfer')" title="{{'BookingSteps-35' | translate}}">{{'BookingSteps-35' | translate}}</a></li>
            <li class="ui-state-default ui-corner-top  h-50" [ngClass]="{'ui-tabs-active ui-state-active': booking.serviceType==='perHour'}">
              <a  (click)="bookingTypeChange('perHour')" title="{{'BookingSteps-36' | translate}}">{{'BookingSteps-36' | translate}}</a></li>
          </ul>
        </div>
        <div class="body-booking-form-wrapper col-9">
                <form  class="" [formGroup]="bookingForm" role="form" class="ps-2 pt-2 pe-2">
                  <div class="row mb-3 align-content-center">
                    <div class="col-2 justify-content-center align-content-center text-center">
                      <label class="mt-3 text-center" style="color: white!important;vertical-align:middle;">{{"BookingSteps-67" | translate}}</label>
                    </div>
                    <div  class="booking-form-4 col-4" style="display: flex; align-items: center; color: white!important;" >
                      <mat-slide-toggle formControlName="searchMode" [color]="color" [checked]="checked">
                        {{booking.searchType === 'List' ? ("BookingSteps-68" | translate) : ("BookingSteps-69" | translate)}}
                      </mat-slide-toggle>
                    </div>
                    <ng-container
                          *ngIf="booking.searchType === 'List'
                    && countries !=null && countries != undefined && countries.length >0
                    && cities !=null && cities != undefined  && cities.length >0">
                      <div class="pb-2 col-3">
                        <div>
                          <span style="color: white">{{"BookingSteps-51" | translate}}</span>
                        </div>
                        <mat-select class="form-control"
                                    formControlName="puCountry">
                          <mat-option  *ngFor="let item of countries" value="{{item.name}}">
                            {{ item.localName }}
                          </mat-option>
                        </mat-select>
                      </div>
                      <div class="ps-2 pb-2 col-3">
                        <div>
                          <span style="color: white">{{"BookingSteps-52" | translate}}</span>
                        </div>
                        <mat-select class="form-control"
                                    formControlName="puCity">
                          <mat-option *ngFor="let item of cities" value="{{item.name}}">
                            {{ item.localName }}
                          </mat-option>
                        </mat-select>
                      </div>
                    </ng-container>
                  </div>


                      <div class="row" >
                        <div class="pu-container mb-2 ps-3" >
                          <div class="input-group mb-2">
                            <input type="text" #searchPu class="" name="pickup-address" class="form-control"
                                   (change)="clearGooglePuPlace()"
                                   placeholder="{{'BookingSteps-37' | translate}}" autocomplete="" formControlName="puAdress"/>
                            <div class="input-group-append">
                              <span  class="fa fa-map-marker input-group-text" (click)="openPickupMap()"   [ngClass]="{ 'marker-active' : booking.puGoogleLocation != null,'marker-error' : booking.puGoogleLocation == null && bookingForm.valid  }"></span>
                            </div>
                          </div>
                          <div class="input-group mb-3" *ngIf="isAirportPickup">
                            <div class="input-group-prepend input-group-text">
                              <span  class="fas fa-plane"></span>
                            </div>
                            <input type="text" name="pickup-flight" class="form-control"
                                   placeholder="{{'BookingSteps-50' | translate}}"
                                   formControlName="flightNumber"/>
                          </div>
                        </div>

                        <div class="input-group mb-2 ps-3">
                          <input type="text" #searchDo name="dropoff-address" class="form-control" (change)="clearGoogleDoPlace()"
                                 placeholder="{{'BookingSteps-38' | translate}}" autocomplete="" formControlName="doAdress"/>
                          <div class="input-group-append" >
                            <span  class="fa fa-map-marker input-group-text" (click)="openDropOffMap()"  [ngClass]="{ 'marker-active' : booking.doGoogleLocation != null,'marker-error' : booking.doGoogleLocation == null && bookingForm.valid  }"></span>
                          </div>
                        </div>
                      </div>
                        <div class="row w-100" >
                              <div class="col-xl-4 col-lg-5 col-sm-12 mb-2 ps-2 pe-2 mt-2 pt-1 form-inline"
                                   data-provide="datepicker" >
                                <div class="pb-2 me-2 ms-2">
                                  <label>{{'BookingSteps-8b' | translate}}</label>
                                </div>
                                <div class="input-group">
                                  <input #pudate="ngbDatepicker" type="text" ngbDatepicker class="form-control"
                                         [minDate]="mindate"
                                         data-date-format="yyyy/mm/dd"
                                         (click)="pudate.toggle()"
                                         placeholder="{{'BookingSteps-8b' | translate}}"
                                         name="pickup-date"  formControlName="puDate"/>
                                  <div class="input-group-append">
                                    <span class="fas fa-calendar-alt input-group-text" (click)="pudate.toggle()"></span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-4 col-lg-3 col-sm-6 mb-2 ps-2 pe-2 mt-2 pt-1 form-inline">
                                <div class="pb-2 me-2 ms-2">
                                  <label>{{'BookingSteps-39' | translate}}</label>
                                </div>
                                <div class="input-group">
                                  <select formControlName="puHour" class="form-control" >
                                    <option *ngFor="let hour of hours" value="{{hour.value}}">{{hour.label}}</option>
                                  </select>
                                  <select formControlName="puMin" class="form-control">
                                    <option *ngFor="let time of times" value="{{time.value}}">{{time.label}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-xl-4 col-lg-4 col-sm-6 ps-2 pe-2 mt-2 pt-1 form-inline" *ngIf="booking.serviceType==='perHour'">
                                <div class="ms-2 pb-2 me-2" *ngIf="booking.serviceType==='perHour'">
                                  <label>{{'BookingSteps-40' | translate}} </label>
                                </div>
                                <select formControlName="duration" class="form-control">
                                  <option *ngFor="let duration of durations"
                                          value="{{duration.value}}">{{duration.label}} {{'Hours' | translate}}</option>
                                </select>
                              </div>
                        </div>
                      <div class="row" >
                        <div class="input-group mb-2">
                          <button class="theme-Button" type="button" (click)="gotoBookingForm()" *ngIf="insideStepper===false">
                            <span>{{'BookingSteps-41' | translate}}</span></button>
                        </div>
                      </div>


                  <!-- END .booking-form-2 -->
                </form>
        </div>
      </div>
  </div>
</ng-template>
