
    <div class="row w-100 m-0 justify-content-center align-content-center content-wrapper-outer home-icons-outer-wrapper-2">
      <div class="col-lg-12 mt-2 ml-0" class="title">
        <h1 class="text-center text-uppercase"> {{'HomeWrapperTitle' | translate}}</h1>
          <div class="row justify-content-center align-content-center">
            <div class="col-lg-4 home-icon-wrapper-2 mt-5 ml-lg-5 ml-sm-2">
              <div class="row ml-0">
                <div class="qns-home-icon"><i class="fa fa-plane"></i></div>
                <div class="ps-5 home-icon-inner col">
                  <h2 class="text-uppercase">{{ 'PerHoursOrTransfers0' | translate}}</h2>
                  <div class="title-block3"></div>
                </div>
              </div>
              <div class="row ml-0">
                <p class="text-justify ps-2 pe-3">{{ 'PerHoursOrTransfers1' | translate}} <br/> {{ 'PerHoursOrTransfers2' | translate}}</p>
              </div>
            </div>
            <div class="col-lg-4 home-icon-wrapper-2 mt-5 ml-lg-5 ml-sm-2">
              <div class="row ml-0">
                <div class="qns-home-icon"><i class="fa fa-user-tie"></i></div>
                <div class="ps-5 home-icon-inner col">
                  <h2 class="text-uppercase">{{ 'ForProfessionalsOrIndividuals0' | translate}}</h2>
                  <div class="title-block3"></div>
                </div>
              </div>
              <div class="row ml-0">
                <p  class="text-justify ps-2 pe-3"><span>{{ 'ForProfessionalsOrIndividuals1' | translate}} </span> <br/><span [innerHTML]=" 'ForProfessionalsOrIndividuals2' | translate"></span></p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center align-content-center">
            <div class="col-lg-4 home-icon-wrapper-2 mt-5 ml-lg-5 ml-sm-2">
              <div class="row ml-0">
                <div class="qns-home-icon"><i class="fa fa-award"></i></div>
                <div class="ps-5 home-icon-inner col">
                  <h2 class="text-uppercase">{{ 'VyVQuality0' | translate}}</h2>
                  <div class="title-block3"></div>
                </div>
              </div>
              <div class="row ml-0">
                  <p class="text-justify ps-2 pe-3" [innerHTML]="'VyVQuality1' | translate">
                  <p class="text-justify ps-2 pe-3" [innerHTML]="'VyVQuality2' | translate">
              </div>
            </div>
            <div class="col-lg-4 home-icon-wrapper-2 mt-5 ml-lg-5 ml-sm-2">
              <div class="row ml-0">
                <div class="qns-home-icon"><i class="fa fa-handshake"></i></div>
                <div class="ps-5 home-icon-inner col">
                  <h2 class="text-uppercase">{{ 'PartnerWithVyV0' | translate}}</h2>
                  <div class="title-block3"></div>
                </div>
              </div>
              <div class="row ml-0">
                <p class="text-justify ps-2 pe-3" [innerHTML]="'PartnerWithVyV1' | translate">
                </p> <br/><br/>
                <p>
                 <a [routerLink]="'/contact-us' | localize" title="{{ 'Contact Us' | translate}}">
                   {{ 'Contact Us' | translate}}</a> <br/>
                  Email : <a href="mailto:info@vehiculosvip.com">info&#64;vehiculosvip.com</a> <br/>
                  {{ 'Colombian PBX' | translate}} : <a href="tel:+576012114949"> +57 601 211 49 49 </a><br/>
                  {{ 'Colombian PBX' | translate}} : <a href="tel:+576017389641"> +57 601-738-9641 </a><br/>
                  {{ 'US Toll-free' | translate}} :  <a href="tel:+18883685236"> <b> 1-888-368-5236 </b></a>
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
