import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {ActivatedRoute} from '@angular/router';
import {FuecService} from '../../services/fuec.service';
import {Fuec} from '../../../models/business-objects';

@Component({
  selector: 'app-fuec',
  templateUrl: './fuec.component.html',
  styleUrl: './fuec.component.css'
})
export class FuecComponent implements OnInit, OnDestroy{
  private subscriptions = [];
  loadingFuec = true;
  fuecId: string;
  public fuec: Fuec;
  constructor(private canonicalAndMetaService: CanonicalAndMetaService , private ref: ChangeDetectorRef, private route: ActivatedRoute,
              private fuecService: FuecService, @Inject(PLATFORM_ID) private platformId: any) {

    this.subscriptions.push(route.params.subscribe(params => {
      this.fuecId = this.route.snapshot.paramMap.get('fuecid');
      this.changeFuec();
    }));
  }
  async ngOnInit() {
    this.fuecId = this.route.snapshot.paramMap.get('fuecid');
    await this.changeFuec();
  }
  async changeFuec(){
    this.loadingFuec = true;
    console.log('fuecid = ' + this.fuecId);
    if (this.fuecId ) {
      this.fuec = await this.fuecService.getFuecById(this.fuecId);
      // this.newsList = this.newsList.filter( n => n.seccionNoticia === 'NEWS');
      console.log('this.fuec = ');
      console.log(this.fuec);
    }
    this.loadingFuec = false;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
