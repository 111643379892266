<div id="page-headerRate" >
    <h1>{{("ServiceRates-" +serviceLevel.toUpperCase())  | translate }}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate }}">
    {{'Home' | translate }}</a><i class="fa fa-angle-right"></i>
    {{ (serviceLevel.toUpperCase()=='VIP' ) ? ("ServiceRates-VIP" | translate) : ("ServiceRates-BUSINESS" | translate) }}
  </p>
</div>
<!-- BEGIN .content-wrapper-outer -->
<div class="content-wrapper-outer row justify-content-center">
  <div class="content-wrapper col-lg-8 col-sm-10 text-justify mb-5">
    <h3 class="center-title">{{("ServiceRates-" +serviceLevel.toUpperCase())  | translate }}</h3>
    <div class="title-block2"></div>

    <span class="fleet-intro-text" [innerHTML]="text1" ></span>
    <br/>
    <br/>
    <span class="fleet-intro-text " [innerHTML]=" 'ServiceRatesText-Common' | translate" ></span>
  </div>
  <!-- BEGIN .main-content -->
  <div class="main-content row justify-content-center">
    <div class="col-lg-8 col-sm-10 col-12">
      <div id="tabs" class="ui-tabs ui-widget ui-widget-content ui-corner-all">
            <ul class="nav clearfix ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
                [attr.disabled]="disableTabs">
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor"
                    [ngClass]="{'ui-tabs-active ui-state-active' : cityParam == 'international'}">
                  <a  (click)="setActiveTab('international',serviceLevel)"
                      title="International"
                      [attr.disabled]="disableTabs">
                    International</a></li>
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor"
                    [ngClass]="{'ui-tabs-active ui-state-active' : cityParam == 'bogota'}">
                  <a (click)="setActiveTab('bogota',serviceLevel)"
                     title="Bogotá"
                     [attr.disabled]="disableTabs">
                    Bogotá</a></li>
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor"
                    [ngClass]="{'ui-tabs-active ui-state-active' : cityParam == 'cartagena'}">
                  <a  (click)="setActiveTab('cartagena',serviceLevel)"
                      title="Cartagena"
                      [attr.disabled]="disableTabs">
                    Cartagena</a></li>
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor"
                    [ngClass]="{'ui-tabs-active ui-state-active' : cityParam == 'medellin'}">
                  <a  (click)="setActiveTab('medellin',serviceLevel)"
                      title="Medellín"
                      [attr.disabled]="disableTabs">
                    Medellín</a></li>
            </ul>
        </div>
      <div class="tab-content">
        <div class="tab-pane" [ngClass]="{'active': cityParam === 'international'}">
          <p  class="text-justify">{{'ServiceRates-INT' | translate}}</p>
          <app-rate-table [rates]="rates"></app-rate-table>
        </div>
        <div class="tab-pane" [ngClass]="{'active': cityParam === 'bogota'}">
          <p  class="text-justify">{{'ServiceRates-BOG' | translate}}</p>
          <app-rate-table [rates]="rates"></app-rate-table>
        </div>
        <div class="tab-pane" [ngClass]="{'active': cityParam === 'cartagena'}">
          <p  class="text-justify">{{'ServiceRates-CTG' | translate}}</p>
          <app-rate-table [rates]="rates"></app-rate-table>
        </div>
        <div class="tab-pane" [ngClass]="{'active': cityParam === 'medellin'}">
          <p  class="text-justify">{{'ServiceRates-MDE' | translate}}</p>
          <app-rate-table [rates]="rates"></app-rate-table>
        </div>
    </div>

      <div class="row w-100 justify-content-center call-to-action-2-section pt-5 pb-5 mt-5">
        <div class="col-6 text-overlay mb-2 mt-2">
          <h3>{{'Homepage-1' | translate}}</h3>
          <div class="title-block5"></div>
          <p>{{'Homepage-2' | translate}}</p>
          <a routerLink="{{'/booking' | localize}}" class="button0 mb-3" title="{{'OnlineBooking' | translate}}">
            {{'OnlineBooking' | translate}}</a>
        </div>
        <!-- END .call-to-action-2-section -->
      </div>
    <div class="main-content row justify-content-center">

      <!-- BEGIN .qns-one-half -->
      <div class="col-6">

        <h3 class="text-uppercase">{{'SalesConditionsTitle0' | translate}}</h3>
        <div class="title-block3"></div>
        <h4 class="text-uppercase">{{'SalesConditionsTitle1' | translate}}</h4>
        <p class="text-justify" [innerHTML]="'SalesConditionsText1' | translate" ></p>
        <h4 class="text-uppercase">{{'SalesConditionsTitle2' | translate}}</h4>
        <p class="text-justify" [innerHTML]="'SalesConditionsText2' | translate" ></p>
      <!-- END .qns-one-half -->
      </div>

      <!-- BEGIN .qns-one-half -->
      <div class="col-6">

        <h3 class="text-uppercase">{{'RatesIncludeTitle0' | translate}}</h3>
        <div class="title-block3"></div>
        <h4 class="text-uppercase">{{'RatesIncludeTitle' | translate}}</h4>
        <ul class="border-list ps-1">
          <li class="text-justify">{{'RatesIncludeBullet1' | translate}}</li>
          <li class="text-justify">{{'RatesIncludeBullet2' | translate}}</li>
          <li class="text-justify">{{'RatesIncludeBullet3' | translate}}</li>
          <li class="text-justify">{{'RatesIncludeBullet4' | translate}}</li>
        </ul>
        <h4 class="text-uppercase">{{'RatesDoNotIncludeTitle' | translate}}</h4>
        <ul class="border-list ps-1">
          <li class="text-justify">{{'RatesDoNotIncludeBullet1' | translate}}</li>
          <li class="text-justify">{{'RatesDoNotIncludeBullet2' | translate}}</li>
        </ul>
        <h4 class="text-uppercase">{{'AdditionalServicesTitle' | translate}}</h4>
        <ul class="border-list ps-1">
          <li class="text-justify">{{('AdditionalServicesBullet1-'+serviceLevel.toUpperCase()) | translate}}</li>
          <li class="text-justify">{{('AdditionalServicesBullet2-'+serviceLevel.toUpperCase()) | translate}}</li>
          <li class="text-justify">{{('AdditionalServicesBullet3-'+serviceLevel.toUpperCase()) | translate}}</li>
        </ul>
      <!-- END .qns-one-half -->
      </div>

    <!-- END .clearfix -->
    </div>

    <!-- END .main-content -->
    </div>
  <!-- END .content-wrapper-outer -->
  </div>
</div>
