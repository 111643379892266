<!-- src/app/chatbot/chatbot.component.html -->

<div
  id="chatbotOverlay"
  class="chatbot-overlay"
  *ngIf="isChatVisible"
  appDraggable=".chatbot-header"
  [constrainToViewport]="true"
  [style.top]="top"
  [style.left]="left"
  [style.bottom]="bottom"
  [style.right]="right"

  (positionChanged)="onPositionChanged($event)"
>
  <div class="chatbot-container modal-content" [class.minimized]="isMinimized">

    <!-- Chatbot Header -->
    <div
      class="chatbot-header"
      tabindex="0"
      (keyup.enter)="toggleChat()"
      role="button"
      [attr.aria-pressed]="isChatVisible"
    >
      <!-- Conditional Rendering: Title or Icon based on minimized state -->
      <ng-container *ngIf="!isMinimized; else minimizedHeader">
        <span class="chatbot-title">Chat with our AI / Funcionalidad de prueba en desarrollo </span>
      </ng-container>
      <ng-template #minimizedHeader >
        <i class="fas fa-comments chatbot-icon" aria-label="Chatbot" (click)="toggleChat()"></i>
      </ng-template>
      <button
        *ngIf="!isMinimized"
        class="close-button me-4 "
        (click)="toggleChat(); $event.stopPropagation();"
        aria-label="Close chat"
      > _
      </button>
      <button
        *ngIf="isChatVisible"
        class="close-button me-2"
        (click)="closeChat(); $event.stopPropagation();"
        aria-label="Close chat"
      >
        X
      </button>
    </div>

    <!-- Chatbot Body -->
    <div class="chatbot-body" *ngIf="!isMinimized">

      <!-- Messages Area -->
      <div class="messages" id="messageContainer">
        <div
          class="message"
          *ngFor="let msg of messages"
          [ngClass]="msg.sender"
        >
          <div class="message-avatar">
            <!-- Conditional Avatar Icon -->
            <ng-container *ngIf="msg.sender === 'bot'; else userAvatar">
              <!-- Bot Icon -->
              <i class="fas fa-robot bot-icon" aria-label="Bot"></i>
              <!-- Typing Indicator -->
              <div *ngIf="isTyping && isLastMessage(msg)" class="typing-indicator" aria-label="Bot is typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </ng-container>
            <ng-template #userAvatar>
              <!-- User Icon -->
              <i class="fas fa-user user-icon" aria-label="User"></i>
            </ng-template>
          </div>
          <div class="message-content" aria-live="polite">
            <ng-container [ngSwitch]="msg.type">
              <p *ngSwitchCase="'text'">{{ msg.message }}</p>
              <img *ngSwitchCase="'image'" [src]="msg.message" alt="Image from chatbot" />
              <a *ngSwitchCase="'link'" [href]="msg.message" target="_blank">{{ msg.message }}</a>
              <p *ngSwitchCase="'error'">{{ msg.message }} {{ msg.error }}</p>
              <!-- Add more cases as needed -->
            </ng-container>
            <span class="message-timestamp">{{formatMessageDate(msg)}}</span>
          </div>
        </div>
        <ng-container  *ngIf="isTyping">
          <!-- Bot Icon -->
          <i class="fas fa-robot bot-icon" aria-label="Bot"></i>
          <!-- Typing Indicator -->
          <div class="typing-indicator" aria-label="Bot is typing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </ng-container>

      </div>

      <!-- Chatbot Input -->
      <div class="chatbot-input">
        <textarea
          #chatInput
          [(ngModel)]="userInput"
          placeholder="Type your message..."
          (input)="autoResize($event)"
          (keyup.enter)="sendMessage()"
          class="form-control"
          aria-label="Chat message input"
          rows="2"
        ></textarea>
        <button class="send-button" (click)="sendMessage()" aria-label="Send message">
          <i class="fas fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
