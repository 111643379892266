import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SurveyService} from '../../services/survey.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {isPlatformBrowser} from '@angular/common';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit , OnDestroy{
  resultClass = '';
  resultDisplay = 'none';
  surveyData: any;
  encuesta: any;
  envioId: string;
  submitButtonDisabled: boolean;
  required = 'This field is required';
  sendResultOk = 'Survey sent. Thank you for your participation.  If you wish to send us any additional feeback please use our email comments@vehiculosvip';
  sendResultError = 'Survey SENDING ERROR.<br/> We are sorry for the inconvenience.<br/> Your feedback is very important to us, to send us your feedback you can us our \'contact\' form or send us a mail at comment@vehiculosvip.com<br/><br/>Thank you for your visit.';
  sendResultDuplicate = 'Survey already send. <br/> We are sorry for the inconvenience.<br/> Your feedback is very important to us, to send us your feedback you can us our \'contact\' form or send us a mail at comment@vehiculosvip.com<br/><br/>Thank you for your visit.';
  sendResultImcomplete = 'Please fill out all mandatory fields.';
  resultStyle;
  resultMessage;
  submitted = false;
  alreadySend = false;
  EPE: any[];
  introduccion;
  surveyForm: UntypedFormGroup;
  @Input() ready: Promise<boolean>;
  element: HTMLTextAreaElement;
  subscriptions = [];
  constructor(  private route: ActivatedRoute, private surveyService: SurveyService, @Inject(PLATFORM_ID) private platformId: any ,
                private canonicalAndMetaService: CanonicalAndMetaService, private ref: ChangeDetectorRef,
                private translate: TranslateService , private formBuilder: UntypedFormBuilder) {
    this.translate.setDefaultLang('en');

  }

  async ngOnInit() {
    console.log('en el nginit ');
    await this.canonicalAndMetaService.setCanonicalURL('survey').then(async result => {
      await this.canonicalAndMetaService.setMetas('survey', false, '');
    });
    console.log(this.ready);
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      console.log('llego una ruta');
      console.log(this.route.snapshot.paramMap);
      this.envioId = params.get('id');
      console.log('envioId: ' + this.envioId);
      if (this.envioId == null) {
        console.log('pero sin id entonces salemos');
        this.envioId = 'invalid';
        this.ready = Promise.resolve(true);
      } else {
        this.ready = firstValueFrom(this.surveyService.Survey(this.envioId))
          .then(data => {
            console.log('llego la encuesta');
            console.log('data del this.surveyService.Survey(this.envioId) ' + this.envioId);
            console.log(data);
            this.encuesta = data;
            if (data == null) {
              console.log('pero nulla');
              this.ready = Promise.resolve(true);
              this.envioId = 'invalid';
            } else {
              this.introduccion = this.encuesta[0].introduccion;
            }
            return Promise.resolve(this.envioId);
          }, error => {
            this.envioId = 'invalid';
            console.log('EnvioId erre 1' + this.envioId);
            console.log(error);
            return Promise.reject(this.envioId);
          })
          .then(envioId => {
            if (envioId === 'invalid') {
              console.log('EnvioId erre 2' + envioId);
              return Promise.resolve(true);
            }
            console.log('llegaron las preguntas');
            return firstValueFrom(this.surveyService.SurveyQuestions({id: envioId})).then(response => {
                  if (response == null) {
                    this.envioId = 'invalid';
                    return Promise.resolve(true);
                  }
                  this.EPE = response;
                  console.log(response);
                  console.log('data del this.EPE ');
                  console.log(this.EPE);
                  let values = [];
                  let opciones = [];
                  const arrayLength = this.EPE.length;
                  for (let i = 0; i < arrayLength; ++i) {
                    this.EPE[i].respuesta = '';
                    this.EPE[i].respuesta2 = '';
                    this.EPE[i].key = i;
                    const preguntaActual = this.EPE[i].preguntaEncuesta;
                    opciones = [];
                    if (preguntaActual.tipo.isMultipleChoice) {
                      const maxOpciones1 = preguntaActual.tipo.max;
                      if (!(preguntaActual.tipo.valueList === '')) {
                        values = preguntaActual.tipo.valueList.split(';');
                      }
                      if (maxOpciones1 > 0) {
                        this.EPE[i].controlType = 'radioButton';
                        this.EPE[i].required = true;
                      } else {
                        this.EPE[i].controlType = 'dropDown';
                        this.EPE[i].required = true;
                      }
                      let maxOpciones = preguntaActual.tipo.max;
                      if (values.length > maxOpciones) {
                        maxOpciones = values.length;
                      }
                      if (maxOpciones > 0) {
                        for (let j = 0; j < maxOpciones; j++) {
                          const opcion: any = {};
                          opcion.id = j;
                          if (values.length > preguntaActual.tipo.max) {
                            opcion.text = values[j];
                          } else {
                            opcion.text = j + 1;
                          }
                          opciones.push(opcion);
                        }
                      }
                      this.EPE[i].preguntaEncuesta.opciones = opciones;
                    } else if (preguntaActual.tipo.isText) {
                      this.EPE[i].controlType = 'freeText';
                      this.EPE[i].required = false;
                    } else {
                      this.EPE[i].controlType = 'infoText';
                      this.EPE[i].required = false;
                    }
                  }
                  if (arrayLength === 0) {
                    this.envioId = 'invalid';
                  }
                  console.log(this.EPE);
                  if (this.envioId !== 'invalid') {
                    this.surveyForm = this.toFormGroup(this.EPE);
                    console.log('Survey Form Values');
                    console.log(this.surveyForm.value);
                    for (let i = 0; i < arrayLength; ++i) {
                      this.subscriptions.push(this.surveyForm.get(i.toString()).valueChanges.subscribe(value =>
                        this.EPE[i].respuesta = value));
                    }
                  }
                  return Promise.resolve(true);
                },
                // Error
                error => {
                  this.envioId = 'invalid';
                  console.log('EnvioId erre ' + this.envioId);
                  console.log(error);
                  return Promise.reject(true);
                });
          });
      }
    }));
    firstValueFrom(this.translate.get('Survey-6')).then(text => {
      this.required = text;
    });
    firstValueFrom(this.translate.get('Survey-7')).then(text => {
      this.sendResultOk = text;
    });
    firstValueFrom(this.translate.get('Survey-8')).then(text => {
      this.sendResultError = text;
    });
    firstValueFrom(this.translate.get('Survey-9')).then(text => {
      this.sendResultDuplicate = text;
    });
    firstValueFrom(this.translate.get('Survey-10')).then(text => {
      this.sendResultImcomplete = text;
    });
    this.submitButtonDisabled = false;
    this.submitted = false; // used so that form errors are shown only after the form has been submitted
  }

  toFormGroup(questions: any[] ) {
    const group: any = {};
    console.log('questions in toFormGroup');
    console.log(questions);
    questions.forEach(question => {
        console.log('question');
        console.log(question);
        if (question.required){
            group[question.key] = ['', Validators.required];
        } else {
            group[question.key] = [''];
        }
    });
    console.log('group in toFormGroup');
    console.log(group);
    const formGroup = this.formBuilder.group(group);
    console.log('formgroup in toFormGroup');
    console.log(formGroup);
    console.log(this.submitted);
    return formGroup;
  }

  submitSurvey() {
      this.submitted = true;
      this.submitButtonDisabled = true;
      this.surveyData = [];
      console.log('in submit survey : this.surveyForm.valid ');
      console.log(this.surveyForm.valid);
      if ( this.surveyForm.valid ) {
        const arrayLength = this.EPE.length;
        this.ready = Promise.resolve(false);
        this.alreadySend = true;
        if (isPlatformBrowser(this.platformId) ){
          window.scrollTo(0, 0);
        }
        for (let i = 0; i < arrayLength; ++i) {
          const respuestaIndividual: any = {};
          respuestaIndividual.envioId = this.envioId;
          respuestaIndividual.preguntaId = this.EPE[i].preguntaEncuesta.id;
          this.element = document.getElementById(i + '-freeText') as HTMLTextAreaElement;
          if (this.element) {
            console.log(this.element.value);
            this.EPE[i].respuesta2 = this.element.value;
          }
          respuestaIndividual.respuesta = this.surveyForm.get(i.toString()).value;
          respuestaIndividual.respuesta2 = this.EPE[i].respuesta2;
          this.surveyData.push(respuestaIndividual);
        }
        console.log('this.surveyData');
        console.log(this.surveyData);

        this.subscriptions.push (this.surveyService.SurveyAnswer(this.surveyData, this.envioId).subscribe( response => {
          const data = response;
          if (data.result === '1'){
            this.submitButtonDisabled = true;
            this.resultMessage = this.sendResultOk;
            this.resultStyle = 'bg-success';
          } else {
            this.submitButtonDisabled = true;
            this.resultMessage = this.sendResultDuplicate;
            this.resultStyle = 'bg-danger';
          }
          this.ready = Promise.resolve(true);
          this.ref.markForCheck();

        }, error => {
          this.alreadySend = false;
          this.submitButtonDisabled = false;
          this.resultMessage = this.sendResultError;
          this.resultStyle = 'bg-danger';
          this.ready = Promise.resolve(true);
          this.ref.markForCheck();

        }));
      } else {
          this.submitButtonDisabled = false;
          this.resultMessage = this.sendResultImcomplete;
          this.resultStyle = 'bg-danger';
      }
  }

  activateButton() {
      this.submitButtonDisabled = false;
  }

  Idioma(){
    return 'es';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
onSubmit(){
    console.log('submitting');
    this.surveyForm.markAllAsTouched();
    this.submitted = true;
    console.log('valid ? ' +  this.surveyForm.valid);
    if (this.surveyForm.valid){
      this.submitSurvey();
    }
}
}

