import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {Booking, Passenger, User} from '../../models/business-objects';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {formatDate, isPlatformBrowser} from '@angular/common';
import {
  AddressDetails,
  Location,
  LocationType,
  NewOrModifiedTripRequest,
  PassengerDetails,
  PassengerName,
  ReservationStop,
  TransportationCenterDetails,
  Trip
} from '../../models/internal-api';
import {LocationService} from '../services/location.service';
import {AuthService} from './auth.service';

@Injectable()
export class BookingService implements OnDestroy{
  private bookingSource = new BehaviorSubject<Booking>(new Booking());
  public currentBooking = this.bookingSource.asObservable();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: User;
  private subscriptions = [];
  UrlAPI: string = environment.apiURL;
  constructor(private http: HttpClient, private locationService: LocationService,
              private authService: AuthService, @Inject(PLATFORM_ID) private platformId: any ) {
    if (isPlatformBrowser(this.platformId)){
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(null);
    }
    this.subscriptions.push (this.currentUserSubject.asObservable().subscribe( user => this.newBooking(new Booking())));
    this.subscriptions.push (authService.currentUser.subscribe( user => this.currentUser = user));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }


  getBookingQuotation(booking: Booking): Observable<any> {
    const request = new NewOrModifiedTripRequest();
    const now = new Date();
    request.apiKey = environment.apiKey;
    request.apiVersion = '1.0';
    booking.confirmationNumber = booking.confirmationNumber == null ? 'Quotation' : booking.confirmationNumber;
    this.initializeBookingRequest(booking, request);
    return this.http.post(`${environment.apiURL}${environment.URL_COTIZACION}`, request);
  }


  getPassengers(): Promise<Passenger[]>{
    const urlFinal = environment.URL_PASAJEROS;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.get<any>(this.UrlAPI + urlFinal,  {headers})).then( data => {
      const items: Passenger[] = data;
      console.log(data);
      return items;
    });
  }
  createBookingRequest(booking: Booking): Observable<any> {
    const request = new NewOrModifiedTripRequest();
    const now = new Date();
    request.apiKey = environment.apiKey;
    request.apiVersion = '1.0';
    console.log(booking.confirmationNumber);
    if (booking.confirmationNumber === null || booking.confirmationNumber === undefined || booking.confirmationNumber === 'Quotation') {
      let initials = '';
      if ( this.currentUser != null && this.currentUser.cliente != null
        && this.currentUser.cliente.code != null && this.currentUser.cliente.code.length > 2) {
        initials =  this.currentUser.cliente.code;
        initials += ('00' + (now.getMonth() + 1)).substr(-2, 2);
        initials += '-';
        console.log('tiene cliente, agregamos prefijo del cliente : ' + initials);
      } else {
        initials = 'WS';
        initials += ('00' + (now.getMonth() + 1)).substr(-2, 2);
        initials += ('00' + (now.getDate())).substr(-2, 2);
        if (booking.firstName != null ) {
          if (booking.lastName != null && booking.lastName.trim().length > 1) {
            initials += booking.firstName.trim().substr(0, 1).toUpperCase()
              + booking.lastName.trim().substr(0, 1).toUpperCase();
            console.log('tiene apellido, agregamos prefijo del pasajero : ' + initials);
          } else {
            initials += booking.firstName.trim().substr(0, 2).toUpperCase();
            console.log('NO tiene apellido, agregamos prefijo del pasajero con solo el nombre : ' + initials);
          }
        }
        initials += '-';
      }
      booking.confirmationNumber = initials.trim().toUpperCase()
        + ('000' + Math.round(Math.random() * 9999)).substr(-4, 4);
      console.log('booking.confirmationNumber no existe, entonce se crea uno :' + booking.confirmationNumber);
    } else {
      console.log('booking.confirmationNumber ya existe :' + booking.confirmationNumber);
    }
    this.initializeBookingRequest(booking, request);
    return this.http.post(`${environment.apiURL}${environment.URL_BOOKING}`, request);
  }

  initializeBookingRequest(booking: Booking, request: NewOrModifiedTripRequest): NewOrModifiedTripRequest {
    const now = new Date();
    console.log('booking entrando en createBookingRequest');
    console.log(booking);
    const user = this.authService.currentUserValue;
    if (user && user.cliente) {
      console.log('booking con cliente');
      request.login = user.cliente.apiId;
    } else {
      console.log('booking sin cliente');
      request.login = 'website';
    }
    request.publishTime = formatDate(now, 'MMMM d, y, h:mm:ss a z', 'en_US', '+0500');
    request.recaptchaMessage = booking.recaptchaMessage;
    const trip = new Trip();
    trip.accountName = null;
    if (user) {
      trip.bookedBy = user.fullName ? user.fullName : user.firstName + ' ' + user.lastName;
      trip.bookedByPhone = user.phone;
    } else {
      trip.bookedBy = booking.firstName + ' ' + booking.lastName;
      trip.bookedByPhone = booking.phone;
    }
    trip.bagsCount = booking.bags;
    const puDetails = new Location();
    if (booking.isAirportPickup) {
      puDetails.locationType = LocationType.Airport;
      const transportationCenter = new TransportationCenterDetails();
      transportationCenter.transportationCenterCode = booking.airportCode?.length==3?booking.airportCode:'999';
      transportationCenter.carrierCode = booking.flightNumber.substr(0, 2);
      transportationCenter.carrierNumber = booking.flightNumber.substr(2, booking.flightNumber.length - 2);
      transportationCenter.domestic = false;
      if (booking.terminal && booking.terminal === 'Domestic') {
        transportationCenter.domestic = true;
      }
      if ( booking.airport != null) {
        transportationCenter.privateAviation = booking.airport.isFBO;
        transportationCenter.transportationCenterName = booking.airport.webName != null ? booking.airport.webName : booking.airport.nombre;
        transportationCenter.selectedAirportId = booking.airport.id;
      }
      puDetails.transportationCenterDetails = transportationCenter;
    } else {
      puDetails.locationType = LocationType.Address;
    }
    const addressDetails1 = new AddressDetails();
    if (booking.puCoordinates) {
      addressDetails1.latitude = booking.puCoordinates.lat;
      addressDetails1.longitude = booking.puCoordinates.lng;
    }
    addressDetails1.addressLine1 = booking.puAdress;
    if (booking.puGoogleLocation) {
      addressDetails1.city = this.locationService.extractCityFromGooglePlace(booking.puGoogleLocation);
      addressDetails1.country = this.locationService.extractCountryFromGooglePlace(booking.puGoogleLocation);
      addressDetails1.stateCode = this.locationService.extractStateCodeFromGooglePlace(booking.puGoogleLocation);
    }
    booking.puDate.setHours(booking.puHour);
    booking.puDate.setMinutes(booking.puMin);
    puDetails.pickUpTime = this.formatDateToYYYYMMDDHHMMSS(booking.puDate);
    // format : 2017-04-19T09:00:00 o 'y-MM-ddTHH:mm:00'
    console.log(puDetails.pickUpTime);
    puDetails.addressDetails = addressDetails1;
    trip.pickUpDetails = puDetails;
    const doDetails = new Location();
    const addressDetails2 = new AddressDetails();
    if (booking.doCoordinates) {
      addressDetails2.latitude = booking.doCoordinates.lat;
      addressDetails2.longitude = booking.doCoordinates.lng;
    }
    addressDetails2.addressLine1 = booking.doAdress;
    if (booking.doGoogleLocation) {
      addressDetails2.city = this.locationService.extractCityFromGooglePlace(booking.doGoogleLocation);
      addressDetails2.country = this.locationService.extractCountryFromGooglePlace(booking.doGoogleLocation);
      addressDetails2.stateCode = this.locationService.extractStateCodeFromGooglePlace(booking.doGoogleLocation);
      if(booking.doGoogleLocation.types.includes('airport')) {
        doDetails.locationType = LocationType.Airport;
        const transportationCenter = new TransportationCenterDetails();
        transportationCenter.transportationCenterName = '999';
        const rx = /\((.*)\)/g;
        const arr = rx.exec(booking.doAdress);
        let airportCode = null ;
        try {
          airportCode = arr[1];
        } catch ( ex ) {
          console.log('Codigo areopuerto no encontrado en la direccion ');
        }
        airportCode = airportCode?airportCode.trim():'Not Found'
        transportationCenter.transportationCenterCode = airportCode.length==3?airportCode:'999';
        doDetails.transportationCenterDetails = transportationCenter;
      }
    }
    if (!doDetails.locationType) doDetails.locationType = LocationType.Address;
    if (booking.serviceType !== 'transfer') {
      const dropOffTime: Date = new Date();
      const pickUpTime: Date = booking.puDate;
      console.log('fecha pickcup en el calculo de la fecha de fin : ');
      console.log(pickUpTime);
      dropOffTime.setTime(pickUpTime.getTime() +  (booking.duration * 60 * 60 * 1000));
      console.log('fecha dropOffTime en el calculo de la fecha de fin : ');
      console.log(dropOffTime);
      puDetails.dropOffTime = this.formatDateToYYYYMMDDHHMMSS(dropOffTime);
      doDetails.dropOffTime = puDetails.dropOffTime;
    }
    doDetails.addressDetails = addressDetails2;
    trip.dropOffDetails = doDetails;
    const passengerDetails = new PassengerDetails();
    passengerDetails.emailAddress = booking.email;
    passengerDetails.firstName = booking.firstName;
    passengerDetails.lastName = booking.lastName;
    passengerDetails.mobileNumber = booking.phone;
    passengerDetails.passengerCount = booking.passengers;
    passengerDetails.nameSign = booking.nameSign;
    const passengerName = new PassengerName();
    passengerName.firstName = booking.firstName;
    passengerName.lastName = booking.lastName;
    passengerName.isPrimary = true;
    const passengerNames: PassengerName[] = [];
    passengerNames.push(passengerName);
    booking.passengerList.forEach( (pax, index) => {
      const newPax = new PassengerName();
      newPax.firstName = pax.firstName;
      newPax.lastName = pax.lastName;
      newPax.email = pax.email;
      newPax.phone = pax.phone;
      newPax.isPrimary = false;
      passengerNames.push(newPax);
    });
    passengerDetails.passengerNames = passengerNames;
    trip.passengerDetails = passengerDetails;
    trip.isNewTrip = true;
    trip.mobileNumber = booking.phone;
    trip.phoneNumber = booking.phone;
    trip.reservationNumber = booking.confirmationNumber;
    // no cambiar este texto abajo porque el api se basa en eso para adpatar las cotizaciones
    trip.reservationSource = 'web site booking form';
    trip.serviceCity = booking.serviceCity;
    trip.tripType  = booking.serviceType;
    trip.tripDuration = booking.duration;
    trip.itinerary = booking.message;
    trip.greeterRequested = booking.greeterRequested;
    if (booking.stops && booking.stops.length > 0) {
      let i = 0;
      const stops: ReservationStop[] = [];
      booking.stops.forEach(s => {
        i = i + 1;
        const stop = new ReservationStop();
        stop.stopSequence = i;
        stop.locationType = LocationType.Address;
        const addressDetails = new AddressDetails();
        if (s.coordinates && s.coordinates.lng && s.coordinates.lat) {
          addressDetails.latitude = s.coordinates.lat;
          addressDetails.longitude = s.coordinates.lng;
        }
        addressDetails.addressLine1 = s.address;
        if (s.placeResult) {
          addressDetails.city = this.locationService.extractCityFromGooglePlace(s.placeResult);
          addressDetails.country = this.locationService.extractCountryFromGooglePlace(s.placeResult);
          addressDetails.stateCode = this.locationService.extractStateCodeFromGooglePlace(s.placeResult);
        }
        stop.addressDetails = addressDetails;
        stops.push(stop);
      });
      trip.reservationStops = stops;
    }
    trip.tripKm = booking.routeDistanceInKm;
    trip.routeDurationInMin = booking.routeDurationInMin;
    trip.vehicleType = booking.vehicleCategory.apiName;
    trip.discountCode = booking.discountCode;
    request.trip = trip;
    console.log('Full request sent : ');
    console.log(request);
    return request;
  }

  updateBooking(booking: Booking) {
    console.log('providing updated Booking to everyone');
    console.log(booking);
    this.bookingSource.next(booking);
  }

  newBooking(originalBooking: Booking) {
    console.log('providing new Booking to everyone');
    const booking: Booking = new Booking();
    booking.city = originalBooking.city;
    booking.serviceCity = originalBooking.serviceCity;

    booking.searchType = originalBooking.searchType;
    booking.serviceType = originalBooking.serviceType;
    // const booking = this.getTestBooking();
    if ( this.authService.currentUserValue && this.authService.currentUserValue.cliente) {
      booking.cliente = this.authService.currentUserValue.cliente;
      booking.isReturn = false;
    } else {
      booking.bags = originalBooking.bags;
      booking.passengers = originalBooking.passengers;
      booking.email = originalBooking.email;
      booking.firstName = originalBooking.firstName;
      booking.lastName = originalBooking.lastName;
      booking.phone = originalBooking.phone;
      booking.passengerList = originalBooking.passengerList;
      booking.vehicleCategory = originalBooking.vehicleCategory;
      booking.isReturn = false;
    }
    this.bookingSource.next(booking);
  }

  bookReturn(originalBooking: Booking){
    console.log('providing return Booking to everyone');
    const booking  = this.createReturnBookingData(originalBooking);
    // const booking = this.getTestBooking();
    if ( this.authService.currentUserValue && this.authService.currentUserValue.cliente) {
      booking.cliente = this.authService.currentUserValue.cliente;
    }
    this.bookingSource.next(booking);
  }
  createReturnBookingData(originalBooking: Booking): Booking{
    const returnJob: Booking = new Booking();
    returnJob.city = originalBooking.city;
    returnJob.serviceCity = originalBooking.serviceCity;
    returnJob.doAdress = originalBooking.puAdress;
    returnJob.doCoordinates = originalBooking.puCoordinates;
    returnJob.doGoogleLocation = originalBooking.puGoogleLocation;
    returnJob.puAdress = originalBooking.doAdress;
    returnJob.puCoordinates = originalBooking.doCoordinates;
    returnJob.puGoogleLocation = originalBooking.doGoogleLocation;
    returnJob.bags = originalBooking.bags;
    returnJob.passengers = originalBooking.passengers;
    returnJob.email = originalBooking.email;
    returnJob.firstName = originalBooking.firstName;
    returnJob.lastName = originalBooking.lastName;
    returnJob.phone = originalBooking.phone;
    returnJob.passengerList = originalBooking.passengerList;
    returnJob.vehicleCategory = originalBooking.vehicleCategory;
    returnJob.searchType = originalBooking.searchType;
    returnJob.serviceType = originalBooking.serviceType;
    returnJob.isReturn = true;
    if ( originalBooking.isAirportPickup ){
      returnJob.isAirportPickup = false;
    }
    if ( originalBooking.doGoogleLocation &&
      originalBooking.doGoogleLocation.types.length > 0 &&
      originalBooking.doGoogleLocation.types.includes('airport')) {
      returnJob.isAirportPickup = true;
    } else {
      returnJob.isAirportPickup = false;
    }
    if ( this.authService.currentUserValue && this.authService.currentUserValue.cliente) {
      returnJob.cliente = this.authService.currentUserValue.cliente;
    }
    return returnJob;
  }

  formatDateToYYYYMMDDHHMMSS(dropOffTime: Date): string{
    let formatedDate = '';
    const year = '000000000' + dropOffTime.getFullYear();
    const month = '000000000' + (dropOffTime.getMonth() + 1);
    const day = '000000000' + dropOffTime.getDate();
    const hour = '000000000' + dropOffTime.getHours();
    const min = '000000000' + dropOffTime.getMinutes();
    formatedDate = year.substr(year.length - 4) + '-' + month.substr(month.length - 2) + '-'
      + day.substr(day.length - 2);
    formatedDate += 'T' + hour.substr(hour.length - 2) + ':'
      + min.substr(min.length - 2) + ':00';
    return formatedDate;
  }

}
