import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../services/interface/layout.service';
import {ConfigService} from '../../services/interface/config.service';
import {isPlatformBrowser} from '@angular/common';
import {UntypedFormControl} from '@angular/forms';
import {LISTITEMS} from '../../shared/data/template-search';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Booking, User} from '../../../models/business-objects';
import {BookingService} from '../../services/booking.service';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {DataService} from '../../services/data.service';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  flag = 'us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-left';
  public isCollapsed = true;
  someOneIsLogged = new BehaviorSubject<boolean>(true);
  width = 960;
  screenSize = 'L';
  showQuickAccesses : Subject<boolean>;
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  suffixUrl: string;
  subscriptions = [];
  inPrivateSection = new BehaviorSubject<boolean>(false);
  loggedUser= new BehaviorSubject<User>(null);
  userName= new BehaviorSubject<string>("");
  clientName= new BehaviorSubject<string>("");
  loggedUserObservable = this.loggedUser.asObservable();
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();
  public config: any = {};
  constructor(@Inject(PLATFORM_ID) private platformId: any, public translate: TranslateService,
              private layoutService: LayoutService,
              private renderer: Renderer2,
              private configService: ConfigService, private cdr: ChangeDetectorRef,
              private bookingService: BookingService, private dataService:DataService,
              private canonicalAndMetaService: CanonicalAndMetaService,
              private router: Router, private authService: AuthService,
              private localize: LocalizeRouterService) {
    this.someOneIsLogged.next(this.authService.currentUserValue != null);
    this.loggedUser.next(this.authService.currentUserValue );
    this.subscriptions.push( this.authService.currentUser.subscribe(user => {
      console.log("received user : ", user)
      this.userName.next(user? user.fullName?.toUpperCase():"")
      this.clientName.next(user? (user.cliente? user.cliente.fullName?.toUpperCase():"" ):"")
      this.someOneIsLogged.next(this.authService.currentUserValue != null);
      this.loggedUser.next(this.authService.currentUserValue );
      })
    )
    this.config = this.configService.templateConf;
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.subscriptions.push (layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      }));
    this.currentLang = this.translate.currentLang;
    this.SetFlag(this.currentLang);
    this.showQuickAccesses = this.dataService.showQuickAccesses;
    this.inPrivateSection = this.dataService.inPrivateSection;
    if (   this.router.url.includes(this.localize.translateRoute('/dispatch') as string)
      || this.router.url.includes(this.localize.translateRoute('/client') as string)
      || this.router.url.includes(this.localize.translateRoute('/private') as string)
      || this.router.url.includes(this.localize.translateRoute('/chat') as string)
    ) {
      console.log('Nav detecta alguien en seccion privada ' +this.router.url)
      this.inPrivateSection.next(true);
      this.showQuickAccesses.next(false);
    } else {
      console.log('Nav detecta alguien en seccion publica ' +this.router.url)
      this.inPrivateSection.next(false);
      this.showQuickAccesses.next(true);
    }
    this.loggedUserObservable.subscribe(user => {
      this.userName.next( user != null? user.fullName?.toUpperCase() : "User Not Found");
      this.clientName.next( (user != null && user.cliente != null )? user.cliente.fullName?.toUpperCase() : "");
    })
    this.loggedUser.next(this.authService.currentUserValue);
  }


  ngOnInit() {
    this.subscriptions.push (this.canonicalAndMetaService.languageChanged.subscribe(event => {
      const lang = event.lang;
      console.log('NavBar detected language change to ' + event.lang + ' Changing Flag');
      this.SetFlag(lang);
    }));
    this.listItems = LISTITEMS;
    if (this.innerWidth < 992) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
    if (isPlatformBrowser(this.platformId)){
      this.onResize();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (event && (this.width !== event.target.innerWidth || this.innerWidth !== event.target.innerWidth)) {
      console.log('window resized in nav component with width change ' + event.target.innerWidth);
      this.width = event.target.innerWidth;
      this.innerWidth = event.target.innerWidth;
      if (this.innerWidth < 992) {
        this.isSmallScreen = true;
      }
      else {
        this.isSmallScreen = false;
      }
    }
    if (this.width > 1280){
      this.screenSize = 'L';
    } else if (this.width < 1280) {
      this.screenSize = 'S';
    } else {
      this.screenSize = 'S';
    }
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    }));
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  public SetFlag(language: string){
    switch (language) {
      case 'es':
        this.selectedLanguageText = 'Spanish';
        this.selectedLanguageFlag = 'es.png';
        this.flag = 'es.png';
        break;
      case 'en':
        this.flag = 'us.png';
        this.selectedLanguageText = 'English';
        this.selectedLanguageFlag = 'us.png';
        break;
      case 'fr':
        this.flag = 'fr.png';
        this.selectedLanguageText = 'Francais';
        this.selectedLanguageFlag = 'fr.png';
        break;
      case 'de':
        this.flag = 'de.png';
        this.selectedLanguageText = 'German';
        this.selectedLanguageFlag = 'de.png';
        break;
      case 'pt':
        this.flag = 'br.png';
        this.selectedLanguageText = 'Portuguese';
        this.selectedLanguageFlag = 'br.png';
        break;
    }
  }

  async ChangeLanguage(language: string) {
    this.localize.changeLanguage(language);
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = '';
    }

  }
  logout() {
    this.authService.logout();
    this.bookingService.updateBooking(new Booking());
    this.router.navigate([this.localize.translateRoute('/home')]).then(ok =>{}, error=>{}).then(() => {
      location.reload();
    });
  }


  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

    protected readonly encodeURIComponent = encodeURIComponent;
}
