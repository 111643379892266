<div id="news-header">
  <h1>{{'News' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
    <i class="fa fa-angle-right"></i>{{'News' | translate}}</p>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-10 col-sm-11 ms-1 me-1">
    <h3 class="center-title text-uppercase" *ngIf="newsList.length === 1 ">{{newsList[0].title}}</h3>
    <h3 class="center-title text-uppercase" *ngIf="newsList.length > 1 ">All news</h3>
    <h3 class="center-title text-uppercase" *ngIf="newsList.length === 0 && !loadingNews">No news Found</h3>
    <div class="title-block2" *ngIf="!loadingNews"></div>
    <div class="news-container" *ngIf="newsList.length > 1 " >
        <mat-card class="example-card m-1" *ngFor="let pieceOfNews of newsList">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{pieceOfNews.title}}</mat-card-title>
              <mat-card-subtitle>
                <div class="tagcloud" >
                  <a *ngFor="let tag of pieceOfNews.tags.split(';').slice(0,2)"
                     [routerLink]="('/news' | localize) + '/' + tag" title="news" href="{{('/news' | localize) + '/' + tag}}" >
                    {{tag | translate}}
                  </a>
                </div>
              </mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image [src]="pieceOfNews.thumbnail_url ? pieceOfNews.thumbnail_url:pieceOfNews.imageUrl"
                 *ngIf="pieceOfNews.thumbnail_url || pieceOfNews.imageUrl"
                 alt="Photo of {{pieceOfNews.title}}" style="height: 250px; object-fit: scale-down; ">
            <mat-card-content style="height: 200px; overflow: hidden">
              <div [innerHTML]="pieceOfNews.newsBody"></div>
              <div *ngIf="pieceOfNews.seccionNoticia === 'INSTAGRAM'">
                <div class="text-justify" class="tagcloud"  *ngIf="newsList[0].seccionNoticia === 'INSTAGRAM'">
                  <a [href]="'https://www.instagram.com/p/'+ newsList[0].myId" target="_blank">{{'News-ViewInInstagram' | translate}}</a>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button [routerLink]="('/news' | localize)+ '/id/'+pieceOfNews.id" >{{'News-ReadMore' | translate}}</button>
            </mat-card-actions>
          </mat-card>
    </div>
    <div class="row justify-content-center" *ngIf="newsList.length === 1">

      <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1" >
        <p class="text-justify" [innerHTML]="newsList[0].newsBody"></p>
        <div class="text-justify" class="tagcloud"  *ngIf="newsList[0].seccionNoticia === 'INSTAGRAM'">
          <a [href]="'https://www.instagram.com/p/'+ newsList[0].myId" target="_blank">{{'News-ViewInInstagram' | translate}}</a>
        </div>
        <ul class="border-list">
          <li>{{newsList[0].author}}</li>
          <li>{{newsList[0].datePublished}}</li>
        </ul>
        <div class="tagcloud" >
          <a *ngFor="let tag of newsList[0].tags.split(';')" [routerLink]="('/news' | localize) + '/' + tag" href="{{('/news' | localize) + '/' + tag}}" title="news">{{tag | translate}}</a>
        </div>
        <!-- END .qns-two-third -->
      </div>
      <div class=" col-lg-2 col-sm-3 mb-5 ">
        <img [src]="newsList[0].imageUrl" *ngIf="newsList[0].seccionNoticia != 'INSTAGRAM'">
        <img [src]="newsList[0].thumbnail_url ? newsList[0].thumbnail_url: newsList[0].imageUrl" *ngIf="newsList[0].seccionNoticia === 'INSTAGRAM'">
      </div>
    </div>
  </div>
</div>
