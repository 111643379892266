import {Component, OnInit} from '@angular/core';
import {MySlide, News} from '../../../models/business-objects';
import {NewsService} from '../../services/news.service';

@Component({
  selector: 'app-news-wrapper',
  templateUrl: './news-wrapper.component.html',
  styleUrls: ['./news-wrapper.component.scss']
})
export class NewsWrapperComponent implements OnInit {

  news: News[] | null = [];
  slides: MySlide[] = [];
  constructor(private newsService: NewsService) { }
  // scriptInstagram = 'https://platform.instagram.com/en_US/embeds.js';
  ngOnInit() {
    // this.newsService.getAllNews().then( response => this.news = response);
    this.news = this.newsService.news;
    if ( this.news.length === 0 ) {
      this.newsService.getCurrentNews().then(response => {
        this.news = response;
        // console.log('slides news received');
        // console.log(response);
        this.constructSlides();
      });
    } else {
      this.constructSlides();
    }
    // console.log(this.slides);
  }
  constructSlides() {
    for (const pieceOfNews of this.news) {
      const newSlide: MySlide = new MySlide();
      newSlide.news = pieceOfNews;
      newSlide.news.hasScripts = false;
       // newSlide.news.scriptUrl = this.scriptInstagram;
      this.slides.push(newSlide);
    }
    // console.log('slides constructed');
    // console.log(this.slides);
  }
}
