<h1 mat-dialog-title [class]="'MapTitle'">{{title | translate}}</h1>
<div mat-dialog-content class="p-0">
  <ng-container
    *ngIf="showRoute; then route; else point">
  </ng-container>
  <ng-template #point>
    <div [ngClass]=" {'map-container-small': isEmbeded || isSmallDevice,
                      'map-container': !isEmbeded && !isSmallDevice}">
      <google-map   #Map [center]="{ lat, lng}"  [zoom]="zoom" height="100%" width="100%">
        <map-marker [options]="{draggable : true}"  (mapDragend)="markerDragEnd(coordinates.lat, coordinates.lng, $event)"
                    [position]="coordinates.LatLng"></map-marker>
      </google-map>
    </div>
  </ng-template>
  <ng-template #route>
    <ng-container
      *ngIf="_googleUrlForRoute!=null; then external; else internal">
    </ng-container>
    <ng-template #internal>
      <div  [ngClass]=" {'map-container-small': isEmbeded || isSmallDevice,
                         'map-container': !isEmbeded && !isSmallDevice }">
        <google-map #Map2 [zoom]="zoom" height="100%" width="100%">
          <map-marker [options]="{draggable : false}"   [position]="puPoint.LatLng"></map-marker>
          <map-marker [options]="{draggable : false}"   [position]="doPoint.LatLng"></map-marker>
          <map-directions-renderer *ngIf="showRoute" ></map-directions-renderer>
        </google-map>
      </div>
    </ng-template>
    <ng-template #external>
    <iframe   width="600" height="450" [src]="iframeSrc"></iframe>
    </ng-template>
  </ng-template>
</div>
<ng-container
  *ngIf="showRoute; then routeButtons; else pointButtons">
</ng-container>
<ng-template #pointButtons>
  <div mat-dialog-actions>
    <button  mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
    <button  mat-button [mat-dialog-close]="coordinates" cdkFocusInitial (click)="onUpdateClick()">{{'Update' | translate}}</button>
  </div>
</ng-template>
<ng-template #routeButtons >
  <div mat-dialog-actions *ngIf="isEmbeded != undefined &&  !isEmbeded">
    <button  mat-button (click)="onNoClick()">{{'Close' | translate}}</button>
  </div>
</ng-template>
