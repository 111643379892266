import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricingWidgetComponent} from './pricing-widget.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    PricingWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    PricingWidgetComponent, TranslateModule
  ]
})
export class PricingWidgetModule { }
