import {isPlatformBrowser} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { Inject, NgModule, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import {REQUEST} from '../../express.tokens';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {Request} from 'express';

import {translateLoaderFactory} from './translate-loaders';


@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, TransferState, PLATFORM_ID]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any ,

    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platform: any
  ) {
      translate.addLangs(['en', 'es', 'fr', 'de', 'pt']);
      translate.setDefaultLang('en');
      let browserLang;
      if (isPlatformBrowser(this.platform)) {
        this.checkCookie()
        const regExp: RegExp = this.getCookieRegExp("LOCALIZE_DEFAULT_LANGUAGE");
        const result: RegExpExecArray = regExp.exec(document.cookie);
        if (result) browserLang = decodeURIComponent(result[1]);
        console.log('browserLang from cookie ' + browserLang);
        if (!browserLang) browserLang = translate.getBrowserLang() || 'en';
        console.log('translate.getBrowserLang ' + browserLang);
      } else {
        browserLang = this.getLangFromServerSideCookie() || 'en';
      }
    this.translate.getTranslation('en')
    this.translate.getTranslation('es')
    this.translate.getTranslation('fr')
    this.translate.getTranslation('de')
    this.translate.getTranslation('pt')
      if(!translate.currentLang) {
        console.log('translate initialized with ' + (browserLang.match(/en|es|fr|de|pt/) ? browserLang : 'en'));
        translate.use(browserLang.match(/en|es|fr|de|pt/) ? browserLang : 'en');
      }
      this.translate.onLangChange.subscribe(event =>{
        console.log("I18n Module detect language change to " + event.lang)
      })
  }

  getLangFromServerSideCookie() {
    if (this.req) {
      return this.req.cookies.LOCALIZE_DEFAULT_LANGUAGE;
      console.log('req.cookies.lang ' + this.req.cookies.LOCALIZE_DEFAULT_LANGUAGE);
    } else {
      console.log('no Request !');
    }
  }

  checkCookie(){
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled){
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
    }
    return cookieEnabled || this.showCookieFail();
  }

  showCookieFail(){
    if (isPlatformBrowser(this.platformId)) {
      window.alert("Without cookies this website will not work for security and translations purposes" +
        "\nPlease enable cookies para nuestra pagina or call us at +57-601-211-4949" +
        "\nPor razones de seguridad y de traducciones, sin cookies este sitio web no funciona" +
        "\nPor favor autorizar cookies para nuestra pagina o llamenos al +57-601-211-4949" +
        "\nV&V Vehiculos VIP");
    }
  }
  private getCookieRegExp( name: string ): RegExp {
    const escapedName: string = name.replace( /([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1' );

    return new RegExp( '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g' );
  }
}
