import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TestimonialsService} from '../../services/testimonials.service';
import {TranslateService} from '@ngx-translate/core';
import {MySlide, Testimonial} from '../../../models/business-objects';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-testimonials-wrapper',
  templateUrl: './testimonials-wrapper.component.html',
  styleUrls: ['./testimonials-wrapper.component.scss']
})

export class TestimonialsWrapperComponent implements OnInit , OnDestroy{
  allTestimonials: Array<Testimonial> = [];
  testimonials: Array<Testimonial> = [];
  slides: MySlide[] = [];
  subscriptions = [];
  @Input() withTitle?;
  constructor(private testimonialService: TestimonialsService, private translate: TranslateService,private canonicalAndMetaService: CanonicalAndMetaService) {
    this.translate.setDefaultLang('en');
    this.loadTestimonials();
    this.subscriptions.push(this.canonicalAndMetaService.languageChanged.subscribe( event => {
      this.loadTestimonials();
    }));
    if (!this.withTitle) { this.withTitle = true; }
  }

  async loadTestimonials() {
    this.allTestimonials = await this.testimonialService.getTestimonials();
    this.testimonials = this.allTestimonials;
    this.slides = [];
    if (this.testimonials == null) { return; }
    this.testimonials.forEach(testimonial => {
      const newSlide = new MySlide();
      newSlide.text = testimonial.reviewBody;
      newSlide.title = testimonial.title;
      newSlide.author = testimonial.author;
      newSlide.date = testimonial.datePublished;
      this.slides.push(newSlide);
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  ngOnInit() {

  }

}
