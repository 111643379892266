<!-- BEGIN .header-area-1 -->
<div class="logo d-none d-xl-block" [routerLink]="'/home' | localize" *ngIf="!(inPrivateSection| async)">
  <img src="../../../assets/images/logo.png" alt="logo V&V Vehiculos VIP"/>
  <!-- END .logo -->
</div>
<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed'}">

  <div class="header-area-1 w-100">
      <div class="top-bar-wrapper col-12 ms-0">
        <div class="top-bar top-bar-right row justify-content-end w-100 ">
          <ul class="navbar-nav w-100 justify-content-end ms-0">
            <li class="nav-item top-logo me-2" *ngIf="!(inPrivateSection| async)">
              <a class="d-block d-xl-none top-logo"  [routerLink]="'/home' | localize" title="{{'Home' | translate}}" >
                <img src="../../../assets/images/logo-top-xs.png" alt="logo V&V Vehiculos VIP">
              </a>
            </li>
            <li class="nav-item top-logo me-2" *ngIf="(inPrivateSection| async)">
              <a class="d-block top-logo"  [routerLink]="'/home' | localize" title="{{'Home' | translate}}" >
                <img src="../../../assets/images/logo-top-xs.png" alt="logo V&V Vehiculos VIP">
              </a>
            </li>
            <div class="ms-2 col text-center w-100" *ngIf="(!isSmallScreen && (someOneIsLogged | async))">
                Welcome {{userName | async}} {{clientName | async}}
            </div>
            <li class="nav-item">
              <a class="navbar-toggle menu-toggle ms-1 d-lg-none d-block pb-2 pt-2 align-items-center menu-toggle-left justify-content-center"
                 data-toggle="collapse" title="Menu" (click)="toggleSidebar()">Menu</a> </li>
            <li class="nav-item d-none d-lg-block" *ngIf="!(inPrivateSection| async)">
              <a class="nav-link position-relative ms-2" [routerLink]="'/about-us' | localize" title="{{'ToolbarLink1' | translate}}">
                {{'ToolbarLink1' | translate}}
              </a></li>

            <li class="nav-item d-block d-xl-none">
              <a class="btn btn-primary position-relative"
                 *ngIf="!(inPrivateSection | async)"
                 title="{{'Booking' | translate}}"
                 style=" background: #997f60;border-color: #ffffff;"
                 [routerLink]="'/booking' | localize">
                {{ innerWidth>=500 && !(inPrivateSection| async) ? ('BookNow' | translate) : ('Booking' | translate)}}
              </a>
            </li>
            <li class="nav-item d-none d-sm-block" *ngIf="(inPrivateSection| async) && isSmallScreen">
               <a class="nav-link position-relative ms-2 pt-2" >
                   Welcome {{userName | async}}
               </a>
            </li>
            <li class="nav-item d-none d-md-block" *ngIf="!(inPrivateSection| async)">
              <a class="nav-link position-relative ms-2" title="{{'FrequentlyAskedQuestions' | translate}}" [routerLink]="'/faq' | localize">
                {{'FrequentlyAskedQuestions' | translate}}
              </a></li>
            <li class="nav-item d-none d-md-block" *ngIf="!(inPrivateSection| async)">
              <a class="nav-link position-relative ms-2" title="{{'ToolbarLink3' | translate}}"
                 [routerLink]="'/fleet/bogota' | localize">
                {{'ToolbarLink3' | translate}}
              </a></li>
            <li class="nav-item ms-2" ngbDropdown [placement]="placement">
              <a class="nav-link position-relative" id="dropdownLang" ngbDropdownToggle
                 title="{{'Language' | translate}}">
                <img src="./assets/img/flags/{{flag}}" alt="English" class="langimg" style="vertical-align: middle ">
                <span *ngIf="innerWidth > 600">{{'Language' | translate}}</span>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownLang" class="dropdownLang text-left ms-2">
                <a class="dropdown-item py-1 lang" href="javascript:"
                   title="English"
                   (click)="ChangeLanguage('en')">
                  <img src="../../../assets/img/flags/us.png" alt="English" class="langimg">
                  <span>English</span>
                </a>
                <a class="dropdown-item py-1 lang" href="javascript:"
                   title="Español"
                   (click)="ChangeLanguage('es')">
                  <img src="../../../assets/img/flags/es.png" alt="Spanish" class="langimg">
                  <span>Español</span>
                </a>
                <a class="dropdown-item py-1 lang" href="javascript:"
                   title="Portugués"
                   (click)="ChangeLanguage('pt')">
                  <img src="../../../assets/img/flags/br.png" alt="Portuguese" class="langimg">
                  <span>Portugués</span>
                </a>
                <a class="dropdown-item py-1 lang" href="javascript:"
                   title="Deutsche"
                   (click)="ChangeLanguage('de')">
                  <img src="../../../assets/img/flags/de.png" alt="German" class="langimg">
                  <span>Deutsche</span>
                </a>
                <a class="dropdown-item py-1 lang" href="javascript:"
                   title="Français"
                   (click)="ChangeLanguage('fr')">
                  <img src="../../../assets/img/flags/fr.png" alt="French" class="langimg">
                  <span>Français</span>
                </a>
              </div>
            </li>
            <li class="nav-item ms-2" ngbDropdown [placement]="placement">
              <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                <i class="ft-user font-medium-3 blue-grey darken-4" style="vertical-align: middle"

                   [ngClass]="{'user-logged': (someOneIsLogged | async)}"
                ></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left"  style="left:-150px">
                <a class="dropdown-item" [routerLink]="'/private' | localize" *ngIf="(someOneIsLogged | async)">
                  <i class="ft-home me-2" tooltip="{{'Private Access' | translate}}"></i>
                  <span>{{'Private Access' | translate}}</span>
                </a>
                <a class="dropdown-item py-1" [routerLink]="'/private/profile' | localize" *ngIf="(someOneIsLogged | async)">
                  <i class="ft-user me-2" tooltip="{{'User Profile' | translate}}"></i>
                  <span>{{'User Profile' | translate}}</span>
                </a>
                <a class="dropdown-item py-1" [routerLink]="'/change-password' | localize" *ngIf="(someOneIsLogged | async)">
                  <i class="ft-edit me-2" tooltip="{{'Login-8' | translate}}"></i>
                  <span>{{'Login-8' | translate}}</span>
                </a>
                <a class="dropdown-item py-1" [routerLink]="'/private/settings' | localize" *ngIf="(someOneIsLogged | async)">
                  <i class="ft-settings me-2" tooltip="{{'Settings-1' | translate}}"></i>
                  <span>{{'Settings-1' | translate}}</span>
                </a>
                <div class="dropdown-divider" *ngIf="(someOneIsLogged | async)"></div>
                <a class="dropdown-item py-1" [routerLink]=" ('/login' | localize)"
                   [queryParams]="{returnUrl:'/private' | json}" *ngIf="!(someOneIsLogged | async)">
                  <i class="ft-log-in me-2" tooltip="{{'Login' | translate}}"></i>
                  <span>{{'Login' | translate}}</span>
                </a>
                <a class="dropdown-item" (click)="logout()" *ngIf="(someOneIsLogged | async)">
                  <i class="ft-log-out me-2" tooltip="{{'Logout' | translate}}"></i>
                  <span>{{'Logout' | translate}}</span>
                </a>
              </div>
            </li>
          </ul>

        </div>
      </div>

    <!-- BEGIN .header-content -->
    <div class="header-content row-100 pr-0 justify-content-start" *ngIf="!(inPrivateSection| async)">

      <!-- BEGIN .header-icons-wrapper -->
      <div class="header-icons-wrapper col-12 d-none d-xl-block pr-0" >
        <div class="row">
          <div class="header-icon col-xl-2 col-lg-3  ps-2 pe-1 box-inner"></div>
          <div class="header-icon col-xl-2  ps-2 pe-1 box-inner">
            <p class="header-icon-text content mb-2">
              <i class="far fa-check-square" aria-hidden="true"></i>
              <strong>{{'HeaderBlock1-0' | translate}}</strong>
            </p>
            <p class="header-icon-text content">{{'HeaderBlock1-1' | translate}}</p>
          </div>
          <div class="header-icon col-xl-2 col-lg-3  ps-2 pe-1 pb-0 box-inner">
            <p class="header-icon-text content mb-2">
              <i class="far fa-check-square" aria-hidden="true"></i>
              <strong>{{'HeaderBlock2-0' | translate}}</strong></p>
            <p class="header-icon-text content">{{'HeaderBlock2-1' | translate}}
            </p>
          </div>
          <div class="header-icon col-xl-3 col-lg-3  ps-2 pe-1 pb-0 box-inner">
            <p class="header-icon-text content mb-2">
              <i class="far fa-check-square" aria-hidden="true"></i>
              <strong>{{'HeaderBlock3-0' | translate}}</strong>
            </p>
            <p class="header-icon-text content">{{'HeaderBlock3-1' | translate}}</p>
          </div>
          <div class="header-icon col-xl-3 col-lg-3  ps-2 pe-1 pt-0 pb-0  box-inner" style="float: right">
            <a [routerLink]="'/booking' | localize" title="{{'OnlineBooking' | translate}}" class="topright-button"><span>{{'OnlineBooking' | translate}}</span></a>
          </div>
        </div>
        <!-- END .header-icons-wrapper -->
      </div>

      <!-- END .header-content -->
    </div>

  </div>
  <ul class="animate-icon" *ngIf="showQuickAccesses | async">
    <li>
      <a class="no-cursor">
        <i class="far fa-envelope"></i>
        <span class="animate-item ps-4">
           <a  href="mailto:info@vehiculosvip.com"> Email: info&#64;vehiculosvip.com  </a>
        </span>
      </a>
    </li>
    <li>
      <a class="no-cursor" >
        <i class="fas fa-phone"></i>
        <span class="animate-item ps-4">
          <a  href="tel:+18883685236">
          {{'CallUs' | translate}}: +1 888 3685236
          </a>
        </span>
      </a>
    </li>
    <li>
      <a class="no-cursor">
        <i class="fab fa-whatsapp"></i>
        <span class="animate-item ps-4" style="margin-top: -20px; ">
            <a  class="fab fa-whatsapp fa-fw me-2" href="https://wa.me/573138443558?text={{encodeURIComponent('WhatsAppUs3' | translate)}}" target="_blank">
            {{'WhatsAppUs' | translate}}
            </a>
        </span>
        <span class="animate-item ps-4" style="margin-top: 20px; ">
            <a class="fab fa-whatsapp fa-fw me-2" href="https://wa.me/573208301271?text={{encodeURIComponent('WhatsAppUs3' | translate)}}" target="_blank">
            {{'WhatsAppUs2' | translate}}
            </a>
        </span>
      </a>
    </li>
    <li *ngIf="(loggedUser | async) != null && (inPrivateSection | async)">
      <a class="no-cursor">
        <i class="fas fa-comments"></i>
        <span class="animate-item ps-4">
            <a  class="fas fa-comments fa-fw me-2"  (click)="showChatBot()" target="_blank">
            {{'How can we help ?' | translate}}
            </a>
        </span>
      </a>
    </li>
  </ul>
</nav>
<!-- Add the ChatbotComponent to the main app component to ensure it's available globally -->
