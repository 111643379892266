import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {MessageService} from '../../services/message.service';
import {ContactRequest, MessageData} from '../../../models/contact-form';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {isPlatformBrowser} from '@angular/common';
import {CountryISO, SearchCountryField} from '@sarveshpop/ngx-intl-tel-input';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  messageForm: UntypedFormGroup;
  personalData: UntypedFormGroup;
  submitted: boolean;
  resultDisplay = 'none';
  resultText = '';
  resultClass = '';
  lang = '';
  type: 'audio'| 'image';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  loading: Subject<boolean> = new Subject();
  recaptchaMessage: string;
  private subscriptions = [];
  constructor(private http: HttpClient, public snackBar: MatSnackBar, private canonicalAndMetaService: CanonicalAndMetaService,
              private cdr: ChangeDetectorRef, public _MessageService: MessageService,
              @Inject(PLATFORM_ID) private platformId: any,
              private formBuilder: UntypedFormBuilder, private translate: TranslateService) {

    this.personalData = this.createPersonalDataFormGroup(formBuilder);
    this.messageForm = this.createMessageFormGroup(formBuilder);
    this.type = 'image' ;
   }


  onBrowser = isPlatformBrowser(this.platformId);
  rating: number;
  success = true;
  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('contact-us').then(async result => {
      await this.canonicalAndMetaService.setMetas('contact', true , '');
    });
    this.rating = 0;
    this.lang = this.translate.currentLang;
    this.subscriptions.push (this.canonicalAndMetaService.languageChanged.subscribe(event => {
      this.lang = event.lang;
      console.log(event.lang);
    }));
  }

  createPersonalDataFormGroup(formBuilder: UntypedFormBuilder) {
    return formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      cellPhone: ['', Validators.required],
      phone: ['', Validators.required],
      company: ['']
});
}

  createMessageFormGroup(formBuilder: UntypedFormBuilder) {
      return formBuilder.group({
        personalData: this.personalData,
        subject: [''],
        content: ['', Validators.required],
        rating: [''],
        recaptcha: ['', Validators.required]
  });
  }

ratingComponentClick(clickObj: any): void {
    this.rating = clickObj.rating;
    console.log('Rating: ' + this.rating);
    this.messageForm.controls.rating.setValue(this.rating);
}

onSubmit() {
  this.submitted = true;
  console.log('submitted: ' + this.submitted);
  if (this.messageForm.status === 'VALID') {
    this.loading.next(true);
    // Make sure to create a deep copy of the form-model
    const formulario: ContactRequest = Object.assign({}, this.messageForm.value);
    formulario.personalData = Object.assign({}, formulario.personalData);
    formulario.personalData.phone = this.personalData.controls.phone.value ?
      this.personalData.controls.phone.value.internationalNumber : '';
    formulario.personalData.cellPhone = this.personalData.controls.cellPhone.value ?
      this.personalData.controls.cellPhone.value.internationalNumber : '';
    // Do useful stuff with the gathered data
    formulario.rating = this.rating;
    console.log(formulario);
    const messageData: MessageData = new MessageData();
    firstValueFrom(this.translate.get('ContactFormEmail_from')).then( from => {
      messageData.from = '"Formulario de Contacto Vehiculos VIP" <info@vehiculosvip.com>';
      messageData.to = '"' + from + '" <info@vehiculosvip.com>';
      messageData.cc =  '"' + formulario.personalData.firstName + ' ' + formulario.personalData.lastName
        + '" <' + formulario.personalData.email + '>';
      return firstValueFrom(this.translate.get('ContactFormEmail_subject'));
    }).then( subject => {
      messageData.subject = subject + formulario.personalData.firstName  + ' ' + formulario.personalData.lastName;
      return firstValueFrom(this.translate.get('ContactFormEmail_body'));
    }).then( body => {
      body = body.replace('<#1>', formulario.personalData.firstName  + ' ' + formulario.personalData.lastName);
      body = body.replace('<#2>', formulario.personalData.cellPhone);
      body = body.replace('<#3>', formulario.personalData.phone);
      body = body.replace('<#4>', formulario.rating);
      body = body.replace('<#5>', formulario.subject);
      body = body.replace('<#6>', formulario.content);
      messageData.html = body;
      return messageData;
    }).then(data => {
      firstValueFrom(this.translate.get('ContactForm_sending')).then(text => {
        this.snackBar.open(text, 'X');
      });
      messageData.recaptchaMessage = this.captchaResponse;
      messageData.apiKey = environment.apiKey;
      this.subscriptions.push( this._MessageService.sendMessage(data).subscribe(
        res => {
          console.log('res.body response : ' + res.body) ;
          firstValueFrom(this.translate.get('ContactFormSuccess-2')).then( text => {
            this.resultText = text;
          });
          this.resultDisplay = 'block';
          firstValueFrom(this.translate.get('ContactFormSuccess-2')).then( text => {
            this.snackBar.open(text, '',  {
              duration: 5000
            });
          });
          this.resultDisplay = 'block';
          firstValueFrom(this.translate.get('ContactFormSuccess-1')).then( text => {
            this.resultText = text;
          });
          this.resultClass = 'bg-info';
          this.loading.next(false);
        },
        (err: HttpErrorResponse) => {
          console.log('error: ' + err);
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.message);
            this.snackBar.open('ERROR Sending Message (001)...', '',  {
              duration: 5000
            });
            this.resultDisplay = 'block';
            firstValueFrom(this.translate.get('ContactFormError-2')).then( error => {
              this.resultText = error;
            });
            this.resultClass = 'bg-danger';
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
            this.snackBar.open('ERROR Sending Message (002)...', '',  {
              duration: 5000
            });
            this.resultDisplay = 'block';
            firstValueFrom(this.translate.get('ContactFormError-1')).then( error => {
              this.resultText = error;
            });
            this.resultClass = 'bg-danger';
          }
          this.loading.next(false);
        }
      ));
    });
  } else {
      this.resultDisplay = 'block';
    firstValueFrom(this.translate.get('FormError-1')).then( error => {
        this.resultText = error;
      });
      this.resultClass = 'bg-danger';
  }
}
  handleReset(){
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleExpire(){
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  handleLoad(){
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleSuccess(captchaResponse: string){
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
