import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
              if (this.authenticationService.currentUserValue){
                this.authenticationService.logout();
                location.reload();
              }
            }
            if ( err.error ) {
              const error = err.error.message;
              return throwError(error);
            }
            console.log(err);
            return throwError('Error intercepted pero undefined :  ' + err.statusText);
        }));
    }
}
