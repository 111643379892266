<div class="row w-100 justify-content-center our-fleet-sections justify-content-center">
  <div class="content-wrapper col-9 mt-1"  *ngIf="withTitle === true ">
    <h3 class="center-title">{{'Testimonials' | translate}}</h3>
    <div class="title-block2"></div>
  </div>
    <div class="row justify-content-center" style="width: 100vw;">
      <div class="col-lg-4 rating">
        <div id="rating" class="text-left ml-lg-5 ms-2 " >
        <div itemscope="" itemtype="http://data-vocabulary.org/Review-aggregate" class="ng-binding">
          {{'Testimonial-1' | translate}}
          <span itemprop="rating" itemscope="" itemtype="http://data-vocabulary.org/Rating">
            <span itemprop="average" class="ng-binding">4.96</span>/<span itemprop="best">5</span>
          </span>
          <br>
          <span itemprop="votes" class="ng-binding">{{testimonials.length}}</span> {{'Testimonial-2' | translate}} <br>
          <span itemprop="count" class="ng-binding">{{testimonials.length}}</span> {{'Testimonial-3' | translate}}
          <div itemprop="itemreviewed" itemscope=""  itemtype="http://schema.org/LocalBusiness">
              <a href="https://www.vehiculosvip.com/assets/images/logo.png" itemprop="image" title="logo"></a>
              <span itemprop="name">V&V Vehículos VIP</span>
            <ul>
              <li class="cw-address">
               <span itemprop="address" itemscope="" itemtype="https://schema.org/PostalAddress">
                  <span itemprop="streetAddress"> Carrera 23 # 68-22 </span>,<br/>
                  <span itemprop="addressLocality">Bogota</span>
               </span>
              </li>
              <li class="cw-phone"><a href="tel:+18883685236" itemprop="telephone" title="phone">
                +1-888-368-5236</a></li>
              <li class="cw-phone"> <a href="tel:+576012114949" itemprop="telephone" title="phone">
                +57-601-211-49-49</a></li>
              <li class="cw-phone"> <a href="tel:+576017389641" itemprop="telephone" title="phone">
                +57-601-738-9641</a></li>
              <li class="cw-cell"> <a href="mailto:info@vehiculosvip.com" itemprop="email">
                info&#64;vehiculosvip.com</a></li>
            </ul>
          </div>
        </div>

        <div class="rating5Star"></div>
        <div class="rating5Star"></div>
        <div class="rating5Star"></div>
        <div class="rating5Star"></div>
        <div class="rating4AndHalfStar"></div>
        <br>
        <a routerLink="{{'/contact-us' | localize}}" title="{{'Testimonial-4' | translate}}"><strong>{{'Testimonial-4' | translate}}</strong></a><br>
        <a routerLink="{{'/testimonials' | localize}}" title="{{'Testimonial-5' | translate}}"><strong>{{'Testimonial-5' | translate}}</strong></a>
        <br>
        <br>
      </div>
      </div>
      <div class="col-lg-8">
        <div class="carousell-fullwidth-container mb-5 align-content-center">
        <app-carousel [slides]="slides" [autoSlideIntervalInSec]="-1" >
        </app-carousel>
        </div>

      </div>
    </div>
</div>

