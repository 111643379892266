<div id="page-header">
        <h1>{{'Survey-1' | translate}}</h1>
        <div class="title-block3"></div>
        <p><a routerLink="{{'/home' | localize}}" title="{{'Home' | translate}}" >{{'Home' | translate}}</a><i class="fa fa-angle-right"></i>{{'Survey-1' | translate}}</p>
    </div>


<!-- BEGIN .content-wrapper-outer -->
<div class="row justify-content-center">
        <div class="col-lg-6 col-md-8 col-sm-10 content-wrapper">
            <h3 class="center-title">{{'Survey-2' | translate}}</h3>
            <div class="title-block2"></div>
            <p class="text-justify " *ngIf=" !alreadySend &&  envioId !== 'invalid'">{{introduccion}}</p>
        </div>
</div>
    <!-- BEGIN .main-content -->
<div class="main-content main-content-full">


  <div class="row justify-content-center content-wrapper" *ngIf="ready | async">
      <div class="row text-left"   *ngIf="alreadySend || envioId === 'invalid'; else survey"  >
        <div class="col" *ngIf="envioId === 'invalid'">
          <H3 [innerHTML]="'Survey-3' | translate"></H3>
          <div class="title-block3"></div>
          <br/>
          <div><H4 [innerHTML]="'Survey-4' | translate"></H4></div>
        </div>
      </div>
      <ng-template #survey >
          <form (ngSubmit)="onSubmit()" id="surveyForm" [formGroup]="surveyForm">
              <ng-container *ngFor="let question of EPE">
                <p class="mb-2 me-3" [attr.for]="question.key" [ngClass]="(question.controlType ==='infoText') ? 'info-text': ''">
                  {{question.preguntaEncuesta.text}} </p>
                <ng-container [ngSwitch]="question.controlType">
                  <ng-container *ngSwitchCase="'freeText'" >
                    <div class="input-group col-12 mb-2"  [ngClass]="{ 'is-invalid': !surveyForm.controls[question.key].valid  && submitted }">
                      <textarea cols="10" rows="9" type="text" class="form-control"
                                [id]="question.key"  placeholder="{{'Survey-11' | translate}}" cols="35" rows="4"
                                [formControlName]="question.key"
                                [ngClass]="{ 'is-invalid': surveyForm.controls[question.key].invalid  && submitted }"
                                [ngClass]="{ 'is-valid': surveyForm.controls[question.key].valid  && submitted }">
                      </textarea>
                      <div class="invalid-feedback ms-4">
                        {{required}}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container    *ngSwitchCase="'radioButton'">
                    <div class="form-row mb-2">
                      <label [for]="question.key" *ngIf="question.text">{{ question.text }}</label>

                            <ng-container *ngFor="let opcion of question.preguntaEncuesta.opciones ; let i = index">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input"  type="radio"
                                       [id]="question.key+' '+i"  [value]="opcion.text" [formControlName]="question.key"
                                       [ngClass]="{ 'is-invalid': surveyForm.controls[question.key].invalid  && submitted }"
                                       [ngClass]="{ 'is-valid': surveyForm.controls[question.key].valid  && submitted }"/>
                                <label class="form-check-label me-2" [for]="question.key+' '+i">{{opcion.text}}</label>
                              </div>
                            </ng-container>
                            <div class="invalid-feedback ms-4">
                              {{required}}

                          </div>
                    </div>
                    <ng-container *ngIf="question.preguntaEncuesta.tipo.isText">
                            <textarea cols="10" rows="9" type="text" class="form-control"  [id]="question.key"
                                      placeholder="{{'Survey-11' | translate}}" cols="35" rows="4"
                                      [(ngModel)]="question.respuesta2"></textarea>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dropDown'">
                    <div class="form-row mb-2">
                      <label [for]="question.key" *ngIf="question.text">{{ question.text }}</label>
                      <select [id]="question.key"  class="form-control"  [formControlName]="question.key"
                              [ngClass]="{ 'is-invalid': surveyForm.controls[question.key].invalid  && submitted }"
                              [ngClass]="{ 'is-valid': surveyForm.controls[question.key].valid  && submitted }">
                          <option *ngFor="let opt of question.preguntaEncuesta.opciones" [ngValue]="opt.text">{{opt.text}}</option>
                      </select>
                      <div class="invalid-feedback  ms-4">
                        {{required}}
                      </div>
                    </div>
                    <ng-container *ngIf="question.preguntaEncuesta.tipo.isText">
                      <textarea cols="10" rows="9" type="text" class="form-control"  [id]="question.key + '-freeText'"
                                placeholder="{{'Survey-11' | translate}}" cols="35" rows="4"></textarea>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <button type="submit" class="book-button form-control" >{{'Survey-5' | translate}}</button>
          </form>
      <br/><br/>
  </ng-template>
      <p [ngClass]="resultStyle" style="padding: 15px; margin: 0;" [innerHTML]="resultMessage"></p>
      <br/>
  </div>
  <div class="row w-100 justify-content-center" *ngIf=" !(ready | async)">
    <div>
      <mat-spinner>
      </mat-spinner>
    </div>
  </div>
</div>
