import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {ConfigService} from './services/interface/config.service';
import {CustomizerService} from './services/interface/customizer.service';
import {LayoutService} from './services/interface/layout.service';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {DeviceDetectorService} from 'ngx-device-detector';
import {environment} from '../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Loader} from '@googlemaps/js-api-loader';
import {DataService} from './services/data.service';
import {CheckForUpdateService} from './services/service.worker/check-for-update.service';
import {LogUpdateService} from './services/service.worker/log-update.service';
import {PromptUpdateService} from './services/service.worker/prompt-update.service';
import {HandleUnrecoverableStateService} from './services/service.worker/handle-unrecoverable-state.service';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

//  title = 'vyv-angular';
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  static isBrowser = new BehaviorSubject<boolean>(null);
  tagManagerCode = environment.googleTagManagerId;
  hideSidebar = true;
  overlayContent = false;
  configSub: Subscription;
  layoutSub: Subscription;
  bgImage: string;
  bgColor: string;
  isSmallScreen = false;
  menuPosition = 'Top';
  displayOverlayMenu = false; // Vertical Side menu for screenSize < 992
  public config: any = {};
  public innerWidth: any;
  isMenuCollapsedOnHover = false;
  isNavbarSeachTextEmpty = true;
  isScrollTopVisible = false;
  resizeTimeout;
  // perfectScrollbarEnable = true;
  subscriptions = [];
  name: string;

  res;
  constructor(
    @Inject(PLATFORM_ID,) private platformId: any,
    private configService: ConfigService,

    private dataService: DataService,
    private layoutService: LayoutService,
    private router: Router,
    private customizerService: CustomizerService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService,
    public checkForUpdateService: CheckForUpdateService,
    public logUpdateService: LogUpdateService,
    public promptUpdateService: PromptUpdateService,
    public handleUnrecoverableStateService: HandleUnrecoverableStateService,
    public updates: SwUpdate
  ) {
    if (isPlatformBrowser(this.platformId)) {
      console.log('Checking for update on initial loading...');
       this.updates.checkForUpdate().then(updateFound => {
         if (updateFound) {
           console.log('update found reloading...');
           window.location.reload();
         }
       });
    }
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    this.config = this.configService.templateConf;
    if (isPlatformBrowser(this.platformId)){
      this.innerWidth = window.innerWidth;
    } else {
      this.innerWidth = 992;
    }
    this.name = 'V&V Vehiculos VIP';
    // On toggle sidebar menu
    this.subscriptions.push (this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
        if (this.hideSidebar) {
          this.overlayContent = false;
        } else {
          this.overlayContent = true;
        }
        this.toggleSidebar();
      }));
  }

  async ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      console.log('Checking for update on initial loading...');

        let loader = new Loader({apiKey: "AIzaSyAaXkP2V4vumvafPKR7v8i5BA4x0ut_tqY", libraries: ["places"]});
        this.dataService.mapReady = loader.load();

    } else {
      this.dataService.mapReady = firstValueFrom(new BehaviorSubject<typeof google>(null));
    }

// load layout
    this.loadLayout();
    this.cdr.markForCheck();
    // hide overlay class on router change
    this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      if (this.config.layout.menuPosition === 'Side' || this.displayOverlayMenu) { // Vertical Menu
        if (this.innerWidth < 992) {
          this.layoutService.toggleSidebarSmallScreen(false);
          this.overlayContent = false;
          this.renderer.removeClass(this.document.body, 'overflow-hidden');
        }
      }
    }));
  }

  ngAfterViewInit() {
    this.setMenuLayout();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  // adjust layout
  setMenuLayout() {
    this.overlayContent = false;
    this.renderer.removeClass(this.document.body, 'blank-page');
    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 992) { // Screen size < 992
        this.displayOverlayMenu = true;
        this.hideSidebar = true;
        this.renderer.removeClass(this.document.body, 'horizontal-menu');
        this.renderer.removeClass(this.document.body, 'menu-open');

        this.renderer.addClass(this.document.body, 'horizontal-layout');
        this.renderer.addClass(this.document.body, 'horizontal-menu-padding');
        this.renderer.addClass(this.document.body, 'vertical-layout');
        this.renderer.addClass(this.document.body, 'vertical-overlay-menu');
        this.renderer.addClass(this.document.body, 'fixed-navbar');
        this.renderer.addClass(this.document.body, 'menu-hide');
      }
      else { // Screen size > 992
        this.displayOverlayMenu = false;
        this.hideSidebar = false;
        this.renderer.setAttribute(this.document.body, 'data-menu', 'horizontal-menu');
        this.renderer.removeClass(this.document.body, 'vertical-layout');
        this.renderer.removeClass(this.document.body, 'vertical-overlay-menu');
        this.renderer.removeClass(this.document.body, 'fixed-navbar');
        this.renderer.removeClass(this.document.body, 'menu-hide');
        this.renderer.removeClass(this.document.body, 'vertical-menu');
        // this.renderer.removeClass(this.document.body, "blank-page");
        this.renderer.addClass(this.document.body, 'horizontal-menu');
        this.renderer.addClass(this.document.body, 'horizontal-layout');
        this.renderer.addClass(this.document.body, 'horizontal-menu-padding');
      }
    }
    else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu
      if (this.innerWidth < 992) { // If Screen size < 992
        this.displayOverlayMenu = true;
        this.renderer.removeClass(this.document.body, 'horizontal-layout');
        this.renderer.removeClass(this.document.body, 'horizontal-menu');
        this.renderer.removeClass(this.document.body, 'horizontal-menu-padding');
        this.renderer.removeClass(this.document.body, 'menu-expanded');
        this.renderer.removeClass(this.document.body, 'vertical-menu');
        this.renderer.removeClass(this.document.body, 'menu-open');
        this.renderer.removeClass(this.document.body, 'nav-collapsed');

        this.renderer.addClass(this.document.body, 'vertical-layout');
        this.renderer.addClass(this.document.body, 'menu-hide');

      }
      else { // If Screen size > 992
        this.displayOverlayMenu = false;

        this.renderer.removeClass(this.document.body, 'horizontal-layout');
        this.renderer.removeClass(this.document.body, 'horizontal-menu');
        this.renderer.removeClass(this.document.body, 'horizontal-menu-padding');
        // this.renderer.removeClass(this.document.body, "blank-page");

        this.renderer.setAttribute(this.document.body, 'data-menu', 'vertical-menu');
        this.renderer.addClass(this.document.body, 'vertical-layout');
        if (!this.config.layout.sidebar.collapsed) {
          this.renderer.addClass(this.document.body, 'menu-expanded');
          this.renderer.addClass(this.document.body, 'menu-open');
        }
        this.renderer.addClass(this.document.body, 'vertical-menu');
        this.renderer.removeClass(this.document.body, 'menu-hide');
        this.renderer.removeClass(this.document.body, 'vertical-overlay-menu');
      }
    }
  }


  loadLayout() {

    // menu position "SIDE" or "TOP"
    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    // Hide/show sidebar menu background image
    if (!this.config.layout.sidebar.backgroundImage) {
      this.bgImage = '';
    } else {
      this.bgImage = this.config.layout.sidebar.backgroundImageURL;
    }

    // Set sidebar menu background color
    if (!this.config.layout.sidebar.backgroundColor) {
      this.bgColor = this.customizerService.light_dark_colors[7].code;
    } else {
      this.bgColor = this.config.layout.sidebar.backgroundColor;
    }

    // toggle side menu
    if (this.config.layout.menuPosition === 'Side') {
      if (this.config.layout.sidebar.collapsed) {
        this.isMenuCollapsedOnHover = true;
      }
      else {
        this.isMenuCollapsedOnHover = true;
      }
      this.toggleSidebar();
    }

    this.removeTransparentBGClasses();

    // Layout variants
    if (this.config.layout.variant === 'Light') {
      this.renderer.removeClass(this.document.body, 'layout-dark');
      this.renderer.removeClass(this.document.body, 'layout-transparent');
    }
    else if (this.config.layout.variant === 'Dark') {
      this.renderer.removeClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, 'layout-dark');
    }
    else if (this.config.layout.variant === 'Transparent') {
      this.renderer.addClass(this.document.body, 'layout-dark');
      this.renderer.addClass(this.document.body, 'layout-transparent');
      this.renderer.addClass(this.document.body, this.bgColor);
      this.bgImage = '';
    }

    this.setMenuLayout();

    // For Sidebar width
    if (this.config.layout.sidebar.size === 'sidebar-sm') {
      this.renderer.removeClass(this.document.body, 'sidebar-lg');
      this.renderer.addClass(this.document.body, 'sidebar-sm');
    }
    else if (this.config.layout.sidebar.size === 'sidebar-lg') {
      this.renderer.removeClass(this.document.body, 'sidebar-sm');
      this.renderer.addClass(this.document.body, 'sidebar-lg');
    }
    else {
      this.renderer.removeClass(this.document.body, 'sidebar-sm');
      this.renderer.removeClass(this.document.body, 'sidebar-lg');
    }

    if (this.config.layout.menuPosition === 'Side') {
      // vertical/Side menu expanded/collapse
      if (this.config.layout.sidebar.collapsed && !this.isSmallScreen) { // collapse side menu
        this.renderer.removeClass(this.document.body, 'menu-expanded');
        this.renderer.addClass(this.document.body, 'nav-collapsed');
      }
      else { // expand side menu
        this.renderer.removeClass(this.document.body, 'nav-collapsed');
        this.renderer.addClass(this.document.body, 'menu-expanded');
      }
    }

    // Navbar types
    if (this.config.layout.navbar.type === 'Static') {
      console.log('Se coloca Static');
      this.renderer.removeClass(this.document.body, 'navbar-sticky');
      this.renderer.addClass(this.document.body, 'navbar-static');
    }
    else if (this.config.layout.navbar.type === 'Fixed') {
      console.log('Se coloca Sticky');
      this.renderer.removeClass(this.document.body, 'navbar-static');
      this.renderer.addClass(this.document.body, 'navbar-sticky');
    }

  }

  toggleSidebar() {
    if (this.hideSidebar) { // on sidebar collapse
      this.renderer.removeClass(this.document.body, 'menu-expanded');
      this.renderer.removeClass(this.document.body, 'vertical-menu');
      this.renderer.removeClass(this.document.body, 'menu-open');

      this.renderer.addClass(this.document.body, 'vertical-layout');
      this.renderer.addClass(this.document.body, 'menu-hide');

      if (this.config.layout.menuPosition === 'Top') {
        this.renderer.addClass(this.document.body, 'vertical-overlay-menu');
      }
    }
    else { // on sidebar expand
      this.renderer.addClass(this.document.body, 'vertical-layout');
      this.renderer.addClass(this.document.body, 'menu-expanded');
      this.renderer.addClass(this.document.body, 'vertical-menu');
      if (this.config.layout.sidebar.collapsed) {
        this.renderer.removeClass(this.document.body, 'menu-open');
      }
      else {
        this.renderer.addClass(this.document.body, 'menu-open');
      }
      this.renderer.removeClass(this.document.body, 'menu-hide');
    }
    this.isTouchDevice();
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      //this.perfectScrollbarEnable = false;
      if (!this.hideSidebar){
        this.renderer.addClass(this.document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(this.document.body, 'overflow-hidden');
      }
    } else {
      //this.perfectScrollbarEnable = true;
    }

  }



  // Remove transparent layout classes
  removeTransparentBGClasses() {
    this.customizerService.transparent_colors.forEach(_ => {
      this.renderer.removeClass(this.document.body, _.class);
    });

    this.customizerService.transparent_colors_with_shade.forEach(_ => {
      this.renderer.removeClass(this.document.body, _.class);
    });
  }

  sidebarMouseenter(e) {
    if (this.config.layout.sidebar.collapsed) {
      this.isMenuCollapsedOnHover = false;
      this.layoutService.overlaySidebartoggle(this.isMenuCollapsedOnHover);
    }
  }

  sidebarMouseleave(e) {
    if (this.config.layout.sidebar.collapsed) {
      this.isMenuCollapsedOnHover = true;
      this.layoutService.overlaySidebartoggle(this.isMenuCollapsedOnHover);
    }
  }

  // scroll to top on click
  scrollToTop() {
    if (isPlatformBrowser(this.platformId)){
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

  }

  onOutsideClick(e) {
    if (this.innerWidth < 992) {
      if (
        !e.target.classList.contains('toggleSidebarNavbarButton')
      ) {
        this.layoutService.toggleSidebarSmallScreen(false);
      }
    }
  }

  onWrapperClick() {
    this.isNavbarSeachTextEmpty = true;
  }

  checkNavbarSeachTextEmpty($event) {
    this.isNavbarSeachTextEmpty = $event;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.innerWidth !== event.target.innerWidth){
      console.log('window resized in app component with width change');
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
        this.setMenuLayout();
      }).bind(this), 500);
    }
  }


  // Add/remove classes on page scroll
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const number1 = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      if (number1 > 60) {
        this.renderer.addClass(this.document.body, 'navbar-scrolled');
      } else {
        this.renderer.removeClass(this.document.body, 'navbar-scrolled');
      }

      if (number1 > 400) {
        this.isScrollTopVisible = true;
      } else {
        this.isScrollTopVisible = false;
      }

      if (number1 > 20) {
        this.renderer.addClass(this.document.body, 'page-scrolled');
      } else {
        this.renderer.removeClass(this.document.body, 'page-scrolled');
      }
    }
  }


}
