import {Injectable} from '@angular/core';
import {Airport, City, Coordinates, Country, Stop} from '../../models/business-objects';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class LocationService {
  UrlAPI: string = environment.apiURL;
  private currentCitiesSubject: BehaviorSubject<City[]>;
  private currentCountriesSubject: BehaviorSubject<Country[]>;
  private currentAirportsSubject: BehaviorSubject<Airport[]>;
  private  readyP: Promise<boolean>;
  constructor(private http: HttpClient) {
    this.currentCitiesSubject = new BehaviorSubject<City[]>(null);
    this.currentCountriesSubject = new BehaviorSubject<Country[]>(null);
    this.currentAirportsSubject = new BehaviorSubject<Airport[]>(null);
    this.readyP = this.initializeLists();
  }

  async initializeLists(): Promise<boolean>{
    console.log('initialing lists cities');
    try {
      this.currentCitiesValue = await this.getCitiesFromAPI() ;
      console.log('initialing lists cities 1');
      if (this.currentCitiesValue == null) {
        this.currentCountriesValue = [];
        this.currentAirportsValue = [];
        this.currentCitiesValue = [];
        return false;
      }
      const countries: Country[] = [] ;
      const airports: Airport[] = [] ;
      this.currentCitiesValue.forEach( city => {
        // console.log('treating city ' + city.nombre);
        // console.log(city);
        const countryFound = countries.find(country => city.pais != null && country.nombre === city.pais.nombre);
        // console.log('countryFound ' + countryFound);
        if ( countryFound == null) {
          if (city.pais != null) {
            // console.log('new country added : ' + city.pais.nombre);
            countries.push(city.pais);
          } else {
            // console.log('no country found for this city !!  ');
          }
        } else {
          // console.log('new country existing o not valid :  ');
          // console.log(countryFound);
        }
        if (city.aeropuertos) {
          // console.log('treating airports ');
          // console.log(city.aeropuertos);
          city.aeropuertos.forEach(aeropuerto => {
            // console.log('airport ');
            // console.log(aeropuerto);
            const airportFound = airports.find(a => aeropuerto != null && a.nombre === aeropuerto.nombre );
            if ( airportFound == null ) {
              // console.log('Airport added');
              // console.log(aeropuerto);
              airports.push(aeropuerto);
            } else {
              // console.log('Airport already in List');
              console.log(airportFound);
            }
          });
        }
      });
      console.log('initialing lists cities 2');
      this.currentCountriesValue = countries;
      this.currentAirportsValue = airports;
      console.log('locations should be ready');
      // console.log(this.currentCitiesValue);
      // console.log(this.currentCountriesValue);
      // console.log(this.currentAirportsValue);
      return true;
    } catch (err) {
      console.log(err);
      console.log('Locations list failed !');
      this.currentCountriesValue = [];
      this.currentAirportsValue = [];
      this.currentCitiesValue = [];
      return false;
    }
  }
  getCitiesFromAPI(): Promise<[City]> {
    console.log('initialing lists cities inside http get');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CITIES, {})).catch( error2 => {
      console.log('initialing lists cities error in http get');
      console.log(error2);
    });
  }
  public get currentCitiesValue(): City[] {
    return this.currentCitiesSubject.value;
  }
  public set currentCitiesValue(cities: City[]) {
    this.currentCitiesSubject.next(cities);
  }
  public get currentCountriesValue(): Country[] {
    return this.currentCountriesSubject.value;
  }
  public set currentCountriesValue(countries: Country[]) {
    this.currentCountriesSubject.next(countries);
  }
  public get currentAirportsValue(): Airport[] {
    return this.currentAirportsSubject.value;
  }
  public set currentAirportsValue(airports: Airport[]) {
    this.currentAirportsSubject.next(airports);
  }

  getCities(): Promise<City[]> {
    return this.readyP.then( result => {
      return this.currentCitiesValue;
    });
  }
  getCountries(): Promise<Country[]> {
    return this.readyP.then( result => {
      return this.currentCountriesValue;
    });
  }
  getAirports(): Promise<Airport[]> {
    return this.readyP.then( result => {
      return this.currentAirportsValue;
    });
  }
  extractCityFromGooglePlace(place: google.maps.places.PlaceResult): string {
    const addressDetails = place.address_components;
    for (const addressComponent of addressDetails ) {
      if (addressComponent.types[0]) {
        if (addressComponent.types.includes('administrative_area_level_1')){
          return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        if (addressComponent.types.includes('locality')) {
          return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        if (addressComponent.types.includes('postal_town')) {
          return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      }
    }
    return '';
  }

  extractCountryFromGooglePlace(place: google.maps.places.PlaceResult): string {
    if (place == null) {
      return null;
    }
    const addressDetails = place.address_components;
    for (const addressComponent of addressDetails ) {
      if (addressComponent.types[0]) {
        if (addressComponent.types[0] === 'country') {
          return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      }
    }
    return '';
  }
  extractCountryCodeFromGooglePlace(place: google.maps.places.PlaceResult): string {
    if (place == null) {
      return null;
    }
    const addressDetails = place.address_components;
    for (const addressComponent of addressDetails ) {
      if (addressComponent.types[0]) {
        if (addressComponent.types[0] === 'country') {
          return addressComponent.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      }
    }
    return '';
  }
  extractRegionFromGooglePlace(place: google.maps.places.PlaceResult): string {
    const addressDetails = place.address_components;
    for (const addressComponent of addressDetails ) {
      if (addressComponent.types[0]) {
        if (addressComponent.types[0] === 'administrative_area_level_1') {
          return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      }
    }
    return '';
  }
  extractStateCodeFromGooglePlace(place: google.maps.places.PlaceResult): string {
    const addressDetails = place.address_components;
    for (const addressComponent of addressDetails ) {
      if (addressComponent.types[0]) {
        if (addressComponent.types[0] === 'administrative_area_level_1') {
          return addressComponent.short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
      }
    }
    return '';
  }
  extractAdressUrlFromGooglePlace(place: google.maps.places.PlaceResult): string {
    if (place) {
      const addressDetails = place.address_components;
      const formatedAddress = place.formatted_address;
      if (formatedAddress) { return formatedAddress; }
      for (const addressComponent of addressDetails) {
        if (addressComponent.types[0]) {
          if (addressComponent.types[0] === 'locality') {
            return addressComponent.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          }
        }
      }
    }
    return '';
  }

  removeAccents(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  generateUrlFromCoordinates(coordinates: Coordinates){
    // https://maps.google.com/?q=10.425608,-75.55073
    return 'https://maps.google.com/?q=' + coordinates.lat + ',' + coordinates.lng ;
  }

  rad(x) {
    return x * Math.PI / 180;
  }

  getDistanceFromCoordinates(p1: Coordinates, p2: Coordinates) {
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = this.rad(p2.lat - p1.lat);
    const dLong = this.rad(p2.lng - p1.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d; // returns the distance in meter
  }

  getTotalDistanceFromRouteEnKm(route: google.maps.DirectionsResult): number{
    let distance = 0;
    if (route != null && route.routes.length > 0){
      const route1 = route.routes[0];
      if (route1 != null){
        const legs = route1.legs;
        if (legs != null && legs.length > 0 ){
          legs.forEach( leg => {
            console.log(' adding '  + leg.distance.value + ' km');
            distance = distance + leg.distance.value;
          });
        }
      }
    }
    console.log(' returning distance '  + distance / 1000);
    return distance / 1000; // porque el valor de google esta en metros
  }

  getTotalDurationFromRouteEnMin(route: google.maps.DirectionsResult): number{
    let duration = 0;
    if ( route != null && route.routes.length > 0){
      const route1 = route.routes[0];
      if (route1 != null){
        const legs = route1.legs;
        if (legs != null && legs.length > 0){
          legs.forEach( leg => {
            console.log(' adding '  + leg.duration.value + ' minutes');
            duration = duration + leg.duration.value;
          });
        }
      }
    }
    console.log(' returning duration '  + duration / 60);
    return duration / 60; // porque el valor de google esta en segundos
  }
  routeHasCityExit(startCity: string, endCity: string, stops: Stop[]): boolean{
    let previousCity = startCity;
    if ( stops != null && stops.length > 0){
      for (const stop of stops) {
        if (stop.placeResult != null) {
          const nextCity = this.extractCityFromGooglePlace(stop.placeResult);
          if (nextCity != null ) {
            if (previousCity !== nextCity) {
              console.log('una parada no tiene la misma ciudad que la de inicio ' + previousCity + ' ' + nextCity);
              return true;
            } else {
              previousCity = nextCity;
            }
          } else {
            console.log('una de las paradas no tiene ciudad, lo vamos a ignorar ');
          }
        } else {
          console.log('una de las paradas no tiene punto google, lo vamos a ignorar ');
        }
      }
    } else {
      if (endCity == null ) {
        console.log('no hay ciudad de fin, se considera que estamos en la misma ' + startCity);
        return false ;
      }
      if (startCity === endCity){
        console.log('la ciudad final es la misma ciudad que la de inicio ' + startCity + ' ' + endCity);
        return false;
      } else {
        console.log('la ciudad final es distinta a la de inicio ' + startCity + ' ' + endCity);
        return true;
      }
    }
    if (endCity == null ) {
      console.log('no hay ciudad de fin, se considera que estamos en la misma ' + startCity);
      return false ;
    }
    if (previousCity !== endCity){
      console.log('la ultima ciudad de la ruta no es la misma que la ciudad de fin ' + previousCity + ' ' + endCity);
      return true;
    }
    console.log('todas las ciudades son la misma que la de inicio ' + startCity );
    return false;
  }
}
/*
export const COUNTRIES: Country[] = [
  { code: 'CO', name: 'Colombia'},      // 0   -  170
  { code: 'MX', name: 'Mexico'},        // 1   -  484
  { code: 'PR', name: 'Puerto Rico'},   // 2   -  630
  { code: 'VE', name: 'Venezuela'},     // 3   -  862
  { code: 'AR', name: 'Argentina'},     // 4   -  32
  { code: 'CL', name: 'Chile'},         // 5   -  152
  { code: 'BR', name: 'Brazil'},        // 6   - 76
  { code: 'PA', name: 'Panama'},        // 7   - 507
  { code: 'US', name: 'United States'}, // 8   - 840
  { code: 'CA', name: 'Canada'},        // 9   - 124
  { code: 'AU', name: 'Australia'},     // 10   - 36
  { code: 'FR', name: 'France'},        // 11    - 250
  { code: 'GB', name: 'United Kingdom'}, // 12   - 826
  { code: 'ES', name: 'Spain'},          // 13   - 724
  { code: 'DE', name: 'Germany'},        // 14   - 276
  { code: 'AT', name: 'Austria'},        // 15   - 40
  { code: 'IT', name: 'Italia'},        // 16   - 39
  { code: 'RU', name: 'Russia'},        // 17   - 634
  { code: 'JP', name: 'Japan'},         // 18   - 392
  { code: 'CN', name: 'China'},        // 19    - 156
  { code: 'SG', name: 'Singapore'},    // 20    - 702
  { code: 'ZA', name: 'South Africa'},  // 21   - 710
  { code: 'IN', name: 'India'},        // 22   - 356
  { code: 'IR', name: 'Iran'},        // 23   - 364
  { code: 'PE', name: 'Peru'},        // 24   - 604
  { code: 'CR', name: 'Costa Rica'},   // 25  - 188
  { code: 'EC', name: 'Ecuador'},        // 26 - 218
  { code: 'BO', name: 'Bolivia'},        // 27 - 68
  { code: 'UY', name: 'Uruguay'},        // 28 - 858


];

export const CITIES: City[] = [
  { code: 'BOG', name: 'Bogota', link: 'bogota', lat: 4.7110, lng: -74.0721, country : COUNTRIES[0]},
  { code: 'MED', name: 'Medellin', link: 'medellin', lat: 6.2486, lng: -75.4832, country : COUNTRIES[0]},
  { code: 'CTG', name: 'Cartagena', link: 'cartagena', lat: 10.3932, lng: -75.4832, country : COUNTRIES[0]},

  { code: 'MEX', name: 'Mexico City', link: 'mexico-city', lat: 19.432608, lng: -99.133209, country : COUNTRIES[1]},

  { code: 'SJU', name: 'Puerto Rico', link: 'puerto-rico', lat: 18.465539, lng: -66.105735, country : COUNTRIES[2]},

  { code: 'CCS', name: 'Caracas', link: 'caracas', lat: 10.469640, lng: -66.803719, country : COUNTRIES[3]},

  { code: 'BAI', name: 'Buenos Aires', link: 'buenos-aires', lat: -34.603723, lng: -58.381593, country : COUNTRIES[4]},

  { code: 'SCL', name: 'Santiago de Chile', link: 'santiago', lat: -33.469120, lng: -70.641997, country : COUNTRIES[5]},

  { code: 'RIO', name: 'Rio De Janeiro', link: 'rio', lat: -22.906847, lng: -43.172897, country : COUNTRIES[6]},
  { code: 'SAO', name: 'Sao Paulo', link: 'sao-paulo', lat: -23.550520, lng: -46.633309, country : COUNTRIES[6]},
  { code: 'BSB', name: 'Brasilia', link: 'brasilia', lat: -15.826691, lng: -47.921820, country : COUNTRIES[6]},
  { code: 'FOR', name: 'Fortaleza', link: 'fortaleza', lat: -3.731862, lng: -38.526670, country : COUNTRIES[6]},

  { code: 'PTY', name: 'Panama City', link: 'panama', lat: 8.983333, lng: -79.516667, country : COUNTRIES[7]},

  { code: 'PTY', name: 'Panama City', link: 'panama', lat: 8.983333, lng: -79.516667, country : COUNTRIES[7]},

  { code: 'MIA', name: 'Miami', link: 'miami', lat: 25.761680, lng: -80.191790, country : COUNTRIES[8]},
  { code: 'ATL', name: 'Atlanta', link: 'atlanta', lat: 33.748995, lng: -84.387982, country : COUNTRIES[8]},
  { code: 'NYC', name: 'New York', link: 'new-york', lat: 40.712784, lng: -74.005941, country : COUNTRIES[8]},
  { code: 'HOU', name: 'Houston', link: 'houston', lat: 29.760427, lng: -95.369803, country : COUNTRIES[8]},
  { code: 'SFO', name: 'San Francisco', link: 'san-francisco', lat: 37.774929, lng: -122.419416, country : COUNTRIES[8]},
  { code: 'SEA', name: 'Seattle', link: 'seattle', lat: 47.606209, lng: -122.419416, country : COUNTRIES[8]},
  { code: 'CHI', name: 'Chicago', link: 'chicago', lat: 41.878114, lng: -87.629798, country : COUNTRIES[8]},
  { code: 'LAS', name: 'Las Vegas', link: 'las-vegas', lat: 36.169941, lng: -115.139830, country : COUNTRIES[8]},
  { code: 'BOS', name: 'Boston', link: 'boston', lat: 42.361145, lng: -71.057083, country : COUNTRIES[8]},

  { code: 'YTO', name: 'Toronto', link: 'toronto', lat: 43.653226, lng: -79.383184, country : COUNTRIES[9]},
  { code: 'YMQ', name: 'Montreal', link: 'montreal', lat: 45.501689, lng: -73.567256, country : COUNTRIES[9]},
  { code: 'YOW', name: 'Ottawa', link: 'Ottawa', lat: 45.421530, lng: -75.697193, country : COUNTRIES[9]},

  { code: 'SYD', name: 'Sydney', link: 'sydney', lat: -33.867487, lng: 151.206990, country : COUNTRIES[10]},
  { code: 'PER', name: 'Perth', link: 'perth', lat: -31.953513, lng: 115.857047, country : COUNTRIES[10]},
  { code: 'MEL', name: 'Melbourne', link: 'melbourne', lat: -39.029020, lng: 146.315101, country : COUNTRIES[10]},

  { code: 'PAR', name: 'Paris', link: 'paris', lat: 48.856614, lng: 2.352222, country : COUNTRIES[11]},
  { code: 'NCE', name: 'Nice', link: 'nice', lat: 43.710173, lng: 7.261953, country : COUNTRIES[11]},

  { code: 'LON', name: 'London', link: 'london', lat: 51.507351, lng: -0.127758, country : COUNTRIES[12]},
  { code: 'DUB', name: 'Dublin', link: 'dublin', lat: 53.349805, lng: -6.260310, country : COUNTRIES[12]},

  { code: 'MAD', name: 'Madrid', link: 'madrid', lat: 40.416775, lng: -3.703790, country : COUNTRIES[13]},
  { code: 'BCN', name: 'Barcelona', link: 'barcelona', lat: 41.385064, lng: 2.173404, country : COUNTRIES[13]},

  { code: 'FRA', name: 'Frankfurt', link: 'frankfurt', lat: 50.110922, lng: 8.682127, country : COUNTRIES[14]},
  { code: 'BER', name: 'Berlin', link: 'berlin', lat: 52.520007, lng:  13.404954, country : COUNTRIES[14]},

  { code: 'VIE', name: 'Vienna', link: 'vienna', lat: 48.208174, lng:  16.373819, country : COUNTRIES[15]},

  { code: 'ROM', name: 'Roma', link: 'roma', lat: 41.902783, lng:  12.496365, country : COUNTRIES[16]},
  { code: 'MIL', name: 'Milan', link: 'milan', lat: 45.465422, lng:  9.185924, country : COUNTRIES[16]},

  { code: 'MOW', name: 'Moscow', link: 'moscow', lat: 55.755826, lng: 37.617300, country : COUNTRIES[17]},
  { code: 'LED', name: 'San Petersburg', link: 'san-petersburg', lat: 59.931058, lng: 30.360910, country : COUNTRIES[17]},

  { code: 'TYO', name: 'Tokyo', link: 'tokyo', lat: 35.676192, lng: 139.650311, country : COUNTRIES[18]},
  { code: 'OSA', name: 'Osaka', link: 'osaka', lat: 34.693738, lng: 135.502165, country : COUNTRIES[18]},

  { code: 'PEK', name: 'Beijing', link: 'beijing', lat: 39.904200, lng: 116.407396, country : COUNTRIES[19]},
  { code: 'SHA', name: 'Shanghai', link: 'shanghai', lat: 31.230416, lng: 121.473701, country : COUNTRIES[19]},

  { code: 'SIN', name: 'Singapore', link: 'singapore', lat: 1.352083, lng: 103.819836, country : COUNTRIES[20]},

  { code: 'CPT', name: 'Cape Town', link: 'cape-town', lat: -33.924869, lng: 18.424055, country : COUNTRIES[21]},

  { code: 'DEL', name: 'New Delhi', link: 'new-delhi', lat: 28.613939, lng: 77.209021, country : COUNTRIES[22]},
  { code: 'BLR', name: 'Bangalore', link: 'bangalore', lat: 12.971599, lng: 77.594563, country : COUNTRIES[22]},

  { code: 'IKA ', name: 'Teheran', link: 'teheran', lat: 35.696111, lng: 51.423056, country : COUNTRIES[23]},

  { code: 'LIM', name: 'Lima', link: 'lima', lat: -12.046374, lng: -77.042793, country : COUNTRIES[24]},

  { code: 'SJO', name: 'San Jose', link: 'san-jose', lat: 9.928069, lng: -84.090725, country : COUNTRIES[25]},

  { code: 'UIO', name: 'Quito', link: 'quito', lat: -0.180653, lng: -78.467838, country : COUNTRIES[26]},

  { code: 'LAP', name: 'La paz', link: 'la-paz', lat: -16.500000, lng: -68.150000, country : COUNTRIES[27]},

  { code: 'MVD', name: 'Montevideo', link: 'montevideo', lat: -34.901113, lng: -56.164531, country : COUNTRIES[28]},

];


/*
// mexico -1
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([19.432608, -99.133209], {icon: this.greenIcon})
  .bindPopup('Mexico City <br/>  <a href="' + this.localize.translateRoute('/booking') + '/mexico_city">' + book + '</a>'));
// puerto rico -2
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([18.465539, -66.105735], {icon: this.greenIcon})
  .bindPopup('San Juan <br/> <a href="' + this.localize.translateRoute('/booking') + '/san_juan">' + book + '</a>'));
// venezuela -3
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([10.469640, -66.803719], {icon: this.greenIcon})
  .bindPopup('Caracas <br/> <a href="' + this.localize.translateRoute('/booking') + '/caracas">' + book + '</a>'));
// venezuela -4
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-34.603723, -58.381593], {icon: this.greenIcon})
  .bindPopup('Buenos Aires <br/> <a href="' + this.localize.translateRoute('/booking') + '/buenos_aires">' + book + '</a>'));
// Chile -5
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-33.469120, -70.641997], {icon: this.greenIcon})
  .bindPopup('Santiago de Chile <br/> <a href="' + this.localize.translateRoute('/booking') + '/santiago">' + book + '</a>'));
// brazil -6
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-22.906847, -43.172897], {icon: this.greenIcon})
  .bindPopup('Rio de Janeiro <br/> <a href="' + this.localize.translateRoute('/booking') + '/rio">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-23.550520, -46.633309], {icon: this.greenIcon})
  .bindPopup('Sao Paulo <br/> <a href="' + this.localize.translateRoute('/booking') + '/sao_paulo">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-15.826691, -47.921820], {icon: this.greenIcon})
  .bindPopup('Brasilia <br/> <a href="' + this.localize.translateRoute('/booking') + '/brasilia">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-3.731862, -38.526670], {icon: this.greenIcon})
  .bindPopup('Fortaleza <br/> <a href="' + this.localize.translateRoute('/booking') + '/fortaleza">' + book + '</a>'));
// panama -7
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([8.983333, -79.516667], {icon: this.greenIcon})
  .bindPopup('Panama City <br/> <a href="' + this.localize.translateRoute('/booking') + '/panama">' + book + '</a>'));
// estados unidos -8
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([25.761680, -80.191790], {icon: this.greenIcon})
  .bindPopup('Miami <br/> <a href="' + this.localize.translateRoute('/booking') + '/miami">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([33.748995, -84.387982], {icon: this.greenIcon})
  .bindPopup('Atlanta <br/> <a href="' + this.localize.translateRoute('/booking') + '/atlanta">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([40.712784, -74.005941], {icon: this.greenIcon})
  .bindPopup('New York <br/> <a href="' + this.localize.translateRoute('/booking') + '/new_york">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([29.760427, -95.369803], {icon: this.greenIcon})
  .bindPopup('Houston <br/> <a href="' + this.localize.translateRoute('/booking') + '/houston">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([37.774929, -122.419416], {icon: this.greenIcon})
  .bindPopup('San Francisco <br/> <a href="' + this.localize.translateRoute('/booking') + '/san_francisco">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([47.606209, -122.419416], {icon: this.greenIcon})
  .bindPopup('Seatle <br/> <a href="' + this.localize.translateRoute('/booking') + '/seatle">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([41.878114, -87.629798], {icon: this.greenIcon})
  .bindPopup('Chicago <br/> <a href="' + this.localize.translateRoute('/booking') + '/chicago">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([36.169941, -115.139830], {icon: this.greenIcon})
  .bindPopup('Las Vegas <br/> <a href="' + this.localize.translateRoute('/booking') + '/las_vegas">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([42.361145, -71.057083], {icon: this.greenIcon})
  .bindPopup('Boston <br/> <a href="' + this.localize.translateRoute('/booking') + '/boston">' + book + '</a>'));
// Canada -9
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([43.653226, -79.383184], {icon: this.greenIcon})
  .bindPopup('Toronto <br/> <a href="' + this.localize.translateRoute('/booking') + '/toronto">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([45.501689, -73.567256], {icon: this.greenIcon})
  .bindPopup('Montreal <br/> <a href="' + this.localize.translateRoute('/booking') + '/montreal">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([45.421530, -75.697193], {icon: this.greenIcon})
  .bindPopup('Otawa <br/> <a href="' + this.localize.translateRoute('/booking') + '/otawa">' + book + '</a>'));
// Australia -10
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-33.867487, 151.206990], {icon: this.greenIcon})
  .bindPopup('Sydney <br/> <a href="' + this.localize.translateRoute('/booking') + '/sydney">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-31.953513, 115.857047], {icon: this.greenIcon})
  .bindPopup('Perth <br/> <a href="' + this.localize.translateRoute('/booking') + '/perth">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-39.029020, 146.315101], {icon: this.greenIcon})
  .bindPopup('Melbourne <br/> <a href="' + this.localize.translateRoute('/booking') + '/melbourne">' + book + '</a>'));
// France -11
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([48.856614, 2.352222], {icon: this.greenIcon})
  .bindPopup('Paris <br/> <a href="' + this.localize.translateRoute('/booking') + '/paris">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([43.710173, 7.261953], {icon: this.greenIcon})
  .bindPopup('Nice <br/> <a href="' + this.localize.translateRoute('/booking') + '/nice">' + book + '</a>'));
// UK -12
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([51.507351, -0.127758], {icon: this.greenIcon})
  .bindPopup('London <br/> <a href="' + this.localize.translateRoute('/booking') + '/london">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([53.349805, -6.260310], {icon: this.greenIcon})
  .bindPopup('Dublin <br/> <a href="' + this.localize.translateRoute('/booking') + '/dublin">' + book + '</a>'));
// España -13
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([40.416775, -3.703790], {icon: this.greenIcon})
  .bindPopup('Madrid <br/> <a href="' + this.localize.translateRoute('/booking') + '/madrid">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([41.385064, 2.173404], {icon: this.greenIcon})
  .bindPopup('Barcelona <br/> <a href="' + this.localize.translateRoute('/booking') + '/barcelona">' + book + '</a>'));
// Alemaña -14
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([50.110922, 8.682127], {icon: this.greenIcon})
  .bindPopup('Franckfurt <br/> <a href="' + this.localize.translateRoute('/booking') + '/franckfurt">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([52.520007, 13.404954], {icon: this.greenIcon})
  .bindPopup('Berlin <br/> <a href="' + this.localize.translateRoute('/booking') + '/berlin">' + book + '</a>'));
// Ostria -15
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([48.208174, 16.373819], {icon: this.greenIcon})
  .bindPopup('Viena <br/> <a href="' + this.localize.translateRoute('/booking') + '/viena">' + book + '</a>'));
// Italia -16
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([41.902783, 12.496365], {icon: this.greenIcon})
  .bindPopup('Roma <br/> <a href="' + this.localize.translateRoute('/booking') + '/roma">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([45.465422, 9.185924], {icon: this.greenIcon})
  .bindPopup('Milan <br/> <a href="' + this.localize.translateRoute('/booking') + '/milan">' + book + '</a>'));
// Russia -17
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([55.755826, 37.617300], {icon: this.greenIcon})
  .bindPopup('Moscow <br/> <a href="' + this.localize.translateRoute('/booking') + '/moscow">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([59.931058, 30.360910], {icon: this.greenIcon})
  .bindPopup('San Petersburg <br/> <a href="' + this.localize.translateRoute('/booking') + '/san_petersburg">' + book + '</a>'));
// Japan -18
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([35.676192, 139.650311], {icon: this.greenIcon})
  .bindPopup('Tokyo <br/> <a href="' + this.localize.translateRoute('/booking') + '/tokyo">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([34.693738, 135.502165], {icon: this.greenIcon})
  .bindPopup('Osaka <br/> <a href="' + this.localize.translateRoute('/booking') + '/osaka">' + book + '</a>'));
// China -19
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([39.904200, 116.407396], {icon: this.greenIcon})
  .bindPopup('Beijin <br/> <a href="' + this.localize.translateRoute('/booking') + '/beijin">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([31.230416, 121.473701], {icon: this.greenIcon})
  .bindPopup('Shanghai <br/> <a href="' + this.localize.translateRoute('/booking') + '/shangai">' + book + '</a>'));
// Singapore -20
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([1.352083, 103.819836], {icon: this.greenIcon})
  .bindPopup('Singapore <br/> <a href="' + this.localize.translateRoute('/booking') + '/singapore">' + book + '</a>'));
// South Africa -21
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-33.924869, 18.424055], {icon: this.greenIcon})
  .bindPopup('Cape Town <br/> <a href="' + this.localize.translateRoute('/booking') + '/cape_town">' + book + '</a>'));
// Inda -22
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([28.613939, 77.209021], {icon: this.greenIcon})
  .bindPopup('New Delhi <br/> <a href="' + this.localize.translateRoute('/booking') + '/new_delhi">' + book + '</a>'));
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([12.971599, 77.594563], {icon: this.greenIcon})
  .bindPopup('Bangalore <br/> <a href="' + this.localize.translateRoute('/booking') + '/bangalor">' + book + '</a>'));
// iran -23
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([35.696111, 51.423056], {icon: this.greenIcon})
  .bindPopup('Teheran <br/> <a href="' + this.localize.translateRoute('/booking') + '/teheran">' + book + '</a>'));
// peru -24
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-12.046374, -77.042793], {icon: this.greenIcon})
  .bindPopup('Lima <br/> <a href="' + this.localize.translateRoute('/booking') + '/lima">' + book + '</a>'));
// costa rica -25
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([9.928069, -84.090725], {icon: this.greenIcon})
  .bindPopup('San Jose <br/> <a href="' + this.localize.translateRoute('/booking') + '/san_jose">' + book + '</a>'));
// ecuador -26
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-0.180653, -78.467838], {icon: this.greenIcon})
  .bindPopup('Quito <br/> <a href="' + this.localize.translateRoute('/booking') + '/quito">' + book + '</a>'));
// bolivia -27
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-16.500000, -68.150000], {icon: this.greenIcon})
  .bindPopup('La paz <br/> <a href="' + this.localize.translateRoute('/booking') + '/la_paz">' + book + '</a>'));
// Colombia -28
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([4.570868, -74.297333], {icon: this.blueIcon})
  .bindPopup('Colombia <br/> <a href="' + this.localize.translateRoute('/booking') + '">' + book + '</a>'));
// Uruguay -29
this.assetLayerGroup2.addLayer(this.leafletService.L.marker([-34.901113, -56.164531], {icon: this.greenIcon})
  .bindPopup('Montevideo <br/> <a href="' + this.localize.translateRoute('/booking') + '/montevideo">' + book + '</a>'));
 */
