export class NewOrModifiedTripRequest {
  /**
   * The Api key.
   */
  apiKey: string;
  /**
   * The Api version.
   */
  apiVersion: string;
  /**
   * The Publish time.
   */
  publishTime: string;
  /**
   * The Trip.
   */
  trip: Trip;
  /**
   * The Login.
   */
  login: string;

  recaptchaMessage?: string;
}
export class Trip {
  discountCode?: string ;
  /**
   * The Reservation number.
   */
  reservationNumber: string;
  /**
   * The Reservation source.
   */
  reservationSource: string;
  /**
   * The Account name.
   */
  accountName: string;
  /**
   * The Event roadshow.
   */
  eventRoadshow?: EventRoadshow;
  /**
   * The Passenger details.
   */
  passengerDetails: PassengerDetails;
  /**
   * The Pick up details.
   */
  pickUpDetails: Location;
  /**
   * The Drop off details.
   */
  dropOffDetails: Location;

  /**
   * The Reservation stops.
   */
  reservationStops?: ReservationStop[];
  /**
   * The Greeter requested.
   */
  greeterRequested?: boolean;
  /**
   * The Mobile number.
   */
  mobileNumber?: string;
  /**
   * The Phone number.
   */
  phoneNumber?: string;
  /**
   * The Phone number extension.
   */
  phoneNumberExtension?: string;
  /**
   * The Bags count.
   */
  bagsCount: number;
  /**
   * The Trip type.
   */
  tripType: string;
  /**
   * The Trip duration i.
   */
  tripDuration: number;
  /**
   * The Trip estimated additional mileage.
   */
  tripKm?: number;

  routeDurationInMin: number;
  /**
   * The Vehicle type.
   */
  vehicleType: string;
  /**
   * The Service type.
   */
  serviceType?: string;
  /**
   * The Booked by.
   */
  bookedBy?: string;
  /**
   * The Booked by phone.
   */
  bookedByPhone?: string;
  /**
   * The Status.
   */
  status?: string;
  /**
   * The Itinerary.
   */
  itinerary?: string;

  /**
   * The Reservation preferences.
   */
  reservationPreferences?: ReservationPreference[];
  /**
   * The Special requests.
   */
  specialRequests?: string;
  /**
   * The Price estimate.
   */
  priceEstimate?: string;
  /**
   * The Payment type.
   */
  paymentType?: string;

  /**
   * The Special inputs.
   */
  specialInputs?: SpecialInput[] ;
  /**
   * The Is new trip.
   */
  isNewTrip: boolean;
  /**
   * The Reservation version.
   */
  reservationVersion?: string;
  /**
   * The Update time.
   */
  updateTime?: string;
  /**
   * The Service city.
   */
  serviceCity: string;
  /**
   * The Service Country.
   */
  serviceCountry: string;
}

export class TransportationCenterDetails {
  /**
   * The Transportation center name.
   */
  transportationCenterName: string;
  /**
   * The Transportation center code.
   */
  transportationCenterCode: string;
  /**
   * The Carrier name.
   */
  carrierName: string;
  /**
   * The Carrier code.
   */
  carrierCode: string;
  /**
   * The Carrier number.
   */
  carrierNumber: string;
  /**
   * The Destination.
   */
  destination: string;
  /**
   * The Departure time.
   */
  departureTime: string;
  /**
   * The Arrival time.
   */
  arrivalTime: string;
  /**
   * The Domestic.
   */
  domestic: boolean;
  /**
   * The State code.
   */
  stateCode: string;
  /**
   * The Country.
   */
  country: string;
  /**
   * The Private aviation.
   */
  privateAviation: boolean;
  /**
   * The Source.
   */
  source: string;

  selectedAirportId?: number;
}


export class AddressDetails {
  /**
   * The Name.
   */
  name?: string;
  /**
   * The Address line 1.
   */
  addressLine1: string;
  /**
   * The Address line 2.
   */
  addressLine2?: string;
  /**
   * The City.
   */
  city?: string;
  /**
   * The State code.
   */
  stateCode?: string;
  /**
   * The Zip code.
   */
  zipCode?: string;
  /**
   * The Country.
   */
  country?: string;
  /**
   * The Pu latitude.
   */
  latitude?: number;
  /**
   * The Pu longitude.
   */
  longitude?: number;
}

export class Location {
  /**
   * The Location type.
   */
  locationType: string;
  /**
   * The Address details.
   */
  addressDetails?: AddressDetails;
  /**
   * The Transportation center details.
   */
  transportationCenterDetails?: TransportationCenterDetails;
  /**
   * The Pick up time.
   */
  pickUpTime?: string;
  /**
   * The Drop off time.
   */
  dropOffTime?: string;
  /**
   * The Location instructions.
   */
  locationInstructions?: string;
  /**
   * The Special instructions.
   */
  specialInstructions?: string;
}

export class PassengerDetails {
  /**
   * The First name.
   */
  firstName: string;
  /**
   * The Last name.
   */
  lastName: string;
  /**
   * The Mobile number.
   */
  mobileNumber?: string;
  /**
   * The Fax number.
   */
  faxNumber?: string;
  /**
   * The Email address.
   */
  emailAddress?: string;
  /**
   * The Service level.
   */
  serviceLevel?: string;

  nameSign?: string;
  /**
   * The Passenger count.
   */
  passengerCount: number;

  /**
   * The Passenger names.
   */
  passengerNames?: PassengerName[];
}

export class EventRoadshow {
  /**
   * The Event roadshow code.
   */
  eventRoadshowCode?: string;
  /**
   * The Event roadshow name.
   */
  eventRoadshowName: string;
}

export class ReservationStop {
  /**
   * The Stop sequence.
   */
  stopSequence: number;
  /**
   * The Stop notes.
   */
  stopNotes?: string;
  /**
   * The Location type.
   */
  locationType?: LocationType;
  /**
   * The Address details.
   */
  addressDetails?: AddressDetails;
  /**
   * The Transportation center details.
   */
  transportationCenterDetails?: TransportationCenterDetails;
}

export class ReservationPreference {
  /**
   * The Type.
   */
  type?: string;
  /**
   * The Value.
   */
  value: string;
}

export class SpecialInput {
  /**
   * The Type.
   */
  type?: string;
  /**
   * The Label.
   */
  label: string;
  /**
   * The Value.
   */
  value: string;
}

export class PassengerName {
  /**
   * The First name.
   */
  firstName: string;
  /**
   * The Last name.
   */
  lastName: string;
  /**
   * The Is primary.
   */
  isPrimary: boolean;

  email?: string;
  phone?: string;
}

export class CancelationRequest {
  /**
   * The Api key.
   */
  apiKey: string;
  /**
   * The Login.
   */
  login: string;
  /**
   * The Api version.
   */
  apiVersion?: string;
  /**
   * The Publish time.
   */
  publishTime?: string;
  /**
   * The Cancelled trip.
   */
  cancelledTrip:  CancelledTrip;
}
export class CancelledTrip {
  /**
   * The Reservation number.
   */
  reservationNumber: string;
  /**
   * The Cancellation time.
   */
  cancellationTime?: string;
  /**
   * The Cancellation number.
   */
  cancellationNumber?: string;
}

export enum LocationType {
  Address= 'Address',
  Airport= 'Airport',
  City_Town= 'City/Town',
  Common_Area= 'Common Area',
  Hotel= 'Hotel',
  Office_Building= 'Office Building',
  PassengerAddress= 'PassengerAddress',
  Port= 'Port',
  Restaurant= 'Restaurant',
  Theater= 'Theater',
  Train_Station= 'Train  Station',
}

export class ResponseToGeneric {
  /**
   * The Response code.
   */
  responseCode: string;
  /**
   * The Message.
   */
  message: string;
  /**
   * The Trip request id.
   */
  tripRequestID: number;
// Getter Methods
}
