<div id="page-header">
  <h1>{{'VehicleCategories' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
    <i class="fa fa-angle-right"></i>{{'VehicleCategories' | translate}}</p>
</div>

<!-- BEGIN .content-wrapper-outer -->
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
    <h3 class="center-title text-uppercase">{{'VehicleCategories-Title1' | translate}}</h3>
    <div class="title-block2"></div>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text1' | translate" ></p>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text1b' | translate" ></p>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text1c' | translate" ></p>
    <!-- END .qns-two-third -->
  </div>
  <div class=" col-lg-2 col-sm-3 mb-5 mt-5">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
    <app-carousel class="photo-container" [slides]="slides1">
    </app-carousel>
  </div>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1 mt-5">
    <h3 class="center-title text-uppercase">{{'VehicleCategories-Title2' | translate}}</h3>
    <div class="title-block2"></div>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text2' | translate" ></p>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text2b' | translate" ></p>
    <ul class="border-list">
      <li>{{'VehicleCategoriesVip-1' | translate}}</li>
      <li>{{'VehicleCategoriesVip-2' | translate}}</li>
      <li>{{'VehicleCategoriesVip-3' | translate}}</li>
      <li>{{'VehicleCategoriesVip-4' | translate}}</li>
      <li>{{'VehicleCategoriesVip-5' | translate}}</li>
      <li>{{'VehicleCategoriesVip-6' | translate}}</li>
      <li>{{'VehicleCategoriesVip-7' | translate}}</li>
    </ul>
    <p class="text-justify" [innerHTML]="'VehicleCategories-Text2c' | translate" ></p>
    <ul class="border-list">
      <li>{{'VehicleCategoriesBusiness-1' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-2' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-3' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-4' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-5' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-6' | translate}}</li>
      <li>{{'VehicleCategoriesBusiness-7' | translate}}</li>
    </ul>
    <!-- END .qns-two-third -->
  </div>
  <div class=" col-lg-2 col-sm-3 mb-5 mt-5">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
    <app-carousel class="photo-container" [slides]="slides2">
    </app-carousel>
  </div>
</div>

<div class="row justify-content-center mb-5 ms-1 me-1">
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/carservice' | localize" href="{{'/carservice' | localize}}" title="{{'Fleet' | translate}}"
                 title="{{'Fleet' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'Fleet' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/coverage' | localize" href="{{'/coverage' | localize}}" title="{{'Coverage' | translate}}"
                 title="{{'Coverage' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'Coverage' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3>
      </li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/booking' | localize" href="{{'/booking' | localize}}" title="{{'BookNow' | translate}}"
                 title="{{'BookNow' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'BookNow' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
</div>

<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1 mb-3">
    <h3 class="center-title text-uppercase">{{'VehicleCategories-Title3' | translate}}</h3>
    <div class="title-block2"></div>
    <h4 class="text-justify mt-5" > VIP</h4>
    <div class="title-block3"></div>
    <mat-accordion>
    <ng-container *ngFor="let category of categories">
      <ng-container *ngIf="category.serviceLevel ==='VIP'">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{category.name}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
        <div *ngFor="let vehicle of vehicles " class="ms-5">
            <ng-container *ngIf="vehicle.categoryId == category.id">
              <!-- [routerLink]=" ('/vehicle/category/' + getCategory(vehicle.categoryId).link)+'/international' | localize"-->
              <a
                   title="{{getCategory(vehicle.categoryId).name}}">
                  <img src="../../assets/img/fleet/{{vehicle.image}}-110x60.jpg" alt="VIP Car Service with {{vehicle.image.replace('-',' ').replace('_',' ')}}" />
                  <span class="link-text me-2">{{vehicle.name}}</span>
                </a>
              <br/>
            </ng-container>
        </div>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
    </mat-accordion>
    <h4 class="text-justify mt-5" > BUSINESS</h4>
    <div class="title-block3"></div>
    <mat-accordion>
    <ng-container *ngFor="let category of categories">
      <ng-container *ngIf="category.serviceLevel ==='BUSINESS'">
        <mat-expansion-panel hideToggle class="mt-3">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{category.name}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let vehicle of vehicles " class="ms-5">
            <ng-container *ngIf="vehicle.categoryId == category.id">
            <!-- [routerLink]=" ('/vehicle/category/' +getCategory(vehicle.categoryId).link)+'/worldwide' | localize" -->
              <a
                 title="{{getCategory(vehicle.categoryId).name}}">
                <img src="../../assets/img/fleet/{{vehicle.image}}-110x60.jpg" alt="VIP Car Service with {{vehicle.image.replace('-',' ').replace('_',' ')}}" />
                <span class="link-text me-2">{{vehicle.name}}</span>
              </a>
              <br/>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
    </mat-accordion>
    <!-- END .qns-two-third -->
  </div>
</div>
