import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {logger} from 'matrix-js-sdk/lib/logger';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }
    UrlAPI: string = environment.apiURL;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Skip interception for Matrix API requests
      if (request.url.includes('/_matrix/')) {
        return next.handle(request); // Don't modify the request
      }
        // add authorization header with jwt token if available
      logger.info("request.url " + request.url)
      const isApiUrl = request.url.startsWith(environment.apiURL);
      logger.info("isApiUrl " + isApiUrl)
      if(isApiUrl) {
        // eso es para estar seguro se solo agregar el token para solicitudes al API
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;

        if (isLoggedIn) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${currentUser.token}`,
              idUsuario: `${currentUser.id}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Credentials': 'true'
            }
          });
        }
      }

      return next.handle(request).pipe( tap( {next: () => {},
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            const currentUser = this.authenticationService.currentUserValue;
            const isLoggedIn = currentUser && currentUser.token;
            if (isLoggedIn && isApiUrl) {
              const isApiUrl = request.url.startsWith(environment.apiURL);
              this.authenticationService.logout();
              location.reload();
            }
          }
        }}
        ));
    }
}
