// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiURL: 'https://prod.vehiculosvip.com/',
  // apiURL: 'https://dev.vehiculosvip.com/',
  // apiURL: 'https://192.168.11.113:8031/core_v1/',
  apiURL: 'https://localhost:8443/',
  URL_CHAT_BOT :"rest/chatbot",
  PAYU_MERCHANT_ID: '508029',
  PAYU_ACCOUNT_ID: '512321',
  PAYU_TEST: 1,
  PAYU_SANDBOX: true,
  DOMAIN: 'https://web.vehiculosvip.com',
  production: false,
  disableLog: false,
  apiKey: '@McQfTjWnZr4u7x!A%C*F-JaNdRgUkXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z$C&',
  googleApiKey1: 'AIzaSyAaXkP2V4vumvafPKR7v8i5BA4x0ut_tqY',
  URL_LOGIN: 'rest/web/login',
  URL_CHATLOGIN: 'rest/web/chatlogin',
  URL_RESERVASDEC0NDUCTOR: 'rest/reserva/conductor',
  URL_RESERVASDEPROTOCOLO: 'rest/reserva/protocolo',
  URL_RESERVASDEPERSONAL: 'rest/reserva/personal',
  URL_RESERVASDEDISPATCH: 'rest/reserva/dispatch',
  URL_RESERVASDEPROVEEDOR: 'rest/reserva/proveedor',
  URL_RESERVASDECLIENTE: 'rest/reserva/cliente',
  URL_CALENDARDEC0NDUCTOR: 'rest/calendar/reserva/conductor',
  URL_CALENDARDEPROTOCOLO: 'rest/calendar/reserva/protocolo',
  URL_CALENDARDEPERSONAL: 'rest/calendar/reserva/personal',
  URL_CALENDARDEDISPATCH: 'rest/calendar/reserva/dispatch',
  URL_CALENDARDEPROVEEDOR: 'rest/calendar/reserva/proveedor',
  URL_CALENDARDECLIENTE: 'rest/calendar/reserva/cliente',
  URL_RESERVAS_DE_EVENTO: 'rest/evento',
  URL_RESERVAS_POR_JWT: 'rest/reserva/jwt',
  URL_POI_BY_CITY: 'rest/poi/city',
  URL_FARMOUT_TO_CONFIRM_POR_JWT : 'rest/provider/farmoutjwt',
  URL_UPDATE_FARMOUT : 'rest/provider/farmoutresponse',
  URL_SEARCH_FARMOUT : 'rest/provider/farmouts',
  URL_RELACIONESTADO: 'rest/relacionEstado',
  URL_VUELO: 'rest/vuelo/reserva',
  URL_LETRERO: 'rest/letrero',
  URL_Excel: 'rest/reserva/excel',
  URL_VOUCHER: 'rest/voucher',
  URL_RESERVASEGUIMIENTOESTADO_RESERVA: 'rest/reservaSeguimientoEstado/reserva',
  URL_RESERVASEGUIMIENTOESTADO: 'rest/reservaSeguimientoEstado',
  URL_RESERVASEGUIMIENTOESTADOSMS: 'rest/status',
  URL_RESERVAULTIMAUBICACION: 'rest/reservaUltimaUbicacion',
  URL_RUTA_RESERVA: 'rest/reservaSeguimientoEstado/reservaruta',
  URL_PASAJEROS_RESERVA: 'rest/pasajero/reserva',
  URL_DF_RESERVA: 'rest/cotizacion/reserva',
  URL_HOJA_MISION: 'rest/hojamision',
  URL_CHECK_LIST: 'rest/checklist',
  URL_CHECK_LIST_CREATE_VEHICULO: 'rest/checklist/vehiculo',
  URL_HOJA_MISION_RESERVA: 'rest/hojamision/reserva',
  URL_CHECK_LIST_RESERVA: 'rest/checklist/reserva',
  URL_CHECK_LIST_VEHICULO: 'rest/checklist/vehiculo',
  URL_LISTAESTADOS: 'rest/estado',
  URL_RESERVAID: 'rest/reserva/id',
  URL_RESERVAS_POR_FACTURAR: 'rest/reserva/hechas',
  URL_SOS: 'rest/asterisk',
  URL_VERSION: 'rest/version',
  URL_VEHICULO_PLACA: 'rest/vehiculo/placa',
  URL_VEHICULO_DISPATCH: 'rest/vehiculo/dispatch',
  URL_VEHICULO_USUARIO: 'rest/vehiculo/proveedor',
  URL_CONDUCTOR_USUARIO: 'rest/conductor/proveedor',
  URL_FORGOT_PASSWORD: 'rest/usuario/forgotpassword',
  URL_RESET_PASSWORD: 'rest/usuario/resetpassword',
  URL_CHANGE_PASSWORD: 'rest/usuario/changepassword',
  URL_REGISTER: 'rest/usuario/register',
  URL_PROFILE: 'rest/usuario/profile',
  URL_BOOKING: 'Generic/newupdatecancel',
  URL_PASAJEROS: 'rest/pasajeros',
  URL_NEWS_INSTAGRAM: 'rest/instagram',
  URL_NEWS: 'rest/news',
  URL_FUEC: 'rest/fuec',
  URL_NEWS_ID: 'rest/newsid',
  URL_NEWS_IMAGE: 'rest/news/image',
  URL_MAIL: 'mail/send',
  URL_COTIZACION: 'rest/cotizacion/booking',
  URL_RATES: 'rest/vehiculos/rates/ciudad',
  URL_VEHICLES: 'rest/vehiculos/ciudad',
  URL_CATEGORIES: 'rest/vehiculos/categorias/ciudad',
  URL_CITIES: 'rest/locations/cities',
  URL_TESTIMONIALS: 'web/testimonials',
  URL_INSTAGRAM: 'web/instagram',
  URL_STAFF_BY_TOKEN: 'rest/conductor/token',
  URL_SURVEY_QUESTIONS: 'rest/preguntas/encuesta/envio',
  URL_SURVEY: 'rest/encuesta/envio',
  URL_SURVEY_RESEPONSE: 'rest/respuestas/envio',
  URL_INVOICES: 'rest/facturas',
  URL_INVOICE_PDF: 'rest/factura/pdf',
  URL_PAYMENTS_LIST :"rest/payments",
  faceBookToken: '133879072678|WihfgKn03waQAdxhn9Apt_YiUNM',
  googleTagManagerId: 'GTM-5KFWLC',
  MATRIX_DOMAIN: 'matrix.vehiculosvip.com'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
// recatcha site key : 6Lfyz9cZAAAAAIsCRRqGPxEqP285ohFgUvqoyhVC
// recatcha secret key : 6Lfyz9cZAAAAAIrGplQxXFjTIkIAPMBOA6TSDX2x
