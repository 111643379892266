  <!-- BEGIN .content-wrapper-outer -->
<div class="content-wrapper-outer row justify-content-center">
  <!-- BEGIN .main-content -->
  <div class="main-content col-12">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-sm-9 col-11 mt-5">
				<p class="page-not-found-text text-justify" [innerHTML]="'Error404' | translate"></p>
        <p>
          <button (click)="reload()"   title="{{'Home' | translate}}">{{'Home' | translate}}</button>
        </p>
      </div>

    </div>
				<hr class="space4" />

  <!-- END .main-content -->
  </div>
<!-- END .content-wrapper-outer -->
</div>
