import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {LocationService} from '../../services/location.service';
import {StaffService} from '../../services/staff.service';
import {Staff} from '../../../models/business-objects';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit, OnDestroy {
  staffId: string;
  staff: Staff = new Staff();
  subscriptions = [];
  constructor(private route: ActivatedRoute, private driverService: StaffService , private changeDetectorRef: ChangeDetectorRef,
              private canonicalAndMetaService: CanonicalAndMetaService, private locationService: LocationService) {

    this.subscriptions.push (this.route.params.subscribe(async params => {
      this.staffId = this.route.snapshot.paramMap.get('staffId');
      await this.changeSelectedDriver();
    }));
  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('staff').then(async result => {
      await this.canonicalAndMetaService.setMetas('driver', true, '');
    });
  }
  async changeSelectedDriver(){
    console.log('changeSelectedDriver');
    console.log(this.staffId);
    this.driverService.getDriverFromToken(this.staffId).then(staff => {
      console.log('staff response');
      console.log(staff);
      this.staff = staff;
      this.canonicalAndMetaService.setMetas('driver', true, this.staff.nombre + ' ' + this.staff.apellido);
      this.changeDetectorRef.detectChanges();
    }, error => {
      this.staff = null;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
