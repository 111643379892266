<div class="row w-100 m-0 justify-content-center">
  <app-home-slider></app-home-slider>
  <app-home-wrapper></app-home-wrapper>
  <app-booking-widget [ngClass]="{'widget-booking-form-wrapper' : bookingWidgetFormat === 'vertical' }"
                      [format]="bookingWidgetFormat"
                      class="w-100 ms-3"></app-booking-widget>
  <app-fleet-wrapper></app-fleet-wrapper>
  <div class="row w-100 justify-content-center call-to-action-2-section pt-5 pb-5 mt-5">
    <div class="col-lg-9 col-xl-7 col-xxl-6 col-sm-11 text-overlay mb-2 mt-2">
      <h3 style="margin: auto;">{{'Homepage-1' | translate}}</h3>
      <div class="title-block5"></div>
      <p>{{'Homepage-2' | translate}}</p>
      <a routerLink="{{'/booking' | localize}}" class="button0 mb-3" title="{{'OnlineBooking' | translate}}">
        {{'OnlineBooking' | translate}}</a>
    </div>
    <!-- END .call-to-action-2-section -->
  </div>
  <app-testimonials-wrapper></app-testimonials-wrapper>
  <div class="col-12 mb-5">
  <div class="row justify-content-center mb-5 ms-1 me-1">
    <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
      <ul class="link-blocks">
        <li><h3><a [routerLink]="'/carservice' | localize" href="{{'/carservice' | localize}}" title="{{'Fleet' | translate}}"
                   title="{{'Fleet' | translate}}" class="link-block-3">
          <span class="link-text me-2">{{'Fleet' | translate}}</span>
          <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
      </ul>
    </div>
    <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
      <ul class="link-blocks">
        <li><h3><a [routerLink]="'/coverage' | localize" href="{{'/coverage' | localize}}" title="{{'Coverage' | translate}}"
                   title="{{'Coverage' | translate}}" class="link-block-3">
          <span class="link-text me-2">{{'Coverage' | translate}}</span>
          <span class="link-arrow fa fa-angle-right"></span></a></h3>
        </li>
      </ul>
    </div>
    <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
      <ul class="link-blocks">
        <li><h3><a [routerLink]="'/booking' | localize" href="{{'/booking' | localize}}" title="{{'BookNow' | translate}}"
                   title="{{'BookNow' | translate}}" class="link-block-3">
          <span class="link-text me-2">{{'BookNow' | translate}}</span>
          <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
      </ul>
    </div>
  </div>
  </div>
  <app-news-wrapper></app-news-wrapper>
</div>
