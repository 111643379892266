import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, Observable, throwError} from 'rxjs';
import {Cliente, User} from '../../models/business-objects';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';


@Injectable()
export class AuthService {
  UrlAPI: string = environment.apiURL;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)){
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(null);
    }

    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public set currentUserValue(usuario: User) {
    this.currentUserSubject.next(usuario);
  }
  login(login: string, password: string, token:String) {
    console.log('Entrando servicio de login enviado a  ' + this.UrlAPI + environment.URL_LOGIN);
    return this.http.post<any>(this.UrlAPI + environment.URL_LOGIN, {login, password, token}).pipe(map(user => {
      console.log('LLego la respuesta a la solicitud de login');
      console.log(user);
      if (user.result){
        throw user;
      }
      // login successful if there's a jwt token in the response
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log('user.roldb');
        console.log(user.roldb);
        const usuario: User = user;
        if ( usuario.roldb && (usuario.roldb === 'dispatch' || usuario.roldb === 'gerencia' ) ) {
              // nothing to do, rolDB already defined
              console.log('usuario es dispatch o gerencia');
        } else {
                if (usuario.proveedorId){
                  console.log('usuario es proveedor');
                  usuario.roldb = 'proveedor';
                } else if (user.cliente){
                  console.log('usuario es cliente');
                  usuario.roldb = 'cliente';
                } else  {
                  console.log('usuario es operador');
                  user.roldb = 'operador';
                }
        }
        console.log('USER LOGED  gotten form server');
        console.log(user);
        console.log('USER created');
        console.log(usuario);
        localStorage.setItem('currentUser', JSON.stringify(usuario));
        this.currentUserSubject.next(usuario);
        return user;
      }
      console.log('saliendo del tratamiento del usuario');
      throw throwError('');
    }));
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('currentUser');
    }
    this.currentUserSubject.next(null);
  }
  logoutChat() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('currentUser');
    }
    let user:User = this.currentUserSubject.value;
    user.chatToken = null;
    this.currentUserSubject.next(user);
  }
  getForgotPassword(user: User): Promise<any>{
    const urlFinal = environment.URL_FORGOT_PASSWORD;
    let headers = new HttpHeaders();
    const body = { ...user };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  getResetPassword(newPassword: string, idUsuario: number, token: string ): Promise<any>{
    const urlFinal = environment.URL_RESET_PASSWORD;
    let headers = new HttpHeaders();
    const body = { idUsuario, newPassword, token  };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  getChangePassword(newPassword: string, idUsuario: number, oldPassword: string ): Promise<any>{
    const urlFinal = environment.URL_RESET_PASSWORD;
    let headers = new HttpHeaders();
    const body = { idUsuario, newPassword, oldPassword };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  getRegistrate(user: User, client: Cliente): Promise<any>{
    const urlFinal = environment.URL_REGISTER;
    let headers = new HttpHeaders();
    user.cliente = client;
    const body = { ...user};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  getSaveProfile(user: User): Promise<any>{
    const urlFinal = environment.URL_PROFILE;
    let headers = new HttpHeaders();
    const body = { ...user};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  /*
  makeIntlNumber(phoneNumber: string): InternationalPhone {
    const intlNumber = new InternationalPhone();
    // const googlePhoneNb = this.phoneUtil.parse(phoneNumber);
    // console.log ('googlePhoneNb'); console.log (googlePhoneNb);
    const plusIndex = phoneNumber.indexOf('+');
    if (plusIndex > -1) {
      const firstSpaceIndex = phoneNumber.indexOf(' ');
      if (firstSpaceIndex > -1) {
        intlNumber.dialCode = phoneNumber.substr(plusIndex + 1, firstSpaceIndex);
        intlNumber.countryCode = 'US';
        intlNumber.internationalNumber = phoneNumber;
        intlNumber.number = phoneNumber.substr(firstSpaceIndex + 1, phoneNumber.length - firstSpaceIndex - 1);
        console.log ('intlNumber'); console.log (intlNumber);
        return intlNumber;
      }
    } else {
      intlNumber.number = phoneNumber;
    }
    return intlNumber;
  }

   */
}
