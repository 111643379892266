import {Marker} from 'leaflet';

export class Vehicle {
  name: string;
  image: string;
  link: string;
  rateFrom: string;
  hour: string;
  hourDayPlan?: string;
  minimumHours?: number;
  passengers: string;
  bags: string;
  id: number;
  wifi?: boolean;
  interior?: string;
  fullyInsured?: boolean;
  drinks?: boolean;
  smoking?: boolean;
  phoneCharger?: boolean;
  meetAndGreet?: boolean;
  photos?: string[];
  photo360?: string;
  options?: string[];
  categoryId: number;
  ciudadWeb?: string;
  countryCode?: string;
}

export class VehicleCategory {
  name: string;
  apiName: string;
  image?: string;
  link?: string;
  bags: number;
  passengers: number;
  rateFrom: number;
  hour: number;
  hourDayPlan?: number;
  minimumHours?: number;
  fixedRates?: FixedRate[];
  id?: number;
  serviceLevel?: string;
  city?: string;
  hourly?: number;
  daily?: number;
  transfer?: number;
  add_km?: number;
  conditions?: string;
  index?: string;
}

export class FixedRate {
  startPoi: Poi;
  endPoi: Poi;
  amount: number;
  condition: string;
  id: number;
}

export class Poi {
  name: string;
  image: string;
  link: string;
  id: number;
  localName?: string;
}

export class City {
  iso3: string;
  nombre: string;
  lng?: number;
  lat?: number;
  link?: string;
  pais: Country;
  image?: string;
  aeropuertos?: Airport[];
  localName?: string;
  horasTraslado?: number;
}
export class Airport {
  iata: string;
  nombre: string;
  direccion?: string;
  lat?: number;
  lon?: number;
  id?: number;
  localName?: string;
  isFBO?: boolean;
  webName?: string;
}
export class Country {
  iso2: string;
  nombre: string;
  continente?: string;
  subcontinente?: string;
  image?: string;
  localName?: string;
}
export class Booking {
    id?: number;
    serviceType = 'transfer';
    serviceCity?: string;
    serviceCountry?: string;
    city?: City;
    puAdress = '';
    puCoordinates: Coordinates = new Coordinates();
    puGoogleLocation?: google.maps.places.PlaceResult;
    doAdress = '';
    doCoordinates: Coordinates = new Coordinates();
    doGoogleLocation?: google.maps.places.PlaceResult;
    route?: google.maps.DirectionsResult;
    routeDurationInMin?: number;
    routeDistanceInKm?: number;
    isWithinCity?: boolean;
    quote?: number;
    puDate: Date = null;
    puDateTime?: string;
    puHour: number = null;
    puMin: number = null;
    duration?: number;
    serviceLevel?: string;
    vehicleCategory: VehicleCategory = null;
    currency?: string;
    amountQuoted?: string;
    confirmationNumber?: any;
    userName?: string;
    passengers: number = null;
    bags: number = null;
    firstName = '';
    lastName = '';
    email = '';
    phone = '';
    message = '';
    apiResponse?: string;
    cliente?: Cliente;
    recaptchaMessage?: string;
    passengerList?: Passenger[];
    flightNumber: string;
    airportCode: string;
    airport?: Airport;
    isAirportPickup: boolean;
    terminal?: string;
    stops?: Stop[];
    detallesFactura?: DetalleFactura[];
    moneda?: string;
    searchType ? = 'List';
    nameSign?: string;
    greeterRequested?:boolean;
    isReturn = false;
    discountCode = '' ;
}

export class Passenger {
  firstName: string;
  lastName: string;
  fullName?: string;
  idNumber?: string;
  phone?: string;
  email?: string;
  id?: number;
  _internationalPhone?: any;
  set internationalPhone(iPhone: any){
    if (iPhone) {
      this.phone = iPhone.internationalNumber;
    }
    this._internationalPhone = iPhone;
  }
  get internationalPhone(){
    return this._internationalPhone;
  }
}

export class User {
  idconductor: number;
  esExterno: string;
  login?: string;
  id: number;
  activo: string;
  roldb?: string;
  proveedorId?: number;
  cliente?: Cliente;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  token?: string;
  email: string;
  phone: string;
  url?: string | any[];
  isTravelArranger?: boolean;
  isAdministrator?: boolean;
  cannotSeeRate?: boolean;
  cannotSeeDiscount?: boolean;
  messageHeader?: string;
  messageFooter?: string;
  messageSignature?: string;
  messageTitle?: string;
  chatIdentity?: string;
  chatToken?: string;
}
export class CalendarJob {
  id: number;
  service: Service;
  title: string;
  description: string;
  startDate: string;
  startHour: number;
  startMinute: number;
  endDate: string;
  endHour: number;
  endMinute: number;
  lastEVent?: string;
  state?: string;
  timeZone?:string;

}
export class Service {
  id: number;
  idcliente: number;
  numeroreserva: string;
  nombrecliente: string;
  celularcliente: string;
  telefonocliente: string;
  cedulacliente: string;
  lugarRecogida?: String;
  direccionrecogida: string;
  direcciondestino: string;
  lugarDestino?: String;
  operacion: string;
  letrero: string;
  fecha: string;
  hora: string;
  instruccionesmision: string;
  /**
   *  set to -1 if there is no event.
   */
  eventoactual: number;
  vuelo: string;
  placa: string;
  cantidadpasajeros: string;
  cantidadmaletas: string;
  instruccionesrecogida: string;
  firmahmision: string;
  gastosadicionales: string;
  conductor: string;
  textoEventoActual: string;
  fechaEventoActual: string;
  nombreProtocolo: string;
  celularProtocolo: string;
  horas: string;
  nivelServico: string;
  tipoVehiculo: string;
  ciudad: string;
  celularConductor: string;
  celularazafatamanual?: string;
  celularmanual?: string;
  conductormanual?: string;
  protocolomanual?: string;
  vueloD: string;
  modeloVehiculo: string;
  referenciacliente: string;
  hm?: HojaMision;
  cl?: CheckList;
  rolReserva: string;
  conductorId: string;
  azafataId: string;
  conductorProveedorId: string;
  azafataProveedorId: string;
  tipoServicio: string;
  noShowOLateCancel: boolean;
  nombrePrimerPasajero: string;
  pasajeros?: Cliente[] = [];
  anulada?: boolean;
  voucher?: boolean;
  moneda?: string;
  /**
   * Time stamp in Epoch seconds
   */
  timeStamp: string;
  description?:String;
  detallesFactura?: DetalleFactura[];
  estado: number;
}

export class CheckList {
    idreserva: number;
    id: number;
    fechasoat: string;
    kminicio: number;
    lavado: boolean;
    comentarios: string;
    presionllantas: string;
    aguas: boolean;
    llantarepuesto: boolean;
    cinturones: boolean;
    fluidos: boolean;
    frenos: boolean;
    luces: boolean;
    fecha: string;
    idvehiculo: number;
    controltecnico: boolean;
    documentos: boolean;
    kitcarretara: boolean;
    tankeado: boolean;
    paraguas: boolean;
}

export class HojaMision {
    id: number;
    idreserva: number;
    inicioservicio: string;
    finservicio: string;
    kminicio: number;
    kmfinal: number;
    salidaciudad: boolean;
    gastosacobrar: number;
    comentarios: string;
}

export class Coordinates {
  lat: number;
  lng: number;
  LatLng ?: google.maps.LatLng;
}

export class News {
  id: string;
  type?: string;
  html?: string;
  thumbnail_url?: string;
  hasScripts?: boolean;
  scriptUrl?: string;
  scriptText?: string;
  title?: string;
  author?: string;
  newsBody?: string;
  datePublished?: string;
  lang?: string;
  tags?: string;
  seccionNoticia?: string;
  imageUrl?: any;
  myId?: string;
}

export class MySlide {
  src?: string;
  text?: string;
  title?: string;
  news?: News;
  author?: string;
  date?: string;
}

export class JobStatus {
  id: number;
  nombre: string;
  descripcion: string;
  activo: boolean;
  inicial: boolean;
  soloConduccion: boolean;
}
export class Cliente {
  id: number;
  fullName: string;
  firstName: string;
  lastName?: string;
  celular?: string;
  phone?: string;
  address?: string;
  country?: string;
  city?: string;
  idNumber?: string;
  idType: string;
  apiId?: string;
  email?: string;
  code?: string;
  operationEmails?: string[];
  accountingEmails?: string[];
  acceptNews?: boolean;
  acceptTerms?: boolean;
  instructions?: string;
  advisePax?: boolean;
  currency?: string;
}
export class JobEvent {
  id?: number;
  service?: Service;
  estado?: JobStatus;
  estadoId: number;
  nombre: string;
  lat: number;
  lng: number;
  date: string;
  personalId: number;
  tipo?: string;
  marker: Marker;
  comentario?: string;
}

export class RelacionEstado {
  id: number;
  padre: JobStatus;
  hijo: JobStatus;
}

export class ButtonStatus {
  idEstado: number;
  nombreEstado: string;
  antiguo: boolean;
  actual: boolean;
  soloConduccion: boolean;
}
export class CompleteJob {
  service: Service;
  eventos: JobEvent[];
  estados: JobStatus[];
  relacionestados: RelacionEstado[];
  pasajeros: Cliente[];
  hojaMision: HojaMision;
  checkList: CheckList;
  ultimoEvento?: JobEvent;
}

export class InternationalPhone {
  number: string;
  internationalNumber: string;
  nationalNumber: string;
  e164Number: string;
  countryCode: string;
  dialCode: string;
}

export class Stop  {
  coordinates: Coordinates;
  address: string;
  placeResult: google.maps.places.PlaceResult;
  name?: string;
}

export class DetalleFactura {
  descripcion: string;
  cantidad: number;
  precioUnitario: number;
  valorTotal: number;
  descuento: number;
  iva: number;
  vehiculo: string;
  ciudad: string;
  fechaServicio: string;
  categoria: string;
  pasajero: string;
  monedaCliente?: string;
  moneda?: string; // eso esta aca en caso de un gasto adicional en otra moneda
  trmMonedaLocal?: number; // eso esta aca en caso de un gasto adicional en otra moneda
}

export class Staff {
  nombre: string;
  apellido: string;
  celular: string;
  activo: string;
  cedula: string;
}

export class Testimonial {
  id: number;
  lang: string;
  author: string;
  datePublished: string;
  date: string;
  rating: number;
  title: string;
  reviewBody: string;
}

export class Invoice {
  id: number;
  number: string;
  currency: string;
  date: string;
  total: number;
  paid?: boolean;
  confirmed?: boolean;
  pdf?: string;
}

export class ClientEVent {
  id: number;
  nombre: string;
  descripcion: String;
  instruccionesConductor: String;
  ciudadDefecto: String;
  fechaInicio?: String;
  fechaFin?: String;
  cliente?: Cliente;
  reservas : Service[] = [];
  grupos: String[];
  lugarTransporte: String;
  lugarHotelEvento: String;
  lugarEvento: String;
}
