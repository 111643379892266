import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {VehiclesService} from '../../services/vehicles.service';
import {Vehicle, VehicleCategory} from '../../../models/business-objects';

@Component({
  selector: 'app-vehicle-widget',
  templateUrl: './vehicle-widget.component.html',
  styleUrls: ['./vehicle-widget.component.scss']
})
export class VehicleWidgetComponent implements OnInit {
 _ciudad;
  vehicles: Vehicle[] = [];
  categories: VehicleCategory[] = [];
  constructor(private vehicleService: VehiclesService , private changeDetectorRef: ChangeDetectorRef ) { }

  async ngOnInit() {
    this.vehicles = await this.vehicleService.getVehicles(this.ciudad);
    this.categories = await this.vehicleService.filterCategoriesByCity(this.ciudad);
    if ( this.vehicles == null){
      this.vehicleService.getVehiclesFromAPI(null).then(result => {
        this.vehicles = result ;
        this.ciudad = this._ciudad;
      });
    }
    if ( this.categories == null){
      this.vehicleService.getCategoriesFromAPI(null).then(result => {
        this.categories = result ;
        this.ciudad = this._ciudad;
      });
    }
  }

  @Input() set ciudad(city: string) {
    this._ciudad = city;
    this.changeCity(city);
  }
  get ciudad(): string {
    return this._ciudad;
  }
  async changeCity(city: string) {
    this.vehicles = await this.vehicleService.getVehicles(city);
    this.categories = await this.vehicleService.filterCategoriesByCity(city);
    this.changeDetectorRef.detectChanges();
  }

}
