import {Injectable} from '@angular/core';
import {Vehicle, VehicleCategory} from '../../models/business-objects';
import {environment} from '../../environments/environment';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class VehiclesService {
  constructor(private http: HttpClient) {
    this.currentVehiclesSubject = new BehaviorSubject<Vehicle[]>(null);
    this.currentCategoriesSubject = new BehaviorSubject<VehicleCategory[]>(null);
    this.readyP = this.initializeLists();
  }

  public get currentVehiclesValue(): Vehicle[] {
    return this.currentVehiclesSubject.value != null ?  this.currentVehiclesSubject.value : [];
  }
  public set currentVehiclesValue(vehicles: Vehicle[]) {
    this.currentVehiclesSubject.next(vehicles);
  }
  public get currentCategoriesValue(): VehicleCategory[] {
    return this.currentCategoriesSubject.value != null ?  this.currentCategoriesSubject.value : [];
  }
  public set currentCategoriesValue(categories: VehicleCategory[]) {
    this.currentCategoriesSubject.next(categories);
  }
  UrlAPI: string = environment.apiURL;

  private currentVehiclesSubject: BehaviorSubject<Vehicle[]>;
  private currentCategoriesSubject: BehaviorSubject<VehicleCategory[]>;
  private  readyP: Promise<boolean>;

  categories: VehicleCategory[] = [
    {name : 'Mid Size Sedan', apiName: 'Sedan', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      rateFrom: 96, hour: 64, passengers: 3, bags: 3, id: 1 , serviceLevel: 'VIP'},
    {name : 'Luxury Sedan', apiName: 'Luxury Sedan', image: 'Audi-A6-1', link: 'luxury-sedan',
      rateFrom: 123, hour: 82, passengers: 3, bags: 3, id: 2, serviceLevel: 'VIP'},
    {name : 'Van VIP', hour: 92, apiName: 'Minibus', image: 'mercedes-sprinter', link: 'sprinter',
      rateFrom: 138, passengers: 15, bags: 10, id: 8, serviceLevel: 'VIP'},
    {name : 'Luxury SUV', apiName: 'Sport Utility Vehicle', image: 'ford_expedition', link: 'luxury-suv',
      rateFrom: 138, hour: 92, passengers: 5, bags: 3, id: 4, serviceLevel: 'VIP'},
    {name : 'Luxury Minivan VIP', apiName: 'Luxury Minivan', image: 'mercedes-viano', link: 'v-class',
      rateFrom: 150, hour: 100, passengers: 6, bags: 4, id: 18, serviceLevel: 'VIP'},
    {name : 'Armored SUV', apiName: 'Armored SUV', image: 'prado-2', link: 'armored',
      rateFrom: 800, hour: 100, passengers: 3, bags: 3, id: 7, serviceLevel: 'VIP'},
    {name : 'Minivan VIP', apiName: 'Minivan', image: 'hyundai_starex_white', link: 'minivan-vip',
      rateFrom: 117, hour: 78, passengers: 9, bags: 2, id: 3, serviceLevel: 'VIP'},
    {name : 'Bus / Mini-Bus', apiName: 'Bus', image: 'minibus', link: 'minibus',
      rateFrom: 180, hour: 90, passengers: 25, bags: 25, id: 26, serviceLevel: 'VIP'},
    {name : 'Coach', apiName: 'Coach', image: 'coach', link: 'coach',
      rateFrom: 220, hour: 110, passengers: 35, bags: 35, id: 28, serviceLevel: 'VIP'},
    {name : 'Mid Size Sedan Business', apiName: 'Sedan_business', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      rateFrom: 57, hour: 38, passengers: 3, bags: 3, id: 9 , serviceLevel: 'BUSINESS'},
    {name : 'SUV Business', apiName: 'Public SUV', image: 'duster', link: 'BUSINESS',
      rateFrom: 35, hour: 23, passengers: 3, bags: 3, id: 13, serviceLevel: 'BUSINESS'},
    {name : 'Minivan Business', apiName: 'Minivan_business', image: 'hyundai_starex_white', link: 'minivan-business',
      rateFrom: 72, hour: 48, passengers: 9, bags: 2, id: 12, serviceLevel: 'BUSINESS'},
    {name : 'Van Business', apiName: 'Minibus_business', image: 'mercedes-sprinter-blanco', link: 'sprinter',
      rateFrom: 96, hour: 64, passengers: 15, bags: 10, id: 15, serviceLevel: 'BUSINESS'},
  ];

  async initializeLists(): Promise<boolean>{
    console.log('initialing list vehicles');
    try {
      this.currentVehiclesValue = await this.getVehiclesFromAPI(null);
      this.currentCategoriesValue = await this.getCategoriesFromAPI(null);
      this.currentVehiclesValue = this.currentVehiclesValue.sort((a, b) => {
        return this.getCategory(a.categoryId).index.localeCompare(this.getCategory(b.categoryId).index) ;
      });
      console.log('vehicles should be ready : '
        + (this.currentVehiclesSubject != null ? this.currentVehiclesSubject.value.length : 'null') );
      // console.log(this.currentVehiclesSubject.value);
      this.currentCategoriesValue = this.currentCategoriesValue.sort((a, b) => {
        return a.index.toString().localeCompare(b.index.toString()) ;
      });
      console.log('categories should be ready: ' + (this.currentCategoriesSubject != null ? this.currentCategoriesSubject.value.length : 'null') );
      // console.log(this.currentCategoriesSubject.value);
      return true;
    } catch (err) {
      console.log(err);
      console.log('vehicles or categories list failed !');
      return false;
    }
  }

  getVehicles(city: string): Promise<Vehicle[]> {
    return this.readyP.then( result => {
      return this.filterVehicleByCity(city);
    });
  }
  getAllVehicles(): Promise<Vehicle[]> {
    return this.readyP.then( result => {
      return this.currentVehiclesValue;
    });
  }
  filterVehicleByCity(city: string): Promise<Vehicle[]>{
    if (city === 'worldwide') { city = null; }
    return this.readyP.then( result => {
      const filters = {ciudadWeb: [city]};
      if (city != null && city.length > 0) {
        // console.log('searching vehicles by city ' + city);
        // console.log(filters);
        // console.log(this.currentVehiclesValue);
        // console.log('son ' + this.currentVehiclesValue.filter(o => filters.ciudadWeb.includes(o.ciudadWeb)).length);
        return this.currentVehiclesValue.filter(o => filters.ciudadWeb.includes(o.ciudadWeb));
      } else {
        const worldWideVehicles: Vehicle[] = [];
        const modelosYaAgregados: Vehicle[] = [];
        this.currentCategoriesValue.forEach(c => {
          // console.log('processando la categoria ' + c.name);
          for (const v of this.currentVehiclesValue) {
            const newVehicle: Vehicle = { ...v };
            // console.log('processando el vehiculo ' + v.name);
            // console.log(newVehicle);
            if (c.id === newVehicle.categoryId) {
              const vehicleFound = modelosYaAgregados.find(ve => ve.name === v.name && ve.categoryId === v.categoryId);
              if (vehicleFound == null) {
                // console.log('este vehiculos es nuevo ');
                if (city == null && newVehicle.ciudadWeb !== 'worldwide') {
                  // console.log('este vehiculos es de una ciudad especifica, se le quitan las fotos ');
                  newVehicle.photos = null;
                }
                worldWideVehicles.push(newVehicle);
                modelosYaAgregados.push(newVehicle);
              } else {
                // console.log('este vehiculos ya existe con el id ' + newVehicle.id);
              }
            } else {
              // console.log('este vehiculos es de otra cagegoria : ' + this.getCategory(v.categoryId).name);
            }
          }
        });
        console.log('worldWideVehicles returned ');
        // console.log(worldWideVehicles);
        return worldWideVehicles;
      }
    });
  }

  filterCategoriesByCity(city: string): Promise<VehicleCategory[]>{
    // aca se construyen las categorias basadas en las listas de vehiculos
    if (city === 'worldwide') { city = null; }
    return this.readyP.then( result => {
      const categoriesForCity: VehicleCategory[] = [];
      if (city != null && city.length > 0) {
        this.currentCategoriesValue.forEach(c => {
          for (const v of this.currentVehiclesValue) {
            if (c.id === v.categoryId && v.ciudadWeb === city) {
              categoriesForCity.push(c);
              break;
            }
          }
        });
        console.log('categorias para '+ city +  '  returned ');
        console.log(categoriesForCity);
        return categoriesForCity;
      } else {
        console.log('');
        return this.currentCategoriesValue;
      }
    });
  }

  getVehiclesFromAPI(ciudad: string): Promise<[Vehicle]> {
    if (ciudad === 'worldwide') { ciudad = null; }
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_VEHICLES, {ciudad}));
  }
  getCategoriesFromAPI(ciudad: string): Promise<[VehicleCategory]> {
    if (ciudad === 'worldwide') { ciudad = null; }
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CATEGORIES, {ciudad}));
  }

  getCategories(): Promise<VehicleCategory[]> {
    return this.readyP.then( result => {
      return this.currentCategoriesValue;
    });
  }
  getCategoriesByCountry(countryCode: string): Promise<VehicleCategory[]> {
    console.log('searching categories by country ' + countryCode);
    const categories: VehicleCategory[] = [];
    return this.readyP.then(async result => {
      if (countryCode != null){
        // aca la idea es de intentar quitar la categoria business si sabemos que en este pais no se presta
        const countriesCovered: string[] = [];
        const categoriesInCountry: number[] = [];
        const vehicles: Vehicle[] = this.currentVehiclesValue;
        vehicles.forEach( v => {
          // para eso identificamos los paises que tienen vehiculos de de categoria business configurados
          const category = this.currentCategoriesValue.find( c => c.id === v.categoryId);
          if ( v.countryCode != null && !countriesCovered.includes(v.countryCode.toLowerCase()) && category.serviceLevel ===  'BUSINESS') {
            countriesCovered.push(v.countryCode.toLowerCase());
          }
          if ( v.countryCode != null && !categoriesInCountry.includes(v.categoryId)
            && v.countryCode.toLowerCase() === countryCode.toLowerCase()) {
            categoriesInCountry.push(v.categoryId);
          }
        });
        if (categoriesInCountry.length !== 0 ) {
          // entramos aca
          console.log('tenemos categorias especificas en este pais ' + categoriesInCountry.length);
          this.currentCategoriesValue.forEach(vc => {
            if (categoriesInCountry.includes(vc.id)) {
              categories.push(vc);
            }
          });
        } else if (!countriesCovered.includes(countryCode.toLowerCase())) {
          // y si el pais en question no esta en los paises que tienen business entonces solo se agregan
          // categorias que no son  BUSINESS
          console.log('NO tenemos Business en ninguna ciudad de este pais, vamos agregar solo los VIP');
          this.currentCategoriesValue.forEach(vc => {
            if (vc.serviceLevel !== 'BUSINESS') {
              categories.push(vc);
            }
          });
        } else {
          console.log('Tenemos Business en algunas ciudades de este pais, vamos agregar todas las categorias del pais');
          return this.currentCategoriesValue;
        }
        return categories;
      } else {
        return this.currentCategoriesValue;
      }
    });
  }
  getCategory(id: number): VehicleCategory {
    if (this.currentCategoriesValue != null) {
      // console.log(this.currentCategoriesValue.find(c => c.id === id).link);
      return this.currentCategoriesValue.find(c => c.id === id);
    } else {
      return null;
    }
  }
}
/*

  vehiclesBogota: Vehicle[] = [
    {name : 'Ford Fusion', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/fusion/1.jpg', 'images/fusion/2.jpg', 'images/fusion/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '96', passengers: '3', bags: '3', id: 1 , categoryId: 1},
    {name : 'Audi A6', image: 'Audi-A6-1', link: 'luxury-sedan-audi',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/audia6/8.jpg', 'images/audia6/9.jpg', 'images/audia6/10.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 2, categoryId: 2},
    {name : 'Mercedes E-Class', image: 'Mercedes-Eclass-2', link: 'luxury-sedan-mercedes',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/mercedeseclass/1.jpg', 'images/mercedeseclass/2.jpg', 'images/mercedeseclass/4.jpg', 'images/mercedeseclass/5.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Cadillac XTS', image: 'cadillac-xts', link: 'luxury-sedan-cadillac',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['ChildSeat'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Mercedes V-class', image: 'mercedes-viano', link: 'v-class',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/viano/0.jpg' , 'images/viano/1.jpg', 'images/viano/2.jpg', 'images/viano/3.jpg', 'images/viano/4.jpg',
        'images/viano/5.jpg', 'images/viano/6.jpg', 'images/viano/7.jpg', 'images/viano/8.jpg'],
      options : ['AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '150', passengers: '6', bags: '4', id: 4, categoryId: 18},
    {name : 'Mercedes Sprinter', image: 'mercedes-sprinter', link: 'sprinter',
      wifi: true, interior: 'Fabric Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/sprinter/0.jpg' , 'images/sprinter/3.jpg', 'images/sprinter/5.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '15', bags: '10', id: 5, categoryId: 8},
    {name : 'Ford Expedition', image: 'ford_expedition', link: 'luxury-suv',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '5', bags: '3', id: 6, categoryId: 4},
    {name : 'Standard Minivan', image: 'hyundai_starex_white', link: 'minivan-vip',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/starex/0.jpg' , 'images/starex/1.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '117', passengers: '9', bags: '2', id: 7, categoryId: 3},
    {name : 'Armored SUV', image: 'prado', link: 'armored',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '250', passengers: '3', bags: '3', id: 8, categoryId: 7},
    {name : 'Mid-Size SUV', image: 'duster', link: 'public',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: false,
      rateFrom: '57', passengers: '3', bags: '3', id: 9, categoryId: 13},
    {name : 'Mini Bus', image: 'minibus', link: 'minibus',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '180', passengers: '25', bags: '25', id: 10, categoryId: 26},
    {name : 'Mini Bus', image: 'coach', link: 'coach',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '220', passengers: '40', bags: '40', id: 11, categoryId: 28},
  ];
    vehiclesCartagena = [
      {name : 'Ford Fusion', image: 'ford-fusion-2012', link: 'mid-size-sedan',
        wifi: true, interior: 'Leather Black', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/fusion/1.jpg', 'images/fusion/2.jpg', 'images/fusion/3.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '88', passengers: '3', bags: '3', id: 1 , categoryId: 1},
      {name : 'Mercedes E-Class', image: 'Mercedes-Eclass-2', link: 'luxury-sedan-mercedes',
        wifi: true, interior: 'Leather Black', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/mercedeseclass/1.jpg', 'images/mercedeseclass/2.jpg',
          'images/mercedeseclass/4.jpg', 'images/mercedeseclass/5.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '106', passengers: '3', bags: '3', id: 3, categoryId: 2},
      {name : 'Mercedes V-class', image: 'mercedes-viano', link: 'v-class',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/viano/0.jpg' , 'images/viano/1.jpg', 'images/viano/2.jpg', 'images/viano/3.jpg', 'images/viano/4.jpg',
          'images/viano/5.jpg', 'images/viano/6.jpg', 'images/viano/7.jpg', 'images/viano/8.jpg'],
        options : ['AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '124', passengers: '6', bags: '4', id: 4, categoryId: 18},
      {name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', link: 'sprinter',
        wifi: true, interior: 'Fabric Black', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/sprinter/0.jpg' , 'images/sprinter/3.jpg', 'images/sprinter/5.jpg'],
        options : ['CellPhone', 'Icebox'],
        rateFrom: '116', passengers: '15', bags: '10', id: 5, categoryId: 2},
      {name : 'Ford Expedition', image: 'ford_expedition', link: 'luxury-suv',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '116', passengers: '5', bags: '3', id: 6, categoryId: 4},
      {name : 'Standard Minivan', image: 'hyundai_starex_white', link: 'minivan-vip',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/starex/0.jpg' , 'images/starex/1.jpg'],
        options : ['CellPhone', 'Icebox'],
        rateFrom: '102', passengers: '9', bags: '2', id: 7, categoryId: 3},
      {name : 'Armored SUV', image: 'prado', link: 'armored',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '324', passengers: '3', bags: '3', id: 8, categoryId: 7},
      {name : 'Mid-Size SUV', image: 'duster', link: 'public',
        wifi: true, interior: 'Fabric', fullyInsured: true,
        drinks: false, smoking: false, phoneCharger: true, meetAndGreet: false,
        rateFrom: '38', passengers: '3', bags: '3', id: 9, categoryId: 13},
      {name : 'Mini Bus', image: 'minibus', link: 'minibus',
        wifi: true, interior: 'Fabric', fullyInsured: true,
        drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
        options : ['CellPhone', 'Icebox'],
        rateFrom: '180', passengers: '25', bags: '25', id: 10, categoryId: 26},
      {name : 'Mini Bus', image: 'coach', link: 'coach',
        wifi: true, interior: 'Fabric', fullyInsured: true,
        drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
        options : ['CellPhone', 'Icebox'],
        rateFrom: '220', passengers: '40', bags: '40', id: 11, categoryId: 28},
  ];
    vehiclesMedellin = [
      {name : 'Mercedes E-Class', image: 'Mercedes-Eclass-2', link: 'luxury-sedan-mercedes',
        wifi: true, interior: 'Leather Black', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/mercedeseclass/1.jpg', 'images/mercedeseclass/2.jpg',
          'images/mercedeseclass/4.jpg', 'images/mercedeseclass/5.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '153', passengers: '3', bags: '3', id: 3, categoryId: 2},
      {name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', link: 'sprinter',
        wifi: true, interior: 'Fabric Black', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/sprinter/0.jpg' , 'images/sprinter/3.jpg', 'images/sprinter/5.jpg'],
        options : ['CellPhone', 'Icebox'],
        rateFrom: '168', passengers: '15', bags: '10', id: 5, categoryId: 2},
      {name : 'SUV', image: 'prado', link: 'luxury-suv',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
        options : ['ChildSeat', 'Icebox'],
        rateFrom: '168', passengers: '5', bags: '3', id: 6, categoryId: 4},
      {name : 'Standard Minivan', image: 'hyundai_starex_white', link: 'minivan-vip',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/starex/0.jpg' , 'images/starex/1.jpg'],
        options : ['ChildSeat', 'Icebox'],
        rateFrom: '147', passengers: '9', bags: '2', id: 7, categoryId: 3},
      {name : 'Armored SUV', image: 'prado', link: 'armored',
        wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
        drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
        photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
        options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
        rateFrom: '280', passengers: '3', bags: '3', id: 8, categoryId: 7},
      {name : 'Mid-Size SUV', image: 'duster', link: 'public',
        wifi: true, interior: 'Fabric', fullyInsured: true,
        drinks: false, smoking: false, phoneCharger: true, meetAndGreet: false,
        options : ['ChildSeat'],
        rateFrom: '73', passengers: '3', bags: '3', id: 9, categoryId: 13},
  ];
  break;
  vehiclesInternational = [
    {name : 'Ford Fusion', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/fusion/1.jpg', 'images/fusion/2.jpg', 'images/fusion/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '96', passengers: '3', bags: '3', id: 1 , categoryId: 1},
    {name : 'Audi A6', image: 'Audi-A6-1', link: 'luxury-sedan-audi',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/audia6/8.jpg', 'images/audia6/9.jpg', 'images/audia6/10.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 2, categoryId: 2},
    {name : 'Mercedes E-Class', image: 'Mercedes-Eclass-2', link: 'luxury-sedan-mercedes',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/mercedeseclass/1.jpg', 'images/mercedeseclass/2.jpg', 'images/mercedeseclass/4.jpg', 'images/mercedeseclass/5.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Cadillac XTS', image: 'cadillac-xts', link: 'luxury-sedan-cadillac',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['ChildSeat'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Mercedes V-class', image: 'mercedes-viano', link: 'v-class',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/viano/0.jpg' , 'images/viano/1.jpg', 'images/viano/2.jpg', 'images/viano/3.jpg', 'images/viano/4.jpg',
        'images/viano/5.jpg', 'images/viano/6.jpg', 'images/viano/7.jpg', 'images/viano/8.jpg'],
      options : ['AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '150', passengers: '6', bags: '4', id: 4, categoryId: 18},
    {name : 'Mercedes Sprinter', image: 'mercedes-sprinter', link: 'sprinter',
      wifi: true, interior: 'Fabric Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/sprinter/0.jpg' , 'images/sprinter/3.jpg', 'images/sprinter/5.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '15', bags: '10', id: 5, categoryId: 8},
    {name : 'Ford Expedition', image: 'ford_expedition', link: 'luxury-suv',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '5', bags: '3', id: 6, categoryId: 4},
    {name : 'Lincoln Navigator', image: 'lincoln-navigator', link: 'luxury-suv',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '5', bags: '3', id: 6, categoryId: 4},
    {name : 'Mini Bus', image: 'minibus', link: 'minibus',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '180', passengers: '25', bags: '25', id: 10, categoryId: 26},
    {name : 'Mini Bus', image: 'coach', link: 'coach',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '220', passengers: '40', bags: '40', id: 11, categoryId: 28},
  ];
  vehiclesGlobal = [
    {name : 'Ford Fusion', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/fusion/1.jpg', 'images/fusion/2.jpg', 'images/fusion/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '96', passengers: '3', bags: '3', id: 1 , categoryId: 1},
    {name : 'Audi A6', image: 'Audi-A6-1', link: 'luxury-sedan-audi',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/audia6/8.jpg', 'images/audia6/9.jpg', 'images/audia6/10.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 2, categoryId: 2},
    {name : 'Mercedes E-Class', image: 'Mercedes-Eclass-2', link: 'luxury-sedan-mercedes',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/mercedeseclass/1.jpg', 'images/mercedeseclass/2.jpg', 'images/mercedeseclass/4.jpg', 'images/mercedeseclass/5.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Cadillac XTS', image: 'cadillac-xts', link: 'luxury-sedan-cadillac',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['ChildSeat'],
      rateFrom: '123', passengers: '3', bags: '3', id: 3, categoryId: 2},
    {name : 'Mercedes V-class', image: 'mercedes-viano', link: 'v-class',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/viano/0.jpg' , 'images/viano/1.jpg', 'images/viano/2.jpg', 'images/viano/3.jpg', 'images/viano/4.jpg',
        'images/viano/5.jpg', 'images/viano/6.jpg', 'images/viano/7.jpg', 'images/viano/8.jpg'],
      options : ['AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '150', passengers: '6', bags: '4', id: 4, categoryId: 18},
    {name : 'Mercedes Sprinter', image: 'mercedes-sprinter', link: 'sprinter',
      wifi: true, interior: 'Fabric Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/sprinter/0.jpg' , 'images/sprinter/3.jpg', 'images/sprinter/5.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '15', bags: '10', id: 5, categoryId: 8},
    {name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', link: 'sprinter',
      wifi: true, interior: 'Fabric Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '96', passengers: '15', bags: '10', id: 5, categoryId: 15},
    {name : 'Ford Expedition', image: 'ford_expedition', link: 'luxury-suv',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '5', bags: '3', id: 6, categoryId: 4},
    {name : 'Lincoln Navigator', image: 'lincoln-navigator', link: 'luxury-suv',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : [],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '138', passengers: '5', bags: '3', id: 6, categoryId: 4},
    {name : 'Standard Minivan', image: 'hyundai_starex_white', link: 'minivan-vip',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/starex/0.jpg' , 'images/starex/1.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '117', passengers: '9', bags: '2', id: 7, categoryId: 3},
    {name : 'Armored SUV', image: 'prado-2', link: 'armored',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/expedition/5.jpg' , 'images/expedition/0.jpg', 'images/expedition/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '250', passengers: '3', bags: '3', id: 8, categoryId: 7},
    {name : 'Mid-Size SUV', image: 'duster', link: 'public',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: false,
      rateFrom: '57', passengers: '3', bags: '3', id: 9, categoryId: 13},
    {name : 'Mini Bus', image: 'minibus', link: 'minibus',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '180', passengers: '25', bags: '25', id: 10, categoryId: 26},
    {name : 'Mini Bus', image: 'coach', link: 'coach',
      wifi: true, interior: 'Fabric', fullyInsured: true,
      drinks: false, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/bus/0.jpg' , 'images/bus/1.jpg', 'images/bus/3.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '220', passengers: '40', bags: '40', id: 11, categoryId: 28},
    {name : 'Ford Fusion', image: 'ford-fusion-2012', link: 'mid-size-sedan',
      wifi: true, interior: 'Leather Black', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/fusion/1.jpg', 'images/fusion/2.jpg', 'images/fusion/3.jpg'],
      options : ['ChildSeat' , 'AndroidTablet', 'CellPhone', 'Icebox'],
      rateFrom: '96', passengers: '3', bags: '3', id: 1 , categoryId: 9},
    {name : 'Standard Minivan', image: 'hyundai_starex_white', link: 'minivan-business',
      wifi: true, interior: 'Leather Black / Beige', fullyInsured: true,
      drinks: true, smoking: false, phoneCharger: true, meetAndGreet: true,
      photos : ['images/starex/0.jpg' , 'images/starex/1.jpg'],
      options : ['CellPhone', 'Icebox'],
      rateFrom: '117', passengers: '9', bags: '2', id: 7, categoryId: 12},
  ];
 */
