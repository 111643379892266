import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../services/news.service';
import {News} from '../../../models/business-objects';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit {
  newsList: News[] = [];
  constructor(private newsService: NewsService) { }

  async ngOnInit() {
    this.newsList = await this.newsService.getCurrentNews();
    this.newsList = this.newsList.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
  }

}
