import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MessageData} from '../../models/contact-form';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class MessageService {
  UrlAPI: string = environment.apiURL;
    constructor(private _http: HttpClient) { }
    sendMessage(body: MessageData ): Observable<any> {
      console.log (' MessageData in Service ');
      console.log (body);
      return this._http.post(this.UrlAPI + environment.URL_MAIL, body);
    }
}
