// src/app/chatbot/chatbot.component.ts

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  Renderer2, AfterViewInit, Inject, PLATFORM_ID
} from '@angular/core';
import { ChatbotService } from '../services/chatbot.service';
import {firstValueFrom, Subscription, timer} from 'rxjs';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../models/business-objects';
import {AuthService} from '../services/auth.service';
import {AiChatMessage} from '../../models/chat-objects';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  providers: [DatePipe]
})
export class ChatbotComponent implements OnInit, OnDestroy, AfterViewInit {
  isChatVisible: boolean = false;
  isMinimized: boolean = false;
  messages: AiChatMessage[] = [];
  userInput: string = '';
  isTyping: boolean = false;
  private visibilitySubscription: Subscription;
  // Position properties
  top: string = null; // Default top position in pixels
  left: string = null; // Default left position in pixels
  bottom:string = "20px";
  right: string = "20px";
  width: string = "600px";
  onBrowser = isPlatformBrowser(this.platformId);
  private pollingSubscription: Subscription | null = null;

  private user:User=null;
  constructor(private chatbotService: ChatbotService, private cdr: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: any,private datePipe: DatePipe,
              private translate: TranslateService, private auth : AuthService, private renderer : Renderer2, @Inject(DOCUMENT) private document: Document) {
    console.log('ChatbotComponent constructor');
    this.user = this.auth.currentUserValue;
  }

  ngOnInit(): void {
    console.log('ChatbotComponent ngOnInit');
    // Subscribe to visibility changes
    this.visibilitySubscription = this.chatbotService.visibility$.subscribe(
      async (visible) => {
        console.log('ChatbotComponent visibilitySubscription :', visible);
        this.isChatVisible = visible;
        if (visible) {
          console.log('ChatbotComponent visibilitySubscription  caso visible');
          const threadId = await this.chatbotService.getThreadId();
          this.loadPosition();
          if (threadId) {
            console.log('ChatbotComponent visibilitySubscription  con threadId :', threadId);
            const chatHistory = await this.chatbotService.fetchChatHistory(threadId);
            if (chatHistory && chatHistory.length > 0) {
              console.log('ChatbotComponent chatHistory  :', chatHistory);
              // Assuming chatHistory.chatHistory is an array of messages
              this.messages = chatHistory;
              this.scrollToBottom();
              this.cdr.detectChanges();
            } else {
              if(this.user) {
                console.log('ChatbotComponent sin chatHistory  se crea el primer mensaje con usuario:');
                firstValueFrom(this.translate.get('app.chat.intro-logged')).then(text => {
                  this.userInput = text + "  " + this.user.fullName + "  " + this.user.email + "  " + this.user.phone;
                  if (this.user.cliente != null) {
                    this.userInput = text + "  " + this.user.cliente.fullName + "\n" + this.user.cliente.email + "  " + this.user.cliente.celular;
                  } else {
                    this.userInput = text + "  " + this.user.fullName + "  " + this.user.email + " " + this.user.phone;
                  }

                  this.cdr.detectChanges();
                  console.log('this.userInput :' + this.userInput);
                }).then(() => {
                  console.log('sending message ...' );
                  this.sendMessage();
                  this.userInput ="";
                });
              } else {
                console.log('ChatbotComponent sin chatHistory  se crea el primer mensaje sin usuario:');
                console.log('sin usuario logueado :');
                firstValueFrom(this.translate.get('app.chat.intro-anonymous')).then(text => {
                  console.log('texto primer mensaje :' + text);
                  this.messages.push( {
                    sender: "bot",
                    message: text,
                    timestamp: new Date(),
                    type: 'text'
                  })
                  this.cdr.detectChanges();
                })
              }
            }
          } else {
            console.log('creando chat :');
            await this.chatbotService.startChat();
            this.messages = [];
            if (this.user) {
              console.log('con usuario logueado :');
              firstValueFrom(this.translate.get('app.chat.intro-logged')).then(text => {
                this.userInput = text + " " + this.user.fullName + " " + this.user.email+ " " + this.user.phone;
                if(this.user.cliente!= null) {
                  this.userInput = text + "  " + this.user.cliente.fullName + " " + this.user.cliente.email + " "+ this.user.cliente.celular;
                } else {
                  this.userInput = text + "  " + this.user.fullName + " " + this.user.email+ " " + this.user.phone;
                }
                console.log('this.userInput :' + this.userInput);
              }).then( () => {
                console.log('sending message ...' );
                this.sendMessage();
                this.userInput ="";
              });
            } else {
              console.log('sin usuario logueado :');
              firstValueFrom(this.translate.get('app.chat.intro-anonymous')).then(text => {
                console.log('texto primer mensaje :' + text);
                this.messages.push( {
                  sender: "bot",
                  message: text,
                  timestamp: new Date(),
                  type: 'text'
                })
                this.cdr.detectChanges();
              })
            }
          }
        } else  if (!visible) {
          console.log('ChatbotComponent visibilitySubscriptioncaso no visible');
          this.isMinimized = false; // Reset minimization when hiding
        }
      }
    );
  }

  ngAfterViewInit(): void {
    console.log('ChatbotComponent ngAfterViewInit');
    if (this.isMinimized) {
      this.loadPosition(); // Load position after view initializes
      this.scrollToBottom();
    }
  }

  ngOnDestroy(): void {
    console.log('ChatbotComponent ngOnDestroy');
    // Clean up the subscription
    if (this.visibilitySubscription) {
      this.visibilitySubscription.unsubscribe();
    }
    this.savePosition();
  }


  async sendMessage(): Promise<void> {
    const message = this.userInput.trim();
    if (message === '') return;

    // Add user message to chat
    this.messages.push({
      sender: 'user',
      message: message,
      timestamp: new Date(),
      type: 'text'
    });
    this.cdr.detectChanges();
    this.userInput = '';
    // Show typing indicator
    this.isTyping = true;
    this.scrollToBottom();
    try {
      const response : AiChatMessage[] = await this.chatbotService.sendMessage(message);
      console.debug('sendMessage response :', response);
      let lastResponse : AiChatMessage ;
      response.forEach( resp => {
        if (resp) {
          this.messages.push({
            ...resp,
            sender: 'bot',
            message: resp.message,
            timestamp: new Date(),
            type: 'text'
          });
          lastResponse = resp;
        } else {
          this.messages.push({
            sender: 'bot',
            message: 'Sorry, something went wrong. Please try again later.',
            timestamp: new Date(),
            type: 'text'
          });
        }
        this.chatbotService.setThreadId(resp.threadId);
      })

      // Hide typing indicator
      if (lastResponse && lastResponse.stillWriting) {
        const threadId = await this.chatbotService.getThreadId();
        console.log('Chatbot is still writing, starting polling for new responses:', threadId);
        this.startPolling(threadId);
      } else {
        this.isTyping = false;
      }
      this.cdr.detectChanges();
      this.scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
      this.messages.push({
        sender: 'bot',
        message: 'Sorry, something went wrong. Please try again later.',
        timestamp: new Date(),
        type: 'text'
      });
      this.scrollToBottom();
      this.isTyping = false;
    }
    this.cdr.detectChanges(); // Update UI immediately
  }

  private startPolling(threadId: string, maxAttempts: number = 10, intervalMs: number = 2000, timeoutMs: number = 20000): void {
    let attempts = 0;
    const startTime = Date.now();

    this.pollingSubscription = timer(0, intervalMs).subscribe(async () => {
      attempts++;
      if (attempts > maxAttempts || (Date.now() - startTime) > timeoutMs) {
        console.warn('Polling timed out or max attempts reached.');
        this.isTyping = false;
        this.pollingSubscription?.unsubscribe();
        this.cdr.detectChanges();
        return;
      }

      try {
        const chatHistory :AiChatMessage[] = await this.chatbotService.fetchChatHistory(threadId);
        if (chatHistory && chatHistory.length > 0) {
          console.log('ChatHistory fetched:', chatHistory);
          // Update messages with new chat history
          this.messages = chatHistory;
          this.scrollToBottom();
          this.cdr.detectChanges();

          // Check if the bot is still writing
          const lastMsg = chatHistory[chatHistory.length - 1];
          if (!lastMsg.stillWriting) {
            this.isTyping = false;
            this.pollingSubscription?.unsubscribe();
          }
        }
      } catch (error) {
        console.error('Error fetching chat history during polling:', error);
        // Optionally, you can decide to stop polling on certain errors
        this.isTyping = false;
        this.pollingSubscription?.unsubscribe();
      }
    });
  }



  /**
   * Toggle chat visibility (minimize/maximize).
   */
  toggleChat(): void {
    this.isMinimized = !this.isMinimized;
    if (this.isMinimized && !this.isChatVisible) {
      this.isChatVisible = true;
      this.loadPosition();
    }
    const messagesElement = this.document.getElementById("#messageContainer");
    if (messagesElement) {
      if(this.isChatVisible) {
        this.width = messagesElement.style.width;
        messagesElement.style.width = '60px';
      } else {
        messagesElement.style.width = this.width;
      }
    } else {
      if(this.isChatVisible) {
        this.width = messagesElement.style.width;
      }
    }

    if (!this.isMinimized) {
      // Delay loadPosition to ensure the element is visible and rendered
      setTimeout(() => {
        this.loadPosition();
      }, 0);
    }
  }


  /**
   * Scroll the messages container to the bottom.
   */
  scrollToBottom(): void {
    console.debug('ChatbotComponent scrollToBottom');
    if (!this.onBrowser) {
      // Do not attempt to save position during SSR
      return;
    }
    const messagesElement = this.document.getElementById("messageContainer");
    if (messagesElement) {
      this.width = messagesElement.style.width;
    }

    setTimeout(() => {
      if (messagesElement) {
        messagesElement.scrollTop = messagesElement.scrollHeight;
        console.debug('ChatbotComponent messagesElement scrollToBottom hecho !');
      } else {
        console.error('messagesElement no existe !');
      }
    }, 100);
  }

  /**
   * Auto-resize the textarea based on content.
   */
  autoResize(event: any): void {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  closeChat(): void {
    this.isChatVisible = false;
    this.isMinimized = false;
    this.width ="60%";
  }
// Handle position changes emitted by the directive
  onPositionChanged(position: { top: number; left: number }): void {
    if (!this.onBrowser) {
      // Do not attempt to position during SSR
      console.debug('ChatbotComponent onPositionChanged : Do not attempt to position during SSR');
      return;
    }
    //console.debug('ChatbotComponent onPositionChanged : ',position.top,position.left);
    if (!isNaN(position.top) && !isNaN(position.left) && ( (position.top+position.left) >1 )) {
      localStorage.setItem('chatbotTop', position.top.toString());
      localStorage.setItem('chatbotLeft', position.left.toString());
    }
  }

  // Load position from localStorage
  loadPosition(): void {
    console.debug('ChatbotComponent loadPosition : inicio');
    if (!this.onBrowser) {
      // Do not attempt to position during SSR
      console.debug('ChatbotComponent loadPosition : Do not attempt to position during SSR');
      return;
    }


    const messagesElement = this.document.getElementById("messageContainer");
    if(this.width && messagesElement) {
      messagesElement.style.width = this.width;
    }

    const savedTop = localStorage.getItem('chatbotTop');
    const savedLeft = localStorage.getItem('chatbotLeft');
    console.debug('savedLeft / savedTop',savedLeft ," / ",savedLeft);
    if (savedTop && savedLeft) {
      console.debug('ChatbotComponent loadPosition : hay datos guardados');
      let top = parseInt(savedTop, 10);
      let left = parseInt(savedLeft, 10);

      // Validate parsed positions
      if (isNaN(top) || isNaN(left)) {
        console.debug('ChatbotComponent Invalid chatbotTop or chatbotLeft in localStorage. Falling back to default position. ' , top, left);
        this.setDefaultPosition();
        return;
      }
      console.debug('ChatbotComponent vamos a cambiar los estilos a top y left : ' , top, left);
      // Remove bottom and right styles to avoid conflict
      this.bottom = null;
      this.right = null;
      // Set top and left using Renderer2
      this.top= (top+'px');
      this.left= (left+'px');
      this.cdr.detectChanges();
      console.log('ChatbotComponent - estilos cambiado - Loaded Position:', { top, left });
    } else {
      // No saved positions; set default position
      console.debug('ChatbotComponent loadPosition : No saved positions; set default position');
      this.setDefaultPosition();
    }

    // Update UI immediately
    this.cdr.detectChanges();
  }



  private setDefaultPosition(): void {
    // Remove any existing top/left styles
    console.log('ChatbotComponent - setDefaultPosition');
    // Set bottom and right for default positioning
    this.bottom = '20px';
    this.right = '20px';
    // Set top and left using Renderer2
    this.top= null;
    this.left= null;
    console.debug('ChatbotComponent se quitaron los top y left ');
    this.cdr.detectChanges();
    console.log('ChatbotComponent - Positioned at bottom right with default padding : 20px');
  }

  savePosition(): void {
    console.log('ChatbotComponent - savePosition:');
    if (!this.onBrowser) {
      // Do not attempt to save position during SSR
      return;
    }

    const element = this.document.querySelector('#chatbotOverlay');
    // Get the bounding rectangle of the #chatbotOverlay element
    if (element) {
      const rect = element.getBoundingClientRect();


    // Calculate position relative to the document
    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;
    console.log('ChatbotComponent - savePosition:',top,left);
    // Validate positions to ensure they are numbers
    if (isNaN(top) || isNaN(left) || top+left < 1) {
      console.warn('ChatbotComponent - Invalid position values. Position not saved.');
      return;
    }
    // Save positions to localStorage
    localStorage.setItem('chatbotTop', top.toString());
    localStorage.setItem('chatbotLeft', left.toString());
    console.log('ChatbotComponent - Position Saved:', { top, left });
    }
  }

  formatMessageDate(msg : AiChatMessage) {
    if (msg.timestamp.getDate() < new Date().getDate()) {
      return this.datePipe.transform(msg.timestamp, 'medium') || ''
    } else {
      return this.datePipe.transform(msg.timestamp, 'shortTime') || ''
    }
  }

  isLastMessage(msg: AiChatMessage) {
    return this.messages[this.messages.length - 1] == msg;
  }
}
