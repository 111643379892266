import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import {
  CalendarJob,
  CheckList,
  Cliente,
  HojaMision,
  JobEvent,
  JobStatus,
  RelacionEstado,
  Service,
  User,
  Vehicle
} from '../../models/business-objects';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';

@Injectable()
export class JobService implements  OnDestroy{
  UrlAPI: string = environment.apiURL;
  public currentUser: User;
  subscriptions = [];
  constructor(private http: HttpClient, private authService: AuthService) {
    this.subscriptions.push(authService.currentUser.subscribe( user => this.currentUser = user));
  }

  getJobs(diasAtras:string, diasAdelante: string, maxReservas: string,minReservas: string,soloConfirmadas: boolean,quitarHechas: boolean): Promise<[Service]> {
    console.log('getting jobs for :');
    console.log(this.currentUser);
    const formatoFecha = 'yyyy/MM/dd';
    if ( this.currentUser.roldb != null && (this.currentUser.roldb === 'dispatch') || this.currentUser.roldb === 'gerencia')  {
      const idpersonal = this.currentUser.idconductor;
      return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_RESERVASDEDISPATCH, { idpersonal, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
    } else {
      if (this.currentUser.proveedorId != null ){
        const proveedorId = this.currentUser.proveedorId;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_RESERVASDEPROVEEDOR, {proveedorId, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      } else if (this.currentUser.cliente != null && this.currentUser.cliente.id != null){
        const clienteId = this.currentUser.cliente.id;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_RESERVASDECLIENTE, {clienteId, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      } else  {
        const idpersonal = this.currentUser.id;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_RESERVASDEPERSONAL, {idpersonal, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      }
    }
  }
  getCalendars(timeZone: string,diasAtras:string, diasAdelante: string, maxReservas: string,minReservas: string,soloConfirmadas: boolean,quitarHechas: boolean): Promise<[CalendarJob]> {
    console.log('getting calendar jobs for :');
    console.log(this.currentUser);
    const formatoFecha = 'yyyy/MM/dd';
    if ( this.currentUser.roldb != null && (this.currentUser.roldb === 'dispatch') || this.currentUser.roldb === 'gerencia')  {
      const idpersonal = this.currentUser.idconductor;
      return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CALENDARDEDISPATCH, { idpersonal , timeZone , diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
    } else {
      if (this.currentUser.proveedorId != null ){
        const proveedorId = this.currentUser.proveedorId;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CALENDARDEPROVEEDOR, {proveedorId , timeZone, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      } else if (this.currentUser.cliente != null && this.currentUser.cliente.id != null){
        const clienteId = this.currentUser.cliente.id;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CALENDARDECLIENTE, {clienteId , timeZone, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      } else  {
        const idpersonal = this.currentUser.id;
        return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_CALENDARDEPERSONAL, {idpersonal , timeZone, diasAtras, diasAdelante, maxReservas,minReservas,soloConfirmadas,quitarHechas,formatoFecha}));
      }
    }
  }
  getReservaPorJwt(jwt: string): Promise<any> { // devuelve un objeto con Job y User
    const urlFinal = environment.URL_RESERVAS_POR_JWT;
    let headers = new HttpHeaders();
    const body = { jwt };
    console.log('Reserva solicitada con jwt ' + jwt);
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }

  getReservasPorID(idReserva: string): Promise<Service[]> {
    const urlFinal = environment.URL_RESERVAID;
    let headers = new HttpHeaders();
    const body = { id: idReserva, idconductor: null };
    if ( this.currentUser.idconductor) {
      body.idconductor = this.currentUser.idconductor;
    }
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom( this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  getReservasPorFacturar(): Promise<Service[]> {
    const urlFinal = environment.URL_RESERVAS_POR_FACTURAR;
    let headers = new HttpHeaders();
    const formatoFecha = 'yyyy/MM/dd';
    const body = { formatoFecha };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom( this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }
  emmitSOS(mensaje: string): Promise<any> {
    const urlFinal = environment.URL_SOS;
    const body = { mensaje, idconductor: null };
    if ( this.currentUser.idconductor) {
      body.idconductor = this.currentUser.idconductor;
    }
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body));
  }

  getStatuses(): Promise<RelacionEstado[]>{
    const urlFinal = environment.URL_RELACIONESTADO;
    let headers = new HttpHeaders();
    const relacionEstados: RelacionEstado[]  = [];
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.get<any>(this.UrlAPI + urlFinal, {headers})).then( data => {
      const estados: any[] = data;
      for ( const object of  estados) {
        const padre: JobStatus = {
          id: object.idpadre,
          nombre: object.nombrepadre, descripcion: '',
          activo: true,
          inicial: object.inicialpadre,
          soloConduccion: false
        };
        const hijo: JobStatus = {
          id: object.idhijo,
          nombre: object.nombrehijo, descripcion: '',
          activo: true,
          inicial: object.inicialpadre,
          soloConduccion: false
        };
        relacionEstados.push({ id: object.id, hijo, padre});
      }
      return relacionEstados;
    });
  }
  getStatusesByType(tipoServicio: string): Promise<RelacionEstado[]> {
    const urlFinal = environment.URL_RELACIONESTADO;
    let headers = new HttpHeaders();
    const body = {tipoServicio};
    const relacionEstados: RelacionEstado[]  = [];
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then(data => {
      const estados: any[] = data;
      for ( const object of  estados) {
        const padre: JobStatus = {
          id: object.idpadre,
          nombre: object.nombrepadre, descripcion: '',
          activo: true,
          inicial: object.inicialpadre,
          soloConduccion: false
        };
        const hijo: JobStatus = {
          id: object.idhijo,
          nombre: object.nombrehijo, descripcion: '',
          activo: true,
          inicial: object.inicialpadre,
          soloConduccion: false
        };
        relacionEstados.push({ id: object.id, hijo, padre});
      }
      return relacionEstados;
    });
  }
  getFlightInfo(jobId: string): Promise<string>{
    const urlFinal = environment.URL_VUELO;
    let headers = new HttpHeaders();
    const body = { id: jobId };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      console.log(data);
      return data;
    });
  }
  getStatusList(): Promise<JobStatus[]>{
    const urlFinal = environment.URL_LISTAESTADOS;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.get<any>(this.UrlAPI + urlFinal,  {headers})).then( data => {
      return data;
    });
  }
  getJobStatuses(idreserva: number): Promise<JobStatus[]>{
    const urlFinal = environment.URL_RESERVASEGUIMIENTOESTADO_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      return data;
    });
  }
  getJobPax(idreserva: number, jwt?: string): Promise<Cliente[]>{
    const urlFinal = environment.URL_PASAJEROS_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva, jwt };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      const items: any[] = data;
      const pasajeros: Cliente[] = [];
      for (const item of items){
        const pasajero: Cliente = new Cliente();
        pasajero.celular = item.celularcliente;
        pasajero.phone = item.telefonocliente;
        pasajero.fullName = item.nombrecliente;
        pasajero.id = item.idcliente;
        pasajeros.push(pasajero);
      }
      console.log(data);
      return pasajeros;
    });
  }

  getVoucher(idreserva: number): Promise<any> {
    const urlFinal = environment.URL_VOUCHER;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { idreserva };
    return firstValueFrom(this.http.post <Blob> (this.UrlAPI + urlFinal, body, { headers, responseType: 'json' }));
  }

  getJobPrice(idreserva: number): Promise<string>{
    const urlFinal = environment.URL_DF_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      console.log(data);
      return data;
    });
  }
  getJobMissionSheet(idreserva: number, jwt?: string): Promise<HojaMision>{
    const urlFinal = environment.URL_HOJA_MISION_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva , jwt};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      return data;
    });
  }

  getJobMissionCheckList(idreserva: number, jwt?: string): Promise<CheckList>{
    const urlFinal = environment.URL_CHECK_LIST_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva, jwt};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      console.log(data);
      return data;
    });
  }

  getVehicleByPlate(placa: string): Promise<Vehicle>{
    const urlFinal = environment.URL_VEHICULO_PLACA;
    let headers = new HttpHeaders();
    const body = { placa };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      console.log(data);
      return data;
    });
  }
  getVehicleforDispatch(placa: string): Promise<Vehicle[]>{
    const urlFinal = environment.URL_VEHICULO_DISPATCH;
    let headers = new HttpHeaders();
    const body = { placa };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers})).then( data => {
      console.log(data);
      return data;
    });
  }

  getVehicleforSupplier(placa: string, proveedorId: string): Promise<Vehicle[]>{
    const urlFinal = environment.URL_VEHICULO_PROVEEDOR;
    let headers = new HttpHeaders();
    const body = { placa, proveedorId };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }

  getRutaPorReserva(idreserva: number, tipoPersonal: string, idPersonal: string, jwt: string): Promise<JobEvent[]>{
    const urlFinal = environment.URL_RUTA_RESERVA;
    let headers = new HttpHeaders();
    const body = { idreserva, tipoPersonal, idPersonal, jwt };
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + urlFinal, body, {headers}));
  }

  saveStatus(service: Service, jobEvent: JobEvent, jwt: string){
    const urlFinal = environment.URL_RESERVASEGUIMIENTOESTADO;
    let headers = new HttpHeaders();
    const body = { idreserva: service.id, idestado: jobEvent.estadoId, latitud: jobEvent.lat, longitud: jobEvent.lng , fecha: jobEvent.date,
      idtercero: jobEvent.personalId , tipo: jobEvent.tipo, comentario: jobEvent.comentario , jwt };
    headers = headers.set('Accept', 'application/json');
    return this.http.post<any>(this.UrlAPI + urlFinal, body, {headers});
  }
  saveStatusFromSMS(jwt: string, lat: number, lon: number): Observable<string>{
    const urlFinal = environment.URL_RESERVASEGUIMIENTOESTADOSMS;
    let headers = new HttpHeaders();
    const body = { };
    const params = new HttpParams()
      .append('jwt', jwt)
      .append('lat', lat)
      .append('lon', lon);
    headers = headers.set('Accept', 'application/json');
    return this.http.post<any>(this.UrlAPI + urlFinal, body, {headers, responseType: 'text' as 'json', params});
  }
  saveLastLocation(service: Service,  jobEvent: JobEvent, jwt: string){
    const urlFinal = environment.URL_RESERVAULTIMAUBICACION;
    let headers = new HttpHeaders();
    const body = { idreserva: service.id, idestado: jobEvent.estadoId, lat : jobEvent.lat, lng: jobEvent.lng , fecha: jobEvent.date,
      idtercero: jobEvent.personalId , tipo: jobEvent.tipo, comentario: jobEvent.comentario, jwt };
    headers = headers.set('Accept', 'application/json');
    return this.http.post<any>(this.UrlAPI + urlFinal, body, {headers});
  }

  getLetrero(idreserva: number, jwt: string): Promise<any> {
    const urlFinal = environment.URL_LETRERO;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { idreserva, jwt };
    return firstValueFrom(this.http.post <Blob> (this.UrlAPI + urlFinal, body, { headers, responseType: 'json' }));
  }

  getExcel(idreservas: number[], jwt: string, conCargos : boolean): Promise<any> {
    const urlFinal = environment.URL_Excel;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { idreservas, jwt,conCargos };
    return firstValueFrom(this.http.post <Blob> (this.UrlAPI + urlFinal, body, { headers, responseType: 'json' }));
  }
  getRolReserva( job: Service, loggedUser: User ): string {
    let rolReserva: string;
    const rolUsuario = loggedUser.roldb;
    if (job.idcliente && loggedUser.cliente &&  job.idcliente == loggedUser.cliente.id)  { // primero eliminamos el caso donde el usuario conectado es un cliente
      rolReserva = 'cliente';
    } else { // aca no se trata de un cliente
      if (loggedUser.idconductor && loggedUser.idconductor > 0  && job.conductorId
        && job.conductorId === loggedUser.idconductor.toString() ) {
        // el primner caso es si se trata de un conductor
        rolReserva = 'conductor';
      } else {
        // aca sabemos que el usuario conectado no es el conductor de este servicio
        if ((loggedUser.idconductor && loggedUser.idconductor > 0 && job.azafataId
          && job.azafataId === job.conductorId)) {
          // aca es un protocolo
          rolReserva = 'protocolo';
        } else { // aca el usuario no es ni el conductor ni el protocolo
          if ( rolUsuario === 'dispatch' || rolUsuario === 'gerencia' || loggedUser.proveedorId ) {
            rolReserva = 'operation';
          } else {
            rolReserva = 'tercero'; // no deberiamos entrar aca... porque el caso proveedor se taguea como operation
            console.log('ERROR, no se pudo identificar el role del usuario para esta reserva ' + job.id);
          }
        }
      }
    }
    return rolReserva;
  }
  // CUIDADO ACA ESTOY INTENTANDO PASAR TimeStamps como BigInt, no se que va a dar...
  saveMissionSheet(hojaMision: HojaMision) {
    const urlFinal = environment.URL_HOJA_MISION;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = {...hojaMision};
    return this.http.post<any>(this.UrlAPI + urlFinal, body, { headers });
  }

  // CUIDADO ACA ESTOY INTENTANDO PASAR TimeStamps como BigInt, no se que va a dar...
  saveCheckList(checkList: CheckList) {
    const urlFinal = environment.URL_CHECK_LIST;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { ...checkList};
    return this.http.post<any>(this.UrlAPI + urlFinal, body, { headers });
  }

  // CUIDADO ACA ESTOY INTENTANDO PASAR TimeStamps como BigInt, no se que va a dar...
  createCheckListVehiculo(idVehiculo: number) {
    const urlFinal = environment.URL_CHECK_LIST_CREATE_VEHICULO;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { idVehiculo};
    return this.http.post<any>(this.UrlAPI + urlFinal, body, { headers });
  }

  updateCheckList(checkList: CheckList) {
    const urlFinal = environment.URL_CHECK_LIST;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = { ...checkList};
    return this.http.put<any>(this.UrlAPI + urlFinal, body, { headers });
  }

  updateMissionSheet(hojaMision: HojaMision) {
    const urlFinal = environment.URL_HOJA_MISION;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    const body = {...hojaMision};
    return this.http.put<any>(this.UrlAPI + urlFinal, body, { headers });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
