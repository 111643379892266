import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Testimonial} from '../../models/business-objects';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom} from 'rxjs';

@Injectable()
export class TestimonialsService {
  testimonials: Array<Testimonial> = [];
  private  readyP: Promise<boolean>;
  constructor(private http: HttpClient) {
    this.currentTestimonialsSubject = new BehaviorSubject<Testimonial[]>(null);
    this.readyP = this.initializeLists();
  }
  private currentTestimonialsSubject: BehaviorSubject<Testimonial[]> = Testimonial[0];
  UrlAPI: string = environment.apiURL;
  fixedData = [
    {lang: 'en' ,  author: 'Jamil  U. - Textil Company' , datePublished: '2018-10-04', date: ' - October 2018', rating: 5,
      title: 'Thank you very much', id: 28,
      reviewBody: 'Espero este bien. El propósito de mi correo es expresar nuestra completa satisfacción con el servicio que ustedes nos brindaron:  ¡¡Eficiente, personal, profesional!!  Desde la cotización, la confirmación de la reserva, la comunicación contigo, el conductor.  ¡¡Todo el proceso…Excelente!! Seguro que los tendremos en mente para un futuro cercano. Y teniendo la oportunidad, no duden que los recomendaremos.  Tú fuiste muy profesional y sentí que la comunicación contigo fue excelente. Nuestro conductor J. , muy profesional, puntual y muy conocedor del área de Medellín.  Su nivel de servicio fue también excelente desde que paso por nosotros al aeropuerto a nuestra llegada hasta que nos dejó en el aeropuerto para nuestra partida.  Para la próxima oportunidad nos gustaría solicitarlo nuevamente. Un cliente muy satisfecho.'},
    {lang: 'en' ,  author: 'Gloria C.  - YPO Group' , datePublished: '2018-06-13', date: ' - June 2018', rating: 5,
      title: 'FELICITATIONS', id: 27,
      reviewBody: 'Se me habia hecho tarde en agradecerte y felicitarte por tus servicios con el grupo YPO. Es un placer trabajar con ustedes, la organizacion, cumplimiento y seriedad de su trabajo.En los años que llevo en turismo, que no son pocos, V & V es la compañia, que le da a uno tranquilidad y seguridad.Nuevamente mil gracias y muchas felicitaciones'},
    {lang: 'en' ,  author: 'Nills. R  - Car Service Affiliate' , datePublished: '2016-09-11', date: ' - September 2016', rating: 5,
      title: 'EXCELLENT', id: 26,
      reviewBody: 'El Sr. R. quedo muy complacido y agradecido de los servicios que le ofrecieron, sus palabras textuales fueron que son \'EXELENTES\' agradesco mucho su servicio y dedicacion.<br /> Ellos regresan el mes entrante a Bogota y ya me solicitaron los servicios de ustedes, quedaron muy complacidos con el chofer.'},
    {lang: 'en' ,  author: 'Rodi B.  - Financial Services' , datePublished: '2016-06-06', date: ' - June 2016', rating: 5,
      title: 'Your team has provided us with excellent service', id: 25,
      reviewBody: 'This week will probably be the last week that we are down here.  Your team has provided us with excellent service, particularly R. who has been terrific.  If V&V ever needs a reference to another firm considering your services, we will be more than happy to provide.Please let me know when the final invoices are ready for this week.<br />Thanks again!<br />Rodi<br />'},
    {lang: 'en' ,  author: 'Russell T. (Oracle) - Cartagena' , datePublished: '2015-09-09', date: ' - September 2015', rating: 5,
      title: 'The best', id: 24,
      reviewBody: 'This team in Cartagena is the best I have encountered so far using [Car Service worldwide]. Reliable transportation when I need it the most, and flexible to make changes based on my daily travels.<br />'},
    {lang: 'en' ,  author: 'Mrs Renata B. - Insurance Company' , datePublished: '2015-08-31', date: ' - August 2015', rating: 5,
      title: 'Thank you very much', id: 23,
      reviewBody: 'Thank you very much, <br /> The service it was excellent!!! <br /> Kind regards.<br />'},
    {lang: 'en' ,  author: 'Miro Z. - Luxury tourism' , datePublished: '2014-12-17', date: '- December 2014', rating: 5,
      title: 'Your drivers were perfect', id: 22,
      reviewBody: 'Both of your drivers were perfect for my bogota visit <br /> Very nice and professional. Both were very knowledgable and willing to assist <br />Thank you again. Job well done<br /> Regards.<br />'},
    {lang: 'en' ,  author: 'Russel G. - Executive Protection Specialists' , datePublished: '2014-11-05', date: '- November 2014', rating: 5,
      title: 'Please extend a thank you to your team', id: 21,
      reviewBody: 'Please extend a thank you to your team: H. and F. and the drivers: L. , E. , F. , and J. who helped with the meet and greet at the airport.<br />All were very friendly, helpful, and professional at all times, and a credit to your company.<br />Please tell Mr Vincent that I will definitely look to use your services on any return to Bogota, or any other area of Colombia<br />'},
    {lang: 'en' ,  author: 'Lorena V. - Event and Meeting Company' , datePublished: '2014-07-07', date: 'July 2014', rating: 5,
      title: 'Everything went perfect', id: 20,
      reviewBody: 'Hola buenas tardes<br /> De antemano mil gracias por toda su colaboración para el evento que se llevo a cabo este fin de semana, todo salio a la perfección. A continuación les comparto los agradecimientos que me envió el cliente. Saludos y mil gracias!!<br /><br />Hola Lorena,<br /> Queremos agradecerte a ti y a todo el equipo de [la agencia] por el excelente trabajo realizado con el transporte y la logística de los inversionistas de India. Sabemos que fue un proyecto con poco tiempo de anticipación pero el compromiso y profesionalismo [] permitió que todo saliera muy bien. Esperamos que podamos continuar con esta alianza para futuros proyectos.<br /> [] Quedamos atentos.<br /> Alex J.'},
    {lang: 'en' ,  author: 'Marisol V. - Financial services company' , datePublished: '2014-03-20', date: ' - March 2014', rating: 5,
      title: 'In the name of our team, many thanks!', id: 19,
      reviewBody: 'Les escribo únicamente para agradecerles por todas las facilidades proporcionadas la semana pasada durante nuestro [Road Show], mismo que sin duda alguna, no habría sido posible sin su excelente coordinación y servicio.<br />    Por favor tengan por seguro que continuaremos trabajando de ahora en más con ustedes, todo el equipo  les agradecemos mucho por todas las atenciones proporcionadas y la capacidad de pronta respuesta que manejaron para este viaje.    Nuevamente muchas gracias y seguimos en contacto para futuras agendas.'},
    {lang: 'en' ,  author: 'Stibaliz H.' , datePublished: '2014-01-15', date: ' - January 2014', rating: 5,
      title: 'Excelente', id: 18,
      reviewBody: 'Muy agradecida por el servicio demasiado satisfecha excelente muchas gracias'},
    {lang: 'en' ,  author: 'Lionel. G - Security Company' , datePublished: '2013-11-06', date: ' - November 2013', rating: 5,
      title: 'Perfect service', id: 17,
      reviewBody: 'La calidad del servicio fue ejemplar. Les transmito mis agradecimientos y les pido transmitirlos a los conductores y al agente de meet & greet.'},
    {lang: 'en' ,  author: 'Jhon Henry. P - Security Company' , datePublished: '2013-09-10', date: ' - September 2013', rating: 5,
      title: 'Very good service', id: 16,
      reviewBody: 'Buenas tardes, muchas gracias por sus servicios y creame que en el momento que necesitemos en Bogota la contactaremos en verdad nos parecio muy bueno el servicio que nos prestaron'},
    {lang: 'en' ,  author: 'Liz L. - Colombia' , datePublished: '2013-08-11', date: ' - August 2013', rating: 5,
      title: 'Thank you very much', id: 15,
      reviewBody: 'Muchisimas gracias por el servicio muy satisfecha.<br/>'},
    {lang: 'en' ,  author: 'Diego R. - Colombia' , datePublished: '2013-08-15', date: ' - August 2013', rating: 5,
      title: 'Excellent as always', id: 14,
      reviewBody: ' informarle y reiterarle que el servicio estuvo excelente como siempre. Estamos en contacto para futuros servicios, muchas gracias.<br />'},
    {lang: 'en' ,  author: '8 luxury tourism clients - Colombia' , datePublished: '2013-07-10', date: ' - July 2013', rating: 5,
      title: 'I am thankful for the quality of service.', id: 13,
      reviewBody: 'Para informarle que el servicio fue excelente, el carro sobrio y elegante, el personal (J.) de primera y ni hablar de la puntualidad. Agradezco la calidad del servicio.<br />'},
    {lang: 'en' ,  author: 'Michael C. - Global Oil and Gas Manager' , datePublished: '2013-06-24', date: ' - June 2013', rating: 5,
      title: 'Thank you so much', id: 12,
      reviewBody: '    Thank you so much, <br />We had a great time and everyone was very professional and nice.<br /> Thank you very very much!!<br />'},
    {lang: 'en' ,  author: 'Johnny Hansel R. - Colombia - Banca de Inversion' , datePublished: '2013-05-10', date: ' - May 2013', rating: 5,
      title: 'Thank you for the good care you took of my client', id: 11,
      reviewBody: ' gracias [J.C.] por la atención prestada y por el buen trato al cliente. Los tendré presente para próximas oportunidades. <br />'},
    {lang: 'en' ,  author: 'Lauren Y.  - USA' , datePublished: '2013-02-22', date: ' - February 2013', rating: 4,
      title: 'Large event with over 200 passengers', id: 10,
      reviewBody: 'I would like to say thank you very much for the discounts and concessions you provided us in order to cut our overall event spend. Your drivers [...] were fantastic and provided wonderful service with a smile. All of our Executives and VIP distributors had a lovely experience with them and for that I want to express my gratitude.<br />Additionally, I would like to applaud [your Operation manager] who was an absolute super star!  [He] and the drivers did an amazing job and I would like to recognize each of the main drivers with a $100 usd tip per person. Is it possible to add this gratuity to the final bill?  [....] <br />I would like to thank you as well for the time and efforts you put in to our program in order to make our event transportation a success!<br /> Many thanks,<br />'},
    {lang: 'en' ,  author: 'Mr Rod C.  - USA' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Thanks for the professional service', id: 9,
      reviewBody: 'Thank you for our car in Cartagena. I wanted to assure you that both the driver and guide were very professional in every way.  [...] Thanks for the professional service.'},
    {lang: 'en' ,  author: 'Sra Claudia B. - Security services' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Culminaron con éxito', id: 8,
      reviewBody: 'Buen día,<br/>Quiero manifestar a ustedes nuestro agradecimiento por el apoyo y colaboración en los servicios prestados los 17 al 19 de abril, los culminaron con éxito.<br/>Un saludo.'},
    {lang: 'en' ,  author: 'Mr Ivan R - Salt Lake City - USA' , datePublished: '2011-08-01', date: ' - August 2011', rating: 5,
      title: 'Excellent service', id: 7,
      reviewBody: 'Excellent service. O. was a very professional driver and just provided an amazing service. It\'s great to see I can find this service now in Bogota. Keep up the great work.'},
    {lang: 'en' ,  author: 'Mr Joo - Korea' , datePublished: '2011-07-01', date: ' - Julio 2011', rating: 5,
      title: 'Les agradezco mucho por su servicio brindado', id: 6,
      reviewBody: 'Les agradezco mucho por su servicio brindado en la ocasion de la visita de nuesto presidente y su delegacion. El presidente y su comitiva estan muy satisfechos por su gentil servicio y seguro que proxima visita tambien les pediremos su servicio.<br />Muchas gracias.<br /> Saludos Cordiales'},
    {lang: 'en' ,  author: 'Mrs L. C. - USA' , datePublished: '2011-05-01', date: ' - May 2011', rating: 5,
      title: 'I truly thank you and your staff', id: 5,
      reviewBody: 'E.,<br /> I truly thank you and your staff, without all of your hard work, dedication and patience this event would not have been possible!<br />    We look forward to working with you again.<br />    Take care,<br />'},
    {lang: 'en' ,  author: 'Mr Leo Q. - Venezuela' , datePublished: '2011-04-01', date: ' - April 2011', rating: 5,
      title: 'los voy a recomendar ampliamente', id: 4,
      reviewBody: ' Estimada J. <br />Sirva la presente para agradecerles el buen trato y la eficiencia que demostró todo su equipo, el Sr. E. , el Sr. O. quien nos acompaño, y usted, quienes siempre nos atendieron y dieron solución a nuestras inquietudes. De más esta decirle que los voy a recomendar ampliamente con mis conocidos para que utilicen los servicios de su empresa. Por mi parte será así cada vez que visite Bogota, lo cual será pronto. <br />'},
    {lang: 'en' ,  author: 'Mr Alberto H. - Panama' , datePublished: '2010-08-01', date: ' - August 2010', rating: 5,
      title: 'Great services', id: 3,
      reviewBody: 'Great services.  V&amp;V always goes to extra mile to accommodate our request (which sometimes are very demanding).  All the drivers are extraordinary and we are loyal clients!  Keep up the good work!  Also, we LOVE H.!'},
    {lang: 'en' ,  author: 'Mrs Ernst' , datePublished: '2010-04-01', date: ' - April 2010', rating: 4,
      title: 'Thank you so much!', id: 2,
      reviewBody: '    Dear Vincent, <br /> Thank you so much! <br /> Everything went well and the driver was a great help for me and assisted me everywhere!!<br /> I\'m sure that I will get back to you.<br /> Regards<br />'},
    {lang: 'en' ,  author: 'Mr Elon Jessen Kristensen' , datePublished: '2009-07-04', date: ' - July 2009', rating: 5,
      title: 'Finally there\'s a decent VIP service in Bogotá', id: 1,
      reviewBody: 'Finally there\'s a decent VIP service in Bogotá - keep up the good work! <br /> I for one will support it 100% !!!<br />'},
    {lang: 'es' ,  author: 'Jamil  U. - Textil Company' , datePublished: '2018-10-04', date: ' - Octubre 2018', rating: 5,
      title: 'MUCHAS GRACIAS', id: 28,
      reviewBody: 'Espero este bien. El propósito de mi correo es expresar nuestra completa satisfacción con el servicio que ustedes nos brindaron:  ¡¡Eficiente, personal, profesional!!  Desde la cotización, la confirmación de la reserva, la comunicación contigo, el conductor.  ¡¡Todo el proceso…Excelente!! Seguro que los tendremos en mente para un futuro cercano. Y teniendo la oportunidad, no duden que los recomendaremos.  Tú fuiste muy profesional y sentí que la comunicación contigo fue excelente. Nuestro conductor J. , muy profesional, puntual y muy conocedor del área de Medellín.  Su nivel de servicio fue también excelente desde que paso por nosotros al aeropuerto a nuestra llegada hasta que nos dejó en el aeropuerto para nuestra partida.  Para la próxima oportunidad nos gustaría solicitarlo nuevamente. Un cliente muy satisfecho.'},
    {lang: 'es' ,  author: 'Gloria C.  - YPO Group' , datePublished: '2018-06-13', date: ' - Junio 2018', rating: 5,
      title: 'FELICITATIONES', id: 27,
      reviewBody: 'Se me habia hecho tarde en agradecerte y felicitarte por tus servicios con el grupo YPO. Es un placer trabajar con ustedes, la organizacion, cumplimiento y seriedad de su trabajo.En los años que llevo en turismo, que no son pocos, V & V es la compañia, que le da a uno tranquilidad y seguridad.Nuevamente mil gracias y muchas felicitaciones'},
    {lang: 'es' ,  author: 'Nills. R  - Empresa Remises' , datePublished: '2016-09-11', date: ' - Septiembre 2016', rating: 5,
      title: 'EXCELENTE', id: 26,
      reviewBody: 'El Sr. R. quedo muy complacido y agradecido de los servicios que le ofrecieron, sus palabras textuales fueron que son \'EXELENTES\' agradesco mucho su servicio y dedicacion. <br />Ellos regresan el mes entrante a Bogota y ya me solicitaron los servicios de ustedes, quedaron muy complacidos con el chofer.'},
    {lang: 'es' ,  author: 'Rodi B.  - Servicios Financieros' , datePublished: '2016-06-06', date: ' - Junio 2016', rating: 5,
      title: 'SU EQUIPO NOS DÍO UN EXCELLENTE SERVICIO', id: 25,
      reviewBody: 'This week will probably be the last week that we are down here.  Your team has provided us with excellent service, particularly R. who has been terrific.  If V&V ever needs a reference to another firm considering your services, we will be more than happy to provide.Please let me know when the final invoices are ready for this week.<br />Thanks again!<br />Rodi<br />'},
    {lang: 'es' ,  author: 'Russell T. (Oracle) - Cartagena' , datePublished: '2015-09-09', date: ' - Septiembre 2015', rating: 5,
      title: 'LOS MEJORES', id: 24,
      reviewBody: 'This team in Cartagena is the best I have encountered so far using [Car Service worldwide]. Reliable transportation when I need it the most, and flexible to make changes based on my daily travels.<br />'},
    {lang: 'es' ,  author: 'Mrs Renata B. - Compania de Seguros' , datePublished: '2015-08-31', date: ' - Agosto 2015', rating: 5,
      title: 'Muchisimas gracias', id: 23,
      reviewBody: 'Thank you very much, The service it was excellent!!! Kind regards.<br />'},
    {lang: 'es' ,  author: 'Miro Z. - Luxury tourism' , datePublished: '2014-12-17', date: '- December 2014', rating: 5,
      title: 'SUS CHOFERES FUERON PERFECTOS', id: 22,
      reviewBody: 'Both of your drivers were perfect for my bogota visit <br /> Very nice and professional. Both were very knowledgable and willing to assist <br />Thank you again. Job well done<br /> Regards.<br />'},
    {lang: 'es' ,  author: 'Russel G. - Executive Protection Specialists' , datePublished: '2014-11-05', date: '- November 2014', rating: 5,
      title: 'Please extend a thank you to your team', id: 21,
      reviewBody: 'Please extend a thank you to your team: H. and F. and the drivers: L. , E. , F. , and J. who helped with the meet and greet at the airport.<br />All were very friendly, helpful, and professional at all times, and a credit to your company.<br />Please tell Mr Vincent that I will definitely look to use your services on any return to Bogota, or any other area of Colombia<br />'},
    {lang: 'es' ,  author: 'Lorena V. - Event and Meeting Company' , datePublished: '2014-07-07', date: 'July 2014', rating: 5,
      title: 'TODO NOS FUES PERFECTO', id: 20,
      reviewBody: 'Hola buenas tardes<br /> De antemano mil gracias por toda su colaboración para el evento que se llevo a cabo este fin de semana, todo salio a la perfección. A continuación les comparto los agradecimientos que me envió el cliente. Saludos y mil gracias!!<br /><br />Hola Lorena,<br /> Queremos agradecerte a ti y a todo el equipo de [la agencia] por el excelente trabajo realizado con el transporte y la logística de los inversionistas de India. Sabemos que fue un proyecto con poco tiempo de anticipación pero el compromiso y profesionalismo [] permitió que todo saliera muy bien. Esperamos que podamos continuar con esta alianza para futuros proyectos.<br /> [] Quedamos atentos.<br /> Alex J.'},
    {lang: 'es' ,  author: 'Marisol V. - Financial services company' , datePublished: '2014-03-20', date: ' - March 2014', rating: 5,
      title: 'In the name of our team, many thanks!', id: 19,
      reviewBody: 'Les escribo únicamente para agradecerles por todas las facilidades proporcionadas la semana pasada durante nuestro [Road Show], mismo que sin duda alguna, no habría sido posible sin su excelente coordinación y servicio.<br />    Por favor tengan por seguro que continuaremos trabajando de ahora en más con ustedes, todo el equipo  les agradecemos mucho por todas las atenciones proporcionadas y la capacidad de pronta respuesta que manejaron para este viaje.    Nuevamente muchas gracias y seguimos en contacto para futuras agendas.'},
    {lang: 'es' ,  author: 'Stibaliz H.' , datePublished: '2014-01-15', date: ' - January 2014', rating: 5,
      title: 'Excelente', id: 18,
      reviewBody: 'Muy agradecida por el servicio demasiado satisfecha excelente muchas gracias'},
    {lang: 'es' ,  author: 'Lionel. G - Security Company' , datePublished: '2013-11-06', date: ' - November 2013', rating: 5,
      title: 'SERVICIO PERFECTO', id: 17,
      reviewBody: 'La calidad del servicio fue ejemplar. Les transmito mis agradecimientos y les pido transmitirlos a los conductores y al agente de meet & greet.'},
    {lang: 'es' ,  author: 'Jhon Henry. P - Security Company' , datePublished: '2013-09-10', date: ' - September 2013', rating: 5,
      title: 'MUY BUEN SERVICIO', id: 16,
      reviewBody: 'Buenas tardes, muchas gracias por sus servicios y creame que en el momento que necesitemos en Bogota la contactaremos en verdad nos parecio muy bueno el servicio que nos prestaron'},
    {lang: 'es' ,  author: 'Liz L. - Colombia' , datePublished: '2013-08-11', date: ' - August 2013', rating: 5,
      title: 'MUCHISIMAS GRACIAS', id: 15,
      reviewBody: 'Muchisimas gracias por el servicio muy satisfecha.<br/>'},
    {lang: 'es' ,  author: 'Diego R. - Colombia' , datePublished: '2013-08-15', date: ' - August 2013', rating: 5,
      title: 'EXCELENTE COMO SIEMPRE', id: 14,
      reviewBody: ' informarle y reiterarle que el servicio estuvo excelente como siempre. Estamos en contacto para futuros servicios, muchas gracias.<br />'},
    {lang: 'es' ,  author: '8 luxury tourism clients - Colombia' , datePublished: '2013-07-10', date: ' - July 2013', rating: 5,
      title: 'Agradezco la calidad del servicio', id: 13,
      reviewBody: 'Para informarle que el servicio fue excelente, el carro sobrio y elegante, el personal (J.) de primera y ni hablar de la puntualidad. Agradezco la calidad del servicio.<br />'},
    {lang: 'es' ,  author: 'Michael C. - Global Oil and Gas Manager' , datePublished: '2013-06-24', date: ' - June 2013', rating: 5,
      title: 'Thank you so much', id: 12,
      reviewBody: '    Thank you so much, <br />We had a great time and everyone was very professional and nice.<br /> Thank you very very much!!<br />'},
    {lang: 'es' ,  author: 'Johnny Hansel R. - Colombia - Banca de Inversion' , datePublished: '2013-05-10', date: ' - May 2013', rating: 5,
      title: 'Thank you for the good care you took of my client', id: 11,
      reviewBody: ' gracias [J.C.] por la atención prestada y por el buen trato al cliente. Los tendré presente para próximas oportunidades. <br />'},
    {lang: 'es' ,  author: 'Lauren Y.  - USA' , datePublished: '2013-02-22', date: ' - February 2013', rating: 4,
      title: 'Large event with over 200 passengers', id: 10,
      reviewBody: 'I would like to say thank you very much for the discounts and concessions you provided us in order to cut our overall event spend. Your drivers [...] were fantastic and provided wonderful service with a smile. All of our Executives and VIP distributors had a lovely experience with them and for that I want to express my gratitude.<br />Additionally, I would like to applaud [your Operation manager] who was an absolute super star!  [He] and the drivers did an amazing job and I would like to recognize each of the main drivers with a $100 usd tip per person. Is it possible to add this gratuity to the final bill?  [....] <br />I would like to thank you as well for the time and efforts you put in to our program in order to make our event transportation a success!<br /> Many thanks,<br />'},
    {lang: 'es' ,  author: 'Mr Rod C.  - USA' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Thanks for the professional service', id: 9,
      reviewBody: 'Thank you for our car in Cartagena. I wanted to assure you that both the driver and guide were very professional in every way.  [...] Thanks for the professional service.'},
    {lang: 'es' ,  author: 'Sra Claudia B. - Security services' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Culminaron con éxito', id: 8,
      reviewBody: 'Buen día,<br/>Quiero manifestar a ustedes nuestro agradecimiento por el apoyo y colaboración en los servicios prestados los 17 al 19 de abril, los culminaron con éxito.<br/>Un saludo.'},
    {lang: 'es' ,  author: 'Mr Ivan R - Salt Lake City - USA' , datePublished: '2011-08-01', date: ' - August 2011', rating: 5,
      title: 'Excellent service', id: 7,
      reviewBody: 'Excellent service. O. was a very professional driver and just provided an amazing service. It\'s great to see I can find this service now in Bogota. Keep up the great work.'},
    {lang: 'es' ,  author: 'Mr Joo - Korea' , datePublished: '2011-07-01', date: ' - Julio 2011', rating: 5,
      title: 'Les agradezco mucho por su servicio brindado', id: 6,
      reviewBody: 'Les agradezco mucho por su servicio brindado en la ocasion de la visita de nuesto presidente y su delegacion. El presidente y su comitiva estan muy satisfechos por su gentil servicio y seguro que proxima visita tambien les pediremos su servicio.<br />Muchas gracias.<br /> Saludos Cordiales'},
    {lang: 'es' ,  author: 'Mrs L. C. - USA' , datePublished: '2011-05-01', date: ' - May 2011', rating: 5,
      title: 'I truly thank you and your staff', id: 5,
      reviewBody: 'E.,<br /> I truly thank you and your staff, without all of your hard work, dedication and patience this event would not have been possible!<br />    We look forward to working with you again.<br />    Take care,<br />'},
    {lang: 'es' ,  author: 'Mr Leo Q. - Venezuela' , datePublished: '2011-04-01', date: ' - April 2011', rating: 5,
      title: 'los voy a recomendar ampliamente', id: 4,
      reviewBody: ' Estimada J. <br />Sirva la presente para agradecerles el buen trato y la eficiencia que demostró todo su equipo, el Sr. E. , el Sr. O. quien nos acompaño, y usted, quienes siempre nos atendieron y dieron solución a nuestras inquietudes. De más esta decirle que los voy a recomendar ampliamente con mis conocidos para que utilicen los servicios de su empresa. Por mi parte será así cada vez que visite Bogota, lo cual será pronto. <br />'},
    {lang: 'es' ,  author: 'Mr Alberto H. - Panama' , datePublished: '2010-08-01', date: ' - August 2010', rating: 5,
      title: 'FANTASTICOS SERVICIOS', id: 3,
      reviewBody: 'Great services.  V&amp;V always goes to extra mile to accomodate our request (which sometimes are very demanding).  All the drivers are extraordinary and we are loyal clients!  Keep up the good work!  Also, we LOVE H.!'},
    {lang: 'es' ,  author: 'Mrs Ernst' , datePublished: '2010-04-01', date: ' - April 2010', rating: 4,
      title: 'Thank you so much!', id: 2,
      reviewBody: '    Dear Vincent, <br /> Thank you so much! <br /> Everything went well and the driver was a great help for me and assisted me everywhere!!<br /> I\'m sure that I will get back to you.<br /> Regards<br />'},
    {lang: 'es' ,  author: 'Mr Elon Jessen Kristensen' , datePublished: '2009-07-04', date: ' - July 2009', rating: 5,
      title: 'EN FIN UN SERVICIO DECENTE EN BOGOTÁ', id: 1,
      reviewBody: 'Finally there\'s a decent VIP service in Bogotá - keep up the good work! <br /> I for one will support it 100% !!!<br />'},
    {lang: 'fr' ,  author: 'Jamil  U. - Textil Company' , datePublished: '2018-10-04', date: ' - Octubre 2018', rating: 5,
      title: 'Merci Beaucoup', id: 28,
      reviewBody: 'Espero este bien. El propósito de mi correo es expresar nuestra completa satisfacción con el servicio que ustedes nos brindaron:  ¡¡Eficiente, personal, profesional!!  Desde la cotización, la confirmación de la reserva, la comunicación contigo, el conductor.  ¡¡Todo el proceso…Excelente!! Seguro que los tendremos en mente para un futuro cercano. Y teniendo la oportunidad, no duden que los recomendaremos.  Tú fuiste muy profesional y sentí que la comunicación contigo fue excelente. Nuestro conductor J. , muy profesional, puntual y muy conocedor del área de Medellín.  Su nivel de servicio fue también excelente desde que paso por nosotros al aeropuerto a nuestra llegada hasta que nos dejó en el aeropuerto para nuestra partida.  Para la próxima oportunidad nos gustaría solicitarlo nuevamente. Un cliente muy satisfecho.'},
    {lang: 'fr' ,  author: 'Gloria C.  - YPO Group' , datePublished: '2018-06-13', date: ' - Juin 2018', rating: 5,
      title: 'FELICITATIONS', id: 27,
      reviewBody: 'Se me habia hecho tarde en agradecerte y felicitarte por tus servicios con el grupo YPO. Es un placer trabajar con ustedes, la organizacion, cumplimiento y seriedad de su trabajo.En los años que llevo en turismo, que no son pocos, V & V es la compañia, que le da a uno tranquilidad y seguridad.Nuevamente mil gracias y muchas felicitaciones'},
    {lang: 'fr' ,  author: 'Nills. R  - Grande Remise' , datePublished: '2016-09-11', date: ' - Septembre 2016', rating: 5,
      title: 'EXCELENT', id: 26,
      reviewBody: 'El Sr. R. quedo muy complacido y agradecido de los servicios que le ofrecieron, sus palabras textuales fueron que son \'EXELENTES\' agradesco mucho su servicio y dedicacion. <br />Ellos regresan el mes entrante a Bogota y ya me solicitaron los servicios de ustedes, quedaron muy complacidos con el chofer.'},
    {lang: 'fr' ,  author: 'Rodi B.  - Services Financiers' , datePublished: '2016-06-06', date: ' - Juin 2016', rating: 5,
      title: 'Votre équipe nous a fourni un service excellent', id: 25,
      reviewBody: 'This week will probably be the last week that we are down here.  Your team has provided us with excellent service, particularly R. who has been terrific.  If V&V ever needs a reference to another firm considering your services, we will be more than happy to provide.Please let me know when the final invoices are ready for this week.<br />Thanks again!<br />Rodi<br />'},
    {lang: 'fr' ,  author: 'Russell T. (Oracle) - Cartagena' , datePublished: '2015-09-09', date: ' - Septembre 2015', rating: 5,
      title: 'Les Meilleurs', id: 24,
      reviewBody: 'This team in Cartagena is the best I have encountered so far using [Car Service worldwide]. Reliable transportation when I need it the most, and flexible to make changes based on my daily travels.<br />'},
    {lang: 'fr' ,  author: 'Mrs Renata B. - Compagnie d\'assurance' , datePublished: '2015-08-31', date: ' - Août 2015', rating: 5,
      title: 'Merci beaucoup', id: 23,
      reviewBody: 'Thank you very much, <br /> The service it was excellent!!! <br /> Kind regards.<br />'},
    {lang: 'fr' ,  author: 'Miro Z. - Luxury tourism' , datePublished: '2014-12-17', date: '- December 2014', rating: 5,
      title: 'Vos chauffeurs ont été parfaits', id: 22,
      reviewBody: 'Both of your drivers were perfect for my bogota visit <br /> Very nice and professional. Both were very knowledgable and willing to assist <br />Thank you again. Job well done<br /> Regards.<br />'},
    {lang: 'fr' ,  author: 'Russel G. - Executive Protection Specialists' , datePublished: '2014-11-05', date: '- November 2014', rating: 5,
      title: 'Transmettre mes remerciements à toute l\'équipe', id: 21,
      reviewBody: 'Please extend a thank you to your team: H. and F. and the drivers: L. , E. , F. , and J. who helped with the meet and greet at the airport.<br />All were very friendly, helpful, and professional at all times, and a credit to your company.<br />Please tell Mr Vincent that I will definitely look to use your services on any return to Bogota, or any other area of Colombia<br />'},
    {lang: 'fr' ,  author: 'Lorena V. - Event and Meeting Company' , datePublished: '2014-07-07', date: 'July 2014', rating: 5,
      title: 'Tout s\'est passé à la perfection', id: 20,
      reviewBody: 'Hola buenas tardes<br /> De antemano mil gracias por toda su colaboración para el evento que se llevo a cabo este fin de semana, todo salio a la perfección. A continuación les comparto los agradecimientos que me envió el cliente. Saludos y mil gracias!!<br /><br />Hola Lorena,<br /> Queremos agradecerte a ti y a todo el equipo de [la agencia] por el excelente trabajo realizado con el transporte y la logística de los inversionistas de India. Sabemos que fue un proyecto con poco tiempo de anticipación pero el compromiso y profesionalismo [] permitió que todo saliera muy bien. Esperamos que podamos continuar con esta alianza para futuros proyectos.<br /> [] Quedamos atentos.<br /> Alex J.'},
    {lang: 'fr' ,  author: 'Marisol V. - Financial services company' , datePublished: '2014-03-20', date: ' - March 2014', rating: 5,
      title: 'Au nom de toute notre équipe, merci beaucoup!', id: 19,
      reviewBody: 'Les escribo únicamente para agradecerles por todas las facilidades proporcionadas la semana pasada durante nuestro [Road Show], mismo que sin duda alguna, no habría sido posible sin su excelente coordinación y servicio.<br />    Por favor tengan por seguro que continuaremos trabajando de ahora en más con ustedes, todo el equipo  les agradecemos mucho por todas las atenciones proporcionadas y la capacidad de pronta respuesta que manejaron para este viaje.    Nuevamente muchas gracias y seguimos en contacto para futuras agendas.'},
    {lang: 'fr' ,  author: 'Stibaliz H.' , datePublished: '2014-01-15', date: ' - January 2014', rating: 5,
      title: 'Excellent', id: 18,
      reviewBody: 'Muy agradecida por el servicio demasiado satisfecha excelente muchas gracias'},
    {lang: 'fr' ,  author: 'Lionel. G - Security Company' , datePublished: '2013-11-06', date: ' - November 2013', rating: 5,
      title: 'Service exemplaire', id: 7,
      reviewBody: 'La calidad del servicio fue ejemplar. Les transmito mis agradecimientos y les pido transmitirlos a los conductores y al agente de meet & greet.'},
    {lang: 'fr' ,  author: 'Jhon Henry. P - Security Company' , datePublished: '2013-09-10', date: ' - September 2013', rating: 5,
      title: 'Très bon service', id: 16,
      reviewBody: 'Buenas tardes, muchas gracias por sus servicios y creame que en el momento que necesitemos en Bogota la contactaremos en verdad nos parecio muy bueno el servicio que nos prestaron'},
    {lang: 'fr' ,  author: 'Liz L. - Colombia' , datePublished: '2013-08-11', date: ' - August 2013', rating: 5,
      title: 'Merci beaucoup', id: 15,
      reviewBody: 'Muchisimas gracias por el servicio muy satisfecha.<br/>'},
    {lang: 'fr' ,  author: 'Diego R. - Colombia' , datePublished: '2013-08-15', date: ' - August 2013', rating: 5,
      title: 'Excellent comme d\'habitude', id: 14,
      reviewBody: ' informarle y reiterarle que el servicio estuvo excelente como siempre. Estamos en contacto para futuros servicios, muchas gracias.<br />'},
    {lang: 'fr' ,  author: '8 luxury tourism clients - Colombia' , datePublished: '2013-07-10', date: ' - July 2013', rating: 5,
      title: 'Je vous remercie pour la qualité du service', id: 13,
      reviewBody: 'Para informarle que el servicio fue excelente, el carro sobrio y elegante, el personal (J.) de primera y ni hablar de la puntualidad. Agradezco la calidad del servicio.<br />'},
    {lang: 'fr' ,  author: 'Michael C. - Global Oil and Gas Manager' , datePublished: '2013-06-24', date: ' - June 2013', rating: 5,
      title: 'Merci beaucoup', id: 12,
      reviewBody: '    Thank you so much, <br />We had a great time and everyone was very professional and nice.<br /> Thank you very very much!!<br />'},
    {lang: 'fr' ,  author: 'Johnny Hansel R. - Colombia - Banca de Inversion' , datePublished: '2013-05-10', date: ' - May 2013', rating: 5,
      title: 'Merci pour avoir si bien pris soin de mon client', id: 11,
      reviewBody: ' gracias [J.C.] por la atención prestada y por el buen trato al cliente. Los tendré presente para próximas oportunidades. <br />'},
    {lang: 'fr' ,  author: 'Lauren Y.  - USA' , datePublished: '2013-02-22', date: ' - February 2013', rating: 4,
      title: 'Evennement de 15 jours et plus de 200 passagers', id: 10,
      reviewBody: 'I would like to say thank you very much for the discounts and concessions you provided us in order to cut our overall event spend. Your drivers [...] were fantastic and provided wonderful service with a smile. All of our Executives and VIP distributors had a lovely experience with them and for that I want to express my gratitude.<br />Additionally, I would like to applaud [your Operation manager] who was an absolute super star!  [He] and the drivers did an amazing job and I would like to recognize each of the main drivers with a $100 usd tip per person. Is it possible to add this gratuity to the final bill?  [....] <br />I would like to thank you as well for the time and efforts you put in to our program in order to make our event transportation a success!<br /> Many thanks,<br />'},
    {lang: 'fr' ,  author: 'Mr Rod C.  - USA' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Merci pour le profesionnalisme', id: 9,
      reviewBody: 'Thank you for our car in Cartagena. I wanted to assure you that both the driver and guide were very professional in every way.  [...] Thanks for the professional service.'},
    {lang: 'fr' ,  author: 'Sra Claudia B. - Security services' , datePublished: '2012-04-01', date: ' - April 2012', rating: 5,
      title: 'Un grande réusite', id: 8,
      reviewBody: 'Buen día,<br/>Quiero manifestar a ustedes nuestro agradecimiento por el apoyo y colaboración en los servicios prestados los 17 al 19 de abril, los culminaron con éxito.<br/>Un saludo.'},
    {lang: 'fr' ,  author: 'Mr Ivan R - Salt Lake City - USA' , datePublished: '2011-08-01', date: ' - August 2011', rating: 5,
      title: 'Excellent service', id: 7,
      reviewBody: 'Excellent service. O. was a very professional driver and just provided an amazing service. It\'s great to see I can find this service now in Bogota. Keep up the great work.'},
    {lang: 'fr' ,  author: 'Mr Joo - Korea' , datePublished: '2011-07-01', date: ' - Julio 2011', rating: 5,
      title: 'Je vous remercie pour le service fourni', id: 6,
      reviewBody: 'Les agradezco mucho por su servicio brindado en la ocasion de la visita de nuesto presidente y su delegacion. El presidente y su comitiva estan muy satisfechos por su gentil servicio y seguro que proxima visita tambien les pediremos su servicio.<br />Muchas gracias.<br /> Saludos Cordiales'},
    {lang: 'fr' ,  author: 'Mrs L. C. - USA' , datePublished: '2011-05-01', date: ' - May 2011', rating: 5,
      title: 'Je vous remercie vraiement, vous et votre équipe', id: 5,
      reviewBody: 'E.,<br /> I truly thank you and your staff, without all of your hard work, dedication and patience this event would not have been possible!<br />    We look forward to working with you again.<br />    Take care,<br />'},
    {lang: 'fr' ,  author: 'Mr Leo Q. - Venezuela' , datePublished: '2011-04-01', date: ' - April 2011', rating: 5,
      title: 'Je vais vous recommender amplement', id: 4,
      reviewBody: ' Estimada J. <br />Sirva la presente para agradecerles el buen trato y la eficiencia que demostró todo su equipo, el Sr. E. , el Sr. O. quien nos acompaño, y usted, quienes siempre nos atendieron y dieron solución a nuestras inquietudes. De más esta decirle que los voy a recomendar ampliamente con mis conocidos para que utilicen los servicios de su empresa. Por mi parte será así cada vez que visite Bogota, lo cual será pronto. <br />'},
    {lang: 'fr' ,  author: 'Mr Alberto H. - Panama' , datePublished: '2010-08-01', date: ' - August 2010', rating: 5,
      title: 'Très bon service', id: 3,
      reviewBody: 'Great services.  V&amp;V always goes to extra mile to accomodate our request (which sometimes are very demanding).  All the drivers are extraordinary and we are loyal clients!  Keep up the good work!  Also, we LOVE H.!'},
    {lang: 'fr' ,  author: 'Mrs Ernst' , datePublished: '2010-04-01', date: ' - April 2010', rating: 4,
      title: 'Merci beaucoup!', id: 2,
      reviewBody: '    Dear Vincent, <br /> Thank you so much! <br /> Everything went well and the driver was a great help for me and assisted me everywhere!!<br /> I\'m sure that I will get back to you.<br /> Regards<br />'},
    {lang: 'fr' ,  author: 'Mr Elon Jessen Kristensen' , datePublished: '2009-07-04', date: ' - July 2009', rating: 5,
      title: 'Enfin un service VIP décent à Bogota', id: 1,
      reviewBody: 'Finally there\'s a decent VIP service in Bogotá - keep up the good work! <br /> I for one will support it 100% !!!<br />'}
  ];

  public getTestimonials(): Promise<Testimonial[]> {
    return this.readyP.then( result => {
      return this.currentTestimonials;
    });
  }
  public get currentTestimonials(): Testimonial[] {
    return this.currentTestimonialsSubject.value;
  }
  public set currentTestimonials(testimonials: Testimonial[]) {
    this.currentTestimonialsSubject.next(testimonials);
  }
  getTestimonialsFromAPI(): Promise<[Testimonial]> {
    return firstValueFrom(this.http.get<any>(this.UrlAPI + environment.URL_TESTIMONIALS));
  }
  async initializeLists(): Promise<boolean>{
    console.log('initialing list de testimonios');
    try {
      this.currentTestimonials = await this.getTestimonialsFromAPI();
      // console.log(this.currentTestimonials);
      console.log('Testimonials should be ready');
      return true;
    } catch (err) {
      console.log(err);
      console.log('Testimonials list failed !');
      return false;
    }

  }
}
