import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {
  ButtonStatus,
  CheckList,
  Cliente,
  CompleteJob,
  Coordinates,
  HojaMision,
  JobEvent,
  JobStatus,
  RelacionEstado,
  Service
} from '../../../../models/business-objects';
import {firstValueFrom, interval, Observable, Subject} from 'rxjs';
import {JobService} from '../../../services/job.service';
import {AuthService} from '../../../services/auth.service';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarModule, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.scss']
})
export class DriverComponent implements OnInit , OnDestroy{
  tabInfo: boolean;
  tabEventos: boolean;
  estadoVueloLlegada: Observable<string>;
  estadoVueloSalida: Observable<string>;
  private subscriptions = [];
  private _jobCompleto: CompleteJob;
  private locationWorker: Worker;
  @Input() jwt: string;
  @Input() set jobCompleto( data: CompleteJob){
    this._jobCompleto = data;
    this.updateData(data);
    this.updateVisibility();
    console.log('pagina de servicio para conductor actualizada con job : ');
    console.log(data);
    this.ref.detectChanges();
  }
  get jobCompleto(): CompleteJob {
    return this._jobCompleto;
  }
  job: Service = new Service();
  checkList: CheckList = new CheckList();
  hojaMision: HojaMision = new HojaMision();
  pasajeros: Cliente[] = [];
  detallePasajero = '';
  flightInfo = '';
  showCheckList = false;
  showHojaMision = false;
  showEventos = false;
  yaReportandoUbicacion = false;
  reportandoEvento: Subject<boolean> = new Subject();
  showPassengers = false ;
  loadingPdf: Subject<boolean> = new Subject();
  myDateFormat = 'dd/MMM/yyyy'; // In which you need put here
  myDateFormat2 = 'dd/MM/yyyy'; // In which you need put here
  myTimeFormat = 'HH:mm'; // In which you need put here
  myTimeAndDateFormat = 'yyyy-MM-dd hh:mm:ss.SSS'; // In which you need put here
  estadosActuales: JobStatus[] = []; // app.listaEstados
  eventosActuales: JobEvent[] = [];
  eventoActualTest: JobEvent[] = [];
  idEstadoActual: number;
  botonesVerMas: ButtonStatus[] = [];
  bottonesEventos: ButtonStatus[] = [];
  coordinates: Coordinates;
  subscription;
  timer30sec ;
  vermas: boolean;
  constructor( private authService: AuthService, private translate: TranslateService, public snackBar: MatSnackBar, @Inject(PLATFORM_ID) private platformId: any,
               private dialogRef: MatDialogRef<DriverComponent>, private jobService: JobService, private ref: ChangeDetectorRef) {
    this.tabInfo = true;
    this.tabEventos = false;
    this.timer30sec = interval(30000);
    this.reportandoEvento.next(false);
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    if(this.locationWorker) {
      this.locationWorker.postMessage('stop');
      this.locationWorker.terminate();
    }
  }
  closeDialog() {
    this.dialogRef.close({event: 'Close', ultimoEstatu: this.job.textoEventoActual});
  }
  activateInfo(){
    this.tabInfo = true;
    this.tabEventos = false;
  }
  activateEventos(){
    this.tabInfo = false;
    this.tabEventos = true;
  }
  parseToInt(str: string): number{
    if (str) {
      return parseInt(str, 10);
    } else {
      return 0;
    }
  }
  openPasajeros() {
    this.showPassengers = !this.showPassengers;
  }
  updateData(jobcompleto: CompleteJob) {
    console.log('entrando updateData');
    this.job = jobcompleto.service;
    this.checkList = jobcompleto.checkList;
    this.hojaMision = jobcompleto.hojaMision;
    this.pasajeros = jobcompleto.pasajeros;
    this.idEstadoActual = jobcompleto.service.eventoactual;
    console.log(jobcompleto.relacionestados);
    this.getBotonesEventos(jobcompleto.relacionestados);
    if ( Number(this.job.conductorId) === this.authService.currentUserValue.idconductor
        || Number(this.job.azafataId) === this.authService.currentUserValue.idconductor) {
      console.log('creating web worker for reporting location');
      // Create a new Web Worker instance
      this.locationWorker = new Worker(new URL('./driver-location.worker', import.meta.url));
      console.log('this.locationWorker location.worker.js ',this.locationWorker);
      // Listen for location updates from the Web Worker
      this.locationWorker.onmessage = ({ data }) => {
        console.log('message received from Web Worker : ',data);
        this.sendLastPosition();
      };
      // Start the location updates in the Web Worker
      this.locationWorker.postMessage('start');
    } else {
      console.log('this.job.conductorId ' + this.job.conductorId);
      console.log(this.authService.currentUserValue);
      console.log('this.authService.currentUserValue.idconductor ' + this.authService.currentUserValue.idconductor);
      console.log(' el conductor no es la persona logueada no se reportan posiciones');
    }
  }
   updateVisibility() {
     if (this.job.eventoactual < 4) {
       this.showCheckList = true;
       this.showHojaMision = false;
     } else {
       this.showHojaMision = true;
       this.showCheckList = false;
     }
     if (this.job.eventoactual === 15 || this.job.rolReserva !== 'operation') {
       this.showEventos = false;
     } else {
       this.showEventos = true;
     }
  }
  toggleVermas() {
    if (this.vermas) {
      this.vermas = false;
    } else{
      this.vermas = true;
    }
  }
  sendEvento(button: ButtonStatus){
    if (isPlatformBrowser(this.platformId)) {
      const coordinates = new Coordinates();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          coordinates.lat = position.coords.latitude;
          coordinates.lng = position.coords.longitude;
          this.saveEvento(button, coordinates);
        }, error => {
          alert(this.translate.get('app.geo.Error-1'));
          this.saveEvento(button, null);
        });
      } else {
        alert(this.translate.get('app.geo.Error-2'));
        this.saveEvento(button, null);
      }
    }
  }
  sendLastPosition(){
    const coordinates = new Coordinates();
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const button = new ButtonStatus();
          button.idEstado = 0;
          coordinates.lat = position.coords.latitude;
          coordinates.lng = position.coords.longitude;
          this.saveEvento(button, coordinates);
        }, error => {
          alert(this.translate.get('app.geo.Error-1'));
        });
      } else {
        alert(this.translate.get('app.geo.Error-2'));
      }
    }
  }
  saveEvento(button: ButtonStatus, coordinates: Coordinates){
    if (button.idEstado !== 0) {
      this.openSnackBar('snack-info', 'Guardando Evento', 5000);
    }
    const jobEvent = new JobEvent();
    if ( coordinates) {
      jobEvent.lng = coordinates.lng;
      jobEvent.lat = coordinates.lat;
    }
    jobEvent.estadoId = button.idEstado;
    jobEvent.personalId = this.authService.currentUserValue.idconductor;
    jobEvent.tipo = this.job.tipoServicio;
    jobEvent.comentario = 'Creado por la interfaz de la pagina web';
    const pipe = new DatePipe('en-US');
    jobEvent.date = pipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSSZ') ;
    if (button.idEstado !== 0) {
      this.reportandoEvento.next(true);
      firstValueFrom(this.jobService.saveStatus(this.job, jobEvent, this.jwt)).then(data => {
        if (data) {
          this.jobCompleto.eventos.push(jobEvent);
          this.idEstadoActual = button.idEstado;
          if (button.nombreEstado) {
            this.job.textoEventoActual = button.nombreEstado;
            this.vermas = false;
            this.getBotonesEventos(this.jobCompleto.relacionestados);
            this.updateVisibility();
            this.openSnackBar('snack-success', 'Evento Guardado', 5000);
          }
        } else {
            this.openSnackBar('snack-error', 'Error al guardar Evento', 5000);
        }
        this.reportandoEvento.next(false);
      }, error => {
          this.openSnackBar('snack-error', 'Error al guardar Evento', 5000);
          console.log(error);
          this.reportandoEvento.next(false);
      });
    } else {
      if ( !this.yaReportandoUbicacion) {
        // eso para evitar multiples envios de ubicacion a la vez en caso que el navegador estaba en segundo plano
        this.yaReportandoUbicacion = true;
        firstValueFrom(this.jobService.saveLastLocation(this.job, jobEvent, this.jwt)).then(data => {
          if (data) {
            console.log('Ultima ubicacion guardada');
          } else {
            console.log('Error guardando Ultima ubicacion');
          }
          this.yaReportandoUbicacion = false;
        }, error => {
          console.log(error);
          console.log('Error guardando Ultima ubicacion');
          this.yaReportandoUbicacion = false;
        });
      } else {
        console.log('ya estando reportando Ultima ubicacion, no se vuelve a hacer');
      }
    }
    return;
  }
  openSnackBar(snackClass: string, snackMessage: string , duration: number) {
    this.snackBar.dismiss();
    const config = new MatSnackBarConfig();
    config.duration = duration ? duration : 0;
    config.panelClass = snackClass ? [snackClass] : undefined;
    firstValueFrom(this.translate.get(snackMessage)).then(text => this.snackBar.open( text,  'x', config));

  }
  getBotonesEventos(relacionestados: RelacionEstado[]) {
    console.log('entrando getBotonesEventos');
    const agregados: number[] = [];
    this.bottonesEventos = [];
    this.botonesVerMas = [];
    console.log('this.idEstadoActual ' + this.idEstadoActual);
    /**********ESTADO ACTUAL**********/
    if (this.idEstadoActual !== -1){ // servicio empezado
      for (const re of  relacionestados){
        if (re.padre.id === this.idEstadoActual) {
          if (agregados.length === 0){
            this.bottonesEventos.push({
              idEstado: re.padre.id,
              nombreEstado: re.padre.nombre,
              antiguo: true,
              actual: true,
              soloConduccion : re.padre.soloConduccion});
            agregados.push(re.padre.id);
          }
        }
      }
    }
    /**********ESTADOS HIJOS**********/
    for (const re of  relacionestados) {
      if (this.idEstadoActual !== -1) { // servicio empezado
        if (re.padre.id === this.idEstadoActual) {
          if (agregados.length === 0) {
            this.bottonesEventos.push({
              idEstado: re.hijo.id,
              nombreEstado: re.hijo.nombre,
              antiguo: false,
              actual: false,
              soloConduccion: re.hijo.soloConduccion
            });
            agregados.push(re.hijo.id);
          } else {
            let agregado = false;
            for (const i of agregados) {
              if (re.hijo.id === agregados[i]) {
                agregado = true;
              }
            }
            if (!agregado) {
              this.bottonesEventos.push({
                idEstado: re.hijo.id,
                nombreEstado: re.hijo.nombre,
                antiguo: false,
                actual: false,
                soloConduccion: re.hijo.soloConduccion
              });
              agregados.push(re.hijo.id);
            }
          }
        }
      }
    }
      /**********ESTADOS PADRES**********/
    for (const re of  relacionestados){
      if (this.idEstadoActual === -1){
        // servicio no empezado
        if (re.padre.inicial) {
          if (agregados.length === 0) {
            this.bottonesEventos.push({
              idEstado: re.padre.id,
              nombreEstado: re.padre.nombre,
              antiguo: false,
              actual: false,
              soloConduccion: re.padre.soloConduccion
            });
            agregados.push(re.padre.id);
          }
        }
      }else{
        // servicio  empezado
        if (re.hijo.id  === this.idEstadoActual) {
          if (agregados.length === 0) {
            this.botonesVerMas.push({
              idEstado: re.padre.id,
              nombreEstado: re.padre.nombre,
              antiguo: true,
              actual: false,
              soloConduccion: re.padre.soloConduccion
            });
            agregados.push(re.padre.id);
          } else {
            let agregado = false;
            for (const i of agregados) {
              if (re.padre.id === agregados[i]) {
                agregado = true;
              }
            }
            if (!agregado) {
              this.botonesVerMas.push({
                idEstado: re.padre.id,
                nombreEstado: re.padre.nombre,
                antiguo: true,
                actual: false,
                soloConduccion: re.padre.soloConduccion
              });
              agregados.push(re.padre.id);
            }
          }
        }
      }
    }
    console.log(' cantidad en vista normal : ' + this.bottonesEventos.length );
    if ( this.bottonesEventos.length === 0) {
      this.bottonesEventos.push();
    }
    console.log(' cantidad en vista vermas : ' + this.botonesVerMas.length );
    this.bottonesEventos.sort((a, b) => a.idEstado - b.idEstado);
    this.botonesVerMas.sort((a, b) => a.idEstado - b.idEstado);
  }
  getPDFLetrero(idReserva: number){
    if (isPlatformBrowser(this.platformId)){
      this.loadingPdf.next(true);
      this.jobService.getLetrero(idReserva, this.jwt).then(response => {
          return this.base64toBlob(response.letrero, 'application/pdf');
        }
      ).then(blob => {
          const fileURL = URL.createObjectURL(blob);
// if you want to open PDF in new tab
          window.open(fileURL);
          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = 'nameSign' + idReserva + '.pdf';
          document.body.appendChild(a);
          a.click();
          this.loadingPdf.next(false);
        },
        (error) => {
          console.log('getPDF error: ', error);
          this.loadingPdf.next(false);
        }
      );
    }
  }

  base64toBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

}
