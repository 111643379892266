import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'createMapsLinks'
})
export class CreateMapsLinksPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(text: string) {
    text = '<li> ' + text;
    text = text.replace(/\r\n/g, ' </li><li>').replace(/[\r\n]/g, ' </li><li>');
    text =  text + ' </li>';
    text = text.replace('http', ' http');
    const waze = text.indexOf('https://waze.com');
    let maps = text.indexOf('https://goo.gl/maps');
    if (maps == null || maps === -1 ) { maps = text.indexOf('https://maps.google.com'); };
    let stringWithUrl = text;
    //console.log(text);
    if (waze !== -1){
      let nextSpace = text.indexOf(' ', waze);
      //console.log(nextSpace);
      if (nextSpace === -1) nextSpace = text.length;
      const url = text.substr(waze, nextSpace - waze);
      //console.log(url);
      stringWithUrl = stringWithUrl.replace(url, ' <a href="' + url + '" target="_blank">Waze</a> ');
    }
    if (maps !== -1){
      let nextSpace = text.indexOf(' ', maps);
      //console.log(nextSpace);
      if (nextSpace === -1) nextSpace = text.length;
      const url = text.substr(maps, nextSpace - maps);
      //console.log(url);
      stringWithUrl = stringWithUrl.replace(url, ' <a href="' + url + '" target="_blank">Maps</a> ');
    }
    //console.log('stringWithUrl ' + stringWithUrl);
    return this.sanitized.bypassSecurityTrustHtml(stringWithUrl);
  }

}
