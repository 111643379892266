import {AfterViewInit, Component, DoCheck, Inject, Input, KeyValueDiffers, OnDestroy, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut,} from './carousel.animations';
import {MySlide} from '../../../models/business-objects';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [useAnimation(fadeIn, {params: { time: '1300ms' }} )]),
      transition('* => void', [useAnimation(fadeOut, {params: { time: '1300ms' }})]),
    ])
  ]
})
export class CarouselComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy  {
  @Input() slides: MySlide[];
  @Input() offsetControl: number;
  @Input() verticalControl = '';
  @Input() autoSlideIntervalInSec: number;
  showSpinner = false;
  differ: any;
  s: HTMLScriptElement;
  constructor(private renderer2: Renderer2, differs: KeyValueDiffers,
              @Inject(DOCUMENT) private _document: Document,  @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.differ = differs.find([]).create();
  }
  private timerSubscription: Subscription;
  currentSlide = 0;
  ngDoCheck() {
    const changes = this.differ.diff(this.slides);
    if (changes) {
      console.log('this.slides change detected');
     // this.injectScript(2000);
    }
  }
  ngOnInit(): void {
    // console.log('slides received in carroussel');
    // console.log(this.slides);
    if (!this.offsetControl) { this.offsetControl = 0 ; }
    if (this.autoSlideIntervalInSec == null) { this.autoSlideIntervalInSec = 5 ; }
  }
   ngOnDestroy() {
      if (this.timerSubscription){
        this.timerSubscription.unsubscribe();
      }
   }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.autoSlideIntervalInSec > 0) {
        this.timerSubscription = timer(this.autoSlideIntervalInSec * 1000, this.autoSlideIntervalInSec * 1000)
          .subscribe(() => {
            const next = this.currentSlide + 1;
            this.currentSlide = next === this.slides.length ? 0 : next;
          });
      }
    }
  }
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    // this.injectScript(0);
    console.log('previous clicked, new current slide is: ');
    console.log( this.slides[this.currentSlide]);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    // this.injectScript(0);
    console.log('next clicked, new current slide is: ');
    console.log( this.slides[this.currentSlide]);
  }
  /*
  injectScript(delay: number){
    if (this.s) this.renderer2.removeChild(this._document.body, this.s);
    if (isPlatformBrowser(this.platformId)) {
    if ( this.slides[this.currentSlide] && this.slides[this.currentSlide].news && this.slides[this.currentSlide].news.hasScripts) {
      this.showSpinner = true;
      (async () => {
      this.s = this.renderer2.createElement('script');
      this.s.type = 'text/javascript';
      // s.src = this.slides[this.currentSlide].news.scriptUrl;
      this.s.innerHTML = 'instgrm.Embeds.process()';
      console.log('executing slide script ' + this.s.text + 'with delay of ' + delay);
      this.delay(delay).then( () => {
        this.renderer2.appendChild(this._document.body, this.s);
        this.showSpinner = false;
      });
      })();
    }
    }
  }
  */

  delay(ms: number) {
    if (ms == null || ms === 0 ) {ms = 2; }
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
