import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class LeafletService {
  public L = null;
  public M = null;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(platformId)) {
      this.L = require('leaflet');
      require('leaflet.markercluster');
    }
  }
}
