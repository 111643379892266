

export class Participant {
  displayName?: string;
  userId?: string;
  avatarUrl?: string;
  isOnline?: boolean;
  isTyping?: boolean;
  lastActiveAgo?: Promise<number> ;
  isSubscribed?: boolean; // Implement logic as per your app
  isNotifiable?: boolean; // Implement logic for notifications
  customType?: string; // Replace or define type according to your use case
}

export class AiChatMessage {
  sender: 'user' | 'bot';
  message: string;
  timestamp: Date;
  type: string;
  threadId?: string;
  stillWriting?: boolean;
  error?: string;

  constructor(data: any) {
    this.sender = data.sender;
    this.message = data.message;
    this.timestamp = new Date(data.timestamp); // Convert string to Date
    this.type = data.type;
    this.threadId = data.threadId;
    this.stillWriting = data.stillWriting;
    this.error = data.error;
  }
}
