import {Injectable} from '@angular/core';
import {News} from '../../models/business-objects';
import {InstagramService} from './instagram.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';

@Injectable()
export class NewsService {
  news: News[] = [];
  UrlAPI: string = environment.apiURL;
  public currentNews: Observable<News[]>;
  private currentNewsSubject: BehaviorSubject<News[]>;
  public currentInsta: Observable<News[]>;
  private currentInstaSubject: BehaviorSubject<News[]>;
  private  readyP: Promise<boolean>;
  timerScript = '<script type="text/javascript"> console.log("Si se ejecuntan los scripts"); </script>';
  constructor(private instagramService: InstagramService, private http: HttpClient) {
    this.currentNewsSubject = new BehaviorSubject<News[]>(this.news);
    this.currentNews = this.currentNewsSubject.asObservable();
    this.readyP = this.initializeLists();
  }

  async initializeLists(): Promise<boolean>{
    console.log('initialing lists of news');
    try {
      this.currentNewsValue = await this.getAllNews() ;
      console.log('initialing lists of news 1');
      if (this.currentNewsValue == null) {
        return false;
      }
      return true;
    } catch (err) {
      console.log(err);
      console.log('lists of news failed !');
      return false;
    }
  }

  getAllNews() {
    let headers = new HttpHeaders();
    const body = {};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_NEWS, body, {headers})).then( data => {
      this.news = data;
      console.log('lista de noticias desde servidor');
      const promises = [];
      console.log('no vamos a buscar imagenes : ');
      for (const n of this.news) {
        console.log('for each news: ', n.id);
        promises.push(this.getImage(n).then(url => {
          if (!url) { url = ''; }
          n.imageUrl = url;
          return url;
        }));
      }
      for (const publication of this.news){
      if (publication.seccionNoticia && publication.seccionNoticia.toLowerCase() === 'instagram'
          && publication.myId){
        console.log('no vamos a buscar este en instagram : ' + publication.myId);
        /*
        promises.push(firstValueFrom(this.instagramService.getPublication(publication.myId)).then(response => {
          publication.html = response.html;
          publication.type = 'instagram';
          publication.thumbnail_url = response.thumbnail_url;
          return response;
          }, error => {
          console.log('error recuperando detalles instagram');
          console.log(error);
          publication.html = '';
          publication.type = 'instagram';
          publication.thumbnail_url = '';
          return error;
        }));
        */
        }
      }
      return Promise.all(promises)
        .then(results => {
          console.log('todo listo con las informaciones de instagram');
          // console.log(this.news);
          this.news = this.news.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
          return this.news;
        }, error => {
          console.log('hubo errores recuperando noticias...');
          return this.news;
        });
   });
  }
  getNewsById(idNews: number): Promise<News> {
    let headers = new HttpHeaders();
    const body = {idNews};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_NEWS_ID, body, {headers}));
  }

  getNewsImageById(idNews: number): Promise<any> {
    let headers = new HttpHeaders();
    const body = {idNews};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<Blob>(this.UrlAPI + environment.URL_NEWS_IMAGE, body, {headers, responseType: 'json'}));
  }

  getAllInsta(): Promise<News[]> {
    console.log('se van a buscar los news a intagram');
    let headers = new HttpHeaders();
    const body = {};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_NEWS_INSTAGRAM, body, {headers})).then( data => {
      console.log('lista de codigo desde servidor');
      // console.log(data);
      const promises = [];
      let instaNews: News[] = data ;
      for (const publication of instaNews) {
        if (publication.seccionNoticia === 'INSTAGRAM'
          && publication.myId){
          console.log('no vamos a buscar este en instagram : ' + publication.myId);
          /*
          promises.push(firstValueFrom(this.instagramService.getPublication(publication.myId)).then(response => {
            response.isError = false;
            publication.type = 'instagram';
            publication.html = response.html;
            publication.thumbnail_url = response.thumbnail_url;
            publication.scriptText = 'instgrm.Embeds.process(); console.log(" embed process launched")';
            return response;
          }, error => {
            console.log('error recuperando detalles instagram');
            console.log(error);
            publication.html = '';
            publication.type = 'instagram';
            publication.thumbnail_url = '';
            return error;
          }));*/
        }
      }
      return Promise.all(promises)
        .then(results => {
          console.log('todo listo con las informaciones de instagram');
          instaNews = instaNews.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
          // console.log(instaNews);
          return instaNews;
        }, error => {
          console.log('hubo algunos errores recuperando las informaciones de instagram');
          instaNews = instaNews.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
          // console.log(instaNews);
          return instaNews;
      } );
    });
  }
  getCurrentNews(): Promise<News[]> {
    return  this.readyP.then(o => {
      return this.currentNewsSubject.value;
    });
  }
  public get currentNewsValue(): News[] {
    return  this.currentNewsSubject.value;
  }
  public set currentNewsValue(news: News[]) {
    news = news.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
    this.currentNewsSubject.next(news);
  }
  public get currentInstaValue(): News[] {
    return this.currentInstaSubject.value;
  }
  public set currentInstaValue(news: News[]) {
    news = news.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
    this.currentInstaSubject.next(news);
  }

  getImage(news: News) {
    console.log('buscando imagen para news id : ' + news.id);
    return this.getNewsImageById(Number(news.id)).then(response => {
      console.log('imagen para news id : ' + news.id + ' ya llego...');
      if (response) {
        console.log('tenemos la info en base64');
        return 'data:image/jpeg;base64,' + response.media;
      } else {
        console.log('la info en base64 esta nulla ');
        return '';
      }
    }, error => {
      console.log('error recuperando foto de ' + news.id + ' error : ' + error);
      return '';
    });
  }
}
