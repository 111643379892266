
  <div class="row justify-content-center our-fleet-sections justify-content-center">
    <div class="content-wrapper col-xxl-6 col-xl-7  col-lg-8 col-md-10 col-sm-11">
      <h3 class="center-title">{{'NewsTitle' | translate}}</h3>
      <div class="title-block2"></div>
      <div class="carousell-news-container mb-5 w-100">
        <app-carousel [slides]="slides" [offsetControl]="0" [autoSlideIntervalInSec]="-1"  [verticalControl]="''" >
        </app-carousel>
      </div>
    </div>
  </div>
