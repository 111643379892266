import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class InstagramService {
  facebookToken: string = environment.faceBookToken;
  constructor(private http: HttpClient) { }
  getPublication(id: string): Observable<any>{
    const url = 'https://graph.facebook.com/v11.0/instagram_oembed?url=https://www.instagram.com/p/';
    return this.http.get<any>( url + id + '&access_token=' + this.facebookToken + '&omitscript=true' );
  }
}
