import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FilterPipe} from './filter.pipe';
import {SearchPipe} from './search.pipe';
import {ShortNamePipe} from './short-name.pipe';
import {SafeUrlPipe} from './safeUrl.pipe';
import {SafeHtmlPipe} from './safeHtml.pipe';
import {CreateMapsLinksPipe} from './create-maps-links.pipe';

@NgModule({
  declarations: [FilterPipe, SearchPipe, ShortNamePipe, SafeUrlPipe, SafeHtmlPipe, CreateMapsLinksPipe],
  imports: [CommonModule],
  exports: [FilterPipe, SearchPipe, ShortNamePipe, SafeUrlPipe, SafeHtmlPipe, CreateMapsLinksPipe]
})

export class PipeModule{}
