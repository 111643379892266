import {RouteInfo} from './menu.metadata';

// Sidebar menu Routes and data
export const PUBLIC_MENU: RouteInfo[] = [
  { path: '/company-services', isUnrestricted: true, title: 'Services', icon: '', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
  { path: '/coverage', isUnrestricted: true, title: 'Coverage', icon: '', class: 'has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
  { path: '/carservice', isUnrestricted: true, title: 'Fleet', icon: '', class: 'dropdown nav-item has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
        submenu: [
          {path: '/vehicle-categories', isUnrestricted: true, title: 'VehicleCategories', icon: '', class: 'dropdown-item',
            badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
          {path: '/carservice/bogota', isUnrestricted: true, title: 'FleetInBogota', icon: '', class: 'dropdown-item',
            badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
          {path: '/carservice/medellin', isUnrestricted: true, title: 'FleetInMedellin', icon: '', class: 'dropdown-item',
            badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
          {path: '/carservice/cartagena', isUnrestricted: true, title: 'FleetInCartagena', icon: '', class: 'dropdown-item',
            badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
          {path: '/carservice/worldwide', isUnrestricted: true, title: 'FleetInternational', icon: '', class: 'dropdown-item',
            badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
       ]},
  { path: '/contact-us', isUnrestricted: true, title: 'Contact Us', icon: '', class: 'dropdown nav-item has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
    submenu: [
      {path: '/contact-us', isUnrestricted: true, title: 'Contact form', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/booking', isUnrestricted: true, title: 'Booking', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/payment', isUnrestricted: true, title: 'Payments-1', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
    ]},
  { path: '/about-us', isUnrestricted: true, title: 'About Us', icon: '', class: 'dropdown nav-item has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
    submenu: [
      { path: '/about-us', isUnrestricted: true, title: 'AboutUs-0', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      { path: '/faq', isUnrestricted: true, title: 'Faq', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      { path: '/testimonials', isUnrestricted: true, title: 'Testimonials', icon: '', class: 'dropdown-item',
      badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      { path: '/news', isUnrestricted: true, title: 'News', icon: '', class: 'dropdown-item', badge: '',
         badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/bio-security', isUnrestricted: true, title: 'Bio-Security', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/onboard', isUnrestricted: true, title: 'OnBoard', icon: '', class: 'dropdown-item', badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []}
      ]},
];
export const DISPATCH_MENU: RouteInfo[] = [
  { path: '/private/view', isUnrestricted: false,isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: true,
    title: 'app.private.View', icon: '', class: 'dropdown nav-item has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
    submenu: [
      {path: '/private/bookings', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: false,
        title: 'app.private.Bookings', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/calendar', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: false,
        title: 'app.private.Calendar', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/map', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: false,
        title: 'app.private.Map', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/event', isUnrestricted: false,isForDispatch: true, isForClient: true, isForDriver:false, isForSupplier: false,
        title: 'app.private.Events', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
    ]},
  { path: '/private/dispatch', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: false,
    title: 'app.private.Dispatch', icon: '', class: 'has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
  { path: '/private/invoicing', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:false, isForSupplier: false,
    title: 'app.private.Invoices', icon: '', class: 'dropdown nav-item has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
    submenu: [
      {path: '/private/invoices', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: false,
        title: 'app.private.Invoices', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/pending-invoicing', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:true, isForSupplier: false,
        title: 'app.private.pendingToInvoice', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/payments', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:false, isForSupplier: false,
        title: 'app.private.Payments', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/payment', isUnrestricted: false, isForDispatch: true, isForClient: true, isForDriver:false, isForSupplier: false,
        title: 'app.private.Payment', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
    ]},
  { path: '/private/afiliado', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: true,
    title: 'app.private.Provider', icon: '', class: 'dropdown nav-item has-sub', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false,
    submenu: [
      {path: '/private/provider/home', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: true,
        title: 'app.private.Provider', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/provider/farmins', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: true,
        title: 'app.private.FarmIns', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/provider/drivers', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: true,
        title: 'app.private.Drivers', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
      {path: '/private/provider/vehicles', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:false, isForSupplier: true,
        title: 'app.private.Vehicles', icon: '', class: 'dropdown-item',
        badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},

    ]
  },
  { path: '/private/book', isUnrestricted: true,
    title: 'app.private.Book', icon: '', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},
  { path: '/chat', isUnrestricted: false, isForDispatch: true, isForClient: false, isForDriver:true, isForSupplier: false,
    title: 'app.private.Chat', icon: '', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right me-1 mt-1', isExternalLink: false, submenu: []},

];
