import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CompleteJob, JobEvent, JobStatus, Service, User} from '../../../models/business-objects';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {firstValueFrom, Subject} from 'rxjs';
import {JobService} from '../../services/job.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {DriverComponent} from '../../private/job/driver/driver.component';
import {AuthService} from '../../services/auth.service';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnDestroy  {
  job: Service = new Service();
  private driverPopup!: any;
  private jwt: string;
  result: string;
  driverPopupComponent = DriverComponent;
  private subscriptions = [];
  jwtUser: User;
  loadingDetails: Subject<boolean> = new Subject();
  abriendoPopUp: Subject<boolean> = new Subject();
  smsReport = false;
  nombreEstado: Subject<string> = new Subject();
  showGpsError = false;
  estadoRecibido = false;
  idEstadoAReportar = '';
  constructor(private jobService: JobService, private translate: TranslateService, public dialog: MatDialog,
              private canonicalAndMetaService: CanonicalAndMetaService, @Inject(PLATFORM_ID) private platformId: any ,
              private route: ActivatedRoute, private ref: ChangeDetectorRef, private authService: AuthService) {
    this.translate.setDefaultLang('en');
    this.abriendoPopUp.next(false);
    if (isPlatformBrowser(this.platformId)) {
      navigator.geolocation.getCurrentPosition(
        (i) => {
          this.showGpsError = false;
        },
        (i) => {
          this.showGpsError = true;
        });
    } else {
       this.showGpsError = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('status').then(async result => {
      await this.canonicalAndMetaService.setMetas('status', true, '');
    });
    this.subscriptions.push(this.route.params.subscribe(async params => {
      await this.initJob();
      this.nombreEstado.next(this.jwt);
      console.log(this.route.snapshot.url);
      console.log('datos inicializados');
      this.formatResult();
    }));
  }
  formatResult() {
    // aca si this.result no esta vacio eso quiere decir que hubo un error en una etapa anterior
    if (this.result === '') {
      console.log('result esta vacio lo llenamos');
      if (this.job) {
        this.result = 'Servicio  ' + this.job.numeroreserva + ' del ' + this.job.fecha + ' a las ' + this.job.hora
          + ' para ' + this.job.nombrePrimerPasajero + '. <br />'
          + ' Ultimo estado : ' + this.job.textoEventoActual;
        this.ref.detectChanges();
      } else {
        this.result = 'Error : Servicio nulo';
        this.ref.detectChanges();
      }
    } else {
      console.log('result no esta vacio');
    }
  }

  async initAndOpen() {
    await this.initJob();
    if (this.result === '') {
      // este test sirve a saber si se logro recuperar el job con initJob()
      this.formatResult();
      this.openDetails(this.job);
    }
  }

  async initJob() {
    this.loadingDetails.next(true);
    this.result = '';
    this.jwt = this.route.snapshot.paramMap.get('jwt');
    if (this.jwt) {
      console.log('jwt encontrado ' + this.jwt );
      try {
        let decodedJWT;
        if (isPlatformBrowser(this.platformId)){
          decodedJWT = JSON.parse(window.atob(this.jwt.split('.')[1]));
        }
        this.idEstadoAReportar = decodedJWT.id_estado;
        console.log('id_estado encontrado ' + this.idEstadoAReportar );
        if (decodedJWT.iss !== 'SMS' ) {this.smsReport = true; }
      } catch (e) {
        console.log('id_estado encontrado ' + this.idEstadoAReportar );
        this.idEstadoAReportar = null;
        this.smsReport = false;
      }
      console.log(this.route.snapshot.paramMap);
      await this.jobService.getReservaPorJwt(this.jwt).then(data => {
        console.log('respuesta reserva jwt');
        console.log(data);
        try {
          this.job = data.job;
          this.jwtUser = data.user;
          this.authService.currentUserValue = this.jwtUser;
          this.loadingDetails.next(false);
          this.result = '';
          this.ref.detectChanges();
        } catch (error) {
          this.loadingDetails.next(false);
          this.job = null;
          console.log('respuesta al jwt invalida ');
          console.log(error);
          this.result = 'respuesta al jwt invalida';
          this.ref.detectChanges();
        }
      }, error => {
        this.loadingDetails.next(false);
        console.log('respuesta al jwt invalida ');
        console.log(error);
        this.job = null;
        this.result = 'Asegurese que el servicio por el cual quiere reportar un evento este activo.<br />' +
          'Los links de eventos se activan maximo 4h antes del inicio de un servicio';
        this.ref.detectChanges();
      });
    } else {
      this.result = 'Url incompleta, por favor averiguar que la copio bien o solicitar que se la vuelvan a enviar';
      this.ref.detectChanges();
      console.log('no jwt encontrado');
    }
  }
  openDetails(job: Service){
    navigator.geolocation.getCurrentPosition(
      (i) => {
        this.showGpsError = false;
        this.abriendoPopUp.next(true);
        let config: MatDialogConfig;
        config = {
          width: '100vw',
          height:  '100vh',
          maxWidth: '100vw',
          maxHeight: '100vh',
          hasBackdrop: false,
          panelClass: 'full-screen-modal',
        };
        const jobCompleto: CompleteJob = new CompleteJob();
        this.result = '';
        if ( !job || !this.jwtUser) {
          // si no tenenmos Job o jwtUser hay un problema
          this.result = ' Servicio imcompletamente descargado ' ;
          return;
        }
        return this.jobService.getRutaPorReserva(job.id, job.tipoServicio, this.jwtUser.idconductor.toString(), this.jwt)
          .then(data => {
            jobCompleto.service = job;
            return this.jobService.getStatusesByType(job.tipoServicio).then(relacionestados => {
              const estados: JobStatus[] = [];
              const eventos: JobEvent[] = [];
              for ( const item of data){
                const estado: JobStatus = new JobStatus();
                const evento: JobEvent = new JobEvent();
                estado.id = item.id;
                estado.nombre = item.nombre;
                estados.push(estado);
                evento.estado = estado;
                evento.date = item.date;
                evento.personalId = item.personalId;
                evento.lng = item.lng ? item.lng : null;
                evento.lat = item.lat ? item.lat : null;
                eventos.push(evento);
              }
              jobCompleto.eventos = eventos;
              jobCompleto.estados = estados;
              console.log('relacionestados');
              console.log(relacionestados);
              jobCompleto.relacionestados = relacionestados;
            }, error => {
              this.result += '<br />Error buscando relacion estados : '  +  JSON.stringify(error);
              this.ref.detectChanges();
            }).then ( nada => {
              return this.jobService.getJobPax(job.id , this.jwt).then( pasajeros => {
                jobCompleto.pasajeros = pasajeros;
              });
            }, error => {
              this.result  += '<br />Error buscando pasajeros : '  +  JSON.stringify(error);
              this.ref.detectChanges();
            }).then (nada => {
              return this.jobService.getJobMissionSheet(job.id, this.jwt).then(hojaMision => {
                jobCompleto.hojaMision = hojaMision;
              });
            }, error => {
              this.result  += '<br />Error buscando Hoja de mision : '  +  JSON.stringify(error);
              this.ref.detectChanges();
            }).then (nada => {
              return this.jobService.getJobMissionCheckList(job.id, this.jwt).then(checklist => {
                jobCompleto.checkList = checklist;
              }, error => {
                this.result  += '<br />Error buscando Hoja de mision : '  +  JSON.stringify(error);
                this.ref.detectChanges();
              });
            });
          }, error => {
            this.result  += '<br />Error buscando la lista de eventos : '  +  JSON.stringify(error);
            this.ref.detectChanges();
          }).then (data2 => {
            this.driverPopup = this.dialog.open(this.driverPopupComponent, config);
            console.log('Job Completo');
            console.log(jobCompleto);
            if ( this.jwtUser.roldb === 'conduccion') {
              jobCompleto.service.rolReserva = 'operation';
            }
            if ( this.jwtUser.roldb === 'protocolo') {
              jobCompleto.service.rolReserva = 'operation';
            }
            if ( this.jwtUser.roldb === 'dispatch') {
              jobCompleto.service.rolReserva = 'operation';
            }
            this.driverPopup.componentInstance.jobCompleto = jobCompleto;
            this.driverPopup.componentInstance.jwt = this.jwt;
            this.abriendoPopUp.next(false);
            this.subscriptions.push(this.driverPopup.afterClosed().subscribe(result => {
              console.log('pagina de eventos cerrada con result : ' + result);
              if (result && result.event === 'Close') {
                console.log('pagina de eventos cerrada devolviendo ');
                console.log( result);
                this.authService.logout();
                location.reload();
                this.result = 'Servicio  ' + job.numeroreserva + ' del ' + job.fecha + ' para ' + job.nombrePrimerPasajero + ' Cerrado. '
                  + ' Ultimo estado : ' + ((result.ultimoEstatu != null) ? result.ultimoEstatu : job.textoEventoActual);
                this.ref.detectChanges();
              }
            }));
            this.result  += '<br />Pop-up abierta ';
            return true;
          }, error => {
            this.result  += '<br />Error Abriendo Reserva : ' +  JSON.stringify(error);
            this.abriendoPopUp.next(false);
            this.ref.detectChanges();
          });
      },
      (error) => {
        this.result = ' Error 2 accediendo a la ubicacion : ' + error.message ;
        this.showGpsError = true;
        this.ref.detectChanges();
      }
    );
  }

  sendSatus(){
    console.log('enviando estado del jwt');
    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        console.log('gps ok');
        this.showGpsError = false;
        this.result = '';
        if ( !this.jwt) {
          // si no tenenmos Job o jwtUser hay un problema
          this.result = ' Servicio incompletamente descargado ' ;
          return;
        }
        firstValueFrom(this.jobService.saveStatusFromSMS(this.jwt, lat , lng))
          .then(data => {
            console.log('estado bien recibido !');
            this.result += '<br /><br /><br />' + data;
            this.estadoRecibido = true;
          }, error => {
            console.log(error);
            this.result  += '<br />Error enviando estado : '  +  JSON.stringify(error);
          }).then(data2 => {
            this.ref.detectChanges();
          });
      },
      (error) => {
        console.log('gps error');
        this.result = ' Error accediendo a la ubicacion : ' + error.message ;
        this.showGpsError = true;
        if ( !this.jwt) {
          // si no tenenmos Job o jwtUser hay un problema
          this.result += ' Servicio incompletamente descargado ' ;
          return;
        }
        firstValueFrom(this.jobService.saveStatusFromSMS(this.jwt, null, null))
          .then(data => {
            console.log('estado bien recibido !');
            this.result += '<br /><br /><br />' + data;
            this.estadoRecibido = true;
          }, error2 => {
            console.log(error);
            this.result  += '<br />Error enviando estado : '  +  JSON.stringify(error2);
          }).then(data2 => {
            this.ref.detectChanges();
          });
      }
    );
  }
}
