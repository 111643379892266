import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {VehiclesService} from '../../services/vehicles.service';
import {isPlatformBrowser} from '@angular/common';
import {VehicleCategory} from '../../../models/business-objects';

@Component({
  selector: 'app-fleet-wrapper',
  templateUrl: './fleet-wrapper.component.html',
  styleUrls: ['./fleet-wrapper.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition('* => toleft', [
        animate('1000ms', style({ transform: 'translateX(-100%)'} ))
      ]),
      transition('void => toLeft', [
        style({ transform: 'translateX(100%)' }),
        animate('1000ms')
      ])
    ]),
    trigger('slideRight', [
      transition('* => toRight', [
        animate('1000ms', style({ transform: 'translateX(100%)'} ))
      ]),
      transition('void => toRight', [
        style({ transform: 'translateX(-100%)' }),
        animate('1000ms')
      ])
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
    ]),

  ]
})

export class FleetWrapperComponent implements OnInit {
  vehicles: Array<any> = [];
  slides: Array<any> = [];
  cardsPerSlide = 3;
  showing = 0;
  toRight = 0;
  toLeft = 0;
  oldIndex = 0;
  translateX1 = 'translateX(-100%)';
  translateX2 = 'translateX(100%)';
  width = 960;
  public categories: VehicleCategory[] = [];
  constructor(private vehiclesService: VehiclesService, @Inject(PLATFORM_ID) private platformId: any ) {
      if (isPlatformBrowser(this.platformId)){
        this.width = window.innerWidth;
      }
    }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)){
      if ( this.width !== window.innerWidth) {
        // eso para evitar que en mobiles cuando aparezca o desaparexca la bara de url se reacalculen los slides
        this.width = window.innerWidth;
        this.reCalculateSides();
      }
    }
  }
  async ngOnInit() {
    this.vehicles = await this.vehiclesService.getVehicles('worldwide');
    if ( this.vehicles == null){
      this.vehiclesService.getVehiclesFromAPI(null).then(result => {
        this.vehicles = result ;
        console.log ('vehicles : ' + this.vehicles.length);
        this.reCalculateSides();
      });
    } else {
      this.reCalculateSides();
      console.log ('vehicles : ' + this.vehicles.length);
    }
    this.categories = await this.vehiclesService.getCategories();
    if ( this.categories == null){
      this.vehiclesService.getCategoriesFromAPI(null).then(result => {
        this.categories = result ;
      });
    }
  }
  reCalculateSides() {
    if (this.vehicles != null) {
      if (this.width > 1280) {
        this.cardsPerSlide = 3;
      } else if (this.width < 640) {
        this.cardsPerSlide = 1;
      } else {
        this.cardsPerSlide = 2;
      }
      this.slides = [];
      let i;
      for (i = 0; i < Math.ceil(this.vehicles.length / this.cardsPerSlide); i++) {
        const slide: Array<any> = [];
        let j;
        for (j = 0; j < this.cardsPerSlide; j++) {
          const vehicle = this.vehicles[i * this.cardsPerSlide + j];
          if (vehicle != null) {
            slide.push(vehicle);
            // console.log ("slides : "+ i + "slide : " + j +" vehicles: " + vehicle.name)
          }
        }
        this.slides.push(slide);
        // console.log ("slides : "+ i + "slide : " + j +" vehicles: " + slide.length + " id" + id.val)
      }
    }
  }

  nextSlide(index){
    this.oldIndex = this.showing;
    if (index != null) {
      if (this.oldIndex !== index ){
        if ( this.oldIndex > index){
          this.translateX1 = 'translateX(100%)';
          this.translateX2 = 'translateX(-100%)';
          this.showing = index;
          this.toRight += 1;
        } else {
          this.translateX1 = 'translateX(-100%)';
          this.translateX2 = 'translateX(100%)';
          this.toLeft += 1;
          this.showing = index;
        }
        console.log (' this.toRight' + this.toRight + ' this.toLEft' + this.toLeft );
      }
    }
  }
  getCategory(id: number): VehicleCategory {
    return this.categories.find(c => c.id === id);
  }
}
