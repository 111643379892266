
<!-- BEGIN .clearfix -->
<div class="service-rate-table-wrapper row justify-content-center ml-0 mr-0 pl-0 pr0">

  <!-- BEGIN .service-rate-table-inner-wrapper -->
  <div class="service-rate-table-inner-wrapper col-12 ml-0 mr-0 pl-0 pr0">
    <div class="row ml-0 mr-0 pl-0 pr-0">
      <div class="col-2 ml-0 mr-0 pl-0 pr-0"></div>
      <div class="service-rate-header col-3 ml-0 mr-0 "><p><strong>{{'Rate-1' | translate}}</strong> {{'Rate-1b' | translate}}*</p></div>
      <div class="service-rate-header col-3 ml-0 mr-0 "><p><strong>{{'Rate-2' | translate}}</strong>{{'Rate-2b' | translate}}*</p></div>
      <div class="service-rate-header col-2 ml-0 mr-0 "><p><strong>{{'Rate-3' | translate}}</strong>{{'Rate-3b' | translate}} </p></div>
      <div class="service-rate-header col-2 ml-0 mr-0 "><p><strong>{{'Rate-4' | translate}}</strong>{{'Rate-4b' | translate}} </p></div>
    </div>
    <div class="row ml-0 mr-0 pl-0 pr-0"  *ngFor="let car of rates">
      <div class="car-list-wrapper col-2 ml-0 mr-0 pl-0  pr-0">
          <div class="car-list-section p-0 h-100">
            <p style="min-width: 100px"><strong>{{car.name}}</strong></p>
            <img class="car-image" src="../../assets/img/fleet/{{car.image}}-110x60.jpg" alt="{{car.name}}" />
          </div>
      </div>
      <div class="service-rate-wrapper p-0  col-3 ml-0 mr-0 pl-0  pr-0">
          <div class="service-rate-section  h-100" >
            <p><strong><span>${{car.hourly | number:'1.0-2'}}</span>{{'Rate-1c' | translate}}</strong>{{'Rate-1d' | translate}}</p>
          </div>
      </div>
      <div class="service-rate-wrapper p-0  col-3 ml-0 mr-0 pl-0  pr-0">
          <div class="service-rate-section  h-100" >
            <p><strong><span>${{car.daily | number:'1.0-2'}}</span>{{'Rate-2c' | translate}}</strong>{{'Rate-2d' | translate}}</p>
          </div>
      </div>
      <div class="service-rate-wrapper p-0 col-2 ml-0 mr-0 pl-0  pr-0">
          <div class="service-rate-section  h-100">
              <p><strong><span>${{car.transfer | number:'1.0-2'}}</span>{{'Rate-3c' | translate}}</strong>{{'Rate-3d' | translate}}</p>
          </div>
      </div>
      <div class="service-rate-wrapper p-0 col-2 ml-0 mr-0 pl-0  pr-0">
        <div class="service-rate-section  h-100">
          <p><strong><span>${{car.add_km | number:'1.0-1'}}</span>{{'Rate-4c' | translate}}</strong>{{'Rate-4d' | translate}}</p>
        </div>
      </div>
    </div>
    <p class="mt-3 text-justify">{{'Rate-14' | translate}}</p>
    <p class="mt-3 rate-conditions-important text-justify"> <strong>{{'Rate-15' | translate}}</strong></p>
  </div>
</div>


