<div id="page-header">
    <h1>{{'AboutUs-0' | translate}}</h1>
    <div class="title-block3"></div>
    <p><a [routerLink]="'/home' | localize">{{'Home' | translate}}</a><i class="fa fa-angle-right"></i>{{'AboutUs-0' | translate}}</p>
  </div>

<div class="row justify-content-center me-1">
  <div class="content-wrapper col-lg-4 col-sm5 ms-1">

          <h3 class="text-uppercase">{{'AboutUs-0' | translate}}</h3>
          <div class="title-block3"></div>

          <p class="text-justify" [innerHTML]="'AboutUs-1' | translate" ></p>
          <p class="text-justify mb-5" [innerHTML]="'AboutUs-2' | translate" ></p>

          <h3 class="text-uppercase">{{'AboutUsTitle-1' | translate}}</h3>
          <div class="title-block3"></div>
          <p class="text-justify mb-5" [innerHTML]="'AboutUsText-1' | translate" ></p>
  </div>
  <div class="content-wrapper col-lg-4 col-sm5 ms-1 ">

    <h3 class="text-uppercase">{{'AboutUsTitle-2' | translate}}</h3>
    <div class="title-block3"></div>
    <p class="mb-5 text-justify" [innerHTML]="'AboutUsText-2' | translate" ></p>

    <h3  class="text-uppercase" >{{'AboutUsTitle-4' | translate}}</h3>
    <div class="title-block3"></div>

    <div class="video-wrapper video-wrapper-page mb-5">
      <iframe width="560" height="349" src="https://www.youtube.com/embed/415oRMx3_0M" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</div>

<div class="row justify-content-center mb-5 ms-1 me-1">
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/carservice' | localize" class="link-block-3"><span class="link-text me-2">{{'Fleet' | translate}}</span><span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/coverage' | localize" class="link-block-3"><span class="link-text me-2">{{'Coverage' | translate}}</span><span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/booking' | localize" class="link-block-3"><span class="link-text me-2">{{'BookNow' | translate}}</span><span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-4 col-sm-5 ms-1 me-1">
    <div class='row'>
      <div class="col">
        <h3 class="center-title text-uppercase">{{'AboutUsTitle-3' | translate}}</h3>
        <div class="title-block2"></div>
        <p class="text-justify" [innerHTML]="'AboutUsText-3' | translate" ></p>
        <ul class="border-list">
          <li class="text-justify">{{'AboutUsBullet-1' | translate}}</li>
          <li class="text-justify">{{'AboutUsBullet-2' | translate}}</li>
          <li class="text-justify">{{'AboutUsBullet-3' | translate}}</li>
          <li class="text-justify">{{'AboutUsBullet-4' | translate}}</li>
        </ul>
        <p  class="row text-justify "[innerHTML]="'AboutUsText-4' | translate" ></p>
      </div>
    </div>
  </div>
  <div class="content-wrapper col-lg-4 col-sm-6 mt-5">
    <div class="logo-block m-3">
      <a href="http://www.paxtraining.org/"><img src="../../../assets/img/logos/pax-medium.jpg" alt="pax chauffeur training"></a><br/>
    </div>
    <div class="logo-block m-3">
      <a href="http://www.limo.org/"><img src="../../../assets/img/logos/nla.jpg" alt="member of national limousine association">
      </a>
    </div>
    <div class="logo-block m-3">
      <a href="https://www.gbta.org/"><img src="../../../assets/img/logos/gbta.svg" alt="member of the Global Business TRavel Association" width="500px">
      </a>
    </div>
  </div>
</div>



