export const HOURS: any[] =  [
  { value: '00', label: '12am'},
  { value: '01', label: '01am'},
  { value: '02', label: '02am'},
  { value: '03', label: '03am'},
  { value: '04', label: '04am'},
  { value: '05', label: '05am'},
  { value: '06', label: '06am'},
  { value: '07', label: '07am'},
  { value: '08', label: '08am'},
  { value: '09', label: '09am'},
  { value: '10', label: '10am'},
  { value: '11', label: '11am'},
  { value: '12', label: '12pm'},
  { value: '13', label: '01pm'},
  { value: '14', label: '02pm'},
  { value: '15', label: '03pm'},
  { value: '16', label: '04pm'},
  { value: '17', label: '05pm'},
  { value: '18', label: '06pm'},
  { value: '19', label: '07pm'},
  { value: '20', label: '08pm'},
  { value: '21', label: '09pm'},
  { value: '22', label: '10pm'},
  { value: '23', label: '11pm'}
];
export const DURATIONS: any[] =  [
  { value: 2, label: '2 '},
  { value: 3, label: '3 '},
  { value: 4, label: '4 '},
  { value: 5, label: '5 '},
  { value: 6, label: '6 '},
  { value: 7, label: '7 '},
  { value: 8, label: '8 '},
  { value: 9, label: '9 '},
  { value: 10, label: '10 '},
  { value: 11, label: '11 '},
  { value: 12, label: '12 '},
  { value: 13, label: '13 '},
  { value: 14, label: '14 '},
  { value: 15, label: '15 '},
  { value: 16, label: '16 '},
  { value: 17, label: '17 '},
  { value: 18, label: '18 '},
  { value: 19, label: '19 '},
  { value: 20, label: '20 '},
  { value: 21, label: '21 '},
  { value: 22, label: '22 '},
  { value: 23, label: '23 '},
  { value: 24, label: '24 '}
];
export const TIMES: any[] = [
  { value: '00', label: '00'},
  { value: '15', label: '15'},
  { value: '30', label: '30'},
  { value: '45', label: '45'}
];
