import {Component, Input, OnInit} from '@angular/core';
import {VehicleCategory} from '../../../models/business-objects';

@Component({
  selector: 'app-rate-table',
  templateUrl: './rate-table.component.html',
  styleUrls: ['./rate-table.component.scss']
})
export class RateTableComponent implements OnInit {
@Input() rates: VehicleCategory[];
  constructor() { }
  ngOnInit() {
  }

}
