<div class="row justify-content-center">
  <div class="content-wrapper col-lg-10 col-sm-11 ms-1 me-1">
    <h3 class="center-title text-uppercase" >Status Report</h3>
    <div class="title-block2"></div>
    <h3 class="center-title"  [hidden]="!(loadingDetails | async)">Cargando Reserva</h3>
    <h3 class="center-title"  [hidden]="!(abriendoPopUp | async)">Abriendo Reserva</h3>
    <div class="title-block2"  [hidden]="!((loadingDetails | async) || (abriendoPopUp | async) )" ></div>
    <div class = "row w-100 "  [hidden]="!((loadingDetails | async) || (abriendoPopUp | async) )">
      <mat-spinner style="margin:0 auto;" >
      </mat-spinner>
    </div>
    <div [hidden]="(loadingDetails | async)">
      <div>
        <p [innerHTML]="result | translate"></p>
      </div>
      <div   [hidden]="!showGpsError">
        <h3>{{'El acceso a la informacion de ubicación no fue autorizado, debe autorizarlo para poder seguir' | translate}}</h3>
      </div>
    <div [hidden]="(loadingDetails | async) || estadoRecibido || !smsReport || idEstadoAReportar === '0' " class="mb-3">
      <button mat-raised-button (click)="sendSatus()" > Enviar evento : {{ ('app.statusCode.' + idEstadoAReportar) | translate}}</button>
    </div>
    <div   [hidden]="(loadingDetails | async) || !(idEstadoAReportar === '0' || !smsReport)">
        <button mat-raised-button (click)="initAndOpen()" > Abrir la reserva </button>
    </div>
    </div>
  </div>
</div>

