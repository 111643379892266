export class ContactRequest {
    personalData?: PersonalData;
    rating ? = 0;
    subject ? = '';
    content ? = '';
  }

export class PersonalData {
    firstName ? = '';
    lastName ? = '';
    email ? = '';
    cellPhone ? = '';
    phone ? = '';
    company ? = '';
}

export class MessageData {
  from: string;
  to: string;
  cc ?: string;
  bcc ?: string;
  subject: string;
  html: string;
  apiKey: string;
  login: string;
  recaptchaMessage: string;
  attachment?: string;
}

