import {Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  bookingWidgetFormat = 'horizontal';
  width = 960;
  constructor(private title: Title, private meta: Meta, private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any,
              private canonicalAndMetaService: CanonicalAndMetaService) {
    translate.setDefaultLang('en');
    if (isPlatformBrowser(this.platformId)) {
      this.width = window.innerWidth;
    }
    this.chooseBookingWidget();
  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('home').then(async result => {
      await this.canonicalAndMetaService.setMetas('home', true, '', 'https://www.vehiculosvip.com/assets/images/fleet/8.jpg', '600', '300');
    });

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.width !== event.target.innerWidth ) {
      this.width = event.target.innerWidth;
      this.chooseBookingWidget();
    }
  }
  chooseBookingWidget(){
    if ( this.width > 1091) {
      this.bookingWidgetFormat = 'horizontal';
    } else {
      this.bookingWidgetFormat = 'vertical';
    }
  }
}
