<div class="widget">
    <div class="widget-block"></div>

    <h3>Tags</h3>

    <div class="tagcloud" >
      <a *ngFor="let tag of tagList" [routerLink]="('/news' | localize) + '/' + tag" title="news">{{tag | translate}}</a>
    </div>


  <!-- END .widget -->
  </div>
