import {Component, OnInit} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private canonicalAndMetaService: CanonicalAndMetaService) {

  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('faq').then(async result => {
      await this.canonicalAndMetaService.setMetas('faq', true, '');
    });
  }

}
