import {Component, OnInit} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(    private canonicalAndMetaService: CanonicalAndMetaService) {


  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('about-us').then(async result => {
      await this.canonicalAndMetaService.setMetas('aboutus', true, '');
    });
  }

}
