import {NgModule, isDevMode} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule, routingComponent} from './app-routing.module';
import {ToastrModule} from 'ngx-toastr';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, provideHttpClient, withFetch} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './services/auth-guard.service';
import {FleetWrapperComponent} from './widget/fleet-wrapper/fleet-wrapper.component';
import {VehicleOverviewComponent} from './pages/vehicle-overview/vehicle-overview.component';
import {TestimonialsComponent} from './pages/testimonials/testimonials.component';
import {TestimonialsWrapperComponent} from './widget/testimonials-wrapper/testimonials-wrapper.component';
import {RateComponent} from './pages/rate/rate.component';
import {HorizontalMenuComponent} from './navigation/horizontal-menu/horizontal-menu.component';
import {NewsComponent} from './pages/news/news.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {ContactComponent} from './pages/contact/contact.component';
import {MessageService} from './services/message.service';
import {SurveyComponent} from './pages/survey/survey.component';
import {StatusComponent} from './pages/status/status.component';
import {RateTableComponent} from './widget/rate-table/rate-table.component';
import {NewsWidgetComponent} from './widget/news-widget/news-widget.component';
import {NewsWrapperComponent} from './widget/news-wrapper/news-wrapper.component';
import {TagCloudWidgetComponent} from './widget/tag-cloud-widget/tag-cloud-widget.component';
import {LoginComponent} from './pages/login/login.component';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {LoadingInterceptor} from './shared/interceptors/loading.interceptor';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AutocompleteModule} from './widget/autocomplete/autocomplete.module';
import {CompanyServicesComponent} from './pages/company-services/company-services.component';
import {PipeModule} from './shared/pipes/pipe.module';
import {CarouselComponent} from './widget/carousel/carousel.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

// DIRECTIVES
import {ToggleFullscreenDirective} from './shared/directives/toggle-fullscreen.directive';
import {TopMenuLinkDirective} from './shared/directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './shared/directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './shared/directives/topmenu-anchor-toggle.directive';
import {TopMenuDirective} from './shared/directives/top-menu.directive';
import {SidebarLinkDirective} from './shared/directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './shared/directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './shared/directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './shared/directives/sidebar.directive';
import {VerticalMenuComponent} from './navigation/vertical-menu/vertical-menu.component';
import {MatSpinnerOverlayComponent} from './widget/mat-spinner-overlay/mat-spinner-overlay.component';
import {StaffComponent} from './pages/staff/staff.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {LeafletService} from './services/leaflet.service';
import {NgxCaptchaModule} from 'ngx-captcha';
import {GoogleAnalyticsGTagComponent} from './widget/google-analytics-gtag/google-analytics-gtag.component';
import {MatDialogRef} from '@angular/material/dialog';
import {BioSecurityComponent} from './pages/bio-security/bio-security.component';
import {VehicleCategoriesComponent} from './pages/vehicle-categories/vehicle-categories.component';
import {FaqComponent} from './pages/faq/faq.component';
import {OnboardComponent} from './pages/onboard/onboard.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import {RatingWidgetComponent} from './widget/rating-widget/rating-widget.component';
import {BookingWidgetModule} from './widget/booking-widget/booking-widget.module';
import {PricingWidgetModule} from './widget/pricing-widget/pricing-widget.module';
import {VehicleWidgetModule} from './widget/vehicle-widget/vehicle-widget.module';
import {SpinnerComponent} from './navigation/spinner/spinner.component';
import {BookingService} from './services/booking.service';
import {LocationService} from './services/location.service';
import {DataService} from './services/data.service';
import {CanonicalAndMetaService} from './services/canonical-and-meta.service';
import {RateService} from './services/rate.service';
import {VehiclesService} from './services/vehicles.service';
import {LoaderService} from './services/loader.service';
import {SurveyService} from './services/survey.service';
import {StaffService} from './services/staff.service';
import {TestimonialsService} from './services/testimonials.service';
import {NewsService} from './services/news.service';
import {InstagramService} from './services/instagram.service';
import {JobService} from './services/job.service';
import {HomeComponent} from './pages/home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwPush } from '@angular/service-worker';
import {HandleUnrecoverableStateService} from './services/service.worker/handle-unrecoverable-state.service';
import {PromptUpdateService} from './services/service.worker/prompt-update.service';
import {LogUpdateService} from './services/service.worker/log-update.service';
import {CheckForUpdateService} from './services/service.worker/check-for-update.service';


import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {NgxIntlTelInputModule} from '@sarveshpop/ngx-intl-tel-input';
import {provideClientHydration} from '@angular/platform-browser';

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localePt, 'pt', localePtExtra);

const firebaseConfig = {
  apiKey: 'AIzaSyBzYaAe0u5GtDOtIFicX-xgy8Imwh6zo0M',
  authDomain: 'website-vehiculosvip.firebaseapp.com',
  databaseURL: 'https://website-vehiculosvip.firebaseio.com',
  projectId: 'website-vehiculosvip',
  storageBucket: 'website-vehiculosvip.appspot.com',
  messagingSenderId: '851560865422',
  appId: '1:851560865422:web:55d605ed5cdb7aa7'
};




@NgModule({
    declarations: [
        AppComponent,
        routingComponent,
        HomeComponent,
        FleetWrapperComponent,
        VehicleOverviewComponent,
        TestimonialsComponent,
        TestimonialsWrapperComponent,
        RateComponent,
        HorizontalMenuComponent,
        NewsComponent,
        AboutUsComponent,
        ContactComponent,
        SurveyComponent,
        RateTableComponent,
        NewsWidgetComponent,
        NewsWrapperComponent,
        TagCloudWidgetComponent,
        LoginComponent,
        CompanyServicesComponent,
        CarouselComponent,
        StatusComponent,
        // DIRECTIVES
        ToggleFullscreenDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        VerticalMenuComponent,
        MatSpinnerOverlayComponent,
        StaffComponent,
        ForgotPasswordComponent,
        GoogleAnalyticsGTagComponent,
        BioSecurityComponent,
        VehicleCategoriesComponent,
        FaqComponent,

        OnboardComponent,
        RatingWidgetComponent,
        SpinnerComponent,
        ConfirmationDialogComponent,
    ],
    imports: [
        HttpClientJsonpModule,
        AutocompleteModule,
        MaterialModule,
        NgxCaptchaModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        NgbModule,
        NgxSpinnerModule,
        GoogleMapsModule,
        NgClickOutsideDirective,
        NgbDropdownModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        TooltipModule,
        PipeModule,
        NgbModule,
        BookingWidgetModule,
        PricingWidgetModule,
        VehicleWidgetModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: true,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
      {
          provide: NgbDateAdapter,
          useClass: NgbDateNativeAdapter
      },
      provideHttpClient(withFetch()),
      AuthService,
      AuthGuard,
      {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
      {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
      {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
      MessageService,
      LeafletService,
      BookingService,
      LocationService,
      DataService,
      CanonicalAndMetaService,
      AuthGuard,
      AuthService,
      RateService,
      VehiclesService,
      LoaderService,
      SurveyService,
      StaffService,
      SurveyService,
      SurveyService,
      TestimonialsService,
      NewsService,
      InstagramService,
      JobService,
      HandleUnrecoverableStateService,
      PromptUpdateService,
      LogUpdateService,
      CheckForUpdateService,
      provideClientHydration(),
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }


