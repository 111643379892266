<app-spinner></app-spinner>
<div>
  <app-nav (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-nav>
  <div class="wrapper" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();" (window:resize)="onResize($event)">
    <app-menu appTopMenu *ngIf="menuPosition === 'Top' && !displayOverlayMenu"></app-menu>
    <div appSidebar *ngIf="menuPosition === 'Side' || displayOverlayMenu"
         [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' }" class="app-sidebar"
         (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)" data-active-color="white"
         [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
         [attr.data-image]="bgImage">
      <app-vertical-menu></app-vertical-menu>
      <div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
           *ngIf="config?.layout.sidebar.backgroundImage"></div>
    </div>
    <div class="main-panel">

          <router-outlet></router-outlet>

    <!-- FOOTER -->

    <!-- Scroll to top button -->
    <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i class="ft-arrow-up"></i></button>
  </div>
  </div>
  <app-footer></app-footer>
  <!-- Google analitics script -->
  <app-google-analytics-gtag></app-google-analytics-gtag>
</div>
