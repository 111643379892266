import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {environment} from '../../environments/environment';
import {Fuec} from '../../models/business-objects';

@Injectable({
  providedIn: 'root'
})
export class FuecService {
  UrlAPI: string = environment.apiURL;
  constructor(private http: HttpClient) { }

  getFuecById(id: string): Promise<Fuec> {
    let headers = new HttpHeaders();
    const body = {id};
    headers = headers.set('Accept', 'application/json');
    return firstValueFrom(this.http.post<Fuec>(this.UrlAPI + environment.URL_FUEC, body, {headers, responseType: 'json'}));
  }

}
