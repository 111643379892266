import {Countries} from '../../../models/payment';

export const LISTITEMS: any[] = [
        {url: '/dashboard/dashboard1', name: 'Dashboard 1', icon: 'ft-home'},
        {url: '/dashboard/dashboard2', name: 'Dashboard 2', icon: 'ft-home'},
        {url: '/uikit/colorpalettes', name: 'Color Palette', icon: 'ft-droplet'},
        {url: '/inbox', name: 'Email', icon: 'ft-mail'},
        {url: '/chat', name: 'Chat', icon: 'ft-message-square'},
        {url: '/taskboard', name: 'Task Board', icon: 'ft-file-text'},
        {url: '/calendar', name: 'Calendar', icon: 'ft-calendar'},
        {url: '/uikit/grids', name: 'Grid', icon: 'ft-grid'},
        {url: '/uikit/typography', name: 'Typography', icon: 'ft-type'},
        {url: '/uikit/syntaxhighlighter', name: 'Syntax Highlighter', icon: 'ft-edit-1'},
        {url: '/uikit/helperclasses', name: 'Helper Classes', icon: 'ft-help-circle'},
        {url: '/uikit/textutilities', name: 'Text Utilities', icon: 'ft-underline'},
        {url: '/uikit/feather', name: 'Feather Icon', icon: 'ft-feather'},
        {url: '/uikit/font-awesome', name: 'Font Awesome Icon', icon: 'ft-flag'},
        {url: '/uikit/simple-line', name: 'Simple Line Icon', icon: 'ft-navigation'},
        {url: '/components/buttons', name: 'Buttons', icon: 'ft-plus-circle'},
        {url: '/components/alerts', name: 'Alerts', icon: 'ft-alert-triangle'},
        {url: '/components/badges', name: 'Badges', icon: 'ft-square'},
        {url: '/components/dropdowns', name: 'Dropdowns', icon: 'ft-pocket'},
        {url: '/components/media', name: 'Media Objects', icon: 'ft-image'},
        {url: '/components/pagination', name: 'Pagination', icon: 'ft-chevrons-right'},
        {url: '/components/progress', name: 'Progress Bars', icon: 'ft-sliders'},
        {url: '/components/models', name: 'Modals', icon: 'ft-layout'},
        {url: '/components/collapse', name: 'Collapse', icon: 'ft-minimize'},
        {url: '/components/lists', name: 'List Group', icon: 'ft-list'},
        {url: '/components/accordion', name: 'Accordion', icon: 'ft-map'},
        {url: '/components/carousel', name: 'Carousel', icon: 'ft-map'},
        {url: '/forms/datepicker', name: 'Datepicker', icon: 'ft-calendar'},
        {url: '/components/popover', name: 'Popover', icon: 'ft-message-square'},
        {url: '/components/rating', name: 'Rating', icon: 'ft-award'},
        {url: '/tables/tables', name: 'Tables', icon: 'ft-align-justify'},
        {url: '/components/tabs', name: 'Tabs', icon: 'ft-server'},
        {url: '/forms/timepicker', name: 'Tabs', icon: 'ft-clock'},
        {url: '/components/tooltip', name: 'Tooltip', icon: 'ft-message-square'},
        {url: '/components/typeahead', name: 'Typeahead', icon: 'ft-type'},
        {url: '/components/toastr', name: 'Toastr', icon: 'ft-map'},
        {url: '/forms/select', name: 'Select', icon: 'ft-chevrons-down'},
        {url: '/components/nouislider', name: 'NoUI Slider', icon: 'ft-sliders'},
        {url: '/forms/editor', name: 'Editor', icon: 'ft-edit'},
        {url: '/components/upload', name: 'Upload', icon: 'ft-upload'},
        {url: '/components/dragndrop', name: 'Drag and Drop', icon: 'ft-copy'},
        {url: '/components/tour', name: 'Tour', icon: 'ft-send'},
        {url: '/components/cropper', name: 'Image Cropper', icon: 'ft-crop'},
        {url: '/forms/tags', name: 'Input Tags', icon: 'ft-tag'},
        {url: '/forms/switch', name: 'Switch', icon: 'ft-toggle-left'},
        {url: '/forms/inputs', name: 'Form Inputs', icon: 'ft-file-text'},
        {url: '/forms/input-groups', name: 'Form Input Groups', icon: 'ft-book-open'},
        {url: '/forms/radio', name: 'Radio', icon: 'ft-target'},
        {url: '/forms/checkbox', name: 'Checkbox', icon: 'ft-check-square'},
        {url: '/forms/layout', name: 'Form Layouts', icon: 'ft-layout'},
        {url: '/forms/validation', name: 'Form Validation', icon: 'ft-check-circle'},
        {url: '/forms/archwizard', name: 'Form Wizard', icon: 'ft-layers'},
        {url: '/tables/basic', name: 'Basic Tables', icon: 'ft-sidebar'},
        {url: '/tables/extended', name: 'Extended Tables', icon: 'ft-grid'},
        {url: '/datatables', name: 'DataTables', icon: 'ft-layout'},
        {url: '/cards/basic', name: 'Basic Cards', icon: 'ft-layers'},
        {url: '/cards/advanced', name: 'Advanced Cards', icon: 'ft-grid'},
        {url: '/maps/google', name: 'Maps', icon: 'ft-map'},
        {url: '/charts/ngx', name: 'NGX Charts', icon: 'ft-bar-chart-2'},
        {url: '/charts/chartjs', name: 'ChartJs', icon: 'ft-pie-chart'},
        {url: '/charts/chartist', name: 'Chartist', icon: 'ft-bar-chart-line'},
        {url: '/charts/apex', name: 'Apex', icon: 'ft-bar-chart-line'},
        {url: '/pages/forgotpassword', name: 'Forgot Password', icon: 'ft-alert-octagon'},
        {url: '/pages/login', name: 'Login', icon: 'ft-log-in'},
        {url: '/pages/register', name: 'Register', icon: 'ft-user-plus'},
        {url: '/pages/lockscreen', name: 'Lock Screen', icon: 'ft-lock'},
        {url: '/pages/horizontaltimeline', name: 'Horizontal Timeline', icon: 'ft-credit-card'},
        {url: '/pages/timeline-vertical-center', name: 'Vertical Timeline Center', icon: 'ft-sidebar'},
        {url: '/pages/timeline-vertical-left', name: 'Vertical Timeline Left', icon: 'ft-sidebar'},
        {url: '/pages/timeline-vertical-right', name: 'Vertical Timeline Right', icon: 'ft-sidebar'},
        {url: '/pages/account-settings', name: 'Account Settings', icon: 'ft-settings'},
        {url: '/pages/profile', name: 'User Profile', icon: 'ft-user'},
        {url: '/pages/invoice', name: 'Invoice', icon: 'ft-file-text'},
        {url: '/pages/error', name: 'Error', icon: 'ft-alert-triangle'},
        {url: '/pages/comingsoon', name: 'Coming Soon', icon: 'ft-clock'},
        {url: '/pages/maintenance', name: 'Maintenance', icon: 'ft-settings'},
        {url: '/pages/gallery', name: 'Gallery', icon: 'ft-image'},
        {url: '/pages/search', name: 'Search', icon: 'ft-search'},
        {url: '/pages/faq', name: 'FAQ', icon: 'ft-help-circle'},
        {url: '/pages/kb', name: 'Knowledge Base', icon: 'ft-info'},
        {url: '/pages/users-list', name: 'Users List', icon: 'ft-search'},
        {url: '/pages/users-view', name: 'Users View', icon: 'ft-search'},
        {url: '/pages/users-edit', name: 'Users Edit', icon: 'ft-search'}
    ];

export let countries: Countries [] = [
  { code: 'AF', code3: 'AFG', name: 'Afganistán', number: '4' },
  { code: 'AL', code3: 'ALB', name: 'Albania', number: '8' },
  { code: 'DZ', code3: 'DZA', name: 'Argelia', number: '12' },
  { code: 'AS', code3: 'ASM', name: 'Samoa Americana', number: '16' },
  { code: 'AD', code3: 'AND', name: 'Andorra', number: '20' },
  { code: 'AO', code3: 'AGO', name: 'Angola', number: '24' },
  { code: 'AI', code3: 'AIA', name: 'Anguilla', number: '660' },
  { code: 'AQ', code3: 'ATA', name: 'Antarctica', number: '10' },
  { code: 'AG', code3: 'ATG', name: 'Antigua y Barbuda', number: '28' },
  { code: 'AR', code3: 'ARG', name: 'Argentina', number: '32' },
  { code: 'AM', code3: 'ARM', name: 'Armenia', number: '51' },
  { code: 'AW', code3: 'ABW', name: 'Aruba', number: '533' },
  { code: 'AU', code3: 'AUS', name: 'Australia', number: '36' },
  { code: 'AT', code3: 'AUT', name: 'Austria', number: '40' },
  { code: 'AZ', code3: 'AZE', name: 'Azerbaiyán', number: '31' },
  { code: 'BS', code3: 'BHS', name: 'Bahamas', number: '44' },
  { code: 'BH', code3: 'BHR', name: 'Bahrein', number: '48' },
  { code: 'BD', code3: 'BGD', name: 'Bangladesh', number: '50' },
  { code: 'BB', code3: 'BRB', name: 'Barbados', number: '52' },
  { code: 'BY', code3: 'BLR', name: 'Bielorrusia', number: '112' },
  { code: 'BE', code3: 'BEL', name: 'Bélgica', number: '56' },
  { code: 'BZ', code3: 'BLZ', name: 'Belice', number: '84' },
  { code: 'BJ', code3: 'BEN', name: 'Benín', number: '204' },
  { code: 'BM', code3: 'BMU', name: 'Bermudas', number: '60' },
  { code: 'BT', code3: 'BTN', name: 'Bután', number: '64' },
  { code: 'BO', code3: 'BOL', name: 'Bolivia', number: '68' },
  { code: 'BQ', code3: 'BES', name: 'Bonaire, Sint Eustatius and Saba', number: '535' },
  { code: 'BA', code3: 'BIH', name: 'Bosnia-Herzegovina', number: '70' },
  { code: 'BW', code3: 'BWA', name: 'Botsuana', number: '72' },
  { code: 'BV', code3: 'BVT', name: 'Bouvet Island', number: '74' },
  { code: 'BR', code3: 'BRA', name: 'Brasil', number: '76' },
  { code: 'IO', code3: 'IOT', name: 'British Indian Ocean Territory', number: '86' },
  { code: 'BN', code3: 'BRN', name: 'Brunei Darussalam', number: '96' },
  { code: 'BG', code3: 'BGR', name: 'Bulgaria', number: '100' },
  { code: 'BF', code3: 'BFA', name: 'Burkina Faso', number: '854' },
  { code: 'BI', code3: 'BDI', name: 'Burundi', number: '108' },
  { code: 'CV', code3: 'CPV', name: 'Cabo Verde', number: '132' },
  { code: 'KH', code3: 'KHM', name: 'Camboya', number: '116' },
  { code: 'CM', code3: 'CMR', name: 'Camerún', number: '120' },
  { code: 'CA', code3: 'CAN', name: 'Canadá', number: '124' },
  { code: 'KY', code3: 'CYM', name: 'Islas Caimán', number: '136' },
  { code: 'CF', code3: 'CAF', name: 'República Centroafricana', number: '140' },
  { code: 'TD', code3: 'TCD', name: 'Chad', number: '148' },
  { code: 'CL', code3: 'CHL', name: 'Chile', number: '152' },
  { code: 'CN', code3: 'CHN', name: 'China', number: '156' },
  { code: 'CX', code3: 'CXR', name: 'Isla de Navidad, Isla Christmas', number: '162' },
  { code: 'CC', code3: 'CCK', name: 'Islas Cocos', number: '166' },
  { code: 'CO', code3: 'COL', name: 'Colombia', number: '170' },
  { code: 'KM', code3: 'COM', name: 'Comores', number: '174' },
  { code: 'CD', code3: 'COD', name: 'Congo (the Democratic Republic of the)', number: '180' },
  { code: 'CG', code3: 'COG', name: 'Congo (the)', number: '178' },
  { code: 'CK', code3: 'COK', name: 'Islas Cook', number: '184' },
  { code: 'CR', code3: 'CRI', name: 'Costa Rica', number: '188' },
  { code: 'HR', code3: 'HRV', name: 'Croacia', number: '191' },
  { code: 'CU', code3: 'CUB', name: 'Cuba', number: '192' },
  { code: 'CW', code3: 'CUW', name: 'Curaçao', number: '531' },
  { code: 'CY', code3: 'CYP', name: 'Chipre', number: '196' },
  { code: 'CZ', code3: 'CZE', name: 'Czechia', number: '203' },
  { code: 'CI', code3: 'CIV', name: 'Côte d\'Ivoire', number: '384' },
  { code: 'DK', code3: 'DNK', name: 'Dinamarca', number: '208' },
  { code: 'DJ', code3: 'DJI', name: 'Djibouti, Yibuti', number: '262' },
  { code: 'DM', code3: 'DMA', name: 'Dominica', number: '212' },
  { code: 'DO', code3: 'DOM', name: 'Dominicana, República', number: '214' },
  { code: 'EC', code3: 'ECU', name: 'Ecuador', number: '218' },
  { code: 'EG', code3: 'EGY', name: 'Egipto', number: '818' },
  { code: 'SV', code3: 'SLV', name: 'El Salvador', number: '222' },
  { code: 'GQ', code3: 'GNQ', name: 'Guinea Ecuatorial', number: '226' },
  { code: 'ER', code3: 'ERI', name: 'Eritrea', number: '232' },
  { code: 'EE', code3: 'EST', name: 'Estonia', number: '233' },
  { code: 'SZ', code3: 'SWZ', name: 'Eswatini', number: '748' },
  { code: 'ET', code3: 'ETH', name: 'Etiopía', number: '231' },
  { code: 'FK', code3: 'FLK', name: 'Islas Malvinas', number: '238' },
  { code: 'FO', code3: 'FRO', name: 'Islas Feroe', number: '234' },
  { code: 'FJ', code3: 'FJI', name: 'Fiyi', number: '242' },
  { code: 'FI', code3: 'FIN', name: 'Finlandia', number: '246' },
  { code: 'FR', code3: 'FRA', name: 'Francia', number: '250' },
  { code: 'GF', code3: 'GUF', name: 'Guayana Francesa', number: '254' },
  { code: 'PF', code3: 'PYF', name: 'Polinesia Francesa', number: '258' },
  { code: 'TF', code3: 'ATF', name: 'Tierras Australes y Antárticas Francesas', number: '260' },
  { code: 'GA', code3: 'GAB', name: 'Gabón', number: '266' },
  { code: 'GM', code3: 'GMB', name: 'Gambia', number: '270' },
  { code: 'GE', code3: 'GEO', name: 'Georgia', number: '268' },
  { code: 'DE', code3: 'DEU', name: 'Alemania', number: '276' },
  { code: 'GH', code3: 'GHA', name: 'Ghana', number: '288' },
  { code: 'GI', code3: 'GIB', name: 'Gibraltar', number: '292' },
  { code: 'GR', code3: 'GRC', name: 'Grecia', number: '300' },
  { code: 'GL', code3: 'GRL', name: 'Groenlandia', number: '304' },
  { code: 'GD', code3: 'GRD', name: 'Granada', number: '308' },
  { code: 'GP', code3: 'GLP', name: 'Guadalupe', number: '312' },
  { code: 'GU', code3: 'GUM', name: 'Guam', number: '316' },
  { code: 'GT', code3: 'GTM', name: 'Guatemala', number: '320' },
  { code: 'GG', code3: 'GGY', name: 'Guernsey', number: '831' },
  { code: 'GN', code3: 'GIN', name: 'República Guinea', number: '324' },
  { code: 'GW', code3: 'GNB', name: 'Guinea Bissau', number: '624' },
  { code: 'GY', code3: 'GUY', name: 'Guyana', number: '328' },
  { code: 'HT', code3: 'HTI', name: 'Haiti', number: '332' },
  { code: 'HM', code3: 'HMD', name: 'Heard Island and McDonald Islands', number: '334' },
  { code: 'VA', code3: 'VAT', name: 'Ciudad del Vaticano', number: '336' },
  { code: 'HN', code3: 'HND', name: 'Honduras', number: '340' },
  { code: 'HK', code3: 'HKG', name: 'Hong Kong', number: '344' },
  { code: 'HU', code3: 'HUN', name: 'Hungría', number: '348' },
  { code: 'IS', code3: 'ISL', name: 'Islandia', number: '352' },
  { code: 'IN', code3: 'IND', name: 'India', number: '356' },
  { code: 'ID', code3: 'IDN', name: 'Indonesia', number: '360' },
  { code: 'IR', code3: 'IRN', name: 'Irán', number: '364' },
  { code: 'IQ', code3: 'IRQ', name: 'Iraq', number: '368' },
  { code: 'IE', code3: 'IRL', name: 'Irlanda', number: '372' },
  { code: 'IM', code3: 'IMN', name: 'Isle of Man', number: '833' },
  { code: 'IL', code3: 'ISR', name: 'Israel', number: '376' },
  { code: 'IT', code3: 'ITA', name: 'Italia', number: '380' },
  { code: 'JM', code3: 'JAM', name: 'Jamaica', number: '388' },
  { code: 'JP', code3: 'JPN', name: 'Japón', number: '392' },
  { code: 'JE', code3: 'JEY', name: 'Jersey', number: '832' },
  { code: 'JO', code3: 'JOR', name: 'Jordania', number: '400' },
  { code: 'KZ', code3: 'KAZ', name: 'Kazajstán', number: '398' },
  { code: 'KE', code3: 'KEN', name: 'Kenia', number: '404' },
  { code: 'KI', code3: 'KIR', name: 'Kiribati', number: '296' },
  { code: 'KP', code3: 'PRK', name: 'Corea del Norte', number: '408' },
  { code: 'KR', code3: 'KOR', name: 'Corea del Sur', number: '410' },
  { code: 'KW', code3: 'KWT', name: 'Kuwait', number: '414' },
  { code: 'KG', code3: 'KGZ', name: 'Kirguistán', number: '417' },
  { code: 'LA', code3: 'LAO', name: 'Laos', number: '418' },
  { code: 'LV', code3: 'LVA', name: 'Letonia', number: '428' },
  { code: 'LB', code3: 'LBN', name: 'Líbano', number: '422' },
  { code: 'LS', code3: 'LSO', name: 'Lesotho', number: '426' },
  { code: 'LR', code3: 'LBR', name: 'Liberia', number: '430' },
  { code: 'LY', code3: 'LBY', name: 'Libia', number: '434' },
  { code: 'LI', code3: 'LIE', name: 'Liechtenstein', number: '438' },
  { code: 'LT', code3: 'LTU', name: 'Lituania', number: '440' },
  { code: 'LU', code3: 'LUX', name: 'Luxemburgo', number: '442' },
  { code: 'MO', code3: 'MAC', name: 'Macao', number: '446' },
  { code: 'MG', code3: 'MDG', name: 'Madagascar', number: '450' },
  { code: 'MW', code3: 'MWI', name: 'Malawi', number: '454' },
  { code: 'MY', code3: 'MYS', name: 'Malasia', number: '458' },
  { code: 'MV', code3: 'MDV', name: 'Maldivas', number: '462' },
  { code: 'ML', code3: 'MLI', name: 'Malí', number: '466' },
  { code: 'MT', code3: 'MLT', name: 'Malta', number: '470' },
  { code: 'MH', code3: 'MHL', name: 'Islas Marshall', number: '584' },
  { code: 'MQ', code3: 'MTQ', name: 'Martinica', number: '474' },
  { code: 'MR', code3: 'MRT', name: 'Mauritania', number: '478' },
  { code: 'MU', code3: 'MUS', name: 'Mauricio', number: '480' },
  { code: 'YT', code3: 'MYT', name: 'Mayotte', number: '175' },
  { code: 'MX', code3: 'MEX', name: 'México', number: '484' },
  { code: 'FM', code3: 'FSM', name: 'Micronesia', number: '583' },
  { code: 'MD', code3: 'MDA', name: 'Moldova', number: '498' },
  { code: 'MC', code3: 'MCO', name: 'Mónaco', number: '492' },
  { code: 'MN', code3: 'MNG', name: 'Mongolia', number: '496' },
  { code: 'ME', code3: 'MNE', name: 'Montenegro', number: '499' },
  { code: 'MS', code3: 'MSR', name: 'Montserrat', number: '500' },
  { code: 'MA', code3: 'MAR', name: 'Marruecos', number: '504' },
  { code: 'MZ', code3: 'MOZ', name: 'Mozambique', number: '508' },
  { code: 'MM', code3: 'MMR', name: 'Myanmar', number: '104' },
  { code: 'NA', code3: 'NAM', name: 'Namibia', number: '516' },
  { code: 'NR', code3: 'NRU', name: 'Nauru', number: '520' },
  { code: 'NP', code3: 'NPL', name: 'Nepal', number: '524' },
  { code: 'NL', code3: 'NLD', name: 'Países Bajos, Holanda', number: '528' },
  { code: 'NC', code3: 'NCL', name: 'Nueva Caledonia', number: '540' },
  { code: 'NZ', code3: 'NZL', name: 'Nueva Zelanda', number: '554' },
  { code: 'NI', code3: 'NIC', name: 'Nicaragua', number: '558' },
  { code: 'NE', code3: 'NER', name: 'Niger', number: '562' },
  { code: 'NG', code3: 'NGA', name: 'Nigeria', number: '566' },
  { code: 'NU', code3: 'NIU', name: 'Niue', number: '570' },
  { code: 'NF', code3: 'NFK', name: 'Norfolk Island', number: '574' },
  { code: 'MP', code3: 'MNP', name: 'Marianas del Norte', number: '580' },
  { code: 'NO', code3: 'NOR', name: 'Noruega', number: '578' },
  { code: 'OM', code3: 'OMN', name: 'Omán', number: '512' },
  { code: 'PK', code3: 'PAK', name: 'Pakistán', number: '586' },
  { code: 'PW', code3: 'PLW', name: 'Palau', number: '585' },
  { code: 'PS', code3: 'PSE', name: 'Palestine', number: '275' },
  { code: 'PA', code3: 'PAN', name: 'Panamá', number: '591' },
  { code: 'PG', code3: 'PNG', name: 'Papúa-Nueva Guinea', number: '598' },
  { code: 'PY', code3: 'PRY', name: 'Paraguay', number: '600' },
  { code: 'PE', code3: 'PER', name: 'Perú', number: '604' },
  { code: 'PH', code3: 'PHL', name: 'Filipinas', number: '608' },
  { code: 'PN', code3: 'PCN', name: 'Pitcairn', number: '612' },
  { code: 'PL', code3: 'POL', name: 'Polonia', number: '616' },
  { code: 'PT', code3: 'PRT', name: 'Portugal', number: '620' },
  { code: 'PR', code3: 'PRI', name: 'Puerto Rico', number: '630' },
  { code: 'QA', code3: 'QAT', name: 'Qatar', number: '634' },
  { code: 'MK', code3: 'MKD', name: 'Republic of North Macedonia', number: '807' },
  { code: 'RO', code3: 'ROU', name: 'Rumanía', number: '642' },
  { code: 'RU', code3: 'RUS', name: 'Federación Rusa', number: '643' },
  { code: 'RW', code3: 'RWA', name: 'Ruanda', number: '646' },
  { code: 'RE', code3: 'REU', name: 'Reunión', number: '638' },
  { code: 'BL', code3: 'BLM', name: 'Saint Barthélemy', number: '652' },
  { code: 'SH', code3: 'SHN', name: 'Saint Helena, Ascension and Tristan da Cunha', number: '654' },
  { code: 'KN', code3: 'KNA', name: 'San Cristobal y Nevis', number: '659' },
  { code: 'LC', code3: 'LCA', name: 'Santa Lucía', number: '662' },
  { code: 'MF', code3: 'MAF', name: 'Saint Martin (French part)', number: '663' },
  { code: 'PM', code3: 'SPM', name: 'Saint Pierre and Miquelon', number: '666' },
  { code: 'VC', code3: 'VCT', name: 'San Vincente y Granadinas', number: '670' },
  { code: 'WS', code3: 'WSM', name: 'Samoa', number: '882' },
  { code: 'SM', code3: 'SMR', name: 'San Marino', number: '674' },
  { code: 'ST', code3: 'STP', name: 'Sao Tome and Principe', number: '678' },
  { code: 'SA', code3: 'SAU', name: 'Arabia Saudita', number: '682' },
  { code: 'SN', code3: 'SEN', name: 'Senegal', number: '686' },
  { code: 'RS', code3: 'SRB', name: 'Serbia', number: '688' },
  { code: 'SC', code3: 'SYC', name: 'Seychelles', number: '690' },
  { code: 'SL', code3: 'SLE', name: 'Sierra Leona', number: '694' },
  { code: 'SG', code3: 'SGP', name: 'Singapur', number: '702' },
  { code: 'SX', code3: 'SXM', name: 'Sint Maarteen', number: '534' },
  { code: 'SK', code3: 'SVK', name: 'Eslovaquia', number: '703' },
  { code: 'SI', code3: 'SVN', name: 'Eslovenia', number: '705' },
  { code: 'SB', code3: 'SLB', name: 'Islas Salomón', number: '90' },
  { code: 'SO', code3: 'SOM', name: 'Somalia', number: '706' },
  { code: 'ZA', code3: 'ZAF', name: 'Sudáfrica', number: '710' },
  { code: 'GS', code3: 'SGS', name: 'South Georgia and the South Sandwich Islands', number: '239' },
  { code: 'SS', code3: 'SSD', name: 'Sudán del Sur', number: '728' },
  { code: 'ES', code3: 'ESP', name: 'España', number: '724' },
  { code: 'LK', code3: 'LKA', name: 'Sri Lanka', number: '144' },
  { code: 'SD', code3: 'SDN', name: 'Sudán', number: '729' },
  { code: 'SR', code3: 'SUR', name: 'Surinam', number: '740' },
  { code: 'SJ', code3: 'SJM', name: 'Svalbard and Jan Mayen', number: '744' },
  { code: 'SE', code3: 'SWE', name: 'Suecia', number: '752' },
  { code: 'CH', code3: 'CHE', name: 'Suiza', number: '756' },
  { code: 'SY', code3: 'SYR', name: 'Siria', number: '760' },
  { code: 'TW', code3: 'TWN', name: 'Taiwán', number: '158' },
  { code: 'TJ', code3: 'TJK', name: 'Tadjikistan', number: '762' },
  { code: 'TZ', code3: 'TZA', name: 'Tanzania', number: '834' },
  { code: 'TH', code3: 'THA', name: 'Tailandia', number: '764' },
  { code: 'TL', code3: 'TLS', name: 'Timor Oriental', number: '626' },
  { code: 'TG', code3: 'TGO', name: 'Togo', number: '768' },
  { code: 'TK', code3: 'TKL', name: 'Tokelau', number: '772' },
  { code: 'TO', code3: 'TON', name: 'Tonga', number: '776' },
  { code: 'TT', code3: 'TTO', name: 'Trinidad y Tobago', number: '780' },
  { code: 'TN', code3: 'TUN', name: 'Túnez', number: '788' },
  { code: 'TR', code3: 'TUR', name: 'Turquía', number: '792' },
  { code: 'TM', code3: 'TKM', name: 'Turkmenistan', number: '795' },
  { code: 'TC', code3: 'TCA', name: 'Islas Turcas y Caicos', number: '796' },
  { code: 'TV', code3: 'TUV', name: 'Tuvalu', number: '798' },
  { code: 'UG', code3: 'UGA', name: 'Uganda', number: '800' },
  { code: 'UA', code3: 'UKR', name: 'Ucrania', number: '804' },
  { code: 'AE', code3: 'ARE', name: 'Emiratos Árabes Unidos', number: '784' },
  { code: 'GB', code3: 'GBR', name: 'Reino Unido', number: '826' },
  { code: 'UM', code3: 'UMI', name: 'United States Minor Outlying Islands', number: '581' },
  { code: 'US', code3: 'USA', name: 'Estados Unidos', number: '840' },
  { code: 'UY', code3: 'URY', name: 'Uruguay', number: '858' },
  { code: 'UZ', code3: 'UZB', name: 'Uzbekistán', number: '860' },
  { code: 'VU', code3: 'VUT', name: 'Vanuatu', number: '548' },
  { code: 'VE', code3: 'VEN', name: 'Venezuela', number: '862' },
  { code: 'VN', code3: 'VNM', name: 'Vietnam', number: '704' },
  { code: 'VG', code3: 'VGB', name: 'Islas Virgenes Británicas', number: '92' },
  { code: 'VI', code3: 'VIR', name: 'Islas Virgenes Americanas', number: '850' },
  { code: 'WF', code3: 'WLF', name: 'Wallis y Futuna', number: '876' },
  { code: 'EH', code3: 'ESH', name: 'Sáhara Occidental', number: '732' },
  { code: 'YE', code3: 'YEM', name: 'Yemen', number: '887' },
  { code: 'ZM', code3: 'ZMB', name: 'Zambia', number: '894' },
  { code: 'ZW', code3: 'ZWE', name: 'Zimbabwe', number: '716' },
  { code: 'AX', code3: 'ALA', name: 'Åland Islands', number: '248' }
];
