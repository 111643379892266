import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})


export class SpinnerComponent implements OnDestroy{
  loading: boolean = false;
  private subscriptions = [];
  constructor(public loader: LoaderService, private ref: ChangeDetectorRef) {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  ngOnInit(): void {
    this.subscriptions.push(this.loader.getLoading().subscribe( globalLoading =>{
        console.log("globlaLoading received in spinner " + globalLoading)
        this.loading = globalLoading;
        this.ref.detectChanges();
      })
    );
  }


}
