import {Component, OnInit} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-company-services',
  templateUrl: './company-services.component.html',
  styleUrls: ['./company-services.component.scss']
})
export class CompanyServicesComponent implements OnInit {
  public slides1 = [
    { src: '../../assets/img/services/services7.jpg' },
    { src: '../../assets/img/services/services15.jpg' },
    { src: '../../assets/img/services/services12.jpg'},
    { src: '../../assets/img/services/services6.jpg'}
  ];
  public slides2 = [
    { src: '../../assets/img/services/services11.jpg' },
    { src: '../../assets/img/services/services5.jpg' },
    { src: '../../assets/img/services/services9.jpg'},
    { src: '../../assets/img/services/services10.jpg'}
  ];
  public slides3 = [
    { src: '../../assets/img/services/services16.jpg' },
    { src: '../../assets/img/services/services13.jpg' },
    { src: '../../assets/img/services/services2.jpg'},
    { src: '../../assets/img/services/services8.jpg'},
    { src: '../../assets/img/services/services17.jpg'}
  ];
  public slides4 = [
    { src: '../../assets/img/services/services1.jpg' },
    { src: '../../assets/img/services/services4.jpg' },
    { src: '../../assets/img/services/services3.jpg'},
    { src: '../../assets/img/services/services14.jpg'}
  ];
  constructor(private canonicalAndMetaService: CanonicalAndMetaService) {

  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('company-services').then(async result => {
      await this.canonicalAndMetaService.setMetas('services', true, '');
    });
  }

}
