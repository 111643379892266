<div class="row justify-content-center">
  <div id="page-header">
    <h1>{{'Vehicle-0' | translate}}</h1>
    <div class="title-block3"></div>
    <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
      <i class="fa fa-angle-right"></i>{{'Vehicle-0' | translate}}</p>
  </div>
<div class="row w-100 justify-content-center align-content-center">
  <div class="col-12 col-lg-6 col-md-6 " *ngIf="vehicle">

    <!-- BEGIN .rev_slider_wrapper2 -->
    <div class="row justify-content-center">
      <div class="col-lg-12">
      <h2> {{getCategory(vehicle.categoryId).name}} : {{ vehicle.name}}</h2>
        <div class="title-block3"></div>
        <h3>{{ ('Vehicle.'+getCategory(vehicle.categoryId).link+'.Overview' ) | translate}}</h3>
        <!-- carousel -->
        <img src="/assets/img/fleet/{{vehicle.image}}-600x350.jpg" alt="{{vehicle.name}}">
        </div>
    <!-- END .rev_slider_wrapper2 -->
    </div>
    <div id="tabsSection" #tabsSection  class="row justify-content-center ms-1 me-1">
      <div class="col-lg-12">
        <p class="mt-3">{{ ('Vehicle.'+getCategory(vehicle.categoryId).link+'.Extra' ) | translate}}</p>
        <div id="tabs" class="ui-tabs ui-widget ui-widget-content ui-corner-all w-100">
            <ul class="nav clearfix ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" [attr.disabled]="disableTabs">
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor w-25" [ngClass]="{'ui-tabs-active ui-state-active' : activeTab == 1 || activeTab == null}">
                  <a  (click)="setActiveTab(1)" class="tab-text" [attr.disabled]="disableTabs">{{ ('Vehicle.Specifications' ) | translate}}</a>
                </li>
                <li class ="ui-state-default ui-corner-top ui-tabs-anchor w-25" [ngClass]="{'ui-tabs-active ui-state-active' : activeTab == 2}">
                  <a (click)="setActiveTab(2)" class="tab-text" [attr.disabled]="disableTabs">{{ ('Vehicle.Extra' ) | translate}}</a>
                </li>
                <li  *ngIf="vehicle.photos && vehicle.photos.length > 0"
                     class ="ui-state-default ui-corner-top ui-tabs-anchor w-25" [ngClass]="{'ui-tabs-active ui-state-active' : activeTab == 3}">
                  <a  (click)="setActiveTab(3)" class="tab-text" [attr.disabled]="disableTabs">{{ ('Vehicle.Photos' ) | translate}} {{this.city.localName}}</a>
                </li>
              <li  *ngIf="vehicle.photo360 && vehicle.photo360.length > 0"
                   class ="ui-state-default ui-corner-top ui-tabs-anchor w-25" [ngClass]="{'ui-tabs-active ui-state-active' : activeTab == 4}">
                <a  (click)="setActiveTab(4)" class="tab-text" [attr.disabled]="disableTabs">{{ ('Vehicle.Photos' ) | translate}} 360</a>
              </li>
          </ul>
          <div class="tab-content">
              <div class="tab-pane" [ngClass]="{'active': !activeTab || activeTab === 1|| activeTab === 0}">
              <table class="table vehicle-data-table">
                <tbody class="col-12">
                  <tr>
                    <td data-title="Column 1" class="me-3">{{ ('Vehicle.Pax' ) | translate}} {{vehicle.passengers}}</td>
                    <td data-title="Column 1" class="me-3">{{ ('Vehicle.Bags' ) | translate}} {{vehicle.bags}}</td>
                    <td data-title="Column 1" class="me-3">{{ ('Vehicle.Insured' ) | translate}} {{vehicle.fullyInsured ? ('Yes' | translate) :('No' | translate) }}</td>
                  </tr>
                  <tr>
                    <td data-title="Column 2" class="me-3">{{ ('Vehicle.Interior' ) | translate}} {{vehicle.interior | translate}}</td>
                    <td data-title="Column 2" class="me-3">{{ ('Vehicle.Drinks' ) | translate}} {{vehicle.drinks? ('Yes' | translate) :('No' | translate) }}</td>
                    <td data-title="Column 2" class="me-3">{{ ('Vehicle.Smoking' ) | translate}} {{vehicle.smoking ? ('Yes' | translate) :('No' | translate) }}</td>
                  </tr>
                  <tr>
                    <td data-title="Column 4" class="me-3">{{ ('Vehicle.Wifi' ) | translate}} {{vehicle.wifi ? ('Yes' | translate) :('No' | translate) }}</td>
                    <td data-title="Column 4" class="me-3">{{ ('Vehicle.PhoneChargers' ) | translate}} {{vehicle.phoneCharger ? ('Yes' | translate) :('No' | translate) }}</td>
                    <td data-title="Column 4" class="me-3">{{ ('Vehicle.MeetAndGreet' ) | translate}} {{vehicle.meetAndGreet? ('Yes' | translate) :('No' | translate) }}</td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="tab-pane" [ngClass]="{'active': activeTab === 2}">
                <ng-container *ngFor="let option  of vehicle.options; let i = index">
                <p>{{ ( 'Vehicle.Options.' + option ) | translate}}</p>
                </ng-container>
              </div>
              <div class="tab-pane photo-pane" [ngClass]="{'active': activeTab === 3}">
                <ngb-carousel class="text-lg-center" [showNavigationIndicators]="false">
                  <ng-template ngbSlide  *ngFor="let url of vehicle.photos; let i = index">
                    <img src="{{'../../../assets/' + url}}" alt="{{ vehicle.name }} {{ city.localName }} ">
                  </ng-template>
                </ngb-carousel>
              </div>
            <div class="tab-pane" [ngClass]="{'active': activeTab === 4}">
              <!-- Container element, with the id "viewer". -->
              <div id="viewer" class="view360-container is-16by9">
                <!-- Canvas element -->
                <canvas class="view360-canvas"></canvas>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6 col-md-6 " *ngIf="!vehicle">

    <!-- BEGIN .rev_slider_wrapper2 -->
    <div class="row w-100 justify-content-center ms-1 me-1">
      <div class="col-12 col-lg-12">
        <h4>{{ ('Vehicle.Overview' ) | translate}}</h4>
        <div class="title-block7"></div>

        <p>{{ ('Vehicle.NotFound' ) | translate}}</p>
        <a class="btn btn-primary position-relative ms-2"
           title="{{'ContactUs-3' | translate}}"
           style=" background: #997f60;border-color: #ffffff;"
           routerLink="{{'/contact-us' | localize}}">
          {{ ('ContactUs-3' ) | translate}}
        </a>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-3 col-md-4 align-content-center">
    <div class="row">
      <div class="col me-1 ms-1">
        <app-booking-widget class="widget-booking-form-wrapper" [format]="'vertical'"></app-booking-widget>
        <div class="col  me-1 ms-1">
        </div>
      </div>
    </div>
  </div>
</div>
</div>
