import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {RouteInfo} from '../../navigation/horizontal-menu/menu.metadata';
import {User} from '../../../models/business-objects';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();

  private menuItems = new BehaviorSubject<RouteInfo[]>([]);
  // Observable

  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }
  createMenuItems(loggedUser: User, menuList: RouteInfo[]): RouteInfo[] {
    const menuItemsFiltered: RouteInfo[] = [];
    for (const menuitem of menuList){

      if (menuitem.isUnrestricted ||
        (  loggedUser &&
          (  (menuitem.isForDispatch && (loggedUser.roldb =="dispatch" || loggedUser.roldb =="gerencia") )
            ||(menuitem.isForClient &&   loggedUser.cliente  )
            ||(menuitem.isForSupplier && loggedUser.proveedorId  )
            ||(menuitem.isForDriver &&  loggedUser.idconductor  )
          )
        )
      ){
        menuitem.submenu = this.createMenuItems( loggedUser, menuitem.submenu);
        menuItemsFiltered.push(menuitem);
        //console.log('menuitem' + menuitem.title + ' added');
      }
    }
    return menuItemsFiltered;
  }
}
