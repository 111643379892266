import {AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Coordinates} from '../../../models/business-objects';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../../environments/environment';
import {GoogleMap} from '@angular/google-maps';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss']
})
export class MapPopupComponent implements OnInit,  AfterViewInit {
  coordinates: Coordinates ;
  _puPoint: Coordinates;
  _doPoint: Coordinates;
  width = 960;
  _lat: number;
  _lng: number;
  _center: google.maps.LatLng;
  @Input() zoom = 12;
  title = '';
  @Input() showRoute = false;
  _googleUrlForRoute: string = null ;
  iframeSrc: SafeUrl;
  @Input() isEmbeded = false;
  isSmallDevice = false;
  @Output() directionsResult = new EventEmitter<google.maps.DirectionsResult>();
  _wayPoints: google.maps.DirectionsWaypoint[] = [];
  @ViewChild('Map') Map: GoogleMap; /* Element Map */
  @ViewChild('Map2') Map2: GoogleMap; /* Element Map */
  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private dialogRef: MatDialogRef<MapPopupComponent>, private dataService : DataService,
              private sanitizer: DomSanitizer) {
    this.coordinates = new Coordinates();
    if (isPlatformBrowser(this.platformId)) {
      this.width = window.innerWidth;
    }
    this.changeCSS();
  }

  changeCSS() {
    if (this.width < 450) {
      this.isSmallDevice = true;
    } else {
      this.isSmallDevice = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.width !== event.target.innerWidth ) {
      this.width = event.target.innerWidth;
      this.changeCSS();
    }
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // set latitude, longitude and zoom
      this.zoom = 12;
      this.coordinates.lat = this._lat;
      this.coordinates.lng = this._lng;
      this.coordinates.LatLng = new google.maps.LatLng(this._lat, this._lng);
      console.log('coordinates inicial lat ' + this.coordinates.lat + ' from ' + this.lat);
      console.log('coordinates inicial lng ' + this.coordinates.lng + ' from ' + this.lng);
      console.log('coordinates inicial' + JSON.stringify(this.coordinates, null, 4));
      this.calcRoute();
    }
  }
  async ngOnInit() {
    await this.dataService.mapReady.then( () => {
      console.log("google map is ready")
    })
  }

  onUpdateClick() {

  }

  markerDragEnd(lat, lng, $event) {
    this.coordinates.lat = $event.latLng.lat();
    this.coordinates.lng = $event.latLng.lng();
    console.log($event.latLng);
  }

  onNoClick() {
    this.dialogRef.close();
  }
  @Input() set lat(value: number) {
    console.log('received lat ' + value);
    this._lat = value;
    this.coordinates.lat = value;
    if (this.coordinates.lat && this.coordinates.lng) {
      this.coordinates.LatLng = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);
    }
  }
  get lat() {
    return this._lat;
  }

  @Input() set lng(value: number) {
    console.log('received lng ' + value);
    this._lng = value;
    this.coordinates.lng = value;
    if (this.coordinates.lat && this.coordinates.lng) {
      this.coordinates.LatLng = new google.maps.LatLng(this.coordinates.lat, this.coordinates.lng);
    }
  }
  get lng() {
    return this._lng;
  }
  get center(){
    this._center = new google.maps.LatLng(this._lat, this._lng);
    return this._center;
  }
  @Input() set wayPoints( points: any[]){
    this._wayPoints = [];
    points.forEach( c => {
      const newPoint = {
        location: new google.maps.LatLng(c.lat, c.lng),
        stopover: true,
      };
      this._wayPoints.push(newPoint);
    });
    this.calcRoute();
  }
  get wayPoints(){
    return this._wayPoints;
  }
  @Input() set puPoint(value: Coordinates) {
    this._puPoint = value;
    this.title = 'PossibleRoute';
    if ( this._puPoint && this._doPoint) {
      this.showRoute = true;
      this.calcRoute();
    }
  }
  get puPoint() {
    return this._puPoint;
  }

  @Input() set doPoint(value: Coordinates) {
    this._doPoint = value;
    this.title = 'PossibleRoute';
    if ( this._puPoint && this._doPoint) {
      this.showRoute = true;
      this.calcRoute();
    }
  }

  calcRoute() {
    const request = {
      origin: this.puPoint,
      destination: this.doPoint,
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: this.wayPoints
    };
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    this.dataService.mapReady.then( () => {
      console.log("google map is ready in calcRoute")
      directionsRenderer.setMap(this.Map2.googleMap);
      directionsService.route(request, (response, status) => {
        console.log('this.directionsService ROUTE');
        console.log(response);
        if (status === google.maps.DirectionsStatus.OK) {

          directionsRenderer.setDirections(response);
          this.onResponse(response);
          console.log(response);
        } else {
          console.log(response);
        }
      });
    })

  }

  get doPoint() {
    return this._doPoint;
  }

  set googleUrlForRoute(value: string) {
    this._googleUrlForRoute = 'https://www.google.com/maps/embed/v1/directions?key=' + environment.googleApiKey1 + value;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this._googleUrlForRoute);
    this.showRoute = true;
  }
  get googleUrlForRoute() {
    return this._googleUrlForRoute;
  }

  public onResponse(route: any){
    this.directionsResult.emit(route);
    // You can do anything.
  }

}
