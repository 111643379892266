import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {NewsService} from '../../services/news.service';
import {News} from '../../../models/business-objects';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  newsList: News[] = [];
  private subscriptions = [];
  loadingNews = true;
  newsId: string;
  newsTag: string;
  constructor(private canonicalAndMetaService: CanonicalAndMetaService , private ref: ChangeDetectorRef, private route: ActivatedRoute,
              private newsService: NewsService, @Inject(PLATFORM_ID) private platformId: any) {

    this.subscriptions.push (route.params.subscribe(params => {
      this.newsId = this.route.snapshot.paramMap.get('id');
      this.newsTag = this.route.snapshot.paramMap.get('tag');
      this.changeNewsList();
    }));
  }
  openNews() {

  }
  async changeNewsList(){
    this.loadingNews = true;
    console.log('newsId = ' + this.newsId);
    console.log('newsTag = ' + this.newsTag);
    this.canonicalAndMetaService.setMetas('news', true, this.newsTag );
    this.newsList = await this.newsService.getCurrentNews();
    // this.newsList = this.newsList.filter( n => n.seccionNoticia === 'NEWS');
    console.log('this.newsList = ');
    console.log(this.newsList);
    if (this.newsId ) {
      console.log('filtering newsId : ' + this.newsId);
      this.newsList = this.newsList.filter(o => o.id.toString() === this.newsId.toString());
      console.log('quedan : ' + this.newsList.length);
    }
    if ( this.newsTag){
      console.log('filtering newsTag : ' + this.newsTag);
      this.newsList = this.newsList.filter(o => o.tags.includes(this.newsTag));
      console.log('quedan : ' + this.newsList.length);
    }
    this.newsList = this.newsList.sort( (a, b) => b.datePublished.localeCompare(a.datePublished));
    for (const n of this.newsList) {
      console.log('for each news: ', n.id);
      await this.getImage(n).then(url => {
        if (!url) { url = ''; }
        n.imageUrl = url;
      });
    }
    this.loadingNews = false;
    this.ref.detectChanges();
  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('news').then(async result => {
      await this.canonicalAndMetaService.setMetas('news', true , '');
    });
    await this.changeNewsList();
  }

  getImage(news: News) {
      console.log('buscando imagen para news id : ' + news.id);
      return this.newsService.getNewsImageById(Number(news.id)).then(response => {
        console.log('imagen para news id : ' + news.id + ' ya llego...');
        if (response) {
          console.log('tenemos la info en base64');
          return 'data:image/jpeg;base64,' + response.media;
        } else {
          console.log('la info en base64 esta nulla ');
          return '';
        }
      }, error => {
        console.log('error recuperando foto de ' + news.id + ' error : ' + error);
        return '';
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
