<div class="row justify-content-center">
  <div id="page-header" class="col-12 text-center">
    <h1>{{'Staff-0' | translate}}</h1>
    <div class="title-block3"></div>
    <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
      <i class="fa fa-angle-right"></i>{{'Staff-0' | translate}}</p>
  </div>

  <div class="col-11 mb-3" *ngIf="staff != null && staff.nombre != null">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-4 col-md-5 col-sm-12 text-center">
        <img src="{{staff.imageLink}}" width="100%" style="max-width: 300px; width: 100%;" alt="staff image" class="img-fluid">
      </div>
      <div class="col-lg-4 col-md-5 col-sm-12 text-left">
        <div>
          <p>{{'Staff-5' | translate}}</p>
          <p>{{'Payments-8' | translate}}: {{staff.nombre}}</p>
          <p>{{'Payments-11' | translate}}: {{staff.apellido}}</p>
          <p>{{'Payments-13' | translate}}: {{staff.cedula}}</p>
          <p>{{'PhoneNumber' | translate}}: {{staff.celular}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="staff.activo" class="text-success text-center">
      <p [innerHTML]="'Staff-4' | translate"></p>
    </div>
    <div *ngIf="!staff.activo" class="text-danger text-center">
      <p [innerHTML]="'Staff-3' | translate"></p>
    </div>
  </div>

  <div class="col-11 mb-3 text-center" *ngIf="staff == null || staff.nombre == null">
    <p [innerHTML]="'Staff-2' | translate"></p>
  </div>
</div>
