<div id="bio-header">
  <h1>{{'Bio-Security' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
    <i class="fa fa-angle-right"></i>{{'Bio-Security' | translate}}</p>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
    <h3 class="center-title text-uppercase">{{'Bio-Security' | translate}}</h3>
    <div class="title-block2"></div>
  </div>
</div>
<div class="row m-0 justify-content-center align-content-center">
  <ng-container *ngIf="translate.currentLang !== 'es' ">
      <object data="../../assets/pdf/BiosecurityProtocolsCovid-19.pdf"
              type="application/pdf"
              class="pdf-container"
              scrolling="no"
      >
       It appears you don't have a PDF plugin for this browser.
       Click here to download the PDF file.
        <a href="../../assets/pdf/BiosecurityProtocolsCovid-19.pdf" target="_blank">BiosecurityProtocolsCovid-19.pdf</a>
      </object>
  </ng-container>
  <ng-container *ngIf="translate.currentLang === 'es' ">
    <object data="../../assets/pdf/ProtocolosBioSeguridadCovid-19.pdf"
            type="application/pdf"
            class="pdf-container"
            scrolling="no"
    >
      It appears you don't have a PDF plugin for this browser.
      Click here to download the PDF file.
      <a href="../../assets/pdf/ProtocolosBioSeguridadCovid-19.pdf" target="_blank">ProtocolosBioSeguridadCovid-19.pdf</a>
    </object>
  </ng-container>
</div>
