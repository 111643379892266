import {Component, OnInit} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

import {ServerSideResponseService} from '../../services/ServerSideResponseService';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component implements OnInit {
  searchBoxText: string;
  constructor(private localize: LocalizeRouterService, private canonicalAndMetaService: CanonicalAndMetaService , private router: Router,
              private ssr: ServerSideResponseService) {

    this.ssr.setNotFound();
  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('404').then(async result => {
      await this.canonicalAndMetaService.setMetas('error404', true, '');
    });
  }
  reload () {
    this.localize.changeLanguage("en");
    this.router.navigateByUrl('/en/home');
  }

}
