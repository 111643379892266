import {Injectable} from '@angular/core';
import {VehicleCategory} from '../../models/business-objects';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom} from 'rxjs';

@Injectable()
export class RateService {
  name: string;
  image: string;
  link: string;
  rate: string;
  passengers: number;
  bags: number;
  id: number;
  condition: string;
  UrlAPI: string = environment.apiURL;
  ratesBogota: any[] = [
      {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 64, daily: 512, transfer: 96, add_km: 1,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Luxury Sedan', image: 'Audi-A6-1', city_id: 1, hourly: 82, daily: 656, transfer: 123, add_km: 1.2,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Luxury SUV', image: 'ford_expedition', city_id: 1, hourly: 92, daily: 736, transfer: 138, add_km: 1.6,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Armored SUV*', image: 'prado-2', city_id: 1, hourly: 100, daily: 800, transfer: 250, add_km: 1.8,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Mercedes V-class', image: 'mercedes-viano', city_id: 1, hourly: 100, daily: 800, transfer: 150, add_km: 1.6,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 78, daily: 624, transfer: 117, add_km: 1,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
      {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter', city_id: 1, hourly: 92, daily: 736, transfer: 138, add_km: 1.6,
        conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesCartagena: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 64, daily: 512, transfer: 88, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury Sedan', image: 'Mercedes-Eclass-2', city_id: 1, hourly: 82, daily: 656, transfer: 106, add_km: 1.2,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury SUV', image: 'ford_expedition', city_id: 1, hourly: 92, daily: 736, transfer: 116, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Armored SUV*', image: 'prado-2', city_id: 1, hourly: 100, daily: 800, transfer: 324, add_km: 1.8,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes V-class', image: 'mercedes-viano', city_id: 1, hourly: 100, daily: 800, transfer: 124, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 78, daily: 624, transfer: 102, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', city_id: 1, hourly: 92, daily: 736, transfer: 116, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesMedellin: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 64, daily: 512, transfer: 126, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury Sedan', image: 'Audi-A6-1', city_id: 1, hourly: 82, daily: 656, transfer: 153, add_km: 1.2,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury SUV', image: 'ford_expedition', city_id: 1, hourly: 92, daily: 736, transfer: 168, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Armored SUV*', image: 'prado-2', city_id: 1, hourly: 100, daily: 800, transfer: 280, add_km: 1.8,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes V-class', image: 'mercedes-viano', city_id: 1, hourly: 100, daily: 800, transfer: 180, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 78, daily: 624, transfer: 147, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', city_id: 1, hourly: 92, daily: 736, transfer: 168, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesInternacional: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 64, daily: 512, transfer: 88, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury Sedan', image: 'Audi-A6-1', city_id: 1, hourly: 123, daily: 656, transfer: 106, add_km: 1.2,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Luxury SUV', image: 'ford_expedition', city_id: 1, hourly: 138, daily: 736, transfer: 116, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Armored SUV*', image: 'prado-2', city_id: 1, hourly: 100, daily: 800, transfer: 324, add_km: 18,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes V-class', image: 'mercedes-viano', city_id: 1, hourly: 100, daily: 800, transfer: 124, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 78, daily: 624, transfer: 102, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter', city_id: 1, hourly: 92, daily: 736, transfer: 116, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesBusinessBogota: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 38, daily: 304, transfer: 57, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Renault Duster', image: 'duster', city_id: 1, hourly: 23, daily: 230, transfer: 34.5, add_km: 0.7,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 48, daily: 384, transfer: 72, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', city_id: 1, hourly: 64, daily: 512, transfer: 96, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesBusinessCartagena: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 38, daily: 304, transfer: 38, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Renault Duster', image: 'duster', city_id: 1, hourly: 23, daily: 230, transfer: 23, add_km: 0.7,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 48, daily: 384, transfer: 72, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', city_id: 1, hourly: 64, daily: 512, transfer: 88, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
  ratesBusinessMedellin: any[] = [
    {  name : 'Mid-size Sedan', image: 'ford-fusion-2012', city_id: 1, hourly: 38, daily: 304, transfer: 73, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Renault Duster', image: 'duster', city_id: 1, hourly: 23, daily: 230, transfer: 50.5, add_km: 0.7,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Hyudaï Starex', image: 'hyundai_starex_white', city_id: 1, hourly: 48, daily: 384, transfer: 88, add_km: 1,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
    {  name : 'Mercedes Sprinter', image: 'mercedes-sprinter-blanco', city_id: 1, hourly: 64, daily: 512, transfer: 112, add_km: 1.6,
      conditions: ' +suplments and oasihsjfg ahfgf ', vehicle_id: 1, id: 1},
  ];
    constructor(private http: HttpClient) {
      this.currentRatesBogotaSubject = new BehaviorSubject<VehicleCategory[]>(null);
      this.currentRatesMedellinSubject = new BehaviorSubject<VehicleCategory[]>(null);
      this.currentRatesCartagenaSubject = new BehaviorSubject<VehicleCategory[]>(null);
      this.currentRatesInternationalSubject = new BehaviorSubject<VehicleCategory[]>(null);
      this.readyP = this.initializeLists();
     }
  private currentRatesBogotaSubject: BehaviorSubject<VehicleCategory[]>;
  private currentRatesMedellinSubject: BehaviorSubject<VehicleCategory[]>;
  private currentRatesCartagenaSubject: BehaviorSubject<VehicleCategory[]>;
  private currentRatesInternationalSubject: BehaviorSubject<VehicleCategory[]>;
  private  readyP: Promise<boolean>;

  public get currentBogotaRate(): VehicleCategory[] {
    return this.currentRatesBogotaSubject.value;
  }
  public set currentBogotaRate(rates: VehicleCategory[]) {
    this.currentRatesBogotaSubject.next(rates);
  }
  public get currentMedellinRate(): VehicleCategory[] {
    return this.currentRatesMedellinSubject.value;
  }
  public set currentMedellinRate(rates: VehicleCategory[]) {
    this.currentRatesMedellinSubject.next(rates);
  }
  public get currentCartagenaRate(): VehicleCategory[] {
    return this.currentRatesCartagenaSubject.value;
  }
  public set currentCartagenaRate(rates: VehicleCategory[]) {
    this.currentRatesCartagenaSubject.next(rates);
  }
  public get currentInternationalRate(): VehicleCategory[] {
    return this.currentRatesInternationalSubject.value;
  }
  public set currentInternationalRate(rates: VehicleCategory[]) {
    this.currentRatesInternationalSubject.next(rates);
  }
  async initializeLists(): Promise<boolean>{
    console.log('initialing lists rates');
    try {
      this.currentBogotaRate = await this.getRatesFromAPI('bogota');
      console.log(this.currentBogotaRate);
      this.currentMedellinRate = await this.getRatesFromAPI('medellin');
      console.log(this.currentMedellinRate);
      this.currentCartagenaRate = await this.getRatesFromAPI('cartagena');
      console.log(this.currentCartagenaRate);
      this.currentInternationalRate = await this.getRatesFromAPI(null);
      console.log(this.currentInternationalRate);
      console.log('rates should be ready');
      return true;
    } catch (err) {
      console.log(err);
      console.log('Rates list failed !');
      return false;
    }
  }
    public getRates(city: string, serviceLevel: string): Promise<VehicleCategory[]> {
      return this.readyP.then( result => {
        let rateToReturn: VehicleCategory[] = [];
        console.log('in getRates : city ' + city + ' serviceLevel : ' + serviceLevel);
        if (city === 'bogota') rateToReturn = this.currentBogotaRate;
        if (city === 'medellin') rateToReturn = this.currentMedellinRate;
        if (city === 'cartagena') rateToReturn = this.currentCartagenaRate;
        if (city === 'international') rateToReturn = this.currentInternationalRate;
        if ( serviceLevel != null ) {
          console.log('filtering by : ' + serviceLevel);
          rateToReturn = rateToReturn.filter( r => r.serviceLevel === serviceLevel.toUpperCase());
        }
        console.log(rateToReturn);
        return rateToReturn;
      });
    }
  public getRates2(city: string): Promise<VehicleCategory[]> {
    return this.readyP.then( result => {
      let rateToReturn: VehicleCategory[] = [];
      console.log('in getRates2 : city ' + city + ' serviceLevel : ' );
      if (city === 'bogota') rateToReturn = this.currentBogotaRate;
      if (city === 'medellin') rateToReturn = this.currentMedellinRate;
      if (city === 'cartagena') rateToReturn = this.currentCartagenaRate;
      if (city === 'international') rateToReturn = this.currentInternationalRate;
      console.log(rateToReturn);
      return rateToReturn;
    });
  }
  getRatesFromAPI(ciudad: string): Promise<[VehicleCategory]> {
    if (ciudad === 'worldwide') { ciudad = null; }
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_RATES, {ciudad}));
  }
}
