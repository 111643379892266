
<div class="carousel" [hidden]="showSpinner">
  {{verticalControl}}
    <ng-container *ngFor="let slide of slides; let i = index" >
      <img *ngIf="i === currentSlide && slide.src" [src]="slide.src" alt="{{slide.title}}" class="slide" @carouselAnimation />
      <div class="carousel-caption text-overlay "  *ngIf="i === currentSlide && slide.text && slide.title && slide.src">
        <h3>{{slide.title | translate}}</h3>
        <p style="text-wrap: normal; white-space: normal">{{slide.text | translate}}</p>
        <p style="text-wrap: normal; white-space: normal">{{slide.author | translate}}</p>
      </div>
      <div class="example-card" *ngIf="i === currentSlide && slide.news">
        <mat-card class="m-1"  >
          <!-- eso si se mostra es porque tiene que ser una noticia oficial de la pagina web -->
          <mat-card-header>
            <mat-card-title>{{slide.news.title}}</mat-card-title>
            <mat-card-subtitle>
              <div class="tagcloud" >
                <a *ngFor="let tag of slide.news.tags.split(';').slice(0,2)"
                   [routerLink]="('/news' | localize) + '/' + tag" title="news">
                  {{tag | translate}}
                </a>
              </div>
            </mat-card-subtitle>
          </mat-card-header>
          <img mat-card-image [src]="slide.news.imageUrl"
               *ngIf="slide.news.imageUrl"
               alt="Photo of {{slide.news.title}}" style="height: 250px; object-fit: scale-down; ">
          <mat-card-content style="height: 200px; overflow: hidden">
            <div [innerHTML]="slide.news.newsBody"></div>
            <div *ngIf="slide.news.seccionNoticia === 'INSTAGRAM'">
              <div class="text-justify" class="tagcloud"  *ngIf="slide.news.seccionNoticia === 'INSTAGRAM'">
                <a [href]="'https://www.instagram.com/p/'+ slide.news.myId" target="_blank">{{'News-ViewInInstagram' | translate}}</a>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button [routerLink]="('/news' | localize)+ '/id/'+slide.news.id" >{{'News-ReadMore' | translate}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div  class="testimonials"  *ngIf="i === currentSlide && slide.text && slide.title && !slide.src">
        <div align="center" >
        <h4 class="text-uppercase" >{{slide.title}}</h4>
        <p class="text-justify" [innerHTML]="slide.text" ></p>
        <div class="testimonial-author"><p>{{slide.author}} <br/>  {{slide.date}} </p></div>
        </div>
      </div>
      <div *ngIf="i === currentSlide &&  slide.news && slide.news.html" [innerHtml]="slide.news.html | safeHtml"
           id="{{slide.news.id}}"  class="slide"></div>
    </ng-container>
  <!-- controls -->
  <button class="control prev" [ngStyle]="{ 'left':  -15-offsetControl +'px;'  }" (click)="onPreviousClick()" style="top: 0px">
    <span class="arrow left"></span>
  </button>
  <button class="control next" [ngStyle]="{ 'right': -15-offsetControl +'px;' +  verticalControl }"  (click)="onNextClick()" style="top: 0px">
    <span class="arrow right"></span>
  </button>
</div>
<div class="row w-100 justify-content-center" [hidden]="!showSpinner">
  <div>
    <mat-spinner>
    </mat-spinner>
  </div>
</div>


