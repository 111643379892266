import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-bio-security',
  templateUrl: './bio-security.component.html',
  styleUrls: ['./bio-security.component.scss']
})
export class BioSecurityComponent implements OnInit {


  constructor( public  translate: TranslateService,private canonicalAndMetaService: CanonicalAndMetaService) { }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('bio-security').then(async result => {
      await this.canonicalAndMetaService.setMetas('bio-security', true, '');
    });
  }

}
