import {Component, OnInit} from '@angular/core';
import {MySlide} from '../../../models/business-objects';


@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit  {
  private carouselToken: string;
  public slides: MySlide[] = [
    { src: '../../assets/img/fleet/slide1b.jpg', text: 'CarouselText1', title: 'CarouselTitel1'},
    { src: '../../assets/img/fleet/slide5b.jpg', text: 'CarouselText2', title: 'CarouselTitel2'},
    { src: '../../assets/img/fleet/slide6b.jpg', text: 'CarouselText3', title: 'CarouselTitel3'},
    { src: '../../assets/img/fleet/slide2b.jpg', text: 'CarouselText4', title: 'CarouselTitel4'},
    { src: '../../assets/img/fleet/slide4b.jpg', text: 'CarouselText4', title: 'CarouselTitel1'}
  ];
  constructor() {  }
  ngOnInit(){

  }


}
