import {Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VehicleCategory} from '../../../models/business-objects';
import {RateService} from '../../services/rate.service';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Subscription} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

declare var dataLayer;
@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit, OnDestroy  {
introduccion: string;
@Input() rates: VehicleCategory[];
cityParam = 'international';
disableTabs = false;
serviceLevel: string;
text1: string;

  private subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute, private rateService: RateService, private canonicalAndMetaService: CanonicalAndMetaService,
              private router: Router, private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any,
              private localize: LocalizeRouterService) {
    this.translate.setDefaultLang('en');

    this.subscriptions.push ( this.route.params.subscribe(async params => {
        this.cityParam = params.city;
        this.serviceLevel = params.serviceLevel;
        await this.reloadRates();
        this.setActiveTab(this.cityParam, this.serviceLevel);
        if (this.serviceLevel) {
          this.subscriptions.push (this.translate.get('ServiceRatesText-' + this.serviceLevel.toUpperCase()).subscribe(text => {
            this.text1 = text;
          }));
        }
      })
    );
   }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('rates').then(async result => {
      await this.canonicalAndMetaService.setMetas('rates', false, this.cityParam + ' ' + this.serviceLevel);
    });
  }

  async reloadRates(){
    console.log('cityParam en Reload Rate: ' + this.cityParam);
    this.rates = await this.rateService.getRates(this.cityParam , this.serviceLevel);
    if (isPlatformBrowser(this.platformId)) {
      dataLayer = dataLayer || [];
      dataLayer.push({
        travel_destid: this.cityParam,
        service_city: this.cityParam,
        service_level: this.serviceLevel
      });
    }
  }

  setActiveTab(city: string, serviceLevel: string){
    this.cityParam = city;
    this.serviceLevel = serviceLevel;
    console.log('param en setActiveTab: ' + this.cityParam);
    console.log('city en setActiveTab: ' + city);
    if (isPlatformBrowser(this.platformId)) {
      const scrollY = window.scrollY;
      this.router.navigate([this.localize.translateRoute('/rates/' + serviceLevel + '/' + city)], {}).then(result => {
        window.scrollTo(0, scrollY);
      });
    } else {
      this.router.navigate([this.localize.translateRoute('/rates/' + serviceLevel + '/' + city)], {});
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}

