<mat-toolbar class="toolbar-centered">
<mat-toolbar-row class="toolbar-centered">
  <span class="me-3">
      <button mat-raised-button (click)="activateInfo()" >
        Informacion
      </button>
  </span>
  <span class="me-3" *ngIf="showEventos">
      <button mat-raised-button (click)="activateEventos()" >
        Eventos
      </button>
  </span>
  <span class="me-3">
      <button mat-raised-button (click)="closeDialog()" >
        <mat-icon>close</mat-icon>
        {{'app.driver.Close' | translate}}
      </button>
  </span>
</mat-toolbar-row>

</mat-toolbar>
<div class="row justify-content-center">
  <h2>
    {{job.fecha + ' '}} - {{' ' + job.hora + ' '}}
  </h2>
  <br />
  <h2>
    {{ ' Res. : ' + job.numeroreserva +'  '}} -
    {{ ' Pax. : ' + job.nombrePrimerPasajero +'  '}}
  </h2>
</div>
<div *ngIf="tabInfo">
  <mat-accordion>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
          {{'app.driver.Details-1' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'app.driver.Details-2' | translate}}
        </mat-panel-description>
      </mat-expansion-panel-header>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-3' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.operacion + ' ' + job.horas +'H - ' + job.nivelServico + " - " + job.tipoVehiculo}}</label>
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-4' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.fecha + ' ' + job.hora}}</label>
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-5' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <div [innerHTML]="job.direccionrecogida | createMapsLinks"></div>
            <label *ngIf="job.vuelo">{{'vuelo : ' + job.vuelo}}</label>
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-6' | translate}}</h5>
          </div>
          <div class="col-9 my-auto"  [innerHTML]="job.direcciondestino | createMapsLinks">
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-7' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.nombrePrimerPasajero}}</label>
            <label *ngIf=" parseToInt(job.cantidadpasajeros)  > 1 ">(+{{parseToInt(job.cantidadpasajeros)-1}})</label>
            <a *ngIf="pasajeros && pasajeros[0] && pasajeros[0].phone && !showPassengers "
               href="tel:{{pasajeros[0].phone}}">{{'tel ' + pasajeros[0].phone + ' '}}</a>
            <a *ngIf="pasajeros && pasajeros[0] && pasajeros[0].celular && !showPassengers "
               href="tel:{{pasajeros[0].celular}}">{{' cel. ' + pasajeros[0].celular}}</a>
            <button mat-raised-button *ngIf="( pasajeros.length  > 1 ) && pasajeros" (click)="openPasajeros()">Ver todos los pasajeros</button>
            <div *ngIf="showPassengers && pasajeros" >
              <div *ngFor="let pax of pasajeros; let i = index">
                <label>{{pasajeros[i].fullName}}</label>
                <a *ngIf="pasajeros[i] && pasajeros[i].phone " href="tel:{{pasajeros[i].phone}}">{{' tel ' + pasajeros[i].phone + ' '}}</a>
                <a *ngIf="pasajeros[i] && pasajeros[i].celular " href="tel:{{pasajeros[i].celular}}">{{' cel. ' + pasajeros[i].celular}}</a>
              </div>
            </div>
            <div class="col-9 my-auto" *ngIf="job.letrero">
              <strong>{{'app.job.Job-13' | translate}}</strong>  {{' '+ job.letrero }}<br/>
              <button mat-raised-button (click)="getPDFLetrero(job.id)" class="btn">
                {{'app.job.Job-16' | translate}}
                <i *ngIf="loadingPdf | async" class="fas fa-spinner fa-pulse ms-3"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row align-content-center" *ngIf="jwt == null || showPassengers">
          <div class="col-3">
            <h5>{{'app.driver.Details-8' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.nombrecliente}}</label><br/>
            <a *ngIf=" job.telefonocliente " href="tel:{{job.telefonocliente}}">{{job.telefonocliente}}</a><br/>
            <a *ngIf=" job.celularcliente " href="tel:{{job.celularcliente}}">{{job.celularcliente}}</a><br/>
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-9' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label class="me-1">{{job.modeloVehiculo}}</label>
            <label>{{job.placa}}</label>
          </div>
        </div>
        <div class="row align-content-center">
          <div class="col-3">
            <h5>{{'app.driver.Details-10' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label class="me-1">{{job.conductor}}</label>
            <a *ngIf="job.celularConductor " href="tel:{{job.celularConductor}}">{{job.celularConductor}}</a>
          </div>
        </div>
        <div class="row align-content-center"  *ngIf=" job.nombreProtocolo ">
          <div class="col-3">
            <h5>{{'app.driver.Details-11' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label class="me-1">{{job.nombreProtocolo }}</label>
            <a *ngIf=" job.celularProtocolo " href="tel:{{job.celularProtocolo}}">{{job.celularProtocolo}}</a>
          </div>
        </div>
      <div class="row align-content-center"  *ngIf=" job.instruccionesmision ">
        <div class="col-3">
          <h5>{{'app.driver.Details-33' | translate}}</h5>
        </div>
        <div class="col-9 my-auto">
          <label>{{job.instruccionesmision }}</label>
        </div>
      </div>
        <div class="row align-content-center"  *ngIf=" job.vuelo ">
          <div class="col-3">
            <h5>{{'app.driver.Details-12' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label class="me-1">{{job.vuelo}}</label>
            <label>{{estadoVueloLlegada | async}}</label>
          </div>
        </div>
        <div class="row align-content-center"  *ngIf=" job.instruccionesrecogida ">
          <div class="col-3">
            <h5>{{'app.driver.Details-13' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.instruccionesrecogida }}</label>
          </div>
        </div>
        <div class="row align-content-center"  *ngIf=" job.vueloD ">
          <div class="col-3">
            <h5>{{'app.driver.Details-14' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label class="me-1">{{job.vueloD}}</label>
            <label>{{estadoVueloSalida | async}}</label>
          </div>
        </div>
        <div class="row align-content-center"  *ngIf=" job.firmahmision ">
          <div class="col-3">
            <h5>{{'app.driver.Details-15' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.firmahmision== 'Si'?('Yes'| translate):('No'|translate )}}</label>
          </div>
        </div>
        <div class="row align-content-center"  *ngIf=" job.gastosadicionales ">
          <div class="col-3">
            <h5>{{'app.driver.Details-16' | translate}}</h5>
          </div>
          <div class="col-9 my-auto">
            <label>{{job.gastosadicionales=='Si'?('Yes'| translate):('No'|translate ) }}</label>
          </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle *ngIf="showEventos">
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
          {{'app.driver.CheckList-1' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'app.driver.CheckList-2' | translate}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <h2>{{'app.driver.CheckList-3' | translate}}</h2>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle *ngIf="showEventos">
      <mat-expansion-panel-header class="right-aligned-header">
        <mat-panel-title>
          {{'app.driver.MissionOrder-1' | translate}}
        </mat-panel-title>
        <mat-panel-description>
          {{'app.driver.MissionOrder-2' | translate}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <h2>{{'app.driver.MissionOrder-3' | translate}}</h2>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div *ngIf="tabEventos" class="col-12">
  <div *ngIf="reportandoEvento | async">
    <mat-spinner style="margin:0 auto;" >
    </mat-spinner>
  </div>
  <div *ngIf="!(reportandoEvento | async)">
    <div class="row w-100 mb-5" *ngFor="let button of bottonesEventos; let i = index">
      <button  (click)="sendEvento(button)"
               [ngClass]="button.idEstado == idEstadoActual?'estado-actual':'next-estado'">
        <mat-icon *ngIf="button.idEstado != idEstadoActual" class="icon">chevron_right</mat-icon>
        <mat-icon *ngIf="button.idEstado == idEstadoActual" class="icon-actual">beenhere</mat-icon>
        {{ ('app.statusCode.' + button.idEstado.toString()) | translate}}
      </button>
    </div>
    <div class="row mb-5">
      <button mat-raised-button (click)="toggleVermas()" >{{'app.driver.VerMas' | translate}}</button>
    </div>
    <ng-container  *ngIf="vermas">
      <div class="row w-100 mb-5" *ngFor="let button of botonesVerMas; let i = index" >
        <button (click)="sendEvento(button)" class="ver-mas" >
          <mat-icon class="icon">chevron_left</mat-icon>
          {{ ('app.statusCode.' + button.idEstado.toString()) | translate}}
        </button>
      </div>
    </ng-container>
  </div>
</div>
