
  <!-- BEGIN .widget -->
  <div class="widget">
      <div class="widget-block"></div>

      <h3>{{'Pricing-1' | translate}}</h3>

      <div class="pricing-options-widget">
        <ul>
          <li class="hour-pricing-option"><strong>$30-85 / {{'Hour' | translate}}</strong>{{'Pricing-2' | translate}}</li>
          <li class="day-pricing-option"><strong>$330-935 / {{'Day' | translate}}</strong>{{'Pricing-4' | translate}}</li>
          <li class="airport-pricing-option"><strong>$30-205 / {{'Pricing-3' | translate}}</strong>{{'Pricing-5' | translate}}</li>
        </ul>

      </div>

    <!-- END .widget -->
    </div>
