import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {City, Vehicle, VehicleCategory} from '../../../models/business-objects';
import {VehiclesService} from '../../services/vehicles.service';
import {LocationService} from '../../services/location.service';
import {isPlatformBrowser, Location} from '@angular/common';
import View360, {EquirectProjection} from '@egjs/view360';

@Component({
  selector: 'app-vehicle-overview',
  templateUrl: './vehicle-overview.component.html',
  styleUrls: ['./vehicle-overview.component.scss']
})
export class VehicleOverviewComponent implements OnInit, OnDestroy  {
  activeTab : number = 0;
  disableTabs = false;
  selectedCategory: string;
  selectedCity: string; // el campo 'link' de las ciudades
  selectedVehicle: string;
  vehiclesFromCity: Vehicle[];
  vehicle: Vehicle;
  imagenes: Array<any> = [];
  cities: City[] = [];
  city: City;
  subscriptions = [];
  viewer :View360;
  @ViewChild('tabsSection', {static: false}) tabsSection: ElementRef;
  // @ViewChild(TemplateRef, { static: true }) tabsSection!: TemplateRef;
  constructor(private route: ActivatedRoute, private location: Location, private vehicleService: VehiclesService,
              private changeDetectorRef: ChangeDetectorRef,@Inject(PLATFORM_ID) private platformId: any,private ref: ChangeDetectorRef,
              private canonicalAndMetaService: CanonicalAndMetaService, private locationService: LocationService, private router: Router) {

  }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('vehicle').then(result => {
      this.subscriptions.push(this.route.params.subscribe(async params => {
        this.selectedCategory = this.route.snapshot.paramMap.get('selectedCategory');
        this.selectedCity = this.route.snapshot.paramMap.get('selectedCity');
        this.selectedVehicle = this.route.snapshot.paramMap.get('selectedVehicle');
        await this.canonicalAndMetaService.setMetas('vehicle', true,
          this.selectedCity + ' ' + this.selectedVehicle + ' ' + this.selectedCategory);
        await this.canonicalAndMetaService.setCanonicalURL('vehicle').then(async none => {
          await this.changeSelectedVehicle();
        });
        const url = '../../../assets/' + (this.vehicle.photo360??"");
        console.log('360 image url ' , url)
        this.viewer  = new View360("#viewer", {
        });
        if (this.vehicle && this.vehicle.photo360) {
          const projection :EquirectProjection = new EquirectProjection({
            // Image URL to your 360 panorama image/video
            src: url,
            // It's false, as it's gonna display image not video here
            video: false
          })
          await this.viewer.load(projection);
          console.log('projection reloaded with ', projection.src)
        }
        const tab : number = Number(this.route.snapshot.queryParamMap.get('tab'));
        console.log('this.route.snapshot.queryParamMap ' , this.route.snapshot.queryParamMap);
        console.log('tab ' , tab)
          if (this.vehicle && this.vehicle.photos && (tab == null || tab == 0|| tab == 4)) {
            this.setActiveTab(3);
            console.log('tab ' , 3)
          } else {
            this.setActiveTab(tab);
            console.log('tab ' , tab)
        }
      }));
    });
  }

  async setActiveTab(tabToSet: number) {
    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {tab: tabToSet}}).toString();
    console.log('this.location.go(url) ', url)
    this.location.go(url);
    if (tabToSet === 4) {
      // https://www.npmjs.com/package/pannellum?activeTab=readme
      const url = '../../../assets/' + (this.vehicle.photo360 ?? "");
      console.log('url ', url)

      if (this.viewer.projection== null || url != this.viewer.projection.src) {
        const projection :EquirectProjection = new EquirectProjection({
          // Image URL to your 360 panorama image/video
          src: url,
          // It's false, as it's gonna display image not video here
          video: false
        })
        await this.viewer.load(projection);
        console.log('projection reloaded with ', projection.src)
      }

    }
    this.activeTab = tabToSet;
    /*
    this.router.navigate(
      [],
      {
        state: { scrollPositionRestoration: 'disabled' },
        relativeTo: this.route,
        queryParams: {tab: tabToSet},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
     */
    if (this.tabsSection) {
      if (isPlatformBrowser(this.platformId)) {
        if (!this.isScrolledIntoView(this.tabsSection.nativeElement)) {
          this.tabsSection.nativeElement.scrollIntoView(false);
        }
      }

    } else {
      console.log('TabSection no definida todavia');
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return (elemTop >= 0) && (elemBottom <= window.innerHeight);
  }

  async changeSelectedVehicle(){
    console.log('changeSelectedVehicle');
    console.log(this.selectedCategory);
    console.log(this.selectedCity);
    console.log(this.selectedVehicle);
    this.cities = await this.locationService.getCities();
    if ( this.selectedCity != null && this.cities != null) {
      this.city = this.cities.find(c => c.link === this.selectedCity);
    }
    this.vehiclesFromCity = await this.vehicleService.getVehicles(this.selectedCity);
    if ( this.vehiclesFromCity == null) {
      this.vehicleService.getVehiclesFromAPI(this.selectedCity).then(result => {
        this.vehiclesFromCity = result;
        this.identifyVehicle();
        if (this.vehicle != null) {
          this.canonicalAndMetaService.setMetas('vehicle', true,
            this.selectedCity + ' ' + this.selectedVehicle + ' ' + this.selectedCategory,
            'https://www.vehiculosvip.com/assets/img/fleet/' + this.vehicle.image + '-600x350.jpg', '600', '350');
          this.changeDetectorRef.detectChanges(); }
      });
    } else {
      this.identifyVehicle();
      if (this.vehicle != null) {
        this.canonicalAndMetaService.setMetas('vehicle', true,
          this.selectedCity + ' ' + this.selectedVehicle + ' ' + this.selectedCategory,
          'https://www.vehiculosvip.com/assets/img/fleet/' + this.vehicle.image + '-600x350.jpg', '600', '350');
        this.changeDetectorRef.detectChanges(); }
    }
  }
  identifyVehicle() {
    console.log('identifyVehicle');
    console.log(this.selectedCategory);
    console.log(this.selectedCity);
    console.log(this.selectedVehicle);
    // console.log(this.vehiclesFromCity);
    console.log('procesando vehiculos ' + this.vehiclesFromCity.length);
    this.vehiclesFromCity.forEach( v => {
      console.log('procesando vehiculo id ' + v.id);
      if (this.selectedCategory != null ) {
        console.log('por categoria :' + this.selectedCategory + '  ?= ' + this.getCategory(v.categoryId).name + '/ ' + v.categoryId);
        if (this.getCategory(v.categoryId).link === this.selectedCategory
          || v.categoryId === parseInt(this.selectedCategory, 10 )) {
          console.log('la categoria corresponde !');
          if (this.selectedVehicle != null ) {
            console.log('por nombre despues de la categoria');
            if (v.link === this.selectedVehicle) {
              this.vehicle = v;
            } else {
              console.log('pero no el nombre del vehiculo !');
            }
          } else {
            console.log('no hay nombre se limita a elegir este carro porque es de la buena categoria');
            this.vehicle = v;
          }
        } else {
          console.log('la categoria no corresponde');
        }
      } else if (this.selectedVehicle != null ) {
        console.log('por nombre :' + this.selectedVehicle + '  ?= ' + v.link);
        if (v.link === this.selectedVehicle) {
          this.vehicle = v;
        }
      }
    });
    // console.log('ya, se encontro  :');
    // console.log(this.vehicle);
    if ( this.vehicle != null) { this.imagenes = this.vehicle.photos; }
  }

  getCategory(id: number): VehicleCategory {
    return this.vehicleService.currentCategoriesValue.find(c => c.id === id);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
