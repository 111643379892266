import {Component, OnInit} from '@angular/core';
import {News} from '../../../models/business-objects';
import {NewsService} from '../../services/news.service';

@Component({
  selector: 'app-tag-cloud-widget',
  templateUrl: './tag-cloud-widget.component.html',
  styleUrls: ['./tag-cloud-widget.component.scss']
})
export class TagCloudWidgetComponent implements OnInit {
  newsList: News[] = [];
  tagList: string[] = [];
  constructor(private newsService: NewsService) { }

  async ngOnInit() {
    this.newsList = await this.newsService.getCurrentNews();
    for (const n of this.newsList) {
      this.tagList.push(...n.tags.split(';'));
    }
    this.tagList = Array.from(new Set(this.tagList));
  }
}
