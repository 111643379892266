import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {BookingWidgetComponent} from './booking-widget.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    BookingWidgetComponent,
  ],
  imports: [
    CommonModule,
    LocalizeRouterModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    NgbDatepickerModule,
    TranslateModule,
  ],
  exports: [
    BookingWidgetComponent, TranslateModule
  ]
})
export class BookingWidgetModule { }
