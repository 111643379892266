import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Injectable()
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthService,
        private localize: LocalizeRouterService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // logged in so return true
            console.log('dice que esta conectado un usuario ');
            console.log(currentUser);
            return true;
        }
        // not logged in so redirect to login page with the return url
        console.log('url solicitada ' + state.url + ' re-enviando a ' + this.localize.translateRoute('/login'));
        this.router.navigate([this.localize.translateRoute('/login')], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
