import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VehicleWidgetComponent} from './vehicle-widget.component';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    VehicleWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterLink
  ],
  exports: [
    VehicleWidgetComponent,TranslateModule
  ]
})
export class VehicleWidgetModule { }
