		<footer class="footer" *ngIf="!(inPrivateSection| async)">
      <div class="row w-100 justify-content-center" >
        <div class="col-lg-5 ml-lg-5 ml-sm-2 mb-3 text-left ms-1">
          <h5>{{'AboutUs-0' | translate}}</h5>
          <div class="title-block2"></div>
          <p class="text-justify">{{'AboutUs-1' | translate}}</p>
          <p class="text-justify">{{'AboutUs-2' | translate}}</p>
        </div>
        <div class="col-lg-3 ml-sm-2  mb-5 text-left ms-1">
          <h5>{{'NewsLetter0' | translate}}</h5>
          <div class="title-block2"></div>
          <p>{{'NewsLetter1' | translate}}</p>
          <form action="#" class="newsletter-form" method="post">
            <input type="text" name="your-name" value="" [(ngModel)]="email" placeholder="{{'BookingSteps-16' | translate}}"/>
            <form [formGroup]="aFormGroup" *ngIf="onBrowser">
              <ng-container *ngIf="email.length>3">
                <ngx-recaptcha2 #captchaElem
                                [siteKey]="'6Lfyz9cZAAAAAIsCRRqGPxEqP285ohFgUvqoyhVC'"
                                (reset)="handleReset()"
                                (expire)="handleExpire()"
                                (load)="handleLoad()"
                                (success)="handleSuccess($event)"
                                [useGlobalDomain]="false"
                                [size]="'normal'"
                                [hl]="lang"
                                [theme]="'dark'"
                                [type]="type"
                                formControlName="recaptcha">
                </ngx-recaptcha2>
              </ng-container>
            </form>
            <button class="mt-3" type="submit" (click)="sendNewsLetterRequest()" id="getNewsLetter" >
              {{'NewsLetter2' | translate}} <i class="fa fa-envelope"></i>
              <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
            </button>
            <div [ngClass]="resultClass" [ngStyle]="{'display': resultDisplay}" class="col-sm-12 mt-3" id="result">
              <p style="padding: 15px; margin: 0; font-size: large" [innerHTML]="resultText"></p>
            </div>
          </form>
        </div>
        <div class="col-lg-3 ml-sm-2  mb-3 text-left ms-1" itemscope="" itemtype="http://schema.org/AutomotiveBusiness">
          <h5>{{'ContactDetails' | translate}}</h5>
          <div class="title-block2"></div>
          <ul class="contact-widget">
            <a href="https://www.vehiculosvip.com/assets/images/logo.png" title="Logo" itemprop="image"></a>
            <span itemprop="name">V&V Vehiculos VIP</span>
            <li class="cw-address">
               <span itemprop="address" itemscope="" itemtype="https://schema.org/PostalAddress">
                  <span itemprop="streetAddress"> Carrera 23 # 68-22 </span>,<br/>
                  <span itemprop="addressLocality">Bogota</span>
               </span>
            </li>
            <li class="cw-phone"><a href="tel:+18883685236" title="phone" itemprop="telephone">+1-888-368-5236</a><span>{{'OpeningHours1' | translate}}</span></li>
            <li class="cw-phone"> <a href="tel:+576012114949" title="phone" itemprop="telephone">+57-601-211-49-49</a><span>{{'OpeningHours2' | translate}}</span></li>
            <li class="cw-phone"> <a href="tel:+576017389641" title="phone" itemprop="telephone">+57-601-738-9641</a><span>{{'OpeningHours3' | translate}}</span></li>
            <li class="cw-cell"> <a href="mailto:info@vehiculosvip.com" title="email" itemprop="email">info&#64;vehiculosvip.com</a><span>{{'ResponseTime' | translate}}</span></li>
          </ul>
        </div>
        </div>
        <div class="footer-bottom">
          <div class="footer-social-icons-wrapper">
            <a href="https://www.facebook.com/VandVcarservice/" target="_blank" title="Facebook"><i class="fab fa-facebook"></i></a>
            <a href="https://www.instagram.com/vehiculosvipcarservice/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a>
            <a href="https://twitter.com/vehiculosvip" target="_blank" title="twitter"><i class="fab fa-twitter"></i></a>
            <a href="https://in.linkedin.com/company/v&v-bogot---veh-culos-vip" target="_blank" title="LinkedIn"><i class="fab fa-linkedin"></i></a>
          </div>
          <p class="footer-message">&copy; {{'CopyWrights' | translate}} {{'GitVersion.tag' | translate}}</p>
          <p class="footer-message"> <a [href]="'/privacy-policy' | localize">{{'Register-18' | translate}}</a></p>
          <p class="footer-message"> <a [href]="'/terms-and-conditions' | localize">{{'Register-17' | translate}}</a></p>
      </div>
    </footer>
