import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {User} from '../../../models/business-objects';
import {firstValueFrom, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {matchValidator} from '../../shared/form-validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @ViewChild('f') forogtPasswordForm: NgForm;
  userId: number;
  routeUserId: number;
  token: string;
  password1: string = "";
  password2: string = "";
  email: string;
  tokenIsValid: boolean;
  passwordsAreEquals: boolean;
  passwordsAreValid: boolean;
  resultClass: string;
  matchClass = 'alert alert-danger';
  resultString: string;
  loading: Subject<boolean> = new Subject();
  subscriptions = [];
  changePwForm : UntypedFormGroup;
  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('forgot-password').then(async result => {
      await this.canonicalAndMetaService.setMetas('forgot', true, '');
    });
    this.subscriptions.push (this.route.paramMap.subscribe(params => {
      console.log('llego una ruta');
      console.log(this.route.snapshot.paramMap);
      if (!this.authService.currentUserValue) {
        this.routeUserId = Number.parseInt(params.get('id'), 10);
      }
      console.log('envioId: ' + this.userId);
      this.token = params.get('token');
    }));
    this.subscriptions.push ( this.authService.currentUser.subscribe(data => {
      if (data) {
        this.userId = data.id;
      } else {
        this.userId = this.routeUserId;
      }
    })
    );
    this.subscriptions.push ( this.changePwForm.controls.password.valueChanges.subscribe(data => {
        this.password1 = data;
      })
    );
    this.subscriptions.push ( this.changePwForm.controls.confirmPassword.valueChanges.subscribe(data => {
        this.password2 = data;
      })
    );
  }

  constructor(private router: Router, private authService: AuthService,
              private canonicalAndMetaService: CanonicalAndMetaService,
              private route: ActivatedRoute, private translate: TranslateService,private formBuilder: UntypedFormBuilder,
              private localize: LocalizeRouterService) {
    this.translate.setDefaultLang('en');
    this.changePwForm = formBuilder.group({
      originalPassword: ['', []],
      password: ['', [
        Validators.required,
        Validators.pattern('^.*[0-9]{1,}.*$'),
        Validators.pattern('^.*[A-Z]{1,}.*$'),
        Validators.pattern('^.*[a-z]{1,}.*$'),
        Validators.minLength(6),
        Validators.maxLength(25),
        matchValidator('confirmPassword', true)
      ]],
      confirmPassword: ['', [
        Validators.required,
        matchValidator('password')
      ]],
    });
    if (authService.currentUserValue) {
      this.userId = authService.currentUserValue.id;
    }
  }
  // On submit click, reset form fields
  async requestResetPassword() {
    this.loading.next(true);
    if (this.email) {
      console.log('ruta traducida');
      console.log(this.localize.translateRoute('/forgot-password'));
      const url = this.localize.translateRoute('/forgot-password');
      const usuario: User = new User();
      usuario.email = this.email.trim() ;
      usuario.url = url ;
      usuario.messageHeader = await firstValueFrom(this.translate.get('User-Message-2'));
      usuario.messageFooter = await firstValueFrom(this.translate.get('User-Message-3'));
      usuario.messageSignature = await firstValueFrom(this.translate.get('User-Message-4'));
      usuario.messageTitle = await firstValueFrom(this.translate.get('User-Message-ForgotPassword-Title'));
      this.authService.getForgotPassword(usuario).then(data => {
        console.log(data);
        if (data.result === 'ok') {
          this.resultClass = 'alert alert-success';
          firstValueFrom(this.translate.get('ForgotPassword-5')).then(title => {
            this.resultString = title;
          });
          setTimeout( () =>  {this.router.navigate([this.localize.translateRoute('/login')]); } , 5000);
        } else {
          if (data.result === 'user not found or not active') {
            this.resultClass = 'alert alert-danger';
            firstValueFrom(this.translate.get('ForgotPassword-6')).then(title => {
              this.resultString = title;
            });
          } else {
            this.resultClass = 'alert alert-danger';
            firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
              this.resultString = title;
            });
          }
        }
        this.loading.next(false);
      }, error => {
        this.resultClass = 'alert alert-danger';
        firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
          this.resultString = title;
        });
        this.loading.next(false);
      });
    } else {
      this.resultClass = 'alert alert-danger';
      firstValueFrom(this.translate.get('ForgotPassword-8')).then(title => {
        this.resultString = title;
      });
      this.loading.next(false);
    }
  }

  // On submit click, reset form fields
  changePassword() {
    if (this.password2 === this.password1 && (this.token || this.changePwForm.controls.originalPassword)) {
      this.loading.next(true);
      this.authService.getChangePassword(this.password2, this.userId,this.changePwForm.controls.originalPassword.value )
        .then( data => {
          console.log('data from resetPassword ' + data)
          if (data.result === 'ok') {
            this.resultClass = 'alert alert-success';
            firstValueFrom(this.translate.get('ForgotPassword-9')).then(title => {
              this.resultString = title;
            });
            setTimeout( () =>  {
              this.authService.logout();
              this.router.navigate([this.localize.translateRoute('/login')]);
              } , 3000);
          } else  if (data.result === 'Token Invalido') {
            this.resultClass = 'alert alert-danger';
            firstValueFrom(this.translate.get('ForgotPassword-11')).then(title => {
              this.resultString = title;
              this.loading.next(false);
            });
          } else if (data.result === 'WRONG_PASSWORD'  || data.result ==='USER_BLOCKED' || data.result === 'IP_BLOCKED') {
            this.resultClass = 'alert alert-danger';
            firstValueFrom(this.translate.get('Error.' + data.result)).then(text => {
              this.resultString = text;
              if (data.attemptsLeft) this.resultString = this.resultString + ' ' + data.attemptsLeft;
              this.loading.next(false);
            });
          } else {
            this.resultClass = 'alert alert-danger';
            firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
              this.resultString = title;
              this.loading.next(false);
            });
          }
        }, error => {
          this.resultClass = 'alert alert-danger';
          firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
            this.resultString = title;
          });
          this.loading.next(false);
        });
    } else {
      if (this.password2 === this.password1) {
        this.resultClass = 'alert alert-danger';
        firstValueFrom(this.translate.get('ForgotPassword-10')).then(title => {
          this.resultString = title;
        });
      } else {
        this.resultClass = 'alert alert-danger';
        firstValueFrom(this.translate.get('ForgotPassword-10')).then(title => {
          this.resultString = title;
        });
      }
    }
  }

  resetPassword() {
    if (this.password2 === this.password1) {
      this.loading.next(true);
      this.authService.getResetPassword(this.password2, this.userId, this.token)
        .then( data => {
          if (data.result === 'ok') {
            this.resultClass = 'alert alert-success';
            firstValueFrom(this.translate.get('ForgotPassword-9')).then(title => {
              this.resultString = title;
            });
            setTimeout( () =>  {this.router.navigate([this.localize.translateRoute('/login')]); } , 3000);
          } else {
            if (data.result === 'Token Invalido') {
              this.resultClass = 'alert alert-danger';
              firstValueFrom(this.translate.get('ForgotPassword-11')).then(title => {
                this.resultString = title;
              });
            } else {
              this.resultClass = 'alert alert-danger';
              firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
                this.resultString = title;
              });
            }
          }
          this.loading.next(false);
        }, error => {
          this.resultClass = 'alert alert-danger';
          firstValueFrom(this.translate.get('ForgotPassword-7')).then(title => {
            this.resultString = title;
          });
          this.loading.next(false);
        });
    } else {
      this.resultClass = 'alert alert-danger';
      firstValueFrom(this.translate.get('ForgotPassword-10')).then(title => {
        this.resultString = title;
      });
    }
  }
  // On login link click
  onLogin() {
    this.router.navigate([this.localize.translateRoute('/login')], { relativeTo: this.route.parent });
  }

  // On registration link click
  onRegister() {
    this.router.navigate([this.localize.translateRoute('/register')], { relativeTo: this.route.parent });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
