<div id="page-header">
  <h1>{{'Testimonials' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize">{{'Home' | translate}}</a><i class="fa fa-angle-right"></i>{{'Testimonials' | translate}}</p>
</div>
<div class="row justify-content-center">
  <div class="col-lg-6 col-sm8">
    <div id="google_translate_element"></div>
    <table mat-table [dataSource]="dataSource">
      <div class="testimonial-list-wrapper testimonial-list-wrapper-full">
              <ng-container matColumnDef="review">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let testimonial">
                    <!-- BEGIN .testimonial-wrapper -->
                    <div class="testimonial-wrapper" >
                      <h3 class="text-uppercase">{{testimonial.title}}</h3>
                      <div>
                      <p><span class="qns-open-quote">“</span></p>
                      <p  [innerHTML]="testimonial.reviewBody">
                      </p>
                      <p><span class="qns-close-quote">”</span></p>
                      </div>
                      <div class="testimonial-author"><p>{{testimonial.author}}</p></div>
                          <!-- END .testimonial-wrapper -->
                    </div>
                  </td>
              </ng-container>
              <tr mat-row *matRowDef="let row; columns: ['review'];">
            </tr>
      <!-- END .testimonial-list-wrapper -->
      </div>
    </table>
    <mat-paginator
      showFirstLastButtons="true"
      [hidePageSize]="true"
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event">
    </mat-paginator>

    <!-- END .main-content -->
    </div>

    <!-- BEGIN .sidebar-content -->
  <div class="col-lg-3 col-sm3 ms-2">

      <!-- BEGIN .widget-booking-form-wrapper -->
      <app-booking-widget class="widget-booking-form-wrapper" [format]="'vertical'"></app-booking-widget>

      <!-- BEGIN .widget -->
      <app-news-widget></app-news-widget>

      <!-- BEGIN .widget -->
      <app-tag-cloud-widget></app-tag-cloud-widget>

      <!-- BEGIN .widget -->


    <!-- END .sidebar-content -->
    </div>
</div>
