import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../../services/loader.service';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService
  ) {}
  shouldShowSpinner (request: HttpRequest<unknown>): boolean {
    console.log('request.url ' +request.url)
    if( request.url.includes("rest/generic/response")) {
      return false;
    }
    return true;
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Skip interception for Matrix API requests
    if (request.url.includes('/_matrix/')) {
      return next.handle(request); // Don't modify the request
    }
    if (request.url.includes('/chatbot/')) {
      return next.handle(request); // Don't modify the request
    }
    console.log('caught')
    if (this.shouldShowSpinner(request)) {
      this.totalRequests++;
      console.log("Set loading globally")
      this.loadingService.setLoading(true);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (this.shouldShowSpinner(request)) this.totalRequests--;
        if (this.totalRequests == 0) {
          console.log("Cancelling loading globally")
          this.loadingService.setLoading(false);
        } else {
          console.log("Not Cancelling loading globally, http requests : " + this.totalRequests);
        }
      })
    );
  }
}
