import {EventEmitter, Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, Location} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {firstValueFrom, lastValueFrom} from 'rxjs';


@Injectable()

export class CanonicalAndMetaService implements OnDestroy{
  subscriptions = [];
  settingCanonicals: boolean = false;
  languageChanged: EventEmitter<LangChangeEvent>= new EventEmitter<null>();
  constructor(@Inject(DOCUMENT) private dom, @Inject(PLATFORM_ID) private platformId: any, private location: Location,
              private title: Title, private meta: Meta, private translate: TranslateService, private localize: LocalizeRouterService) {
    this.translate.setDefaultLang('en');
    this.dom.documentElement.lang = translate.currentLang;
    this.subscriptions.push(this.translate.onLangChange.subscribe(event => {
      this.dom.documentElement.lang = event.lang;
      if(!this.settingCanonicals) this.languageChanged.emit(event);
    }));

  }

   async setCanonicalURL(urlTranslationKey: string) {
    const presentLanguage = this.translate.currentLang;
    this.settingCanonicals = true;
    const canURL = this.dom.URL;
    if (presentLanguage !== 'es') {
      this.translateUrl('es', 'alternate', urlTranslationKey);
    }
    if (presentLanguage !== 'en') {
      this.translateUrl('en', 'alternate', urlTranslationKey);
    }
    if (presentLanguage !== 'fr') {
      this.translateUrl('fr', 'alternate', urlTranslationKey);
    }
    if (presentLanguage !== 'pt') {
      this.translateUrl('pt', 'alternate', urlTranslationKey);
    }
    if (presentLanguage !== 'de') {
      this.translateUrl('de', 'alternate', urlTranslationKey);
    }
    console.log('this.dom.URL  ', canURL);
    this.updateLink(presentLanguage,  canURL,'canonical');
    this.settingCanonicals = false;
  }
  updateLink(lang: string, url: string,  tipo: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let linkEs: HTMLLinkElement = this.dom.querySelector('link[hreflang=' + lang + ']') || null;
    if (linkEs == null) {
      linkEs = this.dom.createElement('link') as HTMLLinkElement;
      linkEs.setAttribute('rel', tipo);
      linkEs.setAttribute('hreflang', lang);
      linkEs.setAttribute('href',  url);
      head.appendChild(linkEs);
    } else {
      linkEs.setAttribute('rel', tipo);
      linkEs.setAttribute('hreflang', lang);
      linkEs.setAttribute('href',  url);
    }
  }
  translateUrl(lang: string, tipo: string, urlTranslationKey: string) {
    let translatedUrl: string = '';
    translatedUrl = this.translate.getParsedResult(this.translate.translations[lang], 'ROUTES.' + urlTranslationKey);
    translatedUrl = '/' + lang + '/' + translatedUrl;
    console.log(this.location.path());
    console.log(this.dom.location.origin);
    console.log('translated url ', translatedUrl);
    this.updateLink(lang, this.dom.location.origin + translatedUrl, tipo);
  }

  async setMetas(page: string, robots: boolean, locationDetail: string, image?: string, width?: string, height?: string){
    console.log('actualizando meta tags con la idioma ' + this.translate.currentLang);
    await firstValueFrom(this.translate.get('meta.title.' + page)).then(text => {
      if (locationDetail != null && locationDetail.length > 0)  {text = text + ' ' + locationDetail; }
      this.title.setTitle(text);
      this.meta.updateTag({ property: 'og:title', content: text });
      this.meta.updateTag({ name: 'twitter:title', content: text });
    });
    this.meta.updateTag({ property: 'og:url', content: this.dom.URL});
    await firstValueFrom(this.translate.get('meta.image.' + page)).then(text => {
      if (image) {
        this.meta.updateTag({ property: 'og:image:secure_url', content: image});
        if (width) { this.meta.updateTag({ property: 'og:image:width', content: width}); }
        if (height) { this.meta.updateTag({ property: 'og:image:height', content: height}); }
        this.meta.updateTag({ name: 'twitter:image', content: image });
      } else if (text) {
        this.meta.updateTag({ property: 'og:image:secure_url', content: text});
        this.meta.updateTag({ property: 'og:image:width', content: '400'});
        this.meta.updateTag({ property: 'og:image:height', content: '300'});
        this.meta.updateTag({ name: 'twitter:image', content: text });
      }
    });
    await firstValueFrom(this.translate.get('meta.description.' + page)).then(text => {
      this.meta.updateTag({ name: 'description', content: text });
      this.meta.updateTag({ property: 'og:description', content: text });
      this.meta.updateTag({ name: 'twitter:description', content: text });
    });
    await firstValueFrom(this.translate.get('meta.keywords.' + page)).then(text => {
      this.meta.updateTag({ name: 'keywords', content: text });
    });
    await firstValueFrom(this.translate.get('meta.robots.' + (robots ? 'yes' : 'no') )).then(text => {
      this.meta.updateTag({ name: 'robots', content: text });
      this.meta.updateTag({ name: 'googlebot', content: text });
    });
    await firstValueFrom(this.translate.get('meta.date')).then(text => {
      this.meta.updateTag({ name: 'date', content: text });
    });
    await firstValueFrom(this.translate.get('meta.author.all')).then(text => {
      this.meta.updateTag({ name: 'author', content: text});
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
