import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, Renderer2} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {BookingService} from '../../services/booking.service';
import {Booking} from '../../../models/business-objects';

declare var dataLayer;

@Component({
  selector: 'app-google-analytics-gtag',
  templateUrl: './google-analytics-gtag.component.html',
})
export class GoogleAnalyticsGTagComponent implements OnDestroy{
  tagManagerCode = environment.googleTagManagerId;
  script: HTMLScriptElement;
  subscriptions = [];
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any, @Inject(DOCUMENT) private _document: Document,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef, private router: Router, private bookingService: BookingService
  ) {
    // BROWSER
    if (isPlatformBrowser(this.platformId)) {
      this.gTagManager();
      this.subscriptions.push(this.bookingService.currentBooking.subscribe(async value =>  {
        console.log('cambio el booking en tag manager');
        if (value != null) {
          this.changeBooking(value);
        }
      }));
    }
  }
  gTagManager() {
    if (this.script != null) {
      console.log('el script de google tag manager ya estaba presente, lo quitamos !');
      this.renderer.removeChild(this._document.head, this.script);
    }
    this.script = this.renderer.createElement('script') as HTMLScriptElement;
    this.script.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.tagManagerCode}');`;
    this.renderer.appendChild(this._document.head, this.script);
    console.log('se agrego el script de google tag manager');
  }
  changeBooking( value: Booking) {
    dataLayer.push({
      travel_destid: value.city != null ? value.city.name : null,
      service_city: value.serviceCity,
      vehicle_category_apiName: value.vehicleCategory != null ? value.vehicleCategory.apiName : null,
      booking_id: value.id,
      booking_moneda: value.moneda,
      booking_quote: value.quote,
      booking_confirmationNumber: value.confirmationNumber
    });
    console.log('se hizo un push al dataLayer ');
    console.log(dataLayer);
    // toca volver agregar el tag manager despues para que coja los valores...
    this.gTagManager();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}


