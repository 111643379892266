		<!-- BEGIN .widget -->
		<div class="widget">
        <div class="widget-block"></div>

        <h3>{{'VehicleType' | translate}}</h3>

        <ul>
          <ng-container *ngFor="let category of categories">
            <li><a [routerLink]="('/fleet' | localize ) + (ciudad ? ('/' + ciudad) : '') + '/' + category.link" href="{{('/fleet' | localize ) + (ciudad ? ('/' + ciudad) : '') + '/' + category.link}}">{{category.name | translate}}
               </a></li>
          </ng-container>
        </ul>

      <!-- END .widget -->
    </div>
