import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {
  private canonicalAndMetaService: any;

  constructor(public translate: TranslateService) { }

  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('onboard').then(async result => {
      await this.canonicalAndMetaService.setMetas('on-board', true, '');
    });
  }

}
