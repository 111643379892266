import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {DISPATCH_MENU, PUBLIC_MENU} from '../horizontal-menu/menu-routes.config';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../../shared/animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../../services/interface/config.service';
import {LayoutService} from '../../services/interface/layout.service';
import {RouteInfo} from '../horizontal-menu/menu.metadata';
import {AuthService} from '../../services/auth.service';
import {User} from '../../../models/business-objects';
import {DataService} from '../../services/data.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: RouteInfo[];
  level = 0;
  logoUrl = 'assets/images/logo-top.png'; // http://localhost:4200/assets/images/logo-top.png
  public config: any = {};

  collapseSidebar = true;
  resizeTimeout;
  subscriptions = [];
  loggedUser: User = null;
  inPrivateSeccion: boolean = false;
  constructor(
    private router: Router,
    public translate: TranslateService, private layoutService: LayoutService,
    private configService: ConfigService, private cdr: ChangeDetectorRef, private dataService: DataService,
    private deviceService: DeviceDetectorService, private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.translate.setDefaultLang('en');
    this.config = this.configService.templateConf;
    this.isTouchDevice();
    this.subscriptions.push (this.dataService.inPrivateSection.subscribe(inPrivateSeccion => {
      this.inPrivateSeccion = inPrivateSeccion;
      this.changeMenu()
    }));
    this.subscriptions.push (this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
      this.changeMenu()
    }));
    this.loggedUser = this.authService.currentUserValue;
    this.inPrivateSeccion = this.dataService.currentPrivateSeccionValue;
    this.config = this.configService.templateConf;
    this.changeMenu()
  }


  ngOnInit() {
  }
  changeMenu (){
    if (this.inPrivateSeccion) {
      this.menuItems = this.layoutService.createMenuItems(this.loggedUser , DISPATCH_MENU);
    } else {
      this.menuItems = this.layoutService.createMenuItems(this.loggedUser , PUBLIC_MENU) ;
    }
  }
  ngAfterViewInit() {

    this.subscriptions.push ( this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
    }));

    this.subscriptions.push (this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      }));

  }

  loadLayout() {
    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/images/logo-top.png';
    }
    else {
      this.logoUrl = 'assets/images/logo-top.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = () => {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    if (isPlatformBrowser(this.platformId)) window.dispatchEvent(evt);

  }

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      //this.perfectScrollbarEnable = false;
    }
    else {
      //this.perfectScrollbarEnable = true;
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
