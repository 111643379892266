import {Injectable} from '@angular/core';
import {Staff} from '../../models/business-objects';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable()
export class StaffService {
  UrlAPI: string = environment.apiURL;
  constructor(private http: HttpClient) { }
  getDriverFromToken(staffId: string): Promise<Staff> {
    return firstValueFrom(this.http.post<any>(this.UrlAPI + environment.URL_STAFF_BY_TOKEN, {staffId}));
  }
}
