import {Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {TestimonialsService} from '../../services/testimonials.service';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {Testimonial} from '../../../models/business-objects';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit,  OnDestroy{
  length: number;
  pageSize = 4;
  pageSizeOptions = [3, 5, 10];
  allTestimonials: Array<Testimonial> = [];
  testimonials: Array<Testimonial> = [];
  dataSource = new MatTableDataSource<Testimonial>(this.testimonials);
  // MatPaginator Output
  pageEvent: PageEvent;
  subscriptions = [];
  script: HTMLScriptElement;
  script2: HTMLScriptElement;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private testimonialService: TestimonialsService, private canonicalAndMetaService: CanonicalAndMetaService,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private readonly platformId: any, @Inject(DOCUMENT) private _document: Document,
              private readonly renderer: Renderer2,
              private readonly el: ElementRef
              ) {
    this.translate.setDefaultLang('en');

    this.loadTestimonials();
    if (isPlatformBrowser(this.platformId)) {
      this.addGoogleTranslateScript();
    }
    this.subscriptions.push (this.canonicalAndMetaService.languageChanged.subscribe( event => {
      this.loadTestimonials();
      if (isPlatformBrowser(this.platformId)) {
        this.addGoogleTranslateScript();
      }
    }));

   }

  addGoogleTranslateScript() {
    //el problema de google translate es que traduce toda la pagina...
    /*
    if (this.script2 == null) {
      this.script2 = this.renderer.createElement('script') as HTMLScriptElement;
      this.script2.type = 'text/javascript';
      this.script2.innerText = `function googleTranslateElementInit() {new google.translate.TranslateElement({pageLanguage: 'es', layout: google.translate.TranslateElement.InlineLayout.SIMPLE}, 'google_translate_element');}`;
      this.renderer.appendChild(this._document.body, this.script2);
      console.log('se agrego el script de reviewBody' );
    }
    if (this.script == null) {
      this.script = this.renderer.createElement('script') as HTMLScriptElement;
      this.script.type = 'text/javascript';
      this.script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      this.renderer.appendChild(this._document.body, this.script);
      console.log('se agrego el script de google translate');
    }
     */
  }
   async loadTestimonials() {
     this.allTestimonials = await this.testimonialService.getTestimonials();
     this.length = this.testimonials.length;
     this.testimonials = this.allTestimonials;
     console.log(this.allTestimonials);
     this.length = this.testimonials.length;
     this.dataSource = new MatTableDataSource<Testimonial>(this.testimonials);
     this.dataSource.paginator = this.paginator;
   }
  async ngOnInit() {
    await this.canonicalAndMetaService.setCanonicalURL('testimonials').then(async result => {
      await this.canonicalAndMetaService.setMetas('testimonials', true, '');
    });
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
}
