import {Component, OnInit} from '@angular/core';
import {Vehicle, VehicleCategory} from '../../../models/business-objects';
import {VehiclesService} from '../../services/vehicles.service';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';

@Component({
  selector: 'app-vehicle-categories',
  templateUrl: './vehicle-categories.component.html',
  styleUrls: ['./vehicle-categories.component.scss']
})
export class VehicleCategoriesComponent implements OnInit {
  public slides1 = [
    { src: '../../assets/img/services/services7.jpg' },
    { src: '../../assets/img/services/services15.jpg' },
    { src: '../../assets/img/services/services12.jpg'},
    { src: '../../assets/img/services/services6.jpg'}
  ];
  public slides2 = [
    { src: '../../assets/img/services/services11.jpg' },
    { src: '../../assets/img/services/services5.jpg' },
    { src: '../../assets/img/services/services9.jpg'},
    { src: '../../assets/img/services/services10.jpg'}
  ];
  public slides3 = [
    { src: '../../assets/img/services/services16.jpg' },
    { src: '../../assets/img/services/services13.jpg' },
    { src: '../../assets/img/services/services2.jpg'},
    { src: '../../assets/img/services/services8.jpg'},
    { src: '../../assets/img/services/services17.jpg'}
  ];
  public categories: VehicleCategory[] = [];
  public vehicles: Vehicle[] = [];
  constructor(private canonicalAndMetaService: CanonicalAndMetaService, private vehicleService: VehiclesService) {
    this.canonicalAndMetaService.setMetas('vehicle', true, '');
  }

  async ngOnInit() {
    this.vehicles = await this.vehicleService.getVehicles(null);
    this.categories = await this.vehicleService.getCategories();
    if ( this.vehicles == null){
      this.vehicleService.getVehiclesFromAPI(null).then(result => {
        this.vehicles = result ;
      });
    }
    if ( this.categories == null){
      this.vehicleService.getCategoriesFromAPI(null).then(result => {
        this.categories = result ;
      });
    }
    await this.canonicalAndMetaService.setCanonicalURL('vehicle-categories').then(async result => {
      await this.canonicalAndMetaService.setMetas('categories', true , '');
    });
  }
  getCategory(id: number): VehicleCategory {
    return this.categories.find(c => c.id === id);
  }
}
