<div class="row justify-content-center">
  <div id="page-header">
    <h1>{{'Staff-0' | translate}}</h1>
    <div class="title-block3"></div>
    <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
      <i class="fa fa-angle-right"></i>{{'Staff-0' | translate}}</p>
  </div>
    <div class="col-11 pl-sm-5 mb-3" *ngIf="staff!=null && staff.nombre!=null">
    {{'Staff-5' | translate}}<br/><br/>
      {{'Payments-8' | translate}}: {{staff.nombre}}<br/>
      {{'Payments-11' | translate}}: {{staff.apellido}}<br/>
      {{'Payments-13' | translate}}: {{staff.cedula}}<br/>
      {{'PhoneNumber' | translate}}: {{staff.celular}}<br/><br/>
      <div *ngIf="staff.activo" class="text-success" [innerHTML]="'Staff-4' | translate">
      </div>
      <div *ngIf="!staff.activo" class="text-danger" [innerHTML]="'Staff-3' | translate">
      </div>
    </div>
  <div class="col-11 pl-sm-5 mb-3" *ngIf="staff==null || staff.nombre==null" [innerHTML]="'Staff-2' | translate">

  </div>
</div>
