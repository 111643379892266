import {ApplicationRef, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import {isPlatformServer} from '@angular/common';

@Injectable()
export class CheckForUpdateService {
  @Inject(PLATFORM_ID,) private platformId: any;
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    // 6 * 60 * 60 * 1000
    const everyTwoHours$ = interval( 6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyTwoHours$);
    if(isPlatformServer(this.platformId)) {
      everySixHoursOnceAppIsStable$.subscribe(async () => {
        try {
          console.log('Checking for update...');

          const updateFound = await updates.checkForUpdate();
          console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
    console.log('server worker configured for scanning updates every : 30 sec ' );
  }
}
