<div id="page-header">
  <h1>{{'Services' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a><i class="fa fa-angle-right"></i>{{'Services' | translate}}</p>
</div>

<!-- BEGIN .content-wrapper-outer -->
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
        <h3 class="center-title text-uppercase">{{'ServicesTitle-1' | translate}}</h3>
        <div class="title-block2"></div>
        <p class="text-justify" [innerHTML]="'ServicesText-1' | translate" ></p>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet1-1' | translate}}</li>
          <li>{{'ServicesTitleBullet1-2' | translate}}</li>
          <li>{{'ServicesTitleBullet1-3' | translate}}</li>
          <li>{{'ServicesTitleBullet1-4' | translate}}</li>
          <li>{{'ServicesTitleBullet1-5' | translate}}</li>
          <li>{{'ServicesTitleBullet1-6' | translate}}</li>
          <li>{{'ServicesTitleBullet1-7' | translate}}</li>
        </ul>
        <!-- END .qns-two-third -->
  </div>
  <div class=" col-lg-2 col-sm-3 mb-5 ">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
      <app-carousel class="photo-container" [slides]="slides1">
      </app-carousel>
    </div>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
    <h3 class="center-title text-uppercase">{{'ServicesTitle-3' | translate}}</h3>
    <div class="title-block2"></div>
    <p class="text-justify" [innerHTML]="'ServicesText-3' | translate" ></p>
    <p class="text-justify" [innerHTML]="'ServicesText-3a' | translate" ></p>
    <ul class="border-list">
      <li>{{'ServicesTitleBullet3-1' | translate}}</li>
      <li>{{'ServicesTitleBullet3-2' | translate}}</li>
      <li>{{'ServicesTitleBullet3-3' | translate}}</li>
      <li>{{'ServicesTitleBullet3-4' | translate}}</li>
    </ul>
    <!-- END .qns-two-third -->
  </div>
  <div class=" col-lg-2 col-sm-3 mb-5 ">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
    <app-carousel class="photo-container" [slides]="slides2">
    </app-carousel>
  </div>
</div>

<div class="row justify-content-center mb-5 ms-1 me-1">
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/carservice' | localize" href="{{'/carservice' | localize}}"  title="{{'Fleet' | translate}}"
                 title="{{'Fleet' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'Fleet' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/coverage' | localize"  href="{{'/coverage' | localize}}" title="{{'Coverage' | translate}}"
                 title="{{'Coverage' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'Coverage' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3>
      </li>
    </ul>
  </div>
  <div class="col-lg-3 col-sm-4 col-8 ms-3 me-3">
    <ul class="link-blocks">
      <li><h3><a [routerLink]="'/booking' | localize" href="{{'/booking' | localize}}" title="{{'BookNow' | translate}}"
                 title="{{'BookNow' | translate}}" class="link-block-3">
        <span class="link-text me-2">{{'BookNow' | translate}}</span>
        <span class="link-arrow fa fa-angle-right"></span></a></h3></li>
    </ul>
  </div>
</div>

<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8  ">
    <h3 class="center-title text-uppercase">{{'ServicesTitle-4' | translate}}</h3>
    <div class="title-block2"></div>
    <p class="text-justify" [innerHTML]="'ServicesText-4' | translate" ></p>
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4a' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4a' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1' | translate}}</li>
          <li>{{'ServicesTitleBullet4-2' | translate}}</li>
          <li>{{'ServicesTitleBullet4-3' | translate}}</li>
          <li>{{'ServicesTitleBullet4-4' | translate}}</li>
          <li>{{'ServicesTitleBullet4-5' | translate}}</li>
          <li>{{'ServicesTitleBullet4-6' | translate}}</li>
          <li>{{'ServicesTitleBullet4-7' | translate}}</li>
          <li>{{'ServicesTitleBullet4-8' | translate}}</li>
          <li>{{'ServicesTitleBullet4-9' | translate}}</li>
          <li>{{'ServicesTitleBullet4-10' | translate}}</li>
          <li>{{'ServicesTitleBullet4-11' | translate}}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4b' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4b' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1b' | translate}}</li>
          <li>{{'ServicesTitleBullet4-2b' | translate}}</li>
          <li>{{'ServicesTitleBullet4-3b' | translate}}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4c' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4c' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1c' | translate}}</li>
          <li>{{'ServicesTitleBullet4-2c' | translate}}</li>
          <li>{{'ServicesTitleBullet4-3c' | translate}}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4d' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4d' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1d' | translate}}</li>
          <li>{{'ServicesTitleBullet4-2d' | translate}}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4e' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4e' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1e' | translate}}</li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ServicesText-4f' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'ServicesText-4f' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ul class="border-list">
          <li>{{'ServicesTitleBullet4-1f' | translate}}</li>
          <li>{{'ServicesTitleBullet4-2f' | translate}}</li>
          <li>{{'ServicesTitleBullet4-3f' | translate}}</li>
          <li>{{'ServicesTitleBullet4-4f' | translate}}</li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
  <div class="col-lg-2 col-sm-3 mb-5">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
    <app-carousel class="photo-container my-auto" [slides]="slides3">
    </app-carousel>
  </div>
</div>
<div class="row justify-content-center mt-5">
  <div class="content-wrapper col-lg-5 col-sm-8  ">
    <h3 class="center-title text-uppercase">{{'ServicesTitle-2' | translate}}</h3>
    <div class="title-block2"></div>
    <p class="text-justify" [innerHTML]="'ServicesText-2' | translate" ></p>
    <ul class="border-list">
      <li>{{'ServicesTitleBullet2-1' | translate}}</li>
      <li>{{'ServicesTitleBullet2-2' | translate}}</li>
      <li>{{'ServicesTitleBullet2-3' | translate}}</li>
      <li>{{'ServicesTitleBullet2-4' | translate}}</li>
      <li>{{'ServicesTitleBullet2-5' | translate}}</li>
      <li>{{'ServicesTitleBullet2-6' | translate}}</li>
      <li>{{'ServicesTitleBullet2-7' | translate}}</li>
    </ul>
  </div>
  <div class="col-lg-2 col-sm-3 mb-5">
    <h3 class="text-center text-uppercase">{{'Photos' | translate}}</h3>
    <div class="title-block2"></div>
    <app-carousel class="photo-container my-auto" [slides]="slides4">
    </app-carousel>
  </div>
</div>



