import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class DataService {

  private _mapReady: Promise<typeof google>;
  private _inPrivateSection = new BehaviorSubject<boolean>(false);
  private _inInterActiveSection = new BehaviorSubject<boolean>(false);
  private _showQuickAccesses = new BehaviorSubject<boolean>(true);

  subscriptions = [];
  constructor(private localize:LocalizeRouterService, private router: Router, private authService: AuthService) {
    if (   this.router.url.includes(this.localize.translateRoute('/login') as string)
      || this.router.url.includes(this.localize.translateRoute('/client') as string)
      || this.router.url.includes(this.localize.translateRoute('/private') as string)
      || this.router.url.includes(this.localize.translateRoute('/chat') as string)
    ) {
      console.log('1 Se detecta alguien en seccion privada ' +this.router.url + " " + authService.currentUserValue?.fullName)
      this._inPrivateSection.next(true);
      this._showQuickAccesses.next(false);
    } else {
      console.log('1 Se detecta alguien en seccion publica ' +this.router.url + " " + authService.currentUserValue?.fullName)
      this._inPrivateSection.next(false);
      this._showQuickAccesses.next(true);
    }
    if(   this.router.url.includes(this.localize.translateRoute('/booking') as string)
      || this.router.url.includes(this.localize.translateRoute('/payment') as string)
      || this.router.url.includes(this.localize.translateRoute('/contact-us') as string)
    ) {
      this._inInterActiveSection.next(true);
    } else {
      this._inInterActiveSection.next(false);
    }
    this.subscriptions.push(this.router.events.subscribe((routeChange) => {
      this.sendUrl(this.router.url);
    }));

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  public sendUrl(url: string) {
    if (   this.router.url.includes(this.localize.translateRoute('/login') as string)
      || this.router.url.includes(this.localize.translateRoute('/client') as string)
      || this.router.url.includes(this.localize.translateRoute('/private') as string)
      || this.router.url.includes(this.localize.translateRoute('/chat') as string)
    ) {
      console.log('2 Se detecta alguien en seccion privada ' +url)
       this._inPrivateSection.next(true);
      this._showQuickAccesses.next(false);
    } else {
      console.log('2 Se detecta alguien en seccion publica ' +url)
      this._inPrivateSection.next(false);
      this._showQuickAccesses.next(true);
    }
    if(   this.router.url.includes(this.localize.translateRoute('/booking') as string)
      || this.router.url.includes(this.localize.translateRoute('/payment') as string)
      || this.router.url.includes(this.localize.translateRoute('/contact-us') as string)
    ) {
      this._inInterActiveSection.next(true);
    } else {
      this._inInterActiveSection.next(false);
    }
  }
  get mapReady(): Promise<typeof google> {
    return this._mapReady;
  }

  /**
   * se crea el promise de DataService va a compartir con los demas
   * @param value
   */
  set mapReady(value: Promise<typeof google>) {
    this._mapReady = value;
  }
  get inPrivateSection(): BehaviorSubject<boolean> {
    return this._inPrivateSection;
  }
  get inInterActiveSection(): BehaviorSubject<boolean> {
    return this._inInterActiveSection;
  }

  public get currentPrivateSeccionValue(): boolean {
    return this._inPrivateSection.value;
  }

  get showQuickAccesses(): BehaviorSubject<boolean> {
    return this._showQuickAccesses;
  }


}
