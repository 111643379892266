import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Airport, Booking, City, Coordinates, Country} from '../../../models/business-objects';
import {BookingService} from '../../services/booking.service';
import {MatDialog} from '@angular/material/dialog';
import {MapPopupComponent} from '../map-popup/map-popup.component';
import {LocationService} from '../../services/location.service';
import {DURATIONS, HOURS, TIMES} from '../../../models/constants';
import {isPlatformBrowser} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {firstValueFrom, Subscription} from 'rxjs';
import {ThemePalette} from '@angular/material/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-booking-widget',
  templateUrl: './booking-widget.component.html',
  styleUrls: ['./booking-widget.component.scss']
})
export class BookingWidgetComponent implements OnInit, OnDestroy , AfterViewInit {
  hours = HOURS;
  durations = DURATIONS;
  times = TIMES;
  zoom = 1;
  isAirportPickup = false;
  isMapApiLoaded?: boolean

  map: google.maps.Map;
  private subscriptions: Subscription[] = [];
  private geoCoder;
  @Input() insideStepper: boolean;
  @Input() format: string;
  @Input() cityInURL: string;
  @Input() clientCity: string;
  @Output() widgetEvent = new EventEmitter<boolean>();
  @ViewChild('searchPu')
  public searchPu: ElementRef;
  @ViewChild('pudate')
  public pudate: HTMLInputElement;
  @ViewChild('searchDo')
  public searchDo: ElementRef;
  bookingForm: UntypedFormGroup;
  readyForSubmission = false;
  required = 'This information is required';
  resultDisplay = 'none';
  resultText = '';
  resultClass = '';
  booking: Booking;
  mapPopupComponent = MapPopupComponent;
  submitted = false;
  autocompletePu: google.maps.places.Autocomplete = null;
  autocompleteDo: google.maps.places.Autocomplete = null;
  autocompleteListenerPu;
  autocompleteListenerDo;
  flightNumber: string;
  airportCode: string;
  hoy = new Date();
  mindate: NgbDateStruct;
  requestedCity: City;
  cities: City[];
  countries: Country[];
  allCities: City[];
  allAirports: Airport[];
  color: ThemePalette = 'primary';
  checked = false;
  puDisabled = false;
  maxRadiusForPuLocation = 100000; // 100 km
  maxRadiusForDoLocation = 800000; // 800 km
  @ViewChild('nextButton', {static: false}) nextButton: ElementRef;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  constructor(private router: Router, private formBuilder: UntypedFormBuilder, private bookingData: BookingService,
              public dialog: MatDialog, private ngZone: NgZone, private ref: ChangeDetectorRef, private dataService : DataService,
              private translate: TranslateService, private localize: LocalizeRouterService, private el: ElementRef,
              private locationService: LocationService, @Inject(PLATFORM_ID) private platformId: any) {
    this.insideStepper = false;
    this.translate.setDefaultLang('en');
    if ( this.booking == null) { this.booking = new Booking(); }
    this.createFormGroup(this.formBuilder);
    this.countries = [];
    this.cities = [];

   }
  ngAfterViewInit() {

  }
  async mapInitializer() {
    if (isPlatformBrowser(this.platformId)) {
      this.map = new google.maps.Map(this.gmap.nativeElement, null);
      console.log(this.map);
      this.geoCoder = new google.maps.Geocoder();
      console.log(this.geoCoder);
      this.createListenersOnPU();
      this.createListenersOnDO();
    }

  }

  async ngOnInit() {
    console.log('construyendo el booking widget con  cityInURL and clientCity : ' + this.cityInURL + " " + this.clientCity);
   await this.dataService.mapReady.then( () => {
      this.mapInitializer();
    })
    // load Places Autocomplete
    this.countries = await this.locationService.getCountries().then(data => {
      return data.sort((a, b) => a.name.localeCompare(b.name));
    }) ;
    this.allCities = await this.locationService.getCities().then(data => {
      return data.sort((a, b) => a.name.localeCompare(b.name));
    }) ;
    this.allAirports = await this.locationService.getAirports().then(data => {
      return data.sort((a, b) => a.name.localeCompare(b.name));
    }) ;
    const translations: Promise<any>[] = [];
    this.countries.forEach(c => {
      translations.push(firstValueFrom(this.translate.get('Country.' + c.name)).then(localName => {
        c.localName = localName != null ? localName : c.name;
      })
    );
    });
    this.allCities.forEach( c => {
      translations.push(firstValueFrom(this.translate.get('City.' + c.name)).then(localName => {
        c.localName = localName != null ? localName : c.name;
      }));
    });
    this.allAirports.forEach( a => {
      translations.push(firstValueFrom(this.translate.get('Airport.' + a.name)).then(localName => {
        a.localName = localName != null ? localName : a.name;
      }));
    });
    await Promise.all(translations).then((values => {
      this.countries = this.countries.sort((a, b) => a.localName.localeCompare(b.localName));
      this.allCities = this.allCities.sort((a, b) => a.localName.localeCompare(b.localName));
      this.allAirports = this.allAirports.sort((a, b) => a.localName.localeCompare(b.localName));
    }));
    this.readyForSubmission = false;
    console.log('insideStepper in NgInit : ');
    console.log(this.insideStepper);
    this.mindate = {year: new Date().getFullYear(), month: (new Date().getMonth() + 1), day: new Date().getDate()};
    if ((this.booking == null || this.booking.serviceCity == null) && this.cityInURL != null) {
      this.requestedCity = this.cities.find(a => a.link === this.cityInURL);
      this.addCountryRestictionsToListeners();
    }
    if ((this.booking == null || this.booking.serviceCity == null) && this.clientCity != null) {
      this.requestedCity = this.cities.find(a => a.name === this.clientCity);
      this.addCountryRestictionsToListeners();
    }
    this.subscriptions.push ( this.bookingData.currentBooking.subscribe( value => {
      console.log('New Booking received in widget');
      console.log(this.booking);
      this.booking = value;
      console.log('this.cityInURL ' + this.cityInURL);
      console.log('this.clientCity ' + this.clientCity);
      if (this.booking.serviceCity != null) {
        this.requestedCity = this.allCities.find(a => a.name === this.booking.serviceCity);
      } else if (this.cityInURL != null) {
        this.requestedCity = this.allCities.find(a => a.link === this.cityInURL);
        if ( this.requestedCity != null ) {
          this.booking.serviceCity = this.cityInURL;
        }
      } else if (this.clientCity != null) {
        this.requestedCity = this.allCities.find(a => a.name === this.clientCity);
        if ( this.requestedCity != null ) {
          this.booking.serviceCity = this.clientCity;
        }
      }
      this.addCountryRestictionsToListeners();
      console.log('this.booking.serviceCity ' + this.booking.serviceCity);
      if (this.booking.puGoogleLocation &&
        this.booking.puGoogleLocation.types.length > 0 &&
        this.booking.puGoogleLocation.types.includes('airport')) {
        this.isAirportPickup = true;
        this.flightNumber = this.booking.flightNumber;
        this.airportCode = this.booking.airportCode;
      } else {
        this.isAirportPickup = false;
      }
      if (this.booking.searchType === 'List') { this.checked = true; }
      this.createFormGroup(this.formBuilder);
      this.addBoundToGooglePUListener();
      Object.keys(this.bookingForm.controls).forEach(key => {
        this.bookingForm.get(key).updateValueAndValidity();
      });
      console.log('Booking received in widget');
      console.log(this.booking);
      if (this.bookingForm.status === 'VALID') {this.sendStepStatus(true); this.readyForSubmission = true; }
    }));
  }

  addCountryRestictionsToListeners() {
    if (this.autocompletePu == null || this.autocompleteDo == null ) {return; }
    if (this.requestedCity != null ) {
      //  si tenemos una ciudad selecionada se restringe por el pais de esta ciudad
      console.log('1 : autocompletePu.setComponentRestrictions ' + this.requestedCity.country.iso2.toLowerCase());
      this.autocompletePu.setComponentRestrictions({
        country: [this.requestedCity.country.iso2.toLowerCase()],
      });
      this.autocompleteDo.setComponentRestrictions({
        country: [this.requestedCity.country.iso2.toLowerCase()],
      });
    } else {
      const countryDropDown = this.bookingForm.controls.puCountry.value;
      if ( countryDropDown != null ) {
        // si tenemos un pais selecionado se restringe por este mismo
        const country: Country = this.countries.find(a => a.name === countryDropDown);
        if (country != null) {
          console.log('2 : autocompletePu.setComponentRestrictions ' + country.iso2.toLowerCase());
          this.autocompletePu.setComponentRestrictions({
            country: [country.iso2.toLowerCase()],
          });
          this.autocompleteDo.setComponentRestrictions({
            country: [country.iso2.toLowerCase()],
          });
        }else {
          console.log('3 : autocompletePu.setComponentRestrictions to null' );
          this.autocompletePu.setComponentRestrictions({
            country: [],
          });
          this.autocompleteDo.setComponentRestrictions({
            country: [],
          });
        }
      } else {
        console.log('4 : autocompletePu.setComponentRestrictions to null' );
        this.autocompletePu.setComponentRestrictions({
          country: [],
        });
        this.autocompleteDo.setComponentRestrictions({
          country: [],
        });
      }
    }
  }
  removeBoundsToListeners() {
    // como pasamos a busqueda por google quitamos el bound sobre el pickup
    if (this.autocompletePu != null ) {  this.autocompletePu.setOptions({strictBounds: false}); }
    // pero sobre el drop-off solo se quita si el pu no fue previamente selectionado y contiene
    // lo necesario para crear el bound
    if ( (this.booking.puCoordinates == null || this.booking.puCoordinates.lat == null || this.booking.puCoordinates.lng == null )
          && this.autocompleteDo != null)
    {
      // aca el pick up no tiene
      this.autocompleteDo.setOptions({strictBounds: false});
    }
  }
  addBoundToGooglePUListener(){
    if (this.autocompletePu != null) {
      if (this.requestedCity != null) {
        console.log('se crea el listener con un bound');
        console.log(this.requestedCity);
        const geolocation = new google.maps.LatLng(this.requestedCity.lat, this.requestedCity.lng);
        console.log(geolocation);
        const circle = new google.maps.Circle({center: geolocation, radius: this.maxRadiusForPuLocation});
        this.autocompletePu.setBounds(circle.getBounds());
        this.autocompletePu.setOptions({strictBounds: true});
      } else {
        this.autocompletePu.setOptions({strictBounds: false});
        console.log('NO se crea el listener con un bound');
      }
    } else {
      console.log('NO se crea el listener con un bound porque no se termino de inicializar el api de google....');
    }
  }
  addBoundToGoogleDOListener(){
    if (this.autocompleteDo != null) {
      if (this.booking.puCoordinates != null && this.booking.puCoordinates.lat != null && this.booking.puCoordinates.lng != null) {
        console.log('se crea el listener con un bound para DO al punto de inicio');
        console.log(this.booking.puCoordinates);
        const geolocation = new google.maps.LatLng(this.booking.puCoordinates.lat, this.booking.puCoordinates.lng);
        console.log(geolocation);
        const circle = new google.maps.Circle({center: geolocation, radius: this.maxRadiusForDoLocation});
        console.log(circle);
        this.autocompleteDo.setBounds(circle.getBounds());
        this.autocompleteDo.setOptions({strictBounds: true});
      } else if (this.requestedCity != null) {
        console.log('se crea el listener con un bound para DO a la ciudad de recogida');
        console.log(this.requestedCity);
        const geolocation = new google.maps.LatLng(this.requestedCity.lat, this.requestedCity.lng);
        console.log(geolocation);
        const circle = new google.maps.Circle({center: geolocation, radius: this.maxRadiusForDoLocation});
        this.autocompleteDo.setBounds(circle.getBounds());
        this.autocompleteDo.setOptions({strictBounds: true});
      } else {
        this.autocompleteDo.setOptions({strictBounds: false});
        console.log('NO se crea el listener con un bound');
      }
    } else {
      console.log('NO se crea el listener con un bound porque no se termino de inicializar el api de google....');
    }
  }
  createListenersOnPU(){
    if (this.searchPu === undefined) { return; }
    this.autocompletePu = new google.maps.places.Autocomplete(this.searchPu.nativeElement);
    this.autocompleteListenerPu = google.maps.event.addListener(this.autocompletePu, 'place_changed',
      () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = this.autocompletePu.getPlace();
          // verify result
          if (place.geometry == null) {
            return;
          }
          let addressFieldText = '' ;
          if (place.name) { addressFieldText = place.name; }
          if (place.formatted_address) { addressFieldText += ' ' + place.formatted_address; }
          console.log('addressFieldText ' + addressFieldText);
          this.booking.puAdress = addressFieldText;
          console.log(place);
          console.log(place.types);
          if ( place.types && place.types.length > 0 && place.types.includes('airport')) {
            console.log('si se detecta aeropuerto');
            this.isAirportPickup = true;
            this.bookingForm.addControl('flightNumber', new UntypedFormControl(this.booking.flightNumber, Validators.minLength(3)));
            this.subscriptions.push (this.bookingForm.controls.flightNumber.valueChanges.subscribe(value =>
              this.booking.flightNumber = value));
            this.booking.isAirportPickup = true;
          } else {
            console.log('no se detecta aeropuerto');
            this.isAirportPickup = false;
            if (this.bookingForm.controls.flightNumber) {  this.bookingForm.removeControl('flightNumber'); }
            this.booking.isAirportPickup = false;
          }
          this.booking.puGoogleLocation = place;
          this.ref.detectChanges();
          // this.bookingForm.controls.puAdress.disable({});
          if (place.geometry.location) {
            this.booking.puCoordinates = new Coordinates();
            this.booking.puCoordinates.lat = place.geometry.location.lat();
            this.booking.puCoordinates.lng = place.geometry.location.lng();
            this.addBoundToGoogleDOListener();
          }
          if (this.booking.puGoogleLocation && this.booking.puGoogleLocation.address_components) {
            const serviceCity = this.locationService.extractCityFromGooglePlace(this.booking.puGoogleLocation);
            const serviceCountry = this.locationService.extractCountryFromGooglePlace(this.booking.puGoogleLocation);
            const city = this.allCities.find( a => a.name === serviceCity);
            const country = this.countries.find( a => a.name === serviceCountry);
            if (city != null) { this.requestedCity = city; }
          }
          console.log(place);
          this.zoom = 16;
        });
      });
  }

  createListenersOnDO(){
    if (this.searchDo === undefined) { return; }
    this.autocompleteDo = new google.maps.places.Autocomplete(this.searchDo.nativeElement);
    // this.addGenericListener(this.autocompleteDo, 'doAddress');
    this.autocompleteListenerDo = google.maps.event.addListener(this.autocompleteDo, 'place_changed',
      () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = this.autocompleteDo.getPlace();
          // verify result
          if (place.geometry ==  null) {
            return;
          }
          let addressFieldText = '' ;
          if (place.name) { addressFieldText = place.name; }
          if (place.formatted_address) { addressFieldText += ' ' + place.formatted_address; }
          console.log('addressFieldText ' + addressFieldText);
          this.booking.doAdress = addressFieldText;
          console.log(place);
          console.log(place.types);
          this.booking.doGoogleLocation = place;
          this.ref.detectChanges();
          // this.bookingForm.controls.doAdress.disable({});
          if (place.geometry.location) {
            this.booking.doCoordinates = new Coordinates();
            this.booking.doCoordinates.lat = place.geometry.location.lat();
            this.booking.doCoordinates.lng = place.geometry.location.lng();
          }
          console.log(place);
          this.zoom = 16;
        });
      });
  }


  bookingTypeChange(type) {
    const serviceTypeHasChanged = this.booking.serviceType !== type;
    this.booking.serviceType = type;
    if (this.bookingForm == null || serviceTypeHasChanged) {
      console.log('se cambia el typo de servicio a ' + type);
      console.log('con ciudad : ' + this.booking.serviceCity);
      if (this.booking.serviceType === 'transfer') {
        console.log('Actualizando duracion a la de traslado y borrando paradas');
        this.booking.duration = 1;
        this.booking.stops = [];
      } else {
        this.booking.duration = null;
      }
    }
}


  createFormGroup(formBuilder: UntypedFormBuilder) {
    let serviceCountry: string;
    let serviceCity: string;
    let searchMode = true;
    if (this.requestedCity != null && this.requestedCity !== undefined){
      serviceCountry = this.requestedCity.country.name;
      serviceCity = this.requestedCity.name;
    }
    if (this.booking.searchType != null && this.booking.searchType === 'Google') {
      searchMode = false;
    }
    const form =  formBuilder.group({
      puCity: [serviceCity],
      puCountry: [serviceCountry],
      searchMode: [searchMode],
      puAdress: [this.booking.puAdress, Validators.required],
      doAdress: [this.booking.doAdress, this.requiredIfServiceIs( 'transfer')],
      puDate: [this.booking.puDate, Validators.required],
      puHour: [this.booking.puHour, Validators.required],
      puMin: [this.booking.puMin, Validators.required],
      duration: [this.booking.duration, this.requiredIfServiceIs( 'perHour')],
    });
    this.bookingForm = form;
    if (this.booking.flightNumber || (this.booking.puGoogleLocation && this.booking.puGoogleLocation.types
      && this.booking.puGoogleLocation.types.includes('airport') ) || this.booking.airportCode ) {
      this.isAirportPickup = true;
      this.bookingForm.addControl('flightNumber', new UntypedFormControl(this.booking.flightNumber, Validators.minLength(3)));
      this.subscriptions.push (this.bookingForm.controls.flightNumber.valueChanges.subscribe(value => this.booking.flightNumber = value));
    } else {
      this.isAirportPickup = false;
      if (this.bookingForm.controls.flightNumber) {  this.bookingForm.removeControl('flightNumber'); }
    }
    this.subscriptions.push (this.bookingForm.controls.puHour.valueChanges.subscribe(value => this.booking.puHour = value ));
    this.subscriptions.push (this.bookingForm.controls.puMin.valueChanges.subscribe(value => this.booking.puMin = value) );
    this.subscriptions.push ( this.bookingForm.controls.puDate.valueChanges.subscribe(value => {
      this.booking.puDate = value;
      console.log('fecha capturada : ');
      console.log(value);
      console.log(this.booking.puDate);
    }));
    this.subscriptions.push (this.bookingForm.controls.duration.valueChanges.subscribe(value => this.booking.duration = value));
    this.subscriptions.push (this.bookingForm.controls.searchMode.valueChanges.subscribe(value =>  {
      if (value) {
        // si el valor es true eso quiere decir que estam os en modo lista
        if (this.booking.searchType === 'Google') {
          // si anteriormente estabamos en modo google :
          // toca este test porque cuando se actualiza el booking se vuelve a pasar por aca
          this.booking.searchType = 'List';
          if (this.requestedCity != null) {
            this.puDisabled = false;
          } else {
            if (this.booking.serviceCity != null) {
              this.requestedCity = this.allCities.find(a => a.name === this.booking.serviceCity);
              if (this.requestedCity != null) {
                this.puDisabled = false;
              } else {
                this.puDisabled = true;
              }
            } else {
              this.puDisabled = true;
            }
          }
        }
      } else {
        if (this.booking.searchType === 'List') {
          // toca este test porque cuando se actualiza el booking se vuelve a pasar por aca
          this.booking.searchType = 'Google';
          this.requestedCity = null;
          this.bookingForm.controls.puCity.setValue(null);
          this.bookingForm.controls.puCountry.setValue(null);
          this.removeBoundsToListeners();
        }
      }
      this.addCountryRestictionsToListeners();
    }));
    this.subscriptions.push ( this.bookingForm.controls.puCountry.valueChanges.subscribe(data => {
      console.log('country selected');
      console.log(data);
      this.cities = this.allCities.filter((a) => a.country == null || a.country.name === data)
        .sort((a, b) => a.localName.localeCompare(b.localName));
      let oldCountry = '';
      if (this.requestedCity != null) {
        oldCountry = this.requestedCity.country.name;
      }
      const newCountry = this.allCities.find(a => a.name === data);
      if (newCountry != null && newCountry.name !== oldCountry) {
        this.clearGooglePuPlace();
        this.clearGoogleDoPlace();
      }
      if (this.cities == null || this.cities.length === 0 ) { this.cities = this.allCities; }
      // console.log('cities');
      // console.log(this.cities);
      this.addCountryRestictionsToListeners();
    }));
    this.subscriptions.push ( this.bookingForm.controls.puCity.valueChanges.subscribe(data => {
      console.log('city selected');
      console.log(data);
      let oldCity = '' ;
      if ( this.requestedCity != null) { oldCity = this.requestedCity.name ; }
      this.requestedCity = this.allCities.find( a => a.name === data);
      if (this.requestedCity != null && this.requestedCity.name !== oldCity) {
        // si la ciudad no cambio no hay que borrar todo
        // el problema aparece cuando cambiamos de tab al actualizar el objeto booking se dispara este evento
        // toca prevenir que se borren los datos de la etapa 1 cuando se publica un booking actualizado
        this.clearGooglePuPlace();
        this.bookingForm.controls.puAdress.setValue(null);
        this.clearGoogleDoPlace();
        this.bookingForm.controls.doAdress.setValue(null);
        const coordinates = new Coordinates();
        coordinates.lat = this.requestedCity.lat;
        coordinates.lng = this.requestedCity.lng;
        this.booking.puCoordinates = coordinates;
        this.booking.doCoordinates = coordinates;
      }
      if (this.requestedCity != null){
        this.puDisabled = false;
        this.booking.city = this.requestedCity;
      } else {
        this.booking.city = null;
        this.puDisabled = true;
      }
      if (this.bookingForm.controls.puCountry.value == null && this.requestedCity !== undefined) {
        this.bookingForm.controls.puCountry.setValue(this.requestedCity.country.name);
      }
      this.addBoundToGooglePUListener();
      this.addBoundToGoogleDOListener();
      // this.changeDetectorRef.detectChanges();
      console.log('celected city');
      console.log(this.requestedCity);
      this.addCountryRestictionsToListeners();
    }));
    this.subscriptions.push (this.bookingForm.statusChanges.subscribe(data => {
      // console.log('bookingForm statuschanged to ' + data);
      if (data === 'VALID') {
        this.sendStepStatus(true);
        this.readyForSubmission = true;
      } else {
        this.sendStepStatus(false);
        this.readyForSubmission = false;
      }
    } ) );
    if ( this.submitted === true ) { this.bookingForm.markAllAsTouched(); }
}

  requiredIfServiceIs( serviceType: string) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (this.booking.serviceType === serviceType)  {
        return Validators.required(formControl);
      }
      return null;
    });
  }

  clickSearchPu(){
    console.log('click input for pu');
    console.log(this.requestedCity);
    console.log(this.bookingForm.controls.searchMode.value);
    if ( this.bookingForm.controls.searchMode.value && this.requestedCity == null ) {
      this.resultText = 'BookingSteps-70';
      this.resultClass = 'alert alert-danger';
      this.puDisabled = true;
    } else {
      this.resultText = '';
      this.resultClass = '';
      this.puDisabled = false;
    }
  }
  clearGooglePuPlace(){
    // eso es necesario para  dar la oportunidad al usuario en entrar una direccion que no este en google.
    // sino cuando cambia la direccion la reserva se queda con la ultima ubicacion google selecccionada
    console.log('clearing google place for pu');
    // this.booking.puAdress = '';
    // this.bookingForm.controls.puAdress.setValue('');
    this.booking.puGoogleLocation = null;
    this.booking.puCoordinates = null;
    this.bookingForm.controls.puAdress.enable({});
    this.isAirportPickup = false;
    this.booking.isAirportPickup = false;
    if (this.bookingForm.controls.flightNumber) {  this.bookingForm.removeControl('flightNumber'); }
  }

  clearGoogleDoPlace(){
    // eso es necesario para  dar la oportunidad al usuario en entrar una direccion que no este en google.
    // sino cuando cambia la direccion la reserva se queda con la ultima ubicacion google selecccionada
    // this.booking.doAdress = '';
    // this.bookingForm.controls.doAdress.setValue('');
    this.booking.doGoogleLocation = null;
    this.booking.doCoordinates = null;
    this.bookingForm.controls.doAdress.enable({});
  }

  gotoBookingForm() {
    console.log('Going to booking');
    console.log(this.booking);
    this.beforeNextStep();
    this.bookingData.updateBooking(this.booking);
    this.insideStepper = true;
    console.log('Going to booking Ya !');
    this.router.navigate([this.localize.translateRoute('/booking')]);
  }
  sendStepStatus(value) {
    this.widgetEvent.emit(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  openPickupMap() {
    if (this.booking.puCoordinates != null && this.booking.puCoordinates.lng != null && this.booking.puCoordinates.lat != null) {
      const mapPopupPickup = this.dialog.open(this.mapPopupComponent);
      this.subscriptions.push (mapPopupPickup.afterClosed().subscribe(result => {
        if (result) {
          console.log(result);
          if (this.booking.puCoordinates.lat !== result.lat || this.booking.puCoordinates.lng !== result.lng) {
            console.log('se detecto un cambio en el punto del mapa');
            this.reverseGeocode(result.lat, result.lng).then(data => {
              const location: google.maps.places.PlaceResult = JSON.parse(data);
              this.booking.puAdress = location.formatted_address;
              this.booking.puGoogleLocation = location;
              this.bookingForm.get('puAdress').setValue(this.booking.puAdress);
              console.log('updated address ' + this.booking.puAdress);
            }, error => {
              console.log('error updating address ' + error);
            });
          }
          this.booking.puCoordinates = new Coordinates();
          this.booking.puCoordinates.lat = result.lat;
          this.booking.puCoordinates.lng = result.lng;
        }
      }));
      mapPopupPickup.componentInstance.lat = this.booking.puCoordinates.lat;
      mapPopupPickup.componentInstance.lng = this.booking.puCoordinates.lng;
      firstValueFrom(this.translate.get('BookingSteps-43')).then(title => {
        mapPopupPickup.componentInstance.title = title;
      });
    }
  }
  openDropOffMap() {
    if (this.booking.doCoordinates != null && this.booking.doCoordinates.lng != null && this.booking.doCoordinates.lat != null ) {
      const mapPopupDropoff = this.dialog.open(this.mapPopupComponent);
      this.subscriptions.push (mapPopupDropoff.afterClosed().subscribe(result => {
        if (result) {
          console.log(result);
          if (this.booking.doCoordinates.lat !== result.lat || this.booking.doCoordinates.lng !== result.lng) {
            console.log('se detecto un cambio en el punto del mapa');
            this.reverseGeocode(result.lat, result.lng).then(data => {
              const location: google.maps.places.PlaceResult = JSON.parse(data);
              this.booking.doAdress = location.formatted_address;
              this.booking.doGoogleLocation = location;
              this.bookingForm.get('doAdress').setValue(this.booking.doAdress);
              console.log('updated address ' + this.booking.doAdress);
            }, error => {
              console.log('error updating address ' + error);
            });
          }
          this.booking.doCoordinates = new Coordinates();
          this.booking.doCoordinates.lat = result.lat;
          this.booking.doCoordinates.lng = result.lng;
        }
      }));
      mapPopupDropoff.componentInstance.lat = this.booking.doCoordinates.lat;
      mapPopupDropoff.componentInstance.lng = this.booking.doCoordinates.lng;
      firstValueFrom(this.translate.get('BookingSteps-44')).then(title => {
        mapPopupDropoff.componentInstance.title = title;
      });
    }
  }

  beforeNextStep() {
    console.log('beforeNextStep');
    this.submitted = true;
    this.bookingForm.markAllAsTouched();
    console.log(this.booking.flightNumber);
    console.log(this.booking.isAirportPickup);
    console.log(this.requestedCity);
    console.log(this.booking.serviceType);
    console.log(this.requestedCity);
    if ( this.requestedCity == null ) {
      if (this.booking.puGoogleLocation && this.booking.puGoogleLocation.address_components) {
        this.booking.serviceCity = this.locationService.extractCityFromGooglePlace(this.booking.puGoogleLocation);
        this.booking.serviceCountry = this.locationService.extractCountryFromGooglePlace(this.booking.puGoogleLocation);
      }
    } else {
      this.booking.serviceCity = this.requestedCity.name;
      this.booking.serviceCountry = this.requestedCity.country.name;
      this.booking.city = this.requestedCity;
    }
    if (this.booking.serviceType === 'transfer') {
      console.log('Actualizando duracion a la de traslado');
      this.booking.duration = 1;
    }
    // esto abajo es para estar seguro que lo que esta en el campo de direcciones se coloca bien en la reserva si el usuarion no eligio
    // un elemento de google places
    if ( this.booking.puGoogleLocation == null) { this.booking.puAdress = this.bookingForm.controls.puAdress.value; }
    if ( this.booking.doGoogleLocation == null) { this.booking.doAdress = this.bookingForm.controls.doAdress.value; }
    this.booking.discountCode = '';
    this.bookingData.updateBooking(this.booking);
    if (this.insideStepper && this.bookingForm.valid) {
      this.nextButton.nativeElement.click();
    }
  }
  submit() {
    this.submitted = true;
  }

  reverseGeocode( lat: number, lng: number): Promise<string> {
      const latlng = new google.maps.LatLng(lat, lng);
      let address = '';
      return new Promise((data, error) => {
        this.geoCoder.geocode({location: latlng}, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              address = results[0].formatted_address;
              console.log('found address doing reverse geocoding' + address);
              data(JSON.stringify(results[0]));
            } else {
              error('No results found');
            }
          } else {
            error('Geocoder failed due to: ' + status);
          }
        });
      });
  }
  newBooking() {
    this.bookingData.newBooking(this.booking);
  }
  async localNameExtract(prefix: string, suffix: string) {
    await firstValueFrom(this.translate.get(prefix + '.' + suffix));
  }
}

