import {EventEmitter, Injectable} from '@angular/core';


@Injectable()
export class LoaderService {
  public loadingUpdated :EventEmitter<boolean> = new EventEmitter();


  constructor() { }
  setLoading(loading: boolean) {
    this.loadingUpdated.emit(loading);
  }

  getLoading(): EventEmitter<boolean> {
    return this.loadingUpdated;
  }
}
