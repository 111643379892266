<div id="page-header">
    <h1>{{"Contact Us" | translate }}</h1>
    <div class="title-block3"></div>
    <p>
      <a [routerLink]="'/home' | localize" title="{{'Contact Us' | translate }}">{{"Home" | translate }} Fleet</a>
      <i class="fa fa-angle-right"></i>{{'Contact Us' | translate }}
    </p>
</div>
  <div class="content-wrapper">
    <div class="row justify-content-center main-content main-content-full">
        <div class="col-lg-7 col-md-8">
          <h3>{{'ContactUs-3' | translate}}</h3>
          <div class="title-block3"></div>
          <form (ngSubmit)="onSubmit()" id="contactForm" [formGroup]="messageForm"  *ngIf="messageForm">
            <div [formGroup]="personalData" formGroupName="personalData" class="form-group mb-5">
            <div class="form-row">
              <div class="col-lg-6">
                <div class="input-group">
                  <span class="input-group-text">{{'FirstName' | translate}}</span>
                  <input id="firstName" type="text" class="form-control" formControlName="firstName"
                         [ngClass]="{ 'is-invalid': personalData.get('firstName').invalid  && submitted }"
                         [ngClass]="{ 'is-valid': personalData.get('firstName').valid  && submitted }"/>
                  <div class="invalid-feedback">
                    {{'ContactFormError-3' | translate}}
                  </div>
                </div>
              </div>
              <div class="col-lg-6" >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{'LastName' | translate}}</span>
                  </div>
                  <input id="lastName" type="text" class="form-control" formControlName="lastName"
                         [ngClass]="{ 'is-invalid': personalData.get('lastName').invalid  && submitted }"
                         [ngClass]="{ 'is-valid': personalData.get('lastName').valid  && submitted }"/>
                  <div class="invalid-feedback">
                    {{'ContactFormError-3' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" >
                <div class="col-lg-6">
                  <div class="input-group">
                    <span class="input-group-text">{{'PhoneNumber' | translate}}</span>
                    <ngx-intl-tel-input
                      style=" color: #000000"
                      [cssClass]="'custom'"
                      [preferredCountries]="[ CountryISO.Colombia, CountryISO.Brazil, CountryISO.France, CountryISO.Germany, CountryISO.UnitedStates]"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.Colombia"
                      [maxLength]="17"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      [id]="'phone2'"
                      name="phone"
                      formControlName="phone"
                    ></ngx-intl-tel-input>
                    <div class="invalid-feedback">
                      {{'ContactFormError-3' | translate}}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6" >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">{{'CellNumber' | translate}}</span>
                    </div>
                    <ngx-intl-tel-input
                      style=" color: #000000"
                      [cssClass]="'custom'"
                      [preferredCountries]="[ CountryISO.Colombia, CountryISO.Brazil, CountryISO.France, CountryISO.Germany, CountryISO.UnitedStates]"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.Colombia"
                      [maxLength]="17"
                      [phoneValidation]="true"
                      [separateDialCode]="true"
                      [id]="'phone1'"
                      name="cellPhone"
                      formControlName="cellPhone"
                    ></ngx-intl-tel-input>
                    <div class="invalid-feedback">
                      {{'ContactFormError-3' | translate}}
                    </div>
                  </div>
                </div>
            </div>
            <div class="form-row" >
                <div class="col-lg-6" >
                  <div class="input-group">
                    <span class="input-group-text">{{'Email' | translate}}</span>
                    <input type="text"  class="required-form-field form-control" value="" formControlName="email"
                           [ngClass]="{ 'is-invalid': personalData.get('email').invalid  && submitted }"
                           [ngClass]="{ 'is-valid': personalData.get('email').valid  && submitted }"/>
                    <div class="invalid-feedback">
                      {{'ContactFormError-4' | translate}}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">{{'Company' | translate}}</span>
                    </div>
                    <input type="text"  class="required-form-field form-control" value="" formControlName="company"
                           [ngClass]="{ 'is-invalid': personalData.get('company').invalid  && submitted }"
                           [ngClass]="{ 'is-valid': personalData.get('company').valid  && submitted }"/>
                    <div class="invalid-feedback">
                      {{'ContactFormError-3' | translate}}
                    </div>
                  </div>
                </div>
            </div>
            </div>
            <div class="form-row" >
              <div class="input-group col-lg-6" [ngClass]="{ 'is-invalid':  messageForm.controls['rating'].invalid   && submitted }">
                <app-rating-widget [rating]='rating'  (ratingClick)='ratingComponentClick($event)' ></app-rating-widget>
                <input type="hidden"  formControlName="rating">
              </div>
            </div>
            <div class="form-row" >
              <div class="input-group" >
                  <label class="input-group-text">{{'Subject' | translate}}</label>
                  <input type="text" value="" class="form-control"
                         formControlName="subject"
                         [ngClass]="{ 'is-invalid': messageForm.get('subject').invalid  && submitted }"
                         [ngClass]="{ 'is-valid': messageForm.get('subject').valid  && submitted }"/>
                  <div class="invalid-feedback">
                    {{'ContactFormError-3' | translate}}
                  </div>
              </div>
            </div>
            <div class="form-row" >
              <div class="input-group" >
                  <label class="input-group-text">{{'Message' | translate}}</label>
                  <textarea cols="10" rows="9" class="form-control" formControlName="content"
                            [ngClass]="{ 'is-invalid': messageForm.get('content').invalid  && submitted }"
                            [ngClass]="{ 'is-valid': messageForm.get('content').valid  && submitted }"></textarea>
                  <div class="invalid-feedback">
                    {{'ContactFormError-3' | translate}}
                  </div>
              </div>
            </div>
            <ngx-recaptcha2 #captchaElem *ngIf="onBrowser"
                            [siteKey]="'6Lfyz9cZAAAAAIsCRRqGPxEqP285ohFgUvqoyhVC'"
                            (reset)="handleReset()"
                            (expire)="handleExpire()"
                            (load)="handleLoad()"
                            (success)="handleSuccess($event)"
                            [useGlobalDomain]="false"
                            [size]="'normal'"
                            [hl]="lang"
                            [theme]="'light'"
                            [type]="type"
                            formControlName="recaptcha">
            </ngx-recaptcha2>
            <button type="submit" class="sendemail form-control mt-2" style="color: black">
              {{'Send' | translate}} <i class="fa fa-envelope ms-3"></i>
              <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
            </button>
          </form>
          <div [ngClass]="resultClass" [ngStyle]="{'display': resultDisplay}" class="col-sm-12 mt-3 mb-3" id="result">
              <p style="padding: 15px; margin: 0; font-size: large" [innerHTML]="resultText"></p>
          </div>
        <!-- END .qns-one-half -->
        </div>

        <!-- BEGIN .qns-one-half -->
        <div class="col-lg-3 col-md-4">
            <h3>{{'ContactUs-0' | translate}}</h3>
            <div class="title-block3"></div>

            <p class="text-justify">{{'ContactUs-1' | translate}}</p>
            <p class="text-justify mb-5"> {{'ContactUs-2' | translate}}</p>
          <h3>{{'ContactDetails' | translate}}</h3>
          <div class="title-block3"></div>

          <ul class="contact-details-list mb-5">
            <li class="cdw-address clearfix">Carrera 23 # 68-22 - Bogota - Colombia</li>
            <li class="cdw-phone clearfix"> <a href="tel:+18883685236" title="phone">+1-888-368-5236</a></li>
            <li class="cdw-phone clearfix"> <a href="tel:+576012114949" title="phone">+57-601-211-49-49</a></li>
            <li class="cdw-phone clearfix"> <a href="tel:+576017389641" title="phone">+57-601-738-9641</a></li>
            <li class="cdw-email clearfix"> <a href="mailto:info@vehiculosvip.com" title="email">info&#64;vehiculosvip.com</a></li>
          </ul>

          <h3>{{'Social Media' | translate}}</h3>
          <div class="title-block3"></div>

          <ul class="social-links clearfix">
            <li><a href="https://www.facebook.com/VandVcarservice/" target="_blank" title="Facebook">
              <i class="fab fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/vehiculosvipcarservice/" target="_blank" title="Instagram">
              <i class="fab fa-instagram"></i></a></li>
            <li><a href="https://twitter.com/vehiculosvip" target="_blank" title="Twitter">
              <i class="fab fa-twitter"> </i></a></li>
            <li><a href="https://in.linkedin.com/company/v&v-bogot---veh-culos-vip" target="_blank" title="LinkedIn">
              <i class="fab fa-linkedin"></i></a></li>
          </ul>

          <hr class="space5" />

    <!-- END .main-content -->
    </div>

  <!-- END .content-wrapper-outer -->
  </div>
  </div>
