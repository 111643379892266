<div id="bio-header">
  <h1>{{'OnBoard' | translate}}</h1>
  <div class="title-block3"></div>
  <p><a [routerLink]="'/home' | localize" title="{{'Home' | translate}}">{{'Home' | translate}}</a>
    <i class="fa fa-angle-right"></i>{{'OnBoard' | translate}}</p>
</div>
<div class="row justify-content-center">
  <div class="content-wrapper col-lg-5 col-sm-8 ms-1 me-1">
    <h3 class="center-title text-uppercase">{{'OnBoard' | translate}}</h3>
    <div class="title-block2"></div>
  </div>
</div>
<div class="row m-0 justify-content-center align-content-center">
  <ng-container *ngIf="translate.currentLang !== 'es' ">
    <object data="../../assets/pdf/OnBoard.pdf"
            type="application/pdf"
            class="pdf-container"
            scrolling="no"
    >
      It appears you don't have a PDF plugin for this browser.
      Click here to download the PDF file.
      <a href="../../assets/pdf/OnBoard.pdf" target="_blank">OnBoard.pdf</a>
    </object>
  </ng-container>
  <ng-container *ngIf="translate.currentLang === 'es' ">
    <object data="../../assets/pdf/ABordo.pdf"
            type="application/pdf"
            class="pdf-container"
            scrolling="no"
    >
      It appears you don't have a PDF plugin for this browser.
      Click here to download the PDF file.
      <a href="../../assets/pdf/ABordo.pdf" target="_blank">ABordo.pdf</a>
    </object>
  </ng-container>
</div>
