import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MessageData} from '../../../models/contact-form';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {MessageService} from '../../services/message.service';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, firstValueFrom, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {CanonicalAndMetaService} from '../../services/canonical-and-meta.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  email = '';
  resultDisplay = 'none';
  resultText = '';
  resultClass = '';
  aFormGroup: UntypedFormGroup;
  lang = '';
  type: string;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  loading: Subject<boolean> = new Subject();
  subscriptions = [];
  inPrivateSection = new BehaviorSubject<boolean>(false);
  constructor(public snackBar: MatSnackBar, public _MessageService: MessageService,private canonicalAndMetaService: CanonicalAndMetaService,
              private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef,
              private translate: TranslateService,
              private router: Router, private authService: AuthService, private dataService:DataService,
              private localize: LocalizeRouterService, @Inject(PLATFORM_ID) private platformId: any) {
    this.type = 'image' ;
    this.inPrivateSection = this.dataService.inPrivateSection;
    if (   this.router.url.includes(this.localize.translateRoute('/dispatch') as string)
      || this.router.url.includes(this.localize.translateRoute('/client') as string)
      || this.router.url.includes(this.localize.translateRoute('/private') as string)
      || this.router.url.includes(this.localize.translateRoute('/chat') as string)
    ) {
      console.log('Nav detecta alguien en seccion privada ' +this.router.url)
      this.inPrivateSection.next(true);
    } else {
      console.log('Nav detecta alguien en seccion publica ' +this.router.url)
      this.inPrivateSection.next(false);
    }
  }
  onBrowser = isPlatformBrowser(this.platformId);
  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
  sendNewsLetterRequest(){
    this.loading.next(true);
    if (this.validateEmail(this.email)){
      if (!this.aFormGroup.valid) {
        this.resultDisplay = 'block';
        firstValueFrom(this.translate.get('RecaptchaError-1')).then( error => {
          this.resultText = error;
        });
        this.resultClass = 'bg-danger';
        this.loading.next(false);
        return;
      }
      const messageData: MessageData = new MessageData();
      messageData.recaptchaMessage = this.captchaResponse;
      messageData.apiKey = environment.apiKey;
      messageData.from = '"Pagina Web V&V" <herramienta@vehiculosvip.com>';
      messageData.to = '"Todo V&V" <info@vehiculosvip.com>';
      messageData.subject = 'Solicitud de inscripcion al boletin de V&V';
      messageData.html = 'Nos enviaron esta direccion de correo electronico: ' + this.email + ' por medio de la pagina web solicitando estar inscrito a ' +
        ' nuestro bolletin de information y redes, por favor procesar esta solicitud.';
      this.subscriptions.push (this._MessageService.sendMessage(messageData).subscribe(
        res => {
          console.log('res.body response : ' + res.body);
          this.resultDisplay = 'block';
          firstValueFrom(this.translate.get('ContactFormSuccess-4')).then(text => {
            this.snackBar.open(text, '', {
              duration: 5000
            });
          });
          this.resultDisplay = 'block';
          firstValueFrom(this.translate.get('ContactFormSuccess-3')).then(text => {
            this.resultText = text;
          });
          this.resultClass = 'bg-info';
          this.loading.next(false);
        },
        (err: HttpErrorResponse) => {
          console.log('error: ' + err);
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.message);
            this.snackBar.open('ERROR Sending Message (001)...', '', {
              duration: 5000
            });
            this.resultDisplay = 'block';
            firstValueFrom(this.translate.get('ContactFormError-2')).then(error => {
              this.resultText = error;
            });
            this.resultClass = 'bg-danger';
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
            this.snackBar.open('ERROR Sending Message (002)...', '', {
              duration: 5000
            });
            this.resultDisplay = 'block';
            firstValueFrom(this.translate.get('ContactFormError-1')).then(error => {
              this.resultText = error;
            });
            this.resultClass = 'bg-danger';
          }
          this.loading.next(false);
        }
      ));
  } else {
      this.resultDisplay = 'block';
      firstValueFrom(this.translate.get('ForgotPassword-8')).then( error => {
        this.resultText = error;
      });
      this.resultClass = 'bg-danger';
      this.loading.next(false);
    }
}
  validateEmail(email)
  {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleReset(){
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleExpire(){
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  handleLoad(){
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleSuccess(captchaResponse : string){
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
}
