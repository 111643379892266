<!--Forgot Password Starts-->
<section id="forgot-password" *ngIf="!userId && !token">
    <div class="row auth-height full-height m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card overflow-hidden mt-5 mb-5">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="../../../assets/img/gallery/forgot_password.png" alt="{{'ForgotPassword-1' | translate}}" class="img-fluid" width="260" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <h4 class="mb-2 card-title">{{'ForgotPassword-1' | translate}}</h4>
                <p class="card-text mb-3">{{'ForgotPassword-12' | translate}}</p>
                <input type="email" class="form-control mb-3" placeholder="Email" [(ngModel)]="email" required>
                <div class="d-flex flex-sm-row flex-column justify-content-start">
                  <a [routerLink]="['/login' | localize]" class="btn bg-light-primary mb-2 mb-sm-0">{{'ForgotPassword-3' | translate}}</a>
                  <button [disabled]="loading | async" class="btn btn-primary ms-3 ml-sm-1" (click)="requestResetPassword()">
                    {{'ForgotPassword-4' | translate}}
                    <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
                  </button>
                </div>
                <div class="{{resultClass}} mt-3">
                  {{resultString}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->
<!--Reset Password Starts-->
<section id="reset-password" *ngIf="userId && token">
  <form (ngSubmit)="resetPassword()" id="resetPw" [formGroup]="changePwForm"  *ngIf="changePwForm">
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-12">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="../../../assets/img/gallery/forgot.png" alt="{{'ForgotPassword-1' | translate}}" class="img-fluid" width="260" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <h4 class="mb-2 card-title">{{'ForgotPassword-13' | translate}}</h4>
                <p class="card-text mb-3">{{'ForgotPassword-14' | translate}}</p>
                <div class="input-group" >
                  <input type="password" class="form-control mb-3" formControlName="password" placeholder="{{'ForgotPassword-16' | translate}}">
                    <div class="invalid-feedback">
                      {{'ForgotPassword-17' | translate}}
                    </div>
                </div>
                <div class="input-group" >
                  <input type="password" class="form-control mb-3" formControlName="confirmPassword" placeholder="{{'ForgotPassword-16' | translate}}">
                  <div class="invalid-feedback">
                    {{'ForgotPassword-10' | translate}}
                  </div>
                </div>
                <div class="d-flex flex-sm-row flex-column justify-content-between">
                  <button [disabled]="loading | async" class="btn btn-primary ml-sm-1" type="submit">
                    {{'ForgotPassword-15' | translate}}
                    <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
                  </button>
                </div>
                <div class="{{resultClass}} mt-3">
                  {{resultString}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </form>
</section>
<!--Reset Password ends-->
<!--Change Password Starts-->
<section id="change-password" *ngIf="userId && !token">
  <form (ngSubmit)="changePassword()" id="changePw" [formGroup]="changePwForm"  *ngIf="changePwForm">
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-12">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="../../../assets/img/gallery/forgot.png" alt="Forgot Password" class="img-fluid" width="260" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <h4 class="mb-2 card-title">{{'ForgotPassword-13' | translate}}</h4>
                <p class="card-text mb-3">{{'ForgotPassword-18' | translate}}</p>
                <div class="input-group" >
                  <input type="password" class="form-control mb-3" formControlName="originalPassword" placeholder="{{'ForgotPassword-18' | translate}}">
                </div>
                <p class="card-text mb-3">{{'ForgotPassword-14' | translate}}</p>
                <div class="input-group" >
                  <input type="password" class="form-control mb-3" formControlName="password" placeholder="{{'ForgotPassword-16' | translate}}">
                  <div class="invalid-feedback">
                    {{'ForgotPassword-17' | translate}}
                  </div>
                </div>
                <div class="input-group" >
                  <input type="password" class="form-control mb-3" formControlName="confirmPassword" placeholder="{{'ForgotPassword-16' | translate}}">
                  <div class="invalid-feedback">
                    {{'ForgotPassword-10' | translate}}
                  </div>
                </div>
                <div class="d-flex flex-sm-row flex-column justify-content-start">
                  <button class="btn btn-primary ml-sm-1" [disabled]="loading | async" type="submit">
                    {{'ForgotPassword-15' | translate}}
                    <i *ngIf="loading | async" class="fas fa-spinner fa-pulse ms-3"></i>
                  </button>
                </div>
                <div class="{{resultClass}} mt-3">
                  {{resultString}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </form>
</section>
<!--Change Password Ends-->

