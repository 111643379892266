import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {DISPATCH_MENU, PUBLIC_MENU} from './menu-routes.config';
import {LayoutService} from '../../services/interface/layout.service';
import {ConfigService} from '../../services/interface/config.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {RouteInfo} from './menu.metadata';
import {DataService} from '../../services/data.service';
import {User} from '../../../models/business-objects';


@Component({
  selector: 'app-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: RouteInfo[];
  public config: any = {};
  level = 0;
  transparentBGClass = '';
  menuPosition = 'Top';
  subscriptions = [];
  loggedUser: User = null;
  inPrivateSeccion: boolean = false;
  constructor(private layoutService: LayoutService,
              private configService: ConfigService, private dataService: DataService,
              private cdr: ChangeDetectorRef, private authService: AuthService,
              private router: Router) {

    this.subscriptions.push (this.dataService.inPrivateSection.subscribe(inPrivateSeccion => {
      this.inPrivateSeccion = inPrivateSeccion;
      this.changeMenu()
    }));
    this.subscriptions.push (this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
      this.changeMenu()
    }));
    this.loggedUser = this.authService.currentUserValue;
    this.inPrivateSeccion = this.dataService.currentPrivateSeccionValue;
    this.config = this.configService.templateConf;
    this.changeMenu()
  }

  ngOnInit() {
  }

  changeMenu (){
    if (this.inPrivateSeccion) {
      this.menuItems = this.layoutService.createMenuItems(this.loggedUser , DISPATCH_MENU);
    } else {
      this.menuItems = this.layoutService.createMenuItems(this.loggedUser , PUBLIC_MENU) ;
    }
  }

  ngAfterViewInit() {
    this.subscriptions.push (this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
      console.log('menuPosition changed to' + this.config.layout.menuPosition);
      console.log('navbar type changed to' + this.config.layout.navbar.type);
    }));
  }

  loadLayout() {
    console.log('loading Layout');
    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }
    console.log('menuPosition' + this.menuPosition);

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = '';
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
