import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()

export class SurveyService {
  UrlAPI: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  SurveyQuestions(data) {
    return this.http.post<any>(this.UrlAPI + environment.URL_SURVEY_QUESTIONS, data, httpOptions);
  }

  Survey(envioID: string) {
    return this.http.get(this.UrlAPI + environment.URL_SURVEY + '/'+ envioID);
  }

  SurveyAnswer(data, envioID: string) {
    return this.http.post<any>(this.UrlAPI + environment.URL_SURVEY_RESEPONSE, data, httpOptions);
  }
}
